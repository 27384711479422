import TOp from './Top/Main';
import MiddlE from './Middle/Main';
import FooTeR from './Footer/Main';
import './Head.css';
import Media from 'react-media';




// Main Home App
export default function HeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='grid-templates-rows'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-templates-rows-sm'>
  <View />
 </div>
);
export const View = () => (

 <div
  style={{
   backgroundImage: `url('/img/weiiewks.jpg')`,
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',
   textAlign: 'center',
   width: '100%',
   height: '100vh',

  }}>

  <div style={{ backgroundColor: '#000', height: '100vh', opacity: 0.7 }}></div>

  <div style={{ position: 'absolute', top: 0, left: '0', right: '0' }}>
   <TOp />
   <MiddlE />
   <FooTeR />
  </div>

 </div>
);