import React from 'react';
import './IMA.css';
import { FcKey } from 'react-icons/fc';
import { TweenMax, Expo } from 'gsap';
import { RiCheckboxBlankCircleFill } from "react-icons/ri";

// View Image Key
export default function ReturnKey() {

 React.useEffect(() => {
  TweenMax.from('.Anima', 1.8, { delay: .2, opacity: 0, y: -30, ease: Expo.easeInOut })

 }, []);

 return (
  <>
   <div className=' wrp-ima-key-now Anima'>
    <FcKey size={'2.5em'} />

    <div style={{ marginTop: '5vh' }} className='flex-id-pin-send'>
     <p>Transaction prêt</p>
     <RiCheckboxBlankCircleFill color='#09bc8a' size={'1.5em'} />
    </div>

   </div>
  </>
 );
}