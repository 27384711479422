
import './Title.css';


// View Title COmponent
export default function ReturnTitle() {
 return (
  <div className='title-stock-input'>

   <h1>Produit</h1>
   <h1>Quantite</h1>
   <h1>Prix Total</h1>

  </div>
 );
}