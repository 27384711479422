import ReturnNavStocK from './Nav';
import ReturnBacK from './Back';

// View Nav component 
export default function NavStocK() {
 return (
  <>
   <ReturnBacK />
   <ReturnNavStocK />
  </>
 );
}