
import './Title.css';

// Title Head Creditaire
export default function RetuRnTitle() {
 return (
  <div className='wrp-title-solde-credit'>
   <h1>Balance Créditeur</h1>

  </div>
 );
};