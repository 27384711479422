import React from 'react';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { collection, getDocs, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";
import dayjs from 'dayjs';




let pushDocs = new Array();


let idemSix = ['111111', '222222', '333333', '444444', '555555', '666666', '777777', '888888', '999999']
let idemFive = ['11111', '22222', '33333', '44444', '55555', '66666', '77777', '88888', '99999']
let idemFour = ['1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999']


// View Form Update view
export default function ReturnFormUpdate() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);
export const FormInputField = () => {

 const navigation = useNavigate();
 const { handleSubmit, control, reset, watch } = useForm({});

 const [open, setOpen] = React.useState(false);
 const [exist, setExist] = React.useState(false);


 const [load, setLoad] = React.useState(false);
 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [firstChart, setFirstChart] = React.useState(false);
 const [checked, setChecked] = React.useState(false);
 const [showPassword, setShowPassword] = React.useState(false);

 const [access, setAccess] = React.useState('');


 const currentDate = dayjs();
 const nextMonth = currentDate.add(1, 'month');


 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleExist = () => {
  setExist(false);
 };

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   pushDocs.push(doc.id);
  });

  const unsub = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setAccess(doc.data().pin);
  });
 }, []);
 React.useEffect(() => {

  const subscription = watch((data) => {

   if (/^\d+$/.test(`${data.first}`) && data.first.length <= 6) {
    setFirstChart(false);
   } else {
    setFirstChart(true);
   }

   if (data.first != data.second) {
    setChecked(true);
   } else {
    setChecked(false);
   }

  });

  return () => {
   subscription.unsubscribe();
  }

 }, [watch]);



 const onSubmitPwd = async (data) => {

  setLoad(true);

  if (data.first != data.second || data.first == '123456' || data.first == '12345' || data.first == '1234' || !(/^\d+$/.test(`${data.first}`)) || data.first.length > 6 || data.first.length < 4 || idemSix.includes(data.first) || idemFive.includes(data.first) || idemFour.includes(data.first)) {

   setOpen(true);
   setLoad(false);
   reset();

  } else {

   if (access === data.first) {

    window.setTimeout(() => {

     setExist(true);
     setLoad(false);

    }, 850);

   } else if (access === data.first) {

    window.setTimeout(() => {

     setExist(true);
     setLoad(false);

    }, 850);

   } else {


    const verifierCollection = pushDocs.some((value) => value == secureLocalStorage.getItem("USER"));
    updatePinInWithDocs(verifierCollection, data.first, secureLocalStorage.getItem("USER"), nextMonth);
    secureLocalStorage.removeItem('updateaccescode');

    window.setTimeout(() => {
     navigation('/pin/success');
    }, 3450);


   }

  };

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>
     <CircularProgress color="inherit" />

    </Backdrop>
   </div>

   <form
    autoComplete='off'
    onSubmit={handleSubmit(onSubmitPwd)}>

    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>

     <FormControl
      sx={{ width: '100%' }}

      variant="standard">
      <InputLabel htmlFor="standard-adornment-password pop-up"><span className='pop-up'>Nouveau code</span></InputLabel>

      <Controller
       name="first"
       control={control}
       render={({ field }) =>

        <Input
         id="standard-adornment-password"
         autocomplete="new-password"
         inputProps={{
          autoComplete: "off", inputMode: 'numeric'
         }}

         {...field}
         type={showPassword ? 'numeric' : 'password'}

         endAdornment={
          <InputAdornment position="end">

           <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
           >
            {showPassword ? <VisibilityOff /> : <Visibility />}
           </IconButton>

          </InputAdornment>
         }
        />}
      />

     </FormControl>

     {firstChart && <p className='wrp-errors-code pop-up'>Seul caractère numérique, a 6 chiffre sont valides</p>}
     <p className='wrp-errors-code-transparent pop-up'>Seul caractère numérique</p>
    </Box>

    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>

     <FormControl
      sx={{ width: '100%' }}

      variant="standard">
      <InputLabel htmlFor="standard-adornment-password pop-up"><span className='pop-up'>Confirmer le nouveau code</span></InputLabel>

      <Controller
       name="second"
       control={control}
       render={({ field }) =>

        <Input
         id="standard-adornment-password"
         inputProps={{
          autoComplete: "off", inputMode: 'numeric'
         }}


         {...field}
         type={showPassword ? 'numeric' : 'password'}

         endAdornment={
          <InputAdornment position="end">

           <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
           >
            {showPassword ? <VisibilityOff /> : <Visibility />}
           </IconButton>

          </InputAdornment>
         }
        />}
      />

     </FormControl>

     {checked && <p className='wrp-errors-code pop-up'>Les codes sont différents vérifier</p>}
     <p className='wrp-errors-code-transparent pop-up'>Seul caractère numérique</p>
    </Box>


    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>

        Valider le code avec des valeurs numériques, Eviter
        les nombres identiques
        en caractères, Le code est valide avec 4 à 6 caractères.


      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={exist}
     onClose={handleExist}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Ce code est déjà utilisé, contactez MuunganoMoney pour plus d'informations.
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleExist}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <button className='Btn'>Valider</button>
   </form>

  </>
 );
};


async function updatePinInWithDocs(verifierCollection, newPin, numPhone, nextMonth) {

 const washingtonRef = doc(db, verifierCollection ? "client" : "agent", numPhone);
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, { code: newPin });


 const updatecodeRef = doc(db, secureLocalStorage.getItem("#@44!!@NB**$$"), secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(updatecodeRef, {
  updatepincheck: true,
  updatepinmonth: nextMonth.format('MMMM'),
 });


};


