import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';

import RetuRnMsgsENd from './MsgSend';


const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const styles = {
 slide: {
  padding: 15,
  minHeight: 100,
  color: '#fff',
 },
 slide1: {
  backgroundColor: '#FEA900',
 },
 slide2: {
  backgroundColor: '#B3DC4A',
 },
 slide3: {
  backgroundColor: '#6AC0FF',
 },
};

function RetuRnSwipeA() {
 return (
  <BindKeyboardSwipeableViews>
   <RetuRnMsgsENd IMA={'/img/mobile wallet.png'} Title={'Suivi en temps réel'} Text={'Nous vous fournissons des mises à jour en temps réel de vos investissements inclus de fixation de prix et performance et les dividendes'} />
   <RetuRnMsgsENd IMA={'/img/1712842278404.png'} Title={'Diversification simplifiée'} Text={'vous pouvez diversifier vos investissements plus facilement en accès dans une large gamme de titres'} />
   <RetuRnMsgsENd IMA={'/img/Screensh.png'} Title={'Réduction des coûts'} Text={`Les frais associés aux transactions et à la gestion peuvent être rédigresse à l'automatisation et à l'efficacité des processus en ligne`} />

  </BindKeyboardSwipeableViews>
 );
}

export default RetuRnSwipeA;
