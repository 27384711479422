
import * as React from 'react';
import RetuRnTrash from './Trash';


// view navbar component 
export default function ReturnNavBar() {
 return (
  <div className='wrp-sharing-navbar'>
   <div></div>
   <RetuRnTrash />
  </div>
 );
};


