import React from 'react';
import Media from 'react-media';
import currency from 'currency.js';


// REturn view Prix available
export default function REturnPriX(props) {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall count={props.count} />}
     {matches.medium && <ScreenLarge count={props.count} />}
     {matches.large && <ScreenLarge count={props.count} />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = (props) => (
 <div className='wrp-prix-withdraw'>
  <WithdrawBalance count={props.count} />
 </div>
);
export const ScreenSmall = (props) => (
 <div className='wrp-prix-withdraw-sm'>
  <WithdrawBalance count={props.count} />
 </div>
);

export const WithdrawBalance = (props) => {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <p>
   <span style={{ color: `${props.count < 0 ? '#e0e0e0' : 'black'}` }}>{euro(props.count).format()}</span>
  </p>
 );
};
