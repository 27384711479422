import './Create.css';
import ReturnFooteR from './Footer';
import secureLocalStorage from "react-secure-storage";


// /Create Component link
export default function RetuRnCreAte() {

 const handlepath = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##previous##$$none#create", true);
  secureLocalStorage.setItem("#!!@%$$switch**^^%%", false);
  window.location.href = '/tontine/model';
 };

 return (
  <div className='create-link-tontine'>
   <p onClick={handlepath}>Créer un groupe Tontine</p>
   <ReturnFooteR />
  </div>
 );
};