

import './Save.css';
import REturnIconSave from './Iconsave';

// REurn Save component 
export default function REturnSAve() {
 return (
  <div className='wrp-save-register'>

   <REturnIconSave />

  </div>
 );
};