import React from 'react';
import './Duration.css';

// Duraction Return 
export default function ReturnDuraTion() {

 return (
  <div className='quote-pret-duration'>
   <p>Le prêt est pour 120 jours renouvelables</p>
  </div>
 );
};