import React from 'react';
import './Btn.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";


// Btn Component view 
export default function ReturnBTn() {

 const navigation = useNavigate();

 const [cdf, setCdf] = React.useState(0.00);
 const [usd, setUsd] = React.useState(0.00);

 const [cdfsolde, setCdfsolde] = React.useState(0.00);
 const [usdsolde, setUsdsolde] = React.useState(0.00);


 const [time, setTime] = React.useState(new Date());
 const [open, setOpen] = React.useState(false);
 const [modulo, setModulo] = React.useState(0);


 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "pret", secureLocalStorage.getItem("USER")), (doc) => {

    setCdf(doc.data().pretcdf === undefined ? 0 : doc.data().pretcdf);
    setUsd(doc.data().pretusd === undefined ? 0 : doc.data().pretusd);
    setTime(doc.data().date === undefined ? '' : doc.data().date);
    setModulo(doc.data().pretmodulo === undefined ? 0 : doc.data().pretmodulo);

   });
  } catch (e) {
   window.console.log(e);
  }

 }, []);
 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {

    setCdfsolde(doc.data().cdf === undefined ? 0 : doc.data().cdf);
    setUsdsolde(doc.data().usd === undefined ? 0 : doc.data().usd);

   });
  } catch (e) {
   window.console.log(e);
  }

 }, []);


 var now = moment(); //todays date
 let year = moment(time).get('year');
 let months = moment(time).get('month');
 let days = moment(time).get('date');
 var end = moment([year, months, days]); // another date

 var duration = moment.duration(now.diff(end));
 var day = duration.asDays();

 let pretCdf = cdf;
 let modulecdf = 0;

 for (let index = 0; index <= parseInt(day); index++) {

  if (index === 0) {
   continue;
  } else {
   modulecdf = (pretCdf * modulo / 100);
   pretCdf += modulecdf;
  }

 };


 let yearusd = moment(time).get('year');
 let monthsusd = moment(time).get('month');
 let daysusd = moment(time).get('date');
 var end = moment([yearusd, monthsusd, daysusd]); // another date

 var duration = moment.duration(now.diff(end));
 var dayusd = duration.asDays();

 let pretUsd = usd;
 let moduleusd = 0;

 for (let index = 0; index <= parseInt(dayusd); index++) {

  if (index === 0) {
   continue;

  } else {
   moduleusd = (pretUsd * modulo / 100);
   pretUsd += moduleusd;

  }

 };

 window.setTimeout(() => {
  setOpen(true);
 }, 3000);

 const handlepathcdf = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("&&money::pret__", pretCdf);
  secureLocalStorage.setItem("&&money::wallet__", cdfsolde);
  secureLocalStorage.setItem("&&money::unite__", 'cdf');
  secureLocalStorage.setItem("^^pret->", true);

  secureLocalStorage.setItem("solde&&%%¢pret", '/pret/costs/asked/cdf');
  navigation('/pret/method');


 }
 const handlepathusd = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("&&money::pret__", pretUsd);
  secureLocalStorage.setItem("&&money::wallet__", usdsolde);
  secureLocalStorage.setItem("&&money::unite__", 'usd');
  secureLocalStorage.setItem("^^pret->", true);


  secureLocalStorage.setItem("solde&&%%¢pret", '/pret/costs/asked/usd');
  navigation('/pret/method');

 };


 return (
  <>
   {moment(time).add(2, 'days').format() > moment(time).format() &&
    <>
     {
      usd > 0 &&
      <div className='btn-pret-asked'>
       <button onClick={handlepathusd}>Remboursé</button>
      </div>
     }
    </>
   }

   {
    moment(time).add(2, 'days').format() > moment(time).format() &&
    <>
     {
      cdf > 0 &&
      <div className='btn-pret-asked'>
       <button onClick={handlepathcdf}>Remboursé</button>
      </div>
     }
    </>
   }
  </>
 );
};

