import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import './List.css';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        '#': /[0-6]/,
      }}
      inputmode="tel"
      pattern="[0-6]*"

      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      inputmode="tel"
      valueIsNumericString
      prefix=""
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 35,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));


// Btn
export default function RetuRnBtn() {

  const {
    register,
    watch,
    control,
    reset,
    handleSubmit,
  } = useForm();


  const navigation = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [etablishcode, setEtablishcode] = React.useState(null);
  const [opn, setOpn] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(null);
  const [item, setItem] = React.useState([]);
  const [verify, setVerify] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);


  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const inputRef = React.useRef();
  const [searchTerm, setSearchTerm] = React.useState('');

  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {

      setItem(doc.data().sharingmpall)
      setCode(doc.data().code)
      setEtablishcode(doc.data().establishmentcode)
      setVerify(doc.data().shareverify === undefined ? false : doc.data().shareverify)


    });

  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handlClose = () => {
    setOpn(false);
    reset();
  };
  const handleOpn = () => {
    setOpn(true);
  };

  let pin = watch("count");

  const handleShare = async (event) => {

    if (pin === code) {

      event.preventDefault();
      setLoad(true);
      setOpn(false);

      // Add a new document in collection "cities"
      await setDoc(doc(db, "sharecashbeck", secureLocalStorage.getItem("USER")), {
        share: item,
        shareverify: true,
        sharingverifieraccess: true,
        establishmentcode: etablishcode
      });

      const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(washingtonRef, {
        shareverifysucces: true,
        shareverify: false,
        establishmentcode: etablishcode
      });


      const availableRef = doc(db, "cashbeckestablishment", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(availableRef, {
        available: true,
      });

      window.setTimeout(() => { navigation(0) }, 3500);

    } else {
      setOpn(false);
      setOpen(true);

    }

  };

  return (
    <>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}>

        <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
        <DialogContent>

          <DialogContentText>
            <p className='pop-up'>
              PIN, incorrect
      </p>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

      <React.Fragment>
        <Dialog
          open={opn}
          onClose={handlClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              console.log(email);
              handlClose();
            },
          }}>

          <DialogTitle><h1 className="pop-up">Cashbeck</h1></DialogTitle>

          <DialogContent>
            <DialogContentText>
              <p className="pop-up">
                Veuillez confirmer le code PIN pour l'envoi du paiement numérique
      </p>
            </DialogContentText>



            <FormControl
              sx={{ width: '100%' }}

              variant="standard">
              <InputLabel htmlFor="standard-adornment-password"><div className='pop-up'>Pin actuel</div></InputLabel>

              <Controller
                name="count"
                control={control}
                render={({ field }) =>


                  <Input
                    autoFocus
                    id="standard-adornment-password"
                    {...field}
                    inputProps={{
                      autoComplete: "off", inputMode: 'numeric'
                    }}

                    sx={{ fontSize: '1.3em', fontFamily: "Alata" }}

                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    type={showPassword ? 'text' : 'password'}

                    endAdornment={
                      <InputAdornment position="end">

                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>

                      </InputAdornment>
                    }

                  />

                } />

            </FormControl>



          </DialogContent>

          <DialogActions>
            <div className="flex-between" style={{ padding: "0 15px" }}>

              <Button onClick={handlClose}><span className="pop-up">Annuler</span></Button>
              <Button onClick={handleShare}><span className="pop-up">confirmer</span></Button>

            </div>
          </DialogActions>

        </Dialog>

      </React.Fragment>

      <div style={{ padding: '1em' }}>
        {verify && <button className="Btn" onClick={handleOpn}>CONFIRMER</button>}

      </div>
    </>
  );
};