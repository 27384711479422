

import RetuRnAccepTed from './Accepted';


// Accepted Main
export default function AccepTed() {
 return (
  <RetuRnAccepTed />
 );
};