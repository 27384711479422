import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";

import secureLocalStorage from "react-secure-storage";
import './Balance.css';
import currency from 'currency.js';



// Name User Account
export default function RetuRnBAlAnce() {

 const [item, setItem] = React.useState(0);
 const [devise, setDevise] = React.useState('');

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##")), (doc) => {
   setItem(doc.data().count);
   setDevise(doc.data().devise);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-balance-must-creditaire'>

   <div className='flex-bord-crediter'>
    <h2>Montant à créditer</h2>
    <p>{euro(item).format()} {devise}</p>


   </div>
  </div>
 );
};
