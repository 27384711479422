
import './Sharing.css';
import HeAd from './Head/Main';
import BodY from './Body/Main';


// Sharing Main 
export default function ReturnShaRing() {

 return (
  <div className="wrp-sharing">
   <HeAd />
   <BodY />
   <div></div>
  </div>
 );
};