import './Nav.css';
import ReturnBacK from './Back';

// Return Nav Component Money
export default function ReturnNavigaTion() {
 return (
  <div className='wrp-nav-pret-method'>
   <ReturnBacK />
   <div></div>
  </div>
 );
};