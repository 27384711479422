
import './Quote.css';

// Return Quote Component View
export default function ReturnQuOTe() {
 return (
  <div className='wrp-quote-printer-ticket'>
   <p>
    Lancer l'impression de la dernière transaction
   </p>
  </div>
 );
};