import './Nav.css';
import ReturnBacK from './Back';


// view navbar component 
export default function ReturnNavBar() {
 return (
  <div className='wrp-tontine-navbar'>
   <ReturnBacK />
   <div></div>
  </div>
 );
};