import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TweenMax, Expo } from 'gsap';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import { db } from '../../firebase';
import { doc, onSnapshot } from "firebase/firestore";


// Return Dollars Component
export default function ReturnDollArs() {

 const navigation = useNavigate();
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);
 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("^^&&register__pret", true);
  secureLocalStorage.setItem("^^&&#$moment#@(@#date", moment().format());
  secureLocalStorage.setItem("##7@88#!!@@NAME#**##", `${first} ${last}`.toLowerCase());

  window.setTimeout(() => {
   navigation('/pret/register');

  }, 1500);

 };
 React.useEffect(() => {
  TweenMax.from('.Anima', 1.2, { delay: 1, opacity: 0, x: -20, ease: Expo.easeInOut })

 }, []);


 return (
  <div onClick={handlepath} className='devise-pret-money Anima'>
   <img src={'/img/dollars.png'} />
   <p>USD</p>
  </div>
 );
};
