import './Footer.css';
import RetuRnButtoN from './Button';

// Footer Main
export default function RetuRnFooTER() {
 return (
  <div className='flex-footer-credit'>
   <RetuRnButtoN />
  </div>
 );
};