import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import { db } from '../../../../firebase';
import { doc, updateDoc, onSnapshot, deleteField, getDocs, collection, deleteDoc } from "firebase/firestore";
import currency from 'currency.js';
import './Devise.css';
import { useNavigate } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export default function RetuRnDevIse(props) {

 const navigation = useNavigate();


 const pushArray = new Array();
 const pushAll = new Array();
 let pushClient = new Array();



 const [item, setItem] = React.useState(!!secureLocalStorage.getItem("**@!##J@1777"));
 const [dollard, setDollard] = React.useState(0);
 const [fran, setFran] = React.useState(0);
 const [load, setLoad] = React.useState(false);
 const [count, setCount] = React.useState(0);
 const [countcdf, setCountcdf] = React.useState(0);
 const [listclient, setListclient] = React.useState([]);



 const [open, setOpen] = React.useState(false);
 const [check, setCheck] = React.useState(null);
 const [verify, setVerify] = React.useState(null);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };

 const [list, setList] = React.useState([]);
 const [phone, setPhone] = React.useState([]);
 const [shareAll, setShareAll] = React.useState([]);


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());

  });

  setListclient([... new Set(pushClient)])

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setList(doc.data().sharingmpall === undefined ? [] : doc.data().sharingmpall);
   setCheck(doc.data().shareverify);
   setVerify(doc.data().verifyshare)
  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "share" + secureLocalStorage.getItem("USER")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushArray.push(doc.id);
  });

  setPhone([...new Set(pushArray)]);

 }, []);


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setShareAll(doc.data().sharingmpall === undefined ? [] : doc.data().sharingmpall)
  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {

   setDollard(doc.data().usd);
   setFran(doc.data().cdf);
   setCount(doc.data().countsharing === undefined ? 0 : doc.data().countsharing);
   setCountcdf(doc.data().countsharingcdf === undefined ? 0 : doc.data().countsharingcdf);

  });

 }, []);


 [...Array(shareAll.length).keys()].forEach(itm => {
  pushAll.push(shareAll[itm].idphone)

 })

 const handleDevise = async (event) => {

  event.preventDefault();
  setLoad(true);
  secureLocalStorage.setItem("**@!##J@1777", !secureLocalStorage.getItem("**@!##J@1777"));
  setItem(!!secureLocalStorage.getItem("**@!##J@1777"));


  await deleteDoc(doc(db, "sharecashbeck", secureLocalStorage.getItem("USER")));

  phone.forEach(async index => {
   await deleteDoc(doc(db, `share${secureLocalStorage.getItem("USER")}`, `${index}`));
  })

  const trashRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Atomically remove a region from the "regions" array field.
  await updateDoc(trashRef, {

   countsharing: 0,
   countsharingcdf: 0,
   sharingmpall: deleteField(),
   shareverify: deleteField(),
   sharingverifieraccess: deleteField(),
   shareverifysucces: deleteField(),

  });

  [...Array(listclient.length).keys()].map(async item => {

   if (pushAll.includes(listclient[item].idphone)) {

    const removeRef = doc(db, "client", listclient[item].idphone);
    await updateDoc(removeRef, {

     sharingdevise: deleteField(),
     sharingmoney: deleteField(),
     sharingverifier: deleteField(),
     countsharing: deleteField(),
     countsharingcdf: deleteField()

    });

   }

  })

  window.setTimeout(() => {
   navigation(0);
  }, 9500)

 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div style={{ cursor: 'pointer' }}
    className="flex-usd-devise-sharing"
    onClick={handleDevise}>
    {item ?

     <div className="flex-center " style={{ gap: '0.2em' }}>
      <p style={{ color: Number(dollard) - Number(count) - Number(props.value) <= 0 && "#fff" }} className="pad-top-2 ">{euro(Number(dollard) - Number(count) - Number(props.value)).format()}</p>
      <Avatar
       src="/img/dollars.png"
       sx={{ width: 30, height: 30 }}
      />
     </div>

     :

     <div className="flex-center " style={{ gap: '1em' }}>
      <p style={{ color: Number(fran) - Number(countcdf) - Number(props.value) <= 0 && "#fff" }} className="pad-top-2 ">{euro(Number(fran) - Number(countcdf) - Number(props.value)).format()}</p>
      <Avatar
       src="/img/franc.png"
       sx={{ width: 30, height: 30 }}
      />
     </div>

    }
   </div>

  </>
 );
};
