
import './Body.css';
import ReturnTitle from './Title';
import ReturnBtn from './Btn';


// Body overview
export default function ReturnOverBody() {
 return (
  <div className='bd-overview-list-child'>
   <ReturnTitle />
   <ReturnBtn />
  </div>
 );
};