import './Nav.css';
import ReturnBacK from './Back';
// import ReturnProfil from './Profil';


// Return Nav Component Money
export default function ReturnNavigaTion() {
 return (
  <div className='wrp-nav-wallet-slide'>
   <ReturnBacK />
   {/* <ReturnProfil /> */}
  </div>
 );
};