
import HeAd from './Head/Main';
import BodY from './Body/Main';

// main home page
export default function Hm() {
 return (
  <>
   <HeAd />
   <BodY />
  </>
 );
}