import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot, } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import './List.css';
import currency from 'currency.js';


// List 
export default function ReturnShaRingList() {

 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setList(doc.data().sharingmpall === undefined ? [] : doc.data().sharingmpall)
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className="margin-top-5">
   <h1 className="pop-up" style={{ marginBottom: '4ch', color: "#2ec4b6" }}>Clients  sur la liste (PN)</h1>

   {[...Array(list.length).keys()].map((index) => {
    return (
     <>
      <div style={{ marginBottom: '1vh' }} className="flex-between">
       <div style={{ paddingTop: '1vh' }} className="grid">
        <Avatar src={list[index].profile} sx={{ width: 40, height: 40 }} />
        <p className="flex-sharing" style={{ paddingTop: '2vh', color: "#6c757d" }}>{`${(list[index].firstname + ' ' + list[index].lastname).toLowerCase()}`}</p>
       </div>

       <p style={{ paddingTop: '1vh', color: "#335c67" }} className="flex-sharing">{euro(list[index].sharingmoney).format()} {list[index].sharingdevise}</p>
      </div>

      <Divider />
     </>
    )
   })}

  </div>
 );
};