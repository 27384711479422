import ReturnLine from './Line';
import ServIceAll from './All/Main';
import SwipeA from './SwipeableViews/Main';
import Media from 'react-media';


// Service  Main Deposit
export default function RetuRnSeRvIce() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenSmall = () => (
 <>
  <SwipeA />
 </>
);
export const ScreenLarge = () => (
 <>
  <ReturnLine />
  <ServIceAll />
 </>
);

