
import './Title.css';
import Media from 'react-media';



// Middle Head 
export default function RetuRnTitle() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <ScreenLarge />}
          {matches.large && <ScreenLarge />}
        </>
      )}
    </Media>
  );
};


export const ScreenLarge = () => (
  <div className='deposit-title-quote-home'>
    <View />
  </div>
)
export const ScreenSmall = () => (
  <div className='deposit-title-quote-home-sm'>
    <View />
  </div>
)
export const View = () => (
  <>
    <h4>
      Payez où vous voulez.
   </h4>

    <p>
      la tontine est basée  sur la confiance mutuelle, l'entraide et la solidarité

   </p>
  </>
);