
import './Title.css';

// Title Sharing 
export default function RetuRnTitle() {
 return (
  <div className="wrp-title-sharing">
   <h1>Paiement Numérique</h1>
  </div>
 );
};