import './Pret.css';
import ReturnTitle from './Title';



// Return Fran Pret 
export default function ReturnPreT() {
 return (
  <>
   <div className='devise-pret'>
    <img src={'/img/pret.png'} />
    <ReturnTitle />
   </div>
  </>
 );
};