import DeVise from './Devise/Main';
import './Head.css';
import RetuRnTitle from './Title';


// View Head 
export default function ReturnHeAd() {
 return (
  <>
   <RetuRnTitle />
   <div style={{ padding: '1em' }} className='flex-transfert-head-swap'>
    <div className='flex-between flex-border-bottom pad-left-right-3'>
     <div></div>
     <DeVise />
    </div>

   </div>
  </>
 );
};