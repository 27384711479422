import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { doc, getDocs, collection, updateDoc, increment, onSnapshot, arrayUnion, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';
import REturnQuOte from './Quote';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import { CiWifiOff } from "react-icons/ci";
import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { reactLocalStorage } from 'reactjs-localstorage';
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import currency from 'currency.js';
import axios from 'axios';




export let nowField = moment().date();
export let now = moment().date();

export let prixHash = 0;
export let hashTrue = true;
export let timespinner = 6570;

export let yoursolde = secureLocalStorage.getItem("@solde!#!");
let key = v4();




// Input Field Component 
export default function ReturnInput() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <SreenLarge />}
					{matches.large && <SreenLarge />}
				</>
			)}
		</Media>
	);
};


export const SreenLarge = () => (
	<div className='wrp-form-input-pin'>
		<REturnQuOte />
		<FormInput />

	</div>
);
export const ScreenSmall = () => (
	<div className='wrp-form-input-pin'>
		<REturnQuOte />
		<FormInput />
	</div>
);

export const FormInput = () => {


	let pushDocs = new Array();
	let verifierSend;
	let verifierGet;

	const [wifi, setWifi] = React.useState(false);
	const [problem, setProblem] = React.useState(false);
	const [blem, setBlem] = React.useState(false);
	const [load, setLoad] = React.useState(false);

	const navigation = useNavigate();
	const { handleSubmit, reset, control } = useForm({});



	let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
	let password = secureLocalStorage.getItem("#$$@%%api$$pasword");



	// send React useState  
	const [pin, setPin] = React.useState(null);
	const [state, setState] = React.useState(null);

	const [first, setFirst] = React.useState('');
	const [last, setLast] = React.useState('');


	const [soldeMain, setSoldeMain] = React.useState(null);
	const [profil, setProfil] = React.useState(null);

	const [thrift, setThrift] = React.useState(null);
	const [frais, setFrais] = React.useState(null);

	// getting React useState
	const [getstate, setGetstate] = React.useState(null);
	const [getfirst, setGetFirst] = React.useState('');
	const [getlast, setGetLast] = React.useState('');
	const [price, setPrice] = React.useState(null);
	const [getProfil, setGetProfil] = React.useState(null);
	const [getState, setGetState] = React.useState(null);
	const [getthrift, setGetThrift] = React.useState(null);
	const [getfrais, setGetfrais] = React.useState(null);


	const [signature, setSignature] = React.useState(null);
	const [signaturetype, setSignaturetype] = React.useState(null);
	const [signaturemoney, setSignaturemoney] = React.useState(null);
	const [signaturekey, setSignaturekey] = React.useState(null);
	const [signaturefraiscdf, setSignaturefraiscdf] = React.useState(null);


	const [getsignature, setGetsignature] = React.useState(null);
	const [getsignaturetype, setGetsignaturetype] = React.useState(null);
	const [getsignaturemoney, setGetsignaturemoney] = React.useState(null);
	const [getsignaturekey, setGetsignaturekey] = React.useState(null);
	const [getsignaturefraiscdf, setGetsignaturefraiscdf] = React.useState(null);



	const [open, setOpen] = React.useState(false);
	const [cancel, setCancel] = React.useState(false);
	const [click, setClick] = React.useState(false);

	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');

	const [showPassword, setShowPassword] = React.useState(false);
	const [getTeam, setGetTeam] = React.useState('');
	const [sendTeam, setSendTeam] = React.useState('');


	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};



	const handleClose = () => {
		setOpen(false);
	};
	const cancelClose = () => {
		setCancel(false);
		setClick(true);
	};
	const handlewifiClose = () => {
		setWifi(false);
	};
	const handleproblemClose = () => {
		setProblem(false);
		navigation('/dash');
	};


	React.useEffect(async () => {


		const querySnapshot = await getDocs(collection(db, "client"));
		querySnapshot.forEach((doc) => {
			pushDocs.push(doc.id);
		});

		verifierSend = pushDocs.some(value => value == secureLocalStorage.getItem("USER"));
		verifierGet = pushDocs.some(value => value == secureLocalStorage.getItem("A@@ph$$&-@#"));

		const unsub = onSnapshot(doc(db, verifierSend ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {

			setPin(doc.data().code);
			setState(doc.data().state);

			setFirst(doc.data().firstname);
			setLast(doc.data().lastname);

			setSoldeMain(doc.data().cdf);
			setSendTeam(doc.data().team);
			setProfil(doc.data().profile);
			setThrift(doc.data().thriftcdf);
			setFrais(doc.data().frais);

			setSignature(doc.data().signaturecdf);
			setSignaturetype(doc.data().signaturecdftype);
			setSignaturemoney(doc.data().signaturemoneycdf);
			setSignaturekey(doc.data().signaturekeycdf);
			setSignaturefraiscdf(doc.data().signaturefraiscdf);

		});
		const unsubget = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("A@@ph$$&-@#")) ? "client" : "agent", secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {

			setGetstate(doc.data().state);
			setGetFirst(doc.data().firstname);
			setGetLast(doc.data().lastname);
			setPrice(doc.data().cdf);
			setGetTeam(doc.data().team);
			setGetProfil(doc.data().profile);
			setGetState(doc.data().state);
			setGetThrift(doc.data().thriftcdf);
			setGetfrais(doc.data().frais);

			setGetsignature(doc.data().signaturecdf);
			setGetsignaturetype(doc.data().signaturecdftype);
			setGetsignaturemoney(doc.data().signaturemoneycdf);
			setGetsignaturekey(doc.data().signaturekeycdf);
			setGetsignaturefraiscdf(doc.data().signaturefraiscdf);

		});

	}, []);


	const handleblemClose = () => {
		setBlem(false);
		navigation('/dash');
	};


	let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
	let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
	let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


	// From get AGENT
	let othersendagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
	let othergetagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
	let checkotheragent = getsignaturetype === 'envoyer' ? othersendagent === getsignature : othergetagent === getsignature;

	// ===========================================

	// From send CLIENT
	let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney) + Number(signaturefraiscdf))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
	let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
	let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;


	// From get CLIENT
	let sendotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney) + Number(getsignaturefraiscdf))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
	let getotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
	let checkotherclient = getsignaturetype === 'envoyer' ? sendotherclient === getsignature : getotherclient === getsignature;


	// window.console.log(checkagent + ' send agent ' + checkotherclient);
	// window.console.log(checkclient + ' send client ' + checkotheragent);
	// window.console.log(checkclient + ' send client to client ' + checkotherclient);


	// window.console.log(signaturetype)
	window.console.log(signature)
	window.console.log(sendagent)




	const onSubmit = async (data) => {

		var eurocurrency = value => currency(value, { separator: ' ', decimal: '.', symbol: '' });

		setLoad(true);
		secureLocalStorage.setItem("@dateª©#&&++#", moment().format('LLLL'));
		reactLocalStorage.set('##^^@@%^***^^++=$', true);
		secureLocalStorage.setItem("@!vew*%%%!!!@@@", parseInt(Number(price)));


		// Checked if value code is length valid
		if (pin != data.code || pin == '000000' || soldeMain <= 2000) {

			setOpen(true);
			setLoad(false);
			reset();

		} else {

			secureLocalStorage.setItem("&&837$$prnt@*#())", true);
			const sendUser = first.toLowerCase() + ' ' + last.toLowerCase();
			const getUser = getfirst.toLowerCase() + ' ' + getlast.toLowerCase();



			if (state === null || state === undefined || first === '' || first === undefined || last === '' || last === undefined || soldeMain === null || soldeMain === undefined || getstate === null || getstate === undefined || getfirst === '' || getfirst === undefined || getlast === '' || getlast === undefined || price === null || price === undefined || profil === null || profil === undefined || getProfil === null || getProfil === undefined || getState === null || getState === undefined || thrift === null || thrift === undefined || getthrift === null || getthrift === undefined || frais === null || frais === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || getsignature === null || getsignature == undefined || getsignaturetype == null || getsignaturetype == undefined || getsignaturemoney == null || getsignaturemoney == undefined) {

				setWifi(true);
				setLoad(false);

			} else {

				if (state == "agent" && getstate == "agent") {

					if (checkagent && checkotheragent) {

						if (sendTeam === 'mere') {

							if (sendTeam === 'mere' && getTeam === 'mere') {

								window.localStorage.setItem('@ª©##', JSON.stringify(false));
								window.localStorage.setItem('@cost##', JSON.stringify(true));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");

								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const unite = secureLocalStorage.getItem("@unite!#!");
								const money = secureLocalStorage.getItem("@solde!#!");
								const main = secureLocalStorage.getItem("@main!#!");


								let comm = money * 0 / 100;

								let soldMainMoney = Number(soldeMain) - Number(money)
								let priceMoney = Number(price) + Number(money)


								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, 0, key, 'CDF', price, priceMoney);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, soldMainMoney);
								swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, signaturekey, price);



								secureLocalStorage.setItem("@frais!#!", 0);
								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);


							} else {


								window.localStorage.setItem('@ª©##', JSON.stringify(false));
								window.localStorage.setItem('@cost##', JSON.stringify(true));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");

								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const unite = secureLocalStorage.getItem("@unite!#!");
								const money = secureLocalStorage.getItem("@solde!#!");
								const main = secureLocalStorage.getItem("@main!#!");

								let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$spagtversagt%%") / 100;


								let soldMainMoney = Number(soldeMain) - Number(money);
								let priceMoney = Number(price) + Number(money);


								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, comm, 0, key, 'CDF', price, priceMoney);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, soldMainMoney);
								swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, signaturekey, price);


								secureLocalStorage.setItem("@frais!#!", 0);
								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);


							}

						} else {



							window.localStorage.setItem('@ª©##', JSON.stringify(false));
							window.localStorage.setItem('@cost##', JSON.stringify(true));

							hashTrue = false;
							prixHash = secureLocalStorage.getItem("@solde!#!");


							const sendPhone = secureLocalStorage.getItem("USER");
							const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
							const unite = secureLocalStorage.getItem("@unite!#!");
							const money = secureLocalStorage.getItem("@solde!#!");
							const main = secureLocalStorage.getItem("@main!#!");
							let comm = money * 0 / 100;
							const soldMainMoney = Number(soldeMain) - Number(money);


							swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, 0, key, 'CDF', price, price + money);
							swapGetUser(getPhone, `Transfért d'argent`, money, price, price + money, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, soldeMain - money);

							swapInWithDocsAgent(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);


							secureLocalStorage.setItem("@frais!#!", 0);

							window.setTimeout(() => {
								navigation('/send-success');
							}, timespinner);

						}

					} else {


						if (checkagent == false) {

							setProblem(true);
							setLoad(false);

						} else {

							setBlem(true);
							setLoad(false);

						}

					};

				} else if (state == "client" && getstate == "client") {

					if (checkclient && checkotherclient) {

						window.localStorage.setItem('@ª©##', JSON.stringify(false));

						hashTrue = false;
						prixHash = secureLocalStorage.getItem("@solde!#!");

						const sendPhone = secureLocalStorage.getItem("USER");
						const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
						const money = secureLocalStorage.getItem("@solde!#!");
						const unite = secureLocalStorage.getItem("@unite!#!");
						const frais = secureLocalStorage.getItem("@frais!#!");
						const main = secureLocalStorage.getItem("@main!#!");


						swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'CDF', price, price + money);
						swapGetUser(getPhone, `Transfért d'argent`, money, price, price + money, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, main);
						isSwapInWithClientToClient(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, key, soldeMain, price);


						let number = secureLocalStorage.getItem("USER");
						let phonesend = `243${number.slice(-9)}`;

						let numbergetter = secureLocalStorage.getItem("A@@ph$$&-@#");
						let phonegetter = `243${numbergetter.slice(-9)}`;

						let depotAPI = `Vous avez recu ${(money)} ${unite} de ${first.toString().toUpperCase()} ${last.toString().toUpperCase()} ${sendPhone}. Nouveau solde: ${parseInt(price + money)} ${unite}.`;
						let retraitAPI = `Votre transfert de ${(money)} ${unite} est effectue par ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()} ${getPhone}. Frais: ${(frais)} ${unite}. Nouveau solde: ${parseInt(main)} ${unite}.`;

						let config = {
							method: 'get',
							maxBodyLength: Infinity,
							url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonegetter}&textmessage=${depotAPI}`,
							headers: {}
						};
						let transfertconfig = {
							method: 'get',
							maxBodyLength: Infinity,
							url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
							headers: {}
						};


						axios.request(config)
							.then((response) => {
								console.log(JSON.stringify(response.data));
							})
							.catch((error) => {
								console.log(error);
							});


						axios.request(transfertconfig)
							.then((response) => {
								console.log(JSON.stringify(response.data));
							})
							.catch((error) => {
								console.log(error);
							});




						secureLocalStorage.setItem("@frais!#!", 0);

						window.setTimeout(() => {
							navigation('/send-success');
						}, timespinner);

					} else {


						if (checkclient == false) {

							setProblem(true);
							setLoad(false);

						} else {

							setBlem(true);
							setLoad(false);

						}



					}


				} else {

					if (state == "agent") {

						if (checkagent && checkotherclient) {

							if (sendTeam === 'mere') {

								window.localStorage.setItem('@ª©##', JSON.stringify(false));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");


								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const money = secureLocalStorage.getItem("@solde!#!");
								const unite = secureLocalStorage.getItem("@unite!#!");

								let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$versclients%%") / 100;

								let soldMainMoney = Number(soldeMain) - Number(money);
								let priceMoney = Number(price) + Number(money);



								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, Number(comm), 0, key, 'CDF', price, priceMoney);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, soldeMain);
								swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);


								let number = secureLocalStorage.getItem("A@@ph$$&-@#");
								let phonesend = `243${number.slice(-9)}`;
								let depotAPI = `Depot de ${(money)} ${unite} est effectue par le ${sendPhone} ${first.toString().toUpperCase()} ${last.toString().toUpperCase()}. Nouveau solde: ${parseInt(price + money)} ${unite}.`;


								let config = {
									method: 'get',
									maxBodyLength: Infinity,
									url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
									headers: {}
								};


								axios.request(config)
									.then((response) => {
										console.log(JSON.stringify(response.data));
									})
									.catch((error) => {
										console.log(error);
									});


								secureLocalStorage.setItem("@frais!#!", 0);
								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);

							} else {


								window.localStorage.setItem('@ª©##', JSON.stringify(false));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");

								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const money = secureLocalStorage.getItem("@solde!#!");
								const unite = secureLocalStorage.getItem("@unite!#!");
								// const main = secureLocalStorage.getItem("@main!#!");

								const soldMainMoney = Number(soldeMain) - Number(money);
								let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$versclients%%") / 100;

								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldeMain - money, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, comm, 0, key, 'CDF', price, price + money);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, price + money, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'CDF', soldeMain, soldeMain - money);

								swapInWithDocsAgentToClient(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);



								let number = secureLocalStorage.getItem("A@@ph$$&-@#");
								let phonesend = `243${number.slice(-9)}`;
								let depotAPI = `Depot de ${(money)} ${unite} est effectue par le ${sendPhone} ${first.toString().toUpperCase()} ${last.toString().toUpperCase()}. Nouveau solde: ${parseInt(price + money)} ${unite}.`;


								let config = {
									method: 'get',
									maxBodyLength: Infinity,
									url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
									headers: {}
								};


								axios.request(config)
									.then((response) => {
										console.log(JSON.stringify(response.data));
									})
									.catch((error) => {
										console.log(error);
									});

								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);


							}

						} else {

							if (checkagent == false) {

								setProblem(true);
								setLoad(false);

							} else {

								setBlem(true);
								setLoad(false);

							}


						}


					} else {

						if (checkclient && checkotheragent) {

							if (getTeam === 'mere') {

								window.localStorage.setItem('@ª©##', JSON.stringify(false));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");


								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const money = secureLocalStorage.getItem("@solde!#!");
								const unite = secureLocalStorage.getItem("@unite!#!");
								const frais = secureLocalStorage.getItem("@frais!#!");
								const main = secureLocalStorage.getItem("@main!#!");

								let comm = Number(money) * secureLocalStorage.getItem("#@@!!#*##frais@@**$client%%") / 100;


								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'CDF', price, price + money);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, price + money, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift + comm, comm, 0, key, 'CDF', soldeMain, main);
								isSwapInWithClientToAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, Number(comm), soldeMain, signaturekey, price);


								let number = secureLocalStorage.getItem("USER");
								let phonesend = `243${number.slice(-9)}`;
								let retraitAPI = `Retrait de ${(money)} ${unite} est effectue par le ${getPhone} ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()}. Frais: ${(frais)} ${unite}. Nouveau solde: ${parseInt(main)} ${unite}.`;

								let config = {
									method: 'get',
									maxBodyLength: Infinity,
									url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
									headers: {}
								};


								axios.request(config)
									.then((response) => {
										console.log(JSON.stringify(response.data));
									})
									.catch((error) => {
										console.log(error);
									});






								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);


							} else {

								window.localStorage.setItem('@ª©##', JSON.stringify(false));

								hashTrue = false;
								prixHash = secureLocalStorage.getItem("@solde!#!");


								const sendPhone = secureLocalStorage.getItem("USER");
								const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
								const money = secureLocalStorage.getItem("@solde!#!");
								const unite = secureLocalStorage.getItem("@unite!#!");
								const frais = secureLocalStorage.getItem("@frais!#!");
								const main = secureLocalStorage.getItem("@main!#!");
								let comm = Number(money) * secureLocalStorage.getItem("#@@!!#*##frais@@**$client%%") / 100;



								swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'CDF', price, price + money);
								swapGetUser(getPhone, `Transfért d'argent`, money, price, price + money, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift + comm, comm, 0, key, 'CDF', soldeMain, main);
								isSwapInWithClientToAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, Number(comm), soldeMain, signaturekey, price);


								let number = secureLocalStorage.getItem("USER");
								let phonesend = `243${number.slice(-9)}`;
								let retraitAPI = `Retrait de ${(money)} ${unite} est effectue par le ${getPhone} ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()}. Frais: ${(frais)} ${unite}. Nouveau solde: ${(main)} ${unite}.`;


								let config = {
									method: 'get',
									maxBodyLength: Infinity,

									url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
									headers: {}
								};

								axios.request(config)
									.then((response) => {
										console.log(JSON.stringify(response.data));
									})
									.catch((error) => {
										console.log(error);
									});

								window.setTimeout(() => {
									navigation('/send-success');
								}, timespinner);

							}

						} else {

							setProblem(true);
							setLoad(false);

							if (checkclient == false) {

								setProblem(true);
								setLoad(false);

							} else {

								setBlem(true);
								setLoad(false);

							}

						}

					}

				}
			}


		}

	};

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>

				<FormControl
					sx={{ width: '100%' }}
					variant="standard">
					<InputLabel htmlFor="standard-adornment-password"><div className='pop-up'>Pin actuel</div></InputLabel>

					<Controller
						name="code"
						control={control}
						render={({ field }) =>

							<Input
								id="standard-adornment-password"
								autocomplete="new-password"

								{...field}
								type={showPassword ? 'numeric' : 'password'}

								inputProps={{
									autoComplete: "off", inputMode: 'numeric'
								}}

								endAdornment={
									<InputAdornment position="end">

										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>

									</InputAdornment>
								}

							/>}
					/>

				</FormControl>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}>

					<DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								PIN, Incorrect
     </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={cancel}
					onClose={cancelClose}>

					<DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								Des frais sont appliqués à cette transaction. Merci de choisir Muunganomoney
     </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={cancelClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>

				<button className='Btn'>Envoi</button>

			</form>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={problem}
				onClose={handleproblemClose}>
				<DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

							Votre compte CDF a été suspendu suite à une situation de litige
							contacter cashbeck pour plus d'informations

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={blem}
				onClose={handleblemClose}>
				<DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

							le compte CDF de votre bénéficiaire a été suspendu

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={wifi}
				onClose={handlewifiClose}>

				<DialogContent>

					<DialogContentText>
						<div className='block-flex-center-wifi'>
							<CiWifiOff size={'2em'} color={'crimson'} />
							<p className='pop-up'>
								Connection internet faible

        </p>
						</div>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

		</>
	)
};


async function swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, unite, solde, comm, before, key, getbefore) {

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }



	const sendRef = doc(db, "agent", sendPhone);
	await updateDoc(sendRef, {
		cdf: Number(main),
		money: Number(money),
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		delay: moment().format(),
		swap: arrayUnion(send),
		commission: Number(comm),

		signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		frais: 0,
		sendtype: 'envoyer',
		thriftcdf: increment(Number(comm)),

	});

	const getRef = doc(db, "agent", getPhone);
	await updateDoc(getRef, {
		cdf: Number(money) + Number(price),
		money: Number(money),
		user: sendUser,
		unite: unite,
		phoneclient: sendPhone,
		delay: moment().format(),

		signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		swap: arrayUnion(get),
		frais: 0,
		commission: 0,
		sendtype: 'Reçu'
	});

	delete window.swapInWithDocsAgentForSuper;

};
async function swapInWithDocsAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, unite, solde, comm, before, key, getbefore) {

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }


	let beforeSend = Number(secureLocalStorage.getItem("&##$$!CDF%!!")) - Number(money);
	let beforeGet = Number(secureLocalStorage.getItem("#$$!!$&%CDF@#")) + Number(money);


	const sendRef = doc(db, "agent", sendPhone);
	await updateDoc(sendRef, {
		cdf: (main),
		money: money,
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		beforecdf: Number(beforeSend),

		signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		delay: moment().format(),
		swap: arrayUnion(send),
		commission: Number(comm),
		frais: 0,
		sendtype: 'envoyer',
		thriftcdf: increment(Number(comm))
	});

	const getRef = doc(db, "agent", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(money),
		money: money,
		user: sendUser,
		unite: unite,
		phoneclient: sendPhone,
		delay: moment().format(),


		signaturecdf: `${getPhone}${Number(money)}${Number(getbefore)}${parseInt(Number(getbefore) + Number(money))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: Number(money),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		swap: arrayUnion(get),
		frais: 0,
		commission: 0,
		sendtype: 'Reçu'
	});


	delete window.swapInWithDocsAgent;

};
async function swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, main, price, prix, unite, solde, comm, before, key, getbefore) {


	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + prix).format(), actual: euro(solde + prix).format() + ' ' + unite, unite: unite }



	const sendRef = doc(db, "agent", sendPhone);
	await updateDoc(sendRef, {
		cdf: Number(main),
		money: prix,
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		delay: moment().format(),

		signaturecdf: `${sendPhone}${parseInt(Number(prix))}${parseInt(Number(before))}${parseInt(Number(before) - Number(prix))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(prix)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		swap: arrayUnion(send),
		thriftcdf: increment(Number(comm)),
		commission: Number(comm),
		frais: 0,
		sendtype: 'envoyer'
	});

	const getRef = doc(db, "client", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(prix),
		money: prix,
		user: sendUser,
		unite: unite,
		phoneclient: sendPhone,

		signaturecdf: `${getPhone}${Number(prix)}${Number(getbefore)}${parseInt(Number(getbefore) + Number(prix))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: Number(prix),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		delay: moment().format(),
		swap: arrayUnion(get),
		frais: 0,
		sendtype: 'Reçu'
	});



	delete window.swapInWithDocsAgentForSuperToClient;


};
async function swapInWithDocsAgentToClient(sendPhone, getPhone, sendUser, getUser, main, price, prix, unite, solde, comm, before, key, getbefore) {


	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');

	let send = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + prix).format(), actual: euro(solde + prix).format() + ' ' + unite, unite: unite }


	const sendRef = doc(db, "agent", sendPhone);
	await updateDoc(sendRef, {
		cdf: Number(main),
		money: prix,
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		delay: moment().format(),

		signaturecdf: `${sendPhone}${parseInt(Number(prix))}${parseInt(Number(before))}${parseInt(Number(before) - Number(prix))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: Number(prix),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		swap: arrayUnion(send),
		thriftcdf: increment(Number(comm)),
		commission: Number(comm),
		frais: 0,
		sendtype: 'envoyer'
	});

	const getRef = doc(db, "client", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(prix),
		money: prix,
		user: sendUser,
		unite: unite,
		phoneclient: sendPhone,
		delay: moment().format(),
		swap: arrayUnion(get),


		signaturecdf: `${getPhone}${parseInt(Number(prix))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(prix))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: parseInt(Number(prix)),
		signaturekeycdf: key,

		signaturefraiscdf: 0,

		frais: 0,
		sendtype: 'Reçu'
	});



	delete window.swapInWithDocsAgentToClient;

};

async function isSwapInWithClientToAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, fraisAgent, before, key, getbefore) {



	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');

	let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }


	let beforeSend = Number(secureLocalStorage.getItem("&##$$!CDF%!!")) - Number(money);
	let beforeGet = Number(secureLocalStorage.getItem("#$$!!$&%CDF@#")) + Number(money);


	const sendRef = doc(db, "client", sendPhone);
	await updateDoc(sendRef, {
		cdf: Number(main),
		money: Number(money),
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		beforecdf: Number(beforeSend),

		signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: frais,

		delay: moment().format(),
		swap: arrayUnion(send),
		frais: Number(frais),
		sendtype: 'envoyer'
	});

	const getRef = doc(db, "agent", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(money),
		money: Number(money),

		user: sendUser,
		unite: unite,
		thriftcdf: increment(Number(fraisAgent)),
		signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,


		phoneclient: sendPhone,
		delay: moment().format(),
		swap: arrayUnion(get),
		frais: 0,
		sendtype: 'Reçu',
		commission: Number(fraisAgent)
	});


	delete window.isSwapInWithClientToAgentForSuper;


};
async function isSwapInWithClientToAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, fraisAgent, before, key, getbefore) {


	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');

	let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }

	// let fraisAdmin = frais;

	let beforeSend = Number(secureLocalStorage.getItem("&##$$!CDF%!!")) - Number(money);
	let beforeGet = Number(secureLocalStorage.getItem("#$$!!$&%CDF@#")) + Number(money);


	const sendRef = doc(db, "client", sendPhone);
	await updateDoc(sendRef, {
		cdf: Number(main),
		money: Number(money),
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		beforecdf: Number(beforeSend),
		delay: moment().format(),
		signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,

		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: frais,

		swap: arrayUnion(send),
		frais: Number(frais),
		sendtype: 'envoyer'
	});

	const getRef = doc(db, "agent", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(money),
		money: Number(money),
		user: sendUser,
		unite: unite,
		thriftcdf: increment(Number(fraisAgent)),


		signaturecdf: `${getPhone}${Number(money)}${Number(getbefore)}${parseInt(Number(getbefore) + Number(money))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: Number(money),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		phoneclient: sendPhone,
		delay: moment().format(),
		swap: arrayUnion(get),
		frais: 0,
		sendtype: 'Reçu',
		commission: Number(fraisAgent)
	});

	delete window.isSwapInWithClientToAgent;

};
async function isSwapInWithClientToClient(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, key, before, getbefore) {


	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');

	let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
	let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }


	let beforeSend = Number(secureLocalStorage.getItem("&##$$!CDF%!!")) - Number(money);
	let beforeGet = Number(secureLocalStorage.getItem("#$$!!$&%CDF@#")) + Number(money);


	const sendRef = doc(db, "client", sendPhone);
	await updateDoc(sendRef, {
		cdf: (main),
		money: money,
		user: getUser,
		unite: unite,
		phoneclient: getPhone,
		beforecdf: Number(beforeSend),

		signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: frais,

		delay: moment().format(),
		swap: arrayUnion(send),
		frais: frais,
		sendtype: 'envoyer'

	});

	const getRef = doc(db, "client", getPhone);
	await updateDoc(getRef, {
		cdf: Number(price) + Number(money),
		money: money,
		user: sendUser,
		unite: unite,
		phoneclient: sendPhone,
		delay: moment().format(),


		signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
		signaturecdftype: 'reussi',
		signaturemoneycdf: parseInt(Number(money)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		swap: arrayUnion(get),
		frais: 0,
		sendtype: 'Reçu'
	});

	delete window.isSwapInWithClientToClient;

};


// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

	await setDoc(doc(db, "swap" + colSwap, key + date), {

		action: action,
		money: money,
		before: before,
		after: after,
		type: type,
		date: date,
		delay: date,

		phone: phone,
		user: user,
		access: false,
		profile: profile,
		beforethrift: beforethrift,
		afterthrift: afterthrift,
		commission: commision,
		frais: frais,
		key: key + date,
		devise: devise,

		soldebefore: soldebefore,
		soldeafter: soldeafter,

		color: '#EFF7FE',
		dateofcreate: serverTimestamp(),

		statetrans: true,
		statepret: false,
		stateunit: false,
		statetontine: false,
		statetv: false,
		statesolaire: false,

	});

};
async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

	await setDoc(doc(db, "swap" + colSwap, key + date), {


		action: action,
		money: money,
		before: before,
		after: after,
		type: type,
		date: date,
		delay: date,

		phone: phone,
		user: user,
		access: false,

		profile: profile,
		beforethrift: beforethrift,
		afterthrift: afterthrift,
		commission: commision,
		frais: frais,
		key: key + date,
		devise: devise,

		soldebefore: soldebefore,
		soldeafter: soldeafter,

		color: '#EFF7FE',
		dateofcreate: serverTimestamp(),

		statetransed: true,
		statepret: false,
		stateunit: false,
		statetontine: false,
		statetv: false,
		statesolaire: false,
	});

};

