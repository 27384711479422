import * as React from 'react';
import Avatar from '@mui/material/Avatar';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, onSnapshot, deleteField, getDocs, collection, deleteDoc } from "firebase/firestore";


import { db } from '../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';




export default function RetuRnTrash() {

 const navigation = useNavigate();
 const pushArray = new Array();

 const [open, setOpen] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [verify, setVerify] = React.useState(null);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };

 const [list, setList] = React.useState([]);
 const [phone, setPhone] = React.useState([]);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setList(doc.data().sharingmpall)
   // setCheck(doc.data().shareverify);
   setVerify(doc.data().shareverify === undefined ? false : doc.data().shareverify)
  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "share" + secureLocalStorage.getItem("USER")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushArray.push(doc.id);
  });

  setPhone([...new Set(pushArray)]);

 }, []);

 const handleOpenValid = async (event) => {

  event.preventDefault();
  setOpen(false);
  setLoad(true);

  await deleteDoc(doc(db, "sharecashbeck", secureLocalStorage.getItem("USER")));

  phone.forEach(async index => {
   await deleteDoc(doc(db, `share${secureLocalStorage.getItem("USER")}`, `${index}`));
  })

  const trashRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Atomically remove a region from the "regions" array field.
  await updateDoc(trashRef, {

   countsharing: 0,
   countsharingcdf: 0,
   sharingmpall: deleteField(),
   shareverify: deleteField(),
   sharingverifieraccess: deleteField(),
   shareverifysucces: deleteField(),


  });



  [...Array(list.length).keys()].map(async item => {

   const removeRef = doc(db, "client", list[item].idphone);
   await updateDoc(removeRef, {

    sharingdevise: deleteField(),
    sharingmoney: deleteField(),
    sharingverifier: deleteField(),
    countsharing: deleteField(),
    countsharingcdf: deleteField()

   });

  })

  window.setTimeout(() => {
   navigation(0);
  }, 9500)

 };


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <React.Fragment>
    <Dialog
     open={open}
     onClose={handleClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
    >
     <DialogTitle id="alert-dialog-title">
      <h1 className="pop-up">{"Cashbeck"}</h1>
     </DialogTitle>
     <DialogContent>
      <DialogContentText id="alert-dialog-description">

       <p className="pop-up">
        Voulez-vous annuler tous les utilisateurs souhaitant partager des monnaies électroniques
        du service Cashbeck
       </p>


      </DialogContentText>
     </DialogContent>
     <DialogActions>

      <div className="flex-between">
       <Button onClick={handleClose} autoFocus><p className="pop-up">Annuler</p></Button>
       <Button onClick={handleOpenValid}><p className="pop-up">Valider</p></Button>
      </div>


     </DialogActions>
    </Dialog>

   </React.Fragment>


   {verify &&
    <div onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
     <Tooltip title="Delete">
      <IconButton>

       <Avatar src="/img/trash-bin.png" />

      </IconButton>
     </Tooltip>
    </div>
   }

  </>
 );
};

