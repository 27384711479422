
import './Deposit.css';
import QuOTe from './Quote/Main';
import DeposiTIMA from './IMA/Main';
import Media from 'react-media';


// Deposit main
export default function RetuRnDepOsIT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};



export const ScreenLarge = () => (
 <div className='grid-template-repeat'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-template-repeat-sm'>
  <ViEw />
 </div>
);

export const ViEw = () => (
 <>
  <QuOTe />
  <DeposiTIMA />
 </>
);
