import './Group.css';
import NavBar from './Nav/Main';
import GRoupBody from './Body/Main';


// Group main tontine
export default function ReturnGrOup() {
 return (
  <div className='wrp-tontine-group'>
   <NavBar />
   <GRoupBody />
  </div>
 );
};