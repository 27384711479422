import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { collection, getDocs, doc, onSnapshot, updateDoc, setDoc, arrayUnion, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';
import { CiWifiOff } from "react-icons/ci";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import { v4 } from 'uuid';
import currency from 'currency.js';



let pushDocs = new Array();
let key = v4();



export async function buyUnitaddDocument(col, value, operator, currency, phone, price, credit, fiatFirstName, fiatLastName, lastsolde, key) {

  await setDoc(doc(db, col, secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value), {

    operator: operator,
    price: price,
    devise: currency,
    count: parseInt(credit),
    status: false,
    key: key,
    date: moment().format(),
    dockey: secureLocalStorage.getItem("#@%##key!!##"),

    fiat: 'unit' + secureLocalStorage.getItem("USER"),
    phone: phone,
    leader: secureLocalStorage.getItem("USER"),
    fiatname: fiatFirstName + ' ' + fiatLastName,
    copy: false,
    fiatdocument: secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value,
    lastsolde: lastsolde,
    remove: true
  }

  );

};


// View Form Update view
export default function ReturnFormUpdate() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <ScreenLarge />}
          {matches.large && <ScreenLarge />}
        </>
      )}
    </Media>
  );
};

export const ScreenLarge = () => (
  <div className='wrp-form-input-nows'>
    <FormInputField />
  </div>
);
export const ScreenSmall = () => (
  <div className='wrp-form-input-nows'>
    <FormInputField />
  </div>
);

export const FormInputField = () => {


  const navigation = useNavigate();
  const { handleSubmit, reset, control } = useForm();

  const [load, setLoad] = React.useState(false);
  const [pin, setPin] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [wifi, setWifi] = React.useState(false);
  const [problem, setProblem] = React.useState(false);



  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [showPassword, setShowPassword] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [solde, setSolde] = React.useState(false);

  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState('');
  const [profil, setProfil] = React.useState(null);

  const [thrift, setThrift] = React.useState(null);
  const [thriftcdf, setThriftcdf] = React.useState(null);

  const [pricecdf, setPricecdf] = React.useState(null);
  const [priceusd, setPriceusd] = React.useState(null);

  const [cmmstockcdf, setCmmstockcdf] = React.useState(null);
  const [cmmstockusd, setCmmstockusd] = React.useState(null);

  const [cmmunitstockcdf, setCmmunitstockcdf] = React.useState(null);
  const [cmmunitstockusd, setCmmunitstockusd] = React.useState(null);

  const [monthmoneycdf, setMonthmoneycdf] = React.useState(null);
  const [monthmoneyusd, setMonthmoneyusd] = React.useState(null);

  const [monthunitcdf, setMonthunitcdf] = React.useState(null);
  const [monthunitusd, setMonthunitusd] = React.useState(null);


  const [pack, setPack] = React.useState(null);
  const [unit, setUnit] = React.useState(null);
  const [unitusdMonth, setUnitusdMonth] = React.useState(null);
  const [moneyusdMonth, setMoneyusdMonth] = React.useState(null);

  const [unitcdfMonth, setUnitcdfMonth] = React.useState(null);
  const [moneycdfMonth, setMoneycdfMonth] = React.useState(null);


  const [packVoda, setPackVoda] = React.useState(null);
  const [unitvoda, setUnitvoda] = React.useState(null);

  const [packairtel, setPackairtel] = React.useState(null);
  const [unitairtel, setUnitairtel] = React.useState(null);

  const [packorange, setPackorange] = React.useState(null);
  const [unitorange, setUnitorange] = React.useState(null);

  const [packafricell, setPackafricell] = React.useState(null);
  const [unitafricell, setUnitafricell] = React.useState(null);


  const [signature, setSignature] = React.useState(null);
  const [signaturetype, setSignaturetype] = React.useState(null);
  const [signaturemoney, setSignaturemoney] = React.useState(null);
  const [signaturekey, setSignaturekey] = React.useState(null);


  const [signaturedollard, setSignaturedollard] = React.useState(null);
  const [signaturetypedollard, setSignaturetypedollard] = React.useState(null);
  const [signaturemoneydollard, setSignaturemoneydollard] = React.useState(null);
  const [signaturekeydollard, setSignaturekeydollard] = React.useState(null);


  const xmas = new Date();
  const tomonth = format(xmas, "LLLL").toLowerCase();



  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleClose = () => {
    setOpen(false);
  };
  const handlewifiClose = () => {
    setWifi(false);
  };
  const handleSolde = () => {
    setSolde(false);
  };
  const handleproblemClose = () => {
    setProblem(false);
    navigation('/dash');
  };



  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      pushDocs.push(doc.id);
    });

    const verifierCollection = pushDocs.some((value) => value == secureLocalStorage.getItem("USER"));
    const unsub = onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {

      setPin(doc.data().code);
      setStatus(doc.data().state);
      setFirst(doc.data().firstname);
      setLast(doc.data().lastname);
      setProfil(doc.data().profile);

      setPricecdf(doc.data().cdf);
      setPriceusd(doc.data().usd);

      setThrift(doc.data().thriftusd);
      setThriftcdf(doc.data().thriftcdf);

      setSignature(doc.data().signaturecdf);
      setSignaturetype(doc.data().signaturecdftype);
      setSignaturemoney(doc.data().signaturemoneycdf);
      setSignaturekey(doc.data().signaturekeycdf);

      setSignaturedollard(doc.data().signatureusd);
      setSignaturetypedollard(doc.data().signatureusdtype);
      setSignaturemoneydollard(doc.data().signaturemoneyusd);
      setSignaturekeydollard(doc.data().signaturekeyusd);

      setCmmstockcdf(doc.data().commistockcdf);
      setCmmstockusd(doc.data().commistockusd);

      setCmmunitstockcdf(doc.data().commistockunitecdf);
      setCmmunitstockusd(doc.data().commistockunite);


    });

  }, []);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganounitdatamonth", tomonth), (doc) => {

      setMonthmoneycdf(doc.data().moneycdf);
      setMonthmoneyusd(doc.data().moneyusd);
      setMonthunitcdf(doc.data().unitcdf);
      setMonthunitusd(doc.data().unitusd);

    });

  }, []);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganomoney", "stockunitaire"), (doc) => {

      setPack(doc.data().pack);
      setUnit(doc.data().unit);
      setUnitusdMonth(doc.data().monthunitusd);
      setMoneyusdMonth(doc.data().monthmoneyusd);

      setUnitcdfMonth(doc.data().monthunitcdf);
      setMoneycdfMonth(doc.data().monthmoneycdf);


      setPackVoda(doc.data().packvoda);
      setUnitvoda(doc.data().unitvoda);

      setPackairtel(doc.data().packairtel);
      setUnitairtel(doc.data().unitairtel);

      setPackorange(doc.data().packorange);
      setUnitorange(doc.data().unitorange);

      setPackafricell(doc.data().packafricell);
      setUnitafricell(doc.data().unitafricell);

    });

  }, []);



  // Number Valid of
  let OperaVoda = secureLocalStorage.getItem("voda#@**__");
  let OperaAirtel = secureLocalStorage.getItem("airtel#@**__");
  let OperaOrange = secureLocalStorage.getItem("orange#@**__");
  let OperaAfricell = secureLocalStorage.getItem("africell#@**__");

  let moneyVoda = secureLocalStorage.getItem("@!money%voda");
  let moneyAirtel = secureLocalStorage.getItem("@!money%airtel");
  let moneyOrange = secureLocalStorage.getItem("@!money%orange");
  let moneyAfricell = secureLocalStorage.getItem("@!money%africell");


  let moneyallmonthfran = Number(monthmoneycdf) + Number(moneyVoda) + Number(moneyAirtel) + Number(moneyOrange) + Number(moneyAfricell);
  let moneyallmonthdollard = Number(monthmoneyusd) + Number(moneyVoda) + Number(moneyAirtel) + Number(moneyOrange) + Number(moneyAfricell);


  let UniteVoda = secureLocalStorage.getItem("@!unite%voda");
  let UniteAirtel = secureLocalStorage.getItem("@!unite%airtel");
  let UniteOrange = secureLocalStorage.getItem("@!unite%orange");
  let UniteAfricell = secureLocalStorage.getItem("@!unite%africell");

  let unitallmonthfran = Number(monthunitcdf) + Number(UniteVoda) + Number(UniteAirtel) + Number(UniteOrange) + Number(UniteAfricell);
  let unitallmonthdollard = Number(monthunitusd) + Number(UniteVoda) + Number(UniteAirtel) + Number(UniteOrange) + Number(UniteAfricell);


  // get phone number 
  let numVoda = secureLocalStorage.getItem("^^^nu**+vod&");
  let phoneAirtel = secureLocalStorage.getItem("^^^nu**+air&");
  let phoneOrange = secureLocalStorage.getItem("^^^nu**+oran&");
  let phoneAfricell = secureLocalStorage.getItem("^^^nu**+afri&");

  // ID collections
  let fiatColl = secureLocalStorage.getItem("USER");
  let moneyBuy = Number(moneyVoda) + Number(moneyAirtel) + Number(moneyOrange) + Number(moneyAfricell);
  let unitBuy = Number(UniteVoda) + Number(UniteAirtel) + Number(UniteOrange) + Number(UniteAfricell);
  let allPack = Number(pack) + Number(OperaVoda) + Number(OperaAirtel) + Number(OperaOrange) + Number(OperaAfricell);


  // Form send AGENT
  let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(pricecdf) + Number(signaturemoney))}${parseInt((Number(pricecdf) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
  let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(pricecdf) - Number(signaturemoney))}${parseInt((Number(pricecdf) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
  let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


  // Form send AGENT DOLLARD
  let sendagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(priceusd) + Number(signaturemoneydollard))}${parseInt((Number(priceusd) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekeydollard}`;
  let getagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(priceusd) - Number(signaturemoneydollard))}${parseInt((Number(priceusd) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekeydollard}`;
  let checkagentdollard = signaturetypedollard === 'envoyer' ? sendagentdollard === signaturedollard : getagentdollard === signaturedollard;


  let argent = (Number(moneyVoda) + Number(moneyAirtel) + Number(moneyOrange) + Number(moneyAfricell));


  //  window.console.log(!!numVoda + ' ' + !!phoneAirtel + ' ' + !!phoneOrange + ' ' + !!phoneAfricell)


  const onSubmit = async (data) => {

    setLoad(true);
    let dataCollection = fiatColl;
    dataCollection = 'unite' + dataCollection;

    if (data.code === undefined || pin === '000000') {

      setOpen(true);
      setLoad(false);
      reset();

    } else {


      if (pin != data.code) {

        setOpen(true);
        setLoad(false);
        reset();


      } else {

        if (status === undefined || status == '' || first === undefined || first == '' || last == '' || pricecdf === null || pricecdf === undefined || priceusd === null || priceusd == undefined || monthmoneycdf === null || monthmoneycdf === undefined || monthmoneyusd === null || monthmoneyusd === undefined || cmmstockusd === null || cmmstockusd === undefined || cmmstockcdf === null || cmmstockcdf === undefined || cmmunitstockcdf === null || cmmunitstockcdf === undefined || cmmunitstockusd === null || cmmunitstockusd === undefined || pack === null || pack === undefined || unit === null || unit === undefined || unitusdMonth === null || unitusdMonth === undefined || moneyusdMonth === null || moneyusdMonth === undefined || unitcdfMonth === null || unitcdfMonth === undefined || moneycdfMonth === null || moneycdfMonth === undefined) {

          setWifi(true);
          setLoad(false);
          reset();

        }
        else if (packVoda === null || packVoda === undefined || unitvoda === null || unitvoda === undefined || packairtel === null || packairtel === undefined || unitairtel === null || unitairtel === undefined || packorange === null || packorange === undefined || unitorange === null || unitorange === undefined || packafricell === null || packafricell === undefined || unitafricell === null || unitafricell === undefined) {

          setWifi(true);
          setLoad(false);
          reset();


        } else {

          if (!!secureLocalStorage.getItem("@!access%devise")) {

            if (checkagentdollard) {

              if (OperaVoda) {


                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackVoda(packVoda + 1, unitvoda + UniteVoda);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 1, 'Vodacom', 'USD', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitvoda', 1, 'Vodacom', 'USD', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, priceusd, key);

                } else {

                  increasePackVoda(packVoda + 1, unitvoda + UniteVoda);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 1, 'Vodacom', 'CDF', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitvoda', 1, 'Vodacom', 'CDF', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, pricecdf, key);

                }

              }
              if (OperaAirtel) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackAirtel(packairtel + 1, unitairtel + UniteAirtel);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 2, 'Airtel', 'USD', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitairtel', 2, 'Airtel', 'USD', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, priceusd, key);

                } else {

                  increasePackAirtel(packairtel + 1, unitairtel + UniteAirtel);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 2, 'Airtel', 'CDF', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitairtel', 2, 'Airtel', 'CDF', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, pricecdf, key);



                }

              }
              if (OperaOrange) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackOrange(packorange + 1, unitorange + UniteOrange);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 3, 'Orange', 'USD', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitorange', 3, 'Orange', 'USD', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, priceusd, key);


                } else {

                  increasePackOrange(packorange + 1, unitorange + UniteOrange);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 3, 'Orange', 'CDF', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitorange', 3, 'Orange', 'CDF', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, pricecdf, key);

                }

              }
              if (OperaAfricell) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackAfricell(packafricell + 1, unitafricell + UniteAfricell);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 4, 'Africell', 'USD', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitafricell', 4, 'Africell', 'USD', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, priceusd, key);


                } else {

                  increasePackAfricell(packafricell + 1, unitafricell + UniteAfricell);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 4, 'Africell', 'CDF', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitafricell', 4, 'Africell', 'CDF', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, pricecdf, key);


                }

              }

              if (!!secureLocalStorage.getItem("@!access%devise")) {


                let soldDollard = Number(priceusd) - Number(argent);

                buyUsd(Number(soldDollard), status, argent, priceusd, thrift + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), key);
                increaseMonthusd(moneyallmonthdollard, unitallmonthdollard);
                stockIncreasePackUsd(allPack, unit + unitBuy, unitusdMonth + unitBuy, moneyusdMonth + moneyBuy);

                if (secureLocalStorage.getItem("@5@@88borker!!^&^*+&&@@")) {
                  if (secureLocalStorage.getItem("@#access@@^^rate!@!!^^&")) {

                    updatesoldusd(cmmstockusd + argent);
                    updatestockunite(cmmunitstockusd + unitBuy);

                  };

                }

              } else {

                let soldeFran = Number(pricecdf) - Number(argent);

                buyCdf(Number(soldeFran), status, argent, pricecdf, thriftcdf + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), key);
                increaseMonthcdf(moneyallmonthfran, unitallmonthfran);
                stockIncreasePackCdf(allPack, unit + unitBuy, unitcdfMonth + unitBuy, moneycdfMonth + moneyBuy);

                if (secureLocalStorage.getItem("@5@@88borker!!^&^*+&&@@")) {
                  if (secureLocalStorage.getItem("@#access@@^^rate!@!!^^&")) {

                    updatesoldcdf(cmmstockcdf + argent);
                    updatestockunitecdf(cmmunitstockcdf + unitBuy)


                  };

                }




              }


              swapSendUser(secureLocalStorage.getItem("USER"), `achat crédit`, argent, priceusd, priceusd - argent, 'envoyer', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thrift, thrift + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), 0, key + secureLocalStorage.getItem("&@#^^&&date#!!&&##"), 'USD', priceusd, priceusd, unitBuy, argent, Number(UniteVoda), Number(UniteAirtel), Number(UniteAfricell), Number(UniteOrange), Number(moneyVoda), Number(moneyAirtel), Number(moneyAfricell), Number(moneyOrange), numVoda, phoneAirtel, phoneOrange, phoneAfricell, !!numVoda, !!phoneAirtel, !!phoneOrange, !!phoneAfricell);



              window.setTimeout(() => {

                secureLocalStorage.removeItem("@!money%voda");
                secureLocalStorage.removeItem("@!money%airtel");
                secureLocalStorage.removeItem("@!money%orange");
                secureLocalStorage.removeItem("@!money%africell");


                secureLocalStorage.removeItem("@!unite%voda");
                secureLocalStorage.removeItem("@!unite%airtel");
                secureLocalStorage.removeItem("@!unite%orange");
                secureLocalStorage.removeItem("@!unite%africell");


                secureLocalStorage.removeItem("@!access%voda");
                secureLocalStorage.removeItem("@!access%airtel");
                secureLocalStorage.removeItem("@!access%orange");
                secureLocalStorage.removeItem("@!access%africell");


                secureLocalStorage.removeItem("^^^nu**+vod&");
                secureLocalStorage.removeItem("^^^nu**+air&");
                secureLocalStorage.removeItem("^^^nu**+oran&");
                secureLocalStorage.removeItem("^^^nu**+afri&");

                secureLocalStorage.removeItem("&@#^^&&date#!!&&##")

                secureLocalStorage.setItem("^*$#path**", false);
                navigation('/brokers/unite/thank');

              }, 3500);

            } else {

              setProblem(true);
              setLoad(false);

            }

          } else {

            if (checkagent) {

              if (OperaVoda) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {


                  increasePackVoda(packVoda + 1, unitvoda + UniteVoda);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 1, 'Vodacom', 'USD', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitvoda', 1, 'Vodacom', 'USD', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, priceusd, key);


                } else {

                  increasePackVoda(packVoda + 1, unitvoda + UniteVoda);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 1, 'Vodacom', 'CDF', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitvoda', 1, 'Vodacom', 'CDF', numVoda, Number(moneyVoda), parseInt(Number(UniteVoda)), first, last, pricecdf, key);


                }



              }
              if (OperaAirtel) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackAirtel(packairtel + 1, unitairtel + UniteAirtel);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 2, 'Airtel', 'USD', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitairtel', 2, 'Airtel', 'USD', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, priceusd, key);


                } else {

                  increasePackAirtel(packairtel + 1, unitairtel + UniteAirtel);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 2, 'Airtel', 'CDF', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitairtel', 2, 'Airtel', 'CDF', phoneAirtel, Number(moneyAirtel), parseInt(Number(UniteAirtel)), first, last, pricecdf, key);


                }

              }
              if (OperaOrange) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackOrange(packorange + 1, unitorange + UniteOrange);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 3, 'Orange', 'USD', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitorange', 3, 'Orange', 'USD', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, priceusd, key);


                } else {

                  increasePackOrange(packorange + 1, unitorange + UniteOrange);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 3, 'Orange', 'CDF', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitorange', 3, 'Orange', 'CDF', phoneOrange, Number(moneyOrange), parseInt(Number(UniteOrange)), first, last, pricecdf, key);

                }

              }
              if (OperaAfricell) {

                if (!!secureLocalStorage.getItem("@!access%devise")) {

                  increasePackAfricell(packafricell + 1, unitafricell + UniteAfricell);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 4, 'Africell', 'USD', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, priceusd, key);
                  buyUnitaddDocument('muunganounitafricell', 4, 'Africell', 'USD', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, priceusd, key);

                } else {

                  increasePackAfricell(packafricell + 1, unitafricell + UniteAfricell);
                  buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 4, 'Africell', 'CDF', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, pricecdf, key);
                  buyUnitaddDocument('muunganounitafricell', 4, 'Africell', 'CDF', phoneAfricell, Number(moneyAfricell), parseInt(Number(UniteAfricell)), first, last, pricecdf, key);


                }

              }

              if (!!secureLocalStorage.getItem("@!access%devise")) {

                buyUsd(Number(priceusd) - Number(argent), status, argent, priceusd, thrift + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), key);

                increaseMonthusd(moneyallmonthdollard, unitallmonthdollard);
                stockIncreasePackUsd(allPack, unit + unitBuy, unitusdMonth + unitBuy, moneyusdMonth + moneyBuy);

                if (secureLocalStorage.getItem("@5@@88borker!!^&^*+&&@@")) {
                  if (secureLocalStorage.getItem("@#access@@^^rate!@!!^^&")) {

                    updatesoldusd(cmmstockusd + argent);
                    updatestockunite(cmmunitstockusd + unitBuy);

                  };

                }

              } else {

                buyCdf(Number(pricecdf) - Number(argent), status, argent, pricecdf, thriftcdf + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), key);
                increaseMonthcdf(moneyallmonthfran, unitallmonthfran);
                stockIncreasePackCdf(allPack, unit + unitBuy, unitcdfMonth + unitBuy, moneycdfMonth + moneyBuy);

                if (secureLocalStorage.getItem("@5@@88borker!!^&^*+&&@@")) {
                  if (secureLocalStorage.getItem("@#access@@^^rate!@!!^^&")) {

                    updatesoldcdf(cmmstockcdf + argent);
                    updatestockunitecdf(cmmunitstockcdf + unitBuy)

                  };

                }

              }

              swapSendUser(secureLocalStorage.getItem("USER"), `achat crédit`, argent, pricecdf, pricecdf - argent, 'envoyer', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thriftcdf, thriftcdf + (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), (argent * secureLocalStorage.getItem("#@%^^^@@&&taux##stock") / 100), 0, key + secureLocalStorage.getItem("&@#^^&&date#!!&&##"), 'CDF', pricecdf, pricecdf, unitBuy, argent, Number(UniteVoda), Number(UniteAirtel), Number(UniteAfricell), Number(UniteOrange), Number(moneyVoda), Number(moneyAirtel), Number(moneyAfricell), Number(moneyOrange), numVoda, phoneAirtel, phoneOrange, phoneAfricell, !!numVoda, !!phoneAirtel, !!phoneOrange, !!phoneAfricell);

              window.setTimeout(() => {

                secureLocalStorage.removeItem("@!money%voda");
                secureLocalStorage.removeItem("@!money%airtel");
                secureLocalStorage.removeItem("@!money%orange");
                secureLocalStorage.removeItem("@!money%africell");


                secureLocalStorage.removeItem("@!unite%voda");
                secureLocalStorage.removeItem("@!unite%airtel");
                secureLocalStorage.removeItem("@!unite%orange");
                secureLocalStorage.removeItem("@!unite%africell");


                secureLocalStorage.removeItem("@!access%voda");
                secureLocalStorage.removeItem("@!access%airtel");
                secureLocalStorage.removeItem("@!access%orange");
                secureLocalStorage.removeItem("@!access%africell");


                secureLocalStorage.removeItem("^^^nu**+vod&");
                secureLocalStorage.removeItem("^^^nu**+air&");
                secureLocalStorage.removeItem("^^^nu**+oran&");
                secureLocalStorage.removeItem("^^^nu**+afri&");

                secureLocalStorage.removeItem("&@#^^&&date#!!&&##")


                secureLocalStorage.setItem("^*$#path**", false);
                navigation('/brokers/unite/thank');

              }, 3500);

            } else {

              setProblem(true);
              setLoad(false);

            }


          }


        };

      };


    }

  };

  return (
    <>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <FormControl
          sx={{ width: '100%' }}

          variant="standard">
          <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

          <Controller
            name="code"
            control={control}
            render={({ field }) =>

              <Input
                id="standard-adornment-password"
                {...field}
                type={showPassword ? 'numeric' : 'password'}

                inputProps={{
                  autoComplete: "off", inputMode: 'numeric'
                }}

                endAdornment={
                  <InputAdornment position="end">

                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>

                  </InputAdornment>
                }

              />}
          />

        </FormControl>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={wifi}
          onClose={handlewifiClose}>

          <DialogContent>

            <DialogContentText>
              <div className='block-flex-center-wifi'>
                <CiWifiOff size={'2em'} color={'crimson'} />
                <p className='pop-up'>
                  Connection internet faible

        </p>
              </div>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}>

          <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                PIN, Incorrect
     </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={solde}
          onClose={handleSolde}>

          <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                Le solde de votre compte est insuffisant
       </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleSolde}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>


        <button className='Btn'>Envoi</button>
      </form>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={problem}
        onClose={handleproblemClose}>
        <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
        <DialogContent>

          <DialogContentText>

            <p className='info-detect'>

              Cette transaction est temporairement suspendu,
              après vérification des informations sur
              cette transaction,  sur une situation
              incorrect des vos compte, soit de vous, soit de votre destinataire, veuillez contacter Muungano Money

								</p>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

    </>
  );
};


export async function buyCdf(prix, collections, solde, before, commthrift, comm, key) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');
  let send = { date: time, solde: `${euro(solde).format()} ${'CDF'}`, phone: secureLocalStorage.getItem("USER"), user: 'achat unité', type: 'envoyer', price: euro(solde).format(), actual: euro(prix).format() + ' ' + 'CDF', unite: 'CDF' }


  const washingtonRef = doc(db, collections, secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {

    cdf: (Number(prix)),

    signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(solde))}${parseInt(Number(before))}${parseInt(Number(before) - Number(solde))}${key}`,
    signaturecdftype: 'envoyer',
    signaturemoneycdf: parseInt(Number(solde)),
    signaturekeycdf: key,
    signaturefraiscdf: 0,
    user: 'achat unité',

    unite: 'CDF',
    money: (Number(solde)),
    swap: arrayUnion(send),
    sendtype: 'envoyer',

    commission: Number(comm),
    thriftcdf: commthrift,

    frais: 0,
    delay: moment().format()

  });


  delete window.buyCdf;

};
export async function buyUsd(prix, collections, solde, before, commthrift, comm, key) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');
  let send = { date: time, solde: `${euro(solde).format()} ${'USD'}`, phone: secureLocalStorage.getItem("USER"), user: 'achat unité', type: 'envoyer', price: euro(solde).format(), actual: euro(prix).format() + ' ' + 'USD', unite: 'USD' }


  const washingtonRef = doc(db, collections, secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {

    usd: (Number(prix)),

    signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(solde))}${parseInt(Number(before))}${parseInt(Number(before) - Number(solde))}${key}`,

    signatureusdtype: 'envoyer',
    signaturemoneyusd: parseInt(Number(solde)),
    signaturekeyusd: key,
    signaturefraisusd: 0,
    user: 'achat unité',

    money: (Number(solde)),
    swap: arrayUnion(send),

    commission: Number(comm),
    thriftusd: commthrift,

    sendtype: 'envoyer',
    unite: 'USD',
    frais: 0,
    delay: moment().format()

  });

  delete window.buyUsd;

};


// View component 
export async function updatesoldcdf(money) {

  const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    commistockcdf: money
  });

};
export async function updatesoldusd(money) {

  const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    commistockusd: money
  });
};
export async function updatestockunite(unite) {

  const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    commistockunite: unite
  });
};
export async function updatestockunitecdf(unite) {

  const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    commistockunitecdf: unite
  });
};


// Add all Command pack MuunganoMoney USD
export async function stockIncreasePackUsd(pack, unit, unitmonth, money) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    pack: pack,
    unit: unit,
    monthunitusd: unitmonth,
    monthmoneyusd: money,
  });
};
// Add all Command pack MuunganoMoney CDF
export async function stockIncreasePackCdf(pack, unit, unitmonth, money) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    pack: pack,
    unit: unit,
    monthunitcdf: unitmonth,
    monthmoneycdf: money,
  });
};


// Add all Command pack Vodacom
export async function increasePackVoda(pack, value) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    packvoda: pack,
    unitvoda: value
  });
};
// Add all Command pack Airtel
export async function increasePackAirtel(pack, value) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    packairtel: pack,
    unitairtel: value
  });
};
// Add all Command pack Orange
export async function increasePackOrange(pack, value) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    packorange: pack,
    unitorange: value
  });
};
// Add all Command pack Africell
export async function increasePackAfricell(pack, value) {

  const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    packafricell: pack,
    unitafricell: value
  });
};


export async function increaseMonthcdf(money, unit) {

  const xmas = new Date();
  const time = format(xmas, "LLLL").toLowerCase();

  const washingtonRef = doc(db, "muunganounitdatamonth", time);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    moneycdf: (money),
    unitcdf: (unit)
  });

}
export async function increaseMonthusd(money, unit) {

  const xmas = new Date();
  const time = format(xmas, "LLLL").toLowerCase();

  const washingtonRef = doc(db, "muunganounitdatamonth", time);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    moneyusd: (money),
    unitusd: (unit)
  });

}

// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter, unit, count, unitvoda, unitairtel, unitafricell, unitorange, countvoda, countairtel, countafricell, countorange, numvoda, numairtel, numorange, numafricell, fiatvoda, fiatairtel, fiatorange, fiatafricell) {

  const data = {
    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key,
    devise: devise,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    unit: unit,
    count: count,

    unitvoda: unitvoda,
    unitairtel: unitairtel,
    unitafricell: unitafricell,
    unitorange: unitorange,

    countvoda: countvoda,
    countairtel: countairtel,
    countafricell: countafricell,
    countorange: countorange,

    numvoda: numvoda,
    numairtel: numairtel,
    numafricell: numafricell,
    numorange: numorange,


    operavoda: fiatvoda,
    operaairtel: fiatairtel,
    operaorange: fiatorange,
    operaafricell: fiatafricell,


    fiatvoda: fiatvoda === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 1,
    fiatairtel: fiatairtel === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 2,
    fiatorange: fiatorange === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 3,
    fiatafricell: fiatafricell === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 4,

    vodastatus: true,
    airtelstatus: true,
    orangestatus: true,
    africellstatus: true,


    fiat: 'unit' + secureLocalStorage.getItem("USER"),
    fiatid: 'unit' + secureLocalStorage.getItem("USER"),
    typefiat: 'simple',
    cancel: true,

    color: '#F4F5F6',
    dateofcreate: serverTimestamp(),

    deletedoc: secureLocalStorage.getItem("&@#^^&&date#!!&&##"),
    team: secureLocalStorage.getItem("#@%$#%team"),

    dockey: secureLocalStorage.getItem("#@%##key!!##"),

    statetrans: false,
    statepret: false,
    stateunit: true,
    statetontine: false,
    statetv: false,
    statesolaire: false,
  };

  await setDoc(doc(db, "swap" + colSwap, secureLocalStorage.getItem("#@%##key!!##")), data);

};

