
import './View.css';
import ReturnNavBar from './Nav';
import ReturnShaRingList from './List';
import RetuRnBtn from './Btn';


// View Drawer Component
export default function RetuRnViEw() {
 return (
  <div
   className="grid-template-rows-auto
   padding-1">

   <ReturnNavBar />
   <ReturnShaRingList />
   <RetuRnBtn />

  </div>
 );
};