import React from 'react';
import { db } from '../../../../firebase';
import { collection, getDocs, doc, onSnapshot, arrayUnion, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import './List.css';
import { useForm, Controller } from 'react-hook-form';
import { CiSearch } from "react-icons/ci";
import Avatar from '@mui/material/Avatar';

import currency from 'currency.js';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import moment from 'moment';
import RetuRnDevIse from './Devise';





const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="00000000000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


const StyledBadge = styled(Badge)(({ theme }) => ({
 '& .MuiBadge-badge': {
  right: 3,
  top: 35,
  border: `2px solid ${theme.palette.background.paper}`,
  padding: '0 4px',
 },
}));




let Empty = true;
export let phoneID = '';
let phone = new Array();





// List Dash 
export default function ReturnLisTUseR() {
 return (
  <ViEw />
 );
};

export const ViEw = () => {

 const {
  register,
  watch,
  control,
  reset,
  handleSubmit,
 } = useForm();


 const navigation = useNavigate();

 let pushClient = new Array();
 let pushAgent = new Array();

 const [load, setLoad] = React.useState(false);
 const [listclient, setListclient] = React.useState([]);
 const [listagent, setListagent] = React.useState([]);

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');

 const [dollard, setDollard] = React.useState(0);
 const [fran, setFran] = React.useState(0);
 const [profil, setProfil] = React.useState(null);


 const [count, setCount] = React.useState(0);
 const [countcdf, setCountcdf] = React.useState(0);

 const [first, setFirst] = React.useState(null);
 const [last, setLast] = React.useState(null);
 const [shareverifysucces, setShareverifysucces] = React.useState(null);



 const [opn, setOpn] = React.useState(false);
 const handleClickOpn = () => {
  setOpn(true);
 };
 const handlClose = () => {
  setOpn(false);
  reset();
 };


 const [openx, setOpenx] = React.useState(false);
 const handleClosex = () => {
  setOpenx(false);
 };


 const [opens, setOpens] = React.useState(false);
 const handleCloses = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpens(false);
 };


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());

  });

  setListclient([... new Set(pushClient)])

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.data());

  });

  setListagent([... new Set(pushAgent)]);

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {

   setDollard(doc.data().usd);
   setFran(doc.data().cdf);

   setCount(doc.data().countsharing === undefined ? 0 : doc.data().countsharing);
   setCountcdf(doc.data().countsharingcdf === undefined ? 0 : doc.data().countsharingcdf);
   setShareverifysucces(doc.data().shareverifysucces === undefined ? 0 : doc.data().shareverifysucces);

   setProfil(doc.data().profile);

   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);


 let watchSearch = watch("search", false);
 let sourceIncome = watch("count");

 let Income = sourceIncome == undefined ? 0 : sourceIncome;
 let list = [...listclient];

 if (Array.isArray(list) && !list.length) {
  Empty = true;
 } else {
  Empty = false;
 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 watchSearch = watchSearch === undefined ? '' : watchSearch;

 const handlepath = (phone, state, data) => {

  if (data.sharingverifier) {
   
   setOpn(false);

  } else {

   if (shareverifysucces === true) {

    setOpn(false);

   }else {

   setOpn(true);
   secureLocalStorage.setItem("@#%%!state&&*@@#!!user++", state.toLowerCase());
   secureLocalStorage.setItem("@#%%!phone&&*@@#!!user++", phone);
   secureLocalStorage.setItem("@#%%*@@#!!user++", data);
   }

  }


 };
 const handleSharing = async (event) => {

  event.preventDefault();
  setOpn(false);
  setLoad(true);


  secureLocalStorage.setItem("@#%%*@@#", Number(Income) + Number(count));
  secureLocalStorage.setItem("@#%%*@@#CDF", Number(Income) + Number(countcdf));


  const sharingRef = doc(db, "client", `${secureLocalStorage.getItem("@#%%*@@#!!user++").idphone}`);
  // Set the "capital" field of the city 'DC'
  await updateDoc(sharingRef, {

   sharingverifier: true,
   sharingverifieraccess: true,
   sharingmoney: Number(Income),
   sharingdevise: !!secureLocalStorage.getItem("**@!##J@1777")

  });


  if (!!secureLocalStorage.getItem("**@!##J@1777")) {

   const sharingRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
   // Set the "capital" field of the city 'DC'
   await updateDoc(sharingRef, {
    
    countsharing: Number(secureLocalStorage.getItem("@#%%*@@#")),
    shareverify: true,
    sharingverifieraccess: true,

    sharedevise: !!secureLocalStorage.getItem("**@!##J@1777") ? "USD" : "CDF",
    
    sharingmpall: arrayUnion({

     profile: secureLocalStorage.getItem("@#%%*@@#!!user++").profile,
     sharingmoney: Number(Income),
     sharingdevise: "USD",
     firstname: secureLocalStorage.getItem("@#%%*@@#!!user++").firstname,
     lastname: secureLocalStorage.getItem("@#%%*@@#!!user++").lastname,
     idphone: secureLocalStorage.getItem("@#%%*@@#!!user++").idphone,

    })

   });


   // Add a new document in collection "cities"
   await setDoc(doc(db, "share" + secureLocalStorage.getItem("USER"), `${secureLocalStorage.getItem("@#%%*@@#!!user++").idphone}`), {

    profile: secureLocalStorage.getItem("@#%%*@@#!!user++").profile,
    profileagent: profil,
    money: Number(Income),
    devise: "USD",
    firstname: secureLocalStorage.getItem("@#%%*@@#!!user++").firstname,
    lastname: secureLocalStorage.getItem("@#%%*@@#!!user++").lastname,
    agentphone: secureLocalStorage.getItem("USER"),

    first: first,
    last: last,

    date: moment().format(),
    datecurrent: serverTimestamp()

   });

  } else {

   const sharingRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
   // Set the "capital" field of the city 'DC'
   await updateDoc(sharingRef, {
    countsharingcdf: Number(secureLocalStorage.getItem("@#%%*@@#CDF")),
    shareverify: true,
    sharingverifieraccess: true,
    sharedevise: !!secureLocalStorage.getItem("**@!##J@1777") ? "USD" : "CDF",
    sharingmpall: arrayUnion({

     profile: secureLocalStorage.getItem("@#%%*@@#!!user++").profile,
     sharingmoney: Number(Income),
     sharingdevise: "CDF",
     firstname: secureLocalStorage.getItem("@#%%*@@#!!user++").firstname,
     lastname: secureLocalStorage.getItem("@#%%*@@#!!user++").lastname,
     idphone: secureLocalStorage.getItem("@#%%*@@#!!user++").idphone,

    })

   });

   // Add a new document in collection "cities"
   await setDoc(doc(db, "share" + secureLocalStorage.getItem("USER"), `${secureLocalStorage.getItem("@#%%*@@#!!user++").idphone}`), {

    profile: secureLocalStorage.getItem("@#%%*@@#!!user++").profile,
    profileagent: profil,
    money: Number(Income),
    devise: "CDF",
    firstname: secureLocalStorage.getItem("@#%%*@@#!!user++").firstname,
    lastname: secureLocalStorage.getItem("@#%%*@@#!!user++").lastname,
    agentphone: secureLocalStorage.getItem("USER"),

    first: first,
    last: last,

    date: moment().format(),
    datecurrent: serverTimestamp()

   });

  }


  reset();
  navigation(0);

 };

 return (
  <>
   <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
    <Alert
     onClose={handleCloses}
     severity="error"
     variant="filled"
     sx={{ width: '100%' }}
    >

     <p className="pop-up">
      Jeton cashbeck est incorrecte
    </p>


    </Alert>
   </Snackbar>

   <React.Fragment>
    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={opn}
     onClose={handlClose}
     PaperProps={{
      component: 'form',
      onSubmit: (event) => {
       event.preventDefault();
       const formData = new FormData(event.currentTarget);
       const formJson = Object.fromEntries(formData.entries());
       const email = formJson.email;
       console.log(email);
       handlClose();
      },
     }}>

     <DialogTitle><h1 className="pop-up">Cashbeck</h1></DialogTitle>

     <DialogContent>
      <DialogContentText>

       <div className="flex-between">
        <Stack direction="column" spacing={1}>
         <Avatar
          src={secureLocalStorage.getItem("@#%%*@@#!!user++").profile}
          sx={{ width: 45, height: 45 }}
         />

         <p className="pop-up">{`${(secureLocalStorage.getItem("@#%%*@@#!!user++").firstname + "").toLowerCase()}`}</p>

        </Stack>

        <RetuRnDevIse
         value={Income}
        />
       </div>

       <p className="pop-up" style={{ marginTop : '3vh', color: "dodgerblue" }} >
      
        La modification de la devise. celle-ci incluse la réinitialisation complète de toute la liste établie
      
      </p>

      </DialogContentText>


      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Montant</h1></InputLabel>

       <Controller
        name="count"
        control={control}
        render={({ field }) =>

         <Input
          // autoFocus
          value={values.textmask}
          onChange={handleChange}
          inputRef={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}

          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}


          sx={{ fontSize: '1.3em', fontFamily: "Alata" }}
          InputProps={{
           inputComponent: NumericFormatCustom,
          }}

          inputmode="tel"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          {...field}

         />

        }
       />
      </FormControl>




     </DialogContent>

     <DialogActions>
      <div className="flex-between" style={{ padding: "0 15px" }}>

       <Button onClick={handlClose}><span className="pop-up" style={{ color: "#006494" }}>Annuler</span></Button>


       {!!secureLocalStorage.getItem("**@!##J@1777") === true &&
        Number(dollard) - Number(count) - Number(Income) > 0 &&
        <Button onClick={handleSharing}><span className="pop-up" style={{ color: "#006494" }}>Confirmer</span></Button>

       }

       {!!secureLocalStorage.getItem("**@!##J@1777") === false &&
        Number(fran) - Number(countcdf) - Number(Income) > 0 &&
        <Button onClick={handleSharing}><span className="pop-up" style={{ color: "#006494" }}>Confirmer</span></Button>
       }



      </div>
     </DialogActions>


    </Dialog>

   </React.Fragment>


   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {
    Empty === true
     ?
     <div></div>
     :
     <div className='wrp-column-hm-dash-list-user pad-left-right-3'>
      <form>
       <input type='search' {...register("search")} autocomplete="off"
        placeholder="Nom, Téléphone" />
       <CiSearch size={'1.8em'} />
      </form>

      <div className='wrp-hm-dash-list-user'>

       {['Utilisateur'].map((index) => {
        return (
         <h3>{index}</h3>
        )
       })}

      </div>

      <div>

       {[...list].filter((item) => {

        return watchSearch.toLowerCase() === '' ? '' : item.iduser.includes(watchSearch.toLowerCase());

       }).map(index => {

        let first = index.firstname.toLowerCase() + '';
        let last = index.lastname.toLowerCase() + '';

        let nameFirst = first.charAt(0).toUpperCase() + first.slice(1);
        let nameLast = last.charAt(0).toUpperCase() + last.slice(1);
        phoneID = index.idphone;

        return (
         <>
          <div onClick={() => handlepath(index.idphone, index.state, index)} className='wrp-hm-dash-list-user'>

           <span>
            <Badge
             overlap="circular"
             anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
             badgeContent={

              index.sharingverifier &&

              <div className='flex-badge-ima'>
               <img src={'/img/pret-valid.png'} style={{ width: '1.8em', height: '1.8em' }} />
              </div>

             }>

             <Avatar src={index.profile} sx={{ width: 50, height: 50 }} />
            </Badge>
            <div className='grid-start'>
             <p>{nameFirst} {nameLast}</p>
            </div>
           </span>

          </div>
         </>
        );
       })}

      </div>
     </div>
   }
  </>
 );
};