import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import './Btn.css';

import { doc, updateDoc, increment, onSnapshot, deleteDoc, arrayRemove, collection, getDocs, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';

import { CiWallet } from "react-icons/ci";
import Backdrop from '@mui/material/Backdrop';
import secureLocalStorage from "react-secure-storage";
import { CiWifiOff } from "react-icons/ci";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import { useNavigate } from 'react-router-dom';

import { v4 } from 'uuid';
import moment from 'moment';



let key = v4();


export default function ReturnAskedWallet() {


 let pushdelarr = new Array();
 const navigation = useNavigate();


 const [load, setLoad] = React.useState(false);
 const [asked, setAsked] = React.useState(null);
 const [rising, setRising] = React.useState(null);
 const [devise, setDevise] = React.useState('');
 const [countasked, setCountasked] = React.useState(null);

 const [delarray, setDelarray] = React.useState([]);
 const [namegroup, setNamegroup] = React.useState(null);
 const [problem, setProblem] = React.useState(false);

 const [count, setCount] = React.useState(null);
 const [position, setPosition] = React.useState(null);
 const [prixusd, setPrixusd] = React.useState(null);
 const [prixcdf, setPrixcdf] = React.useState(null);

 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');


 const [loading, setLoading] = React.useState(false);
 const [success, setSuccess] = React.useState(false);
 const [wifi, setWifi] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [thrift, setThrift] = React.useState(null);
 const [thriftcdf, setThriftcdf] = React.useState(null);

 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);
 const [signaturefrais, setSignaturefrais] = React.useState(null);

 const [signaturecdf, setSignaturecdf] = React.useState(null);
 const [signaturetypecdf, setSignaturetypecdf] = React.useState(null);
 const [signaturemoneycdf, setSignaturemoneycdf] = React.useState(null);
 const [signaturekeycdf, setSignaturekeycdf] = React.useState(null);
 const [signaturefraiscdf, setSignaturefraiscdf] = React.useState(null);


 const timer = React.useRef();


 const buttonSx = {
  ...(success && {
   bgcolor: green[500],
   '&:hover': {
    bgcolor: green[700],
   },
  }),
 };
 React.useEffect(() => {
  return () => {
   clearTimeout(timer.current);
  };
 }, []);
 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"), secureLocalStorage.getItem("USER")), (doc) => {
    setAsked(doc.data().asked === undefined ? false : doc.data().asked);
   });

  } catch (error) {
   window.console.log(error);
  }

 }, []);
 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, 'tontine', secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")), (doc) => {
    setDevise(doc.data().currency);
    setCount(doc.data().count);
    setPosition(doc.data().askedposition);
    setRising(doc.data().rising);
    setNamegroup(doc.data().namegroup)
    setCountasked(doc.data().countasked)
   });
  } catch (error) {
   window.console.log(error);
  }

 }, []);
 React.useEffect(async () => {

  try {

   const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {

    setPrixusd(doc.data().usd);
    setPrixcdf(doc.data().cdf);

    setFirst(doc.data().firstname);
    setLast(doc.data().lastname);

    setThrift(doc.data().thriftusd);
    setThriftcdf(doc.data().thriftcdf);

    setSignature(doc.data().signatureusd);
    setSignaturetype(doc.data().signatureusdtype);
    setSignaturemoney(doc.data().signaturemoneyusd);
    setSignaturekey(doc.data().signaturekeyusd);
    setSignaturefrais(doc.data().signaturefraisusd);

    setSignaturecdf(doc.data().signaturecdf);
    setSignaturetypecdf(doc.data().signaturecdftype);
    setSignaturemoneycdf(doc.data().signaturemoneycdf);
    setSignaturekeycdf(doc.data().signaturekeycdf);
    setSignaturefraiscdf(doc.data().signaturefraiscdf);


   });
  } catch (error) {
   window.console.log(error);
  }
 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushdelarr.push(doc.id);
  });

  setDelarray([...new Set(pushdelarr)])

 }, []);



 // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(prixusd) + Number(signaturemoney) + Number(signaturefrais))}${parseInt((Number(prixusd) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(prixusd) - Number(signaturemoney))}${parseInt((Number(prixusd) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;

 let sendclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(prixcdf) + Number(signaturemoneycdf) + Number(signaturefraiscdf))}${parseInt((Number(prixcdf) + Number(signaturemoneycdf)) - Number(signaturemoneycdf))}${signaturekeycdf}`;
 let getclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(prixcdf) - Number(signaturemoneycdf))}${parseInt((Number(prixcdf) - Number(signaturemoneycdf)) + Number(signaturemoneycdf))}${signaturekeycdf}`;
 let checkclientfran = signaturetypecdf === 'envoyer' ? sendclientfran === signaturecdf : getclientfran === signaturecdf;



 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };

 const handleButtonClick = async () => {

  if (asked === null || asked === undefined || rising === null || rising === undefined || devise === '' || devise === undefined || count === null || count === undefined || position === null || position === undefined || prixcdf === null || prixcdf === undefined || prixusd === null || prixusd === undefined || namegroup === null || namegroup === undefined) {

   setWifi(true);

  } else {

   if (checkclient && checkclientfran) {

    setLoad(true);

    if (count == (countasked + 1)) {

     if (devise.includes('USD')) {

      const soldeuserRef = doc(db, "client", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(soldeuserRef, {
       usd: Number(prixusd + asked),

       signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(asked))}${parseInt(Number(prixusd))}${parseInt(Number(prixusd) + Number(asked))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: parseInt(Number(asked)),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       money: asked,
       sendtype: 'Reçu',
       frais: 0,
       user: 'tontine',
       delay: moment().format(),
       unite: 'USD'

      });

      [...delarray].map(async (item) => {

       const deleteclientlistRef = doc(db, "client", `${item}`);
       // Atomically remove a region from the "regions" array field.
       await updateDoc(deleteclientlistRef, {

        grouptontine: arrayRemove(`${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`),
        grouptontinedevise: arrayRemove(`${devise}`),
        grouptontinemoney: arrayRemove(`${namegroup}${rising}`),
        grouptontinename: arrayRemove(`${namegroup}`)


       });

       await deleteDoc(doc(db, `${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`, `${item}`));

      })

      await deleteDoc(doc(db, "tontine", `${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`));

      window.setTimeout(async () => {
       secureLocalStorage.setItem("@!!#$$&&^==$$$", false)
       window.location.href = "/tontine";
      }, 2000);


     } else if (devise.includes('CDF')) {

      const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(washingtonRef, {

       cdf: Number(prixcdf + asked),

       signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(asked))}${parseInt(Number(prixcdf))}${parseInt(Number(prixcdf) + Number(asked))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: parseInt(Number(asked)),
       signaturekeycdf: key,
       signaturefraiscdf: 0,

       money: asked,
       sendtype: 'Reçu',
       frais: 0,
       user: 'tontine',
       delay: moment().format(),
       unite: 'CDF'


      });

      [...delarray].map(async (item) => {

       const deleteclientlistRef = doc(db, "client", `${item}`);
       // Atomically remove a region from the "regions" array field.
       await updateDoc(deleteclientlistRef, {

        grouptontine: arrayRemove(`${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`),
        grouptontinedevise: arrayRemove(`${devise}`),
        grouptontinemoney: arrayRemove(`${namegroup}${rising}`),
        grouptontinename: arrayRemove(`${namegroup}`)


       });

       await deleteDoc(doc(db, `${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`, `${item}`));

      });


      await deleteDoc(doc(db, "tontine", `${secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")}`));

      window.setTimeout(async () => {
       secureLocalStorage.setItem("@!!#$$&&^==$$$", false)
       window.location.href = "/tontine";
      }, 2000);

     } else {
      window.console.log('sorry not nothing');
     }

    } else {

     if (devise.includes('USD')) {

      const soldeuserRef = doc(db, "client", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(soldeuserRef, {
       usd: Number(prixusd + asked),

       signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(asked))}${parseInt(Number(prixusd))}${parseInt(Number(prixusd) + Number(asked))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: parseInt(Number(asked)),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       money: asked,
       sendtype: 'Reçu',
       frais: 0,
       user: 'tontine',
       delay: moment().format(),
       unite: 'USD'

      });

      const nullAskedRef = doc(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"), secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(nullAskedRef, {
       asked: 0
      });

      const tontineRef = doc(db, "tontine", secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(tontineRef, {
       countasked: increment(1)
      });

      window.setTimeout(async () => {
       secureLocalStorage.setItem("@!!#$$&&^==$$$", false);
       window.location.href = "/tontine";
      }, 2000);


     } else if (devise.includes('CDF')) {

      const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(washingtonRef, {
       cdf: Number(prixcdf + asked),

       signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(asked))}${parseInt(Number(prixcdf))}${parseInt(Number(prixcdf) + Number(asked))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: parseInt(Number(asked)),
       signaturekeycdf: key,
       signaturefraiscdf: 0,

       money: asked,
       sendtype: 'Reçu',
       frais: 0,
       user: 'tontine',
       delay: moment().format(),
       unite: 'CDF'


      });

      const nullAskedRef = doc(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"), secureLocalStorage.getItem("USER"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(nullAskedRef, {
       asked: 0
      });

      const tontineRef = doc(db, "tontine", secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"));
      // Set the "capital" field of the city 'DC'
      await updateDoc(tontineRef, {
       countasked: increment(1)
      });

      window.setTimeout(async () => {
       secureLocalStorage.setItem("@!!#$$&&^==$$$", false);
       window.location.href = "/tontine";
      }, 2000);


     } else {
      window.console.log('sorry not nothing');
     }

    }

    if (!loading) {

     setSuccess(false);
     setLoading(true);
     timer.current = window.setTimeout(async () => {
      setSuccess(true);
      setLoading(false);
     }, 7737);
    };

    if (devise.includes('USD')) {
     swapSendUser(secureLocalStorage.getItem("USER"), `récupération dela tontine`, Number(asked), Number(prixusd), Number(prixusd) + Number(asked), 'Reçu', moment().format(), '', first + ' ' + last, '', thrift, thrift, 0, 0, key, 'USD');
    } else {
     swapSendUser(secureLocalStorage.getItem("USER"), `récupération dela tontine`, Number(asked), Number(prixcdf), Number(prixcdf) + Number(asked), 'Reçu', moment().format(), '', first + ' ' + last, '', thriftcdf, thriftcdf, 0, 0, key, 'CDF');
    }



   } else {

    setProblem(true);
   }

  }

 };

 return (
  <>
   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

       Votre compte a été verrouillé,
       suite à une situation de litige.
       pour plus d'informations contacter cashbeck

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>


   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {count == position && asked > 0 &&
    <div className='wrp-btn-asked-tontine-withdraw'>
     <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
       <Fab
        aria-label="save"
        color="primary"
        sx={buttonSx}
        onClick={handleButtonClick}
       >
        {success ? <CheckIcon /> : <CiWallet size={'2.3em'} />}
       </Fab>
       {loading && (
        <CircularProgress
         size={68}
         sx={{
          color: green[500],
          position: 'absolute',
          top: -6,
          left: -6,
          zIndex: 1,
         }}
        />
       )}
      </Box>

     </Box>

    </div>
   }
  </>
 );
};


// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  access: false,
  key: key,
  devise: devise,

  id: newCityRef.id,

  color: '#eff6e0',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: true,
  statetv: false,
  statesolaire: false,

 });

};
