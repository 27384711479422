import './Phone.css';
import ReturnInput from './Input';

// Register Main Component 
export default function ReturnPhoneRegister() {
 return (
  <div className='wrp-phone'>
   <ReturnInput />
  </div>
 )
};