import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import ExChANge from './Exchange/Main';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import { db } from '../../../firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";



export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {

 let pushDocfran = new Array();
 let pushDoc = new Array();

 const [listcdf, setListcdf] = React.useState([]);
 const [list, setList] = React.useState([]);


 React.useEffect(async () => {

  const q = query(collection(db, "swap" + secureLocalStorage.getItem("USER")), orderBy('dateofcreate', 'desc'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {

   if (doc.data().devise === 'CDF' && doc.data().action != 'restauration') {
    pushDocfran.push(doc.data())
   };


  })

  setListcdf([...new Set(pushDocfran.slice(0, 20))]);

 }, []);
 React.useEffect(async () => {

  const q = query(collection(db, "swap" + secureLocalStorage.getItem("USER")), orderBy('dateofcreate', 'desc'));
  const snapshot = await getDocs(q);

  snapshot.forEach((doc) => {

   if (doc.data().devise === 'USD' && doc.data().action != 'restauration') {
    pushDoc.push(doc.data())
    window.console.log(doc.data())
   };

  })

  setList([...new Set(pushDoc.slice(0, 20))]);

 }, []);



 const [load, setLoad] = React.useState(false);
 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setLoad(true);

  secureLocalStorage.setItem("#$$!@fc$%list!!", listcdf);
  secureLocalStorage.setItem("#$$!@usd$%list!!", list);



  setState({ ...state, [anchor]: open });
  window.setTimeout(() => { setLoad(false); }, 5550);
 };


 return (
  <div>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, true)} className='tontine-nav-invited-icon-dash'>
      <IconButton>
       <div onClick={() => secureLocalStorage.setItem("###@devise^^##", true)}>
        <img
         src='/img/arrow.png'
        />
       </div>
      </IconButton>

     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <ExChANge />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 )
};
export const ScreenSmall = () => {


 let pushDocfran = new Array();
 let pushDoc = new Array();

 const [listcdf, setListcdf] = React.useState([]);
 const [list, setList] = React.useState([]);


 React.useEffect(async () => {

  const q = query(collection(db, "swap" + secureLocalStorage.getItem("USER")), orderBy('dateofcreate', 'desc'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {

   if (doc.data().devise === 'CDF' && doc.data().action != 'restauration') {
    pushDocfran.push(doc.data())
   };

  })

  setListcdf([...new Set(pushDocfran.slice(0, 20))]);

 }, []);
 React.useEffect(async () => {

  const q = query(collection(db, "swap" + secureLocalStorage.getItem("USER")), orderBy('dateofcreate', 'desc'));
  const snapshot = await getDocs(q);

  snapshot.forEach((doc) => {

   if (doc.data().devise === 'USD' && doc.data().action != 'restauration') {
    pushDoc.push(doc.data())
    window.console.log(doc.data())
   };

  })

  setList([...new Set(pushDoc.slice(0, 20))]);

 }, []);



 const [load, setLoad] = React.useState(false);
 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setLoad(true);

  secureLocalStorage.setItem("#$$!@fc$%list!!", listcdf);
  secureLocalStorage.setItem("#$$!@usd$%list!!", list);



  setState({ ...state, [anchor]: open });
  window.setTimeout(() => { setLoad(false); }, 5550);
 };


 return (
  <div>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, true)}>
      <IconButton>
       <div
        onClick={() => secureLocalStorage.setItem("###@devise^^##", true)}>
        <img
         src='/img/arrow.png'
        />
       </div>
      </IconButton>
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      PaperProps={{
       sx: { width: 300 }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <ExChANge />
     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};

