import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { getDocs, doc, onSnapshot, updateDoc, collection, setDoc, arrayUnion, increment, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import { CiWifiOff } from "react-icons/ci";
import { v4 } from 'uuid';
import currency from 'currency.js';




let key = v4();



export async function getDocsView(col, fiatDocs, data) {
	await setDoc(doc(db, col, fiatDocs), {
		date: secureLocalStorage.getItem("&@#^^&&date#!!&&##"), data: arrayUnion(data)
	}

	);
};
export async function buyUnitaddDocument(col, value, operator, phone, price, credit, fiatFirstName, fiatLastName, lastsolde, key) {

	await setDoc(doc(db, col, secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value), {

		operator: operator,
		price: price,
		devise: 'USD',
		count: parseInt(credit),
		status: false,
		date: secureLocalStorage.getItem("&@#^^&&date#!!&&##"),
		fiat: JSON.parse(window.localStorage.getItem('--vie&&id')),
		key: key,
		phone: phone,
		dockey: secureLocalStorage.getItem("#@%##key!!##"),
		leader: secureLocalStorage.getItem("USER"),
		fiatname: fiatFirstName + ' ' + fiatLastName,
		copy: false,
		fiatcollection: col,
		fiatdocument: secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value,
		lastsolde: lastsolde,

		remove: true

	}

	);
};
export async function buyUnitaddDocumentCDF(col, value, operator, phone, price, credit, fiatFirstName, fiatLastName, lastsolde, key) {

	await setDoc(doc(db, col, secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value), {

		operator: operator,
		price: price,
		devise: 'CDF',
		count: parseInt(credit),
		status: false,
		date: secureLocalStorage.getItem("&@#^^&&date#!!&&##"),
		key: key,
		fiat: JSON.parse(window.localStorage.getItem('--vie&&id')),
		phone: phone,
		leader: secureLocalStorage.getItem("USER"),
		fiatname: fiatFirstName + ' ' + fiatLastName,
		dockey: secureLocalStorage.getItem("#@%##key!!##"),
		copy: false,
		fiatcollection: col,
		fiatdocument: secureLocalStorage.getItem("&@#^^&&date#!!&&##") + value,
		lastsolde: lastsolde,

	}

	);
};
export async function updateTimeTransaction() {
	await setDoc(doc(db, secureLocalStorage.getItem("USER"), JSON.parse(window.localStorage.getItem('--vie&&id'))),
		{ date: moment().format() },
		{ merge: true });
};


// Unit Command Voda
export async function increaseCmduseVoda(unit) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Atomically increment the command use of the city by 1.
	await updateDoc(washingtonRef, {
		vodacmd: increment(1),
		vodacmdcopy: increment(1),
		unitvoda: increment(unit),
		pack: increment(1),
		unit: increment(unit)
	});

};
// Unit Command Airtel
export async function increaseCmduseAirtel(unit) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Atomically increment the command use of the city by 1.
	await updateDoc(washingtonRef, {
		airtelcmd: increment(1),
		airtelcmdcopy: increment(1),
		unitairtel: increment(unit),
		pack: increment(1),
		unit: increment(unit)
	});

};
// Unit Command Orange
export async function increaseCmduseOrange(unit) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Atomically increment the command use of the city by 1.
	await updateDoc(washingtonRef, {
		orangecmd: increment(1),
		orangecmdcopy: increment(1),
		unitorange: increment(unit),
		pack: increment(1),
		unit: increment(unit)
	});

};
// Unit Command Africell
export async function increaseCmduseAfricell(unit) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Atomically increment the command use of the city by 1.
	await updateDoc(washingtonRef, {
		africcellcmd: increment(1),
		africcellcmdcopy: increment(1),
		unitafricell: increment(unit),
		pack: increment(1),
		unit: increment(unit)
	});

};


// View Form Update view
export default function ReturnFormUpdate() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
};

export const ScreenLarge = () => (
	<div className='wrp-form-input-nows'>
		<FormInputField />
	</div>
);
export const ScreenSmall = () => (
	<div className='wrp-form-input-nows'>
		<FormInputField />
	</div>
);


export const FormInputField = () => {


	let pushDocs = new Array();
	const [load, setLoad] = React.useState(false);
	const [solde, setSolde] = React.useState(false);
	const [wifi, setWifi] = React.useState(false);


	const [signature, setSignature] = React.useState(null);
	const [signaturetype, setSignaturetype] = React.useState(null);
	const [signaturemoney, setSignaturemoney] = React.useState(null);
	const [signaturekey, setSignaturekey] = React.useState(null);

	const [signaturedollard, setSignaturedollard] = React.useState(null);
	const [signaturetypedollard, setSignaturetypedollard] = React.useState(null);
	const [signaturemoneydollard, setSignaturemoneydollard] = React.useState(null);
	const [signaturekeydollard, setSignaturekeydollard] = React.useState(null);


	const [problem, setProblem] = React.useState(false);


	let priceAfricell = secureLocalStorage.getItem("´ððprice˝˝africell");
	let priceOrange = secureLocalStorage.getItem("´ððprice˝˝orange");
	let priceAirtel = secureLocalStorage.getItem("´ððprice˝˝airtel");
	let priceVoda = secureLocalStorage.getItem("´ððprice˝˝voda");



	let numAfricell = secureLocalStorage.getItem("´ððchecked˝˝africell");
	let numVoda = secureLocalStorage.getItem("´ððchecked˝˝voda");
	let numAirtel = secureLocalStorage.getItem("´ððchecked˝˝airtel");
	let numOrange = secureLocalStorage.getItem("´ððchecked˝˝orange");


	let creditusdAfricell = parseInt(secureLocalStorage.getItem("´ððprice˝˝africell") / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell"));
	let creditusdorange = parseInt(secureLocalStorage.getItem("´ððprice˝˝orange") / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange"));
	let creditusdairtel = parseInt(secureLocalStorage.getItem("´ððprice˝˝airtel") / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel"));
	let creditusdvoda = parseInt(secureLocalStorage.getItem("´ððprice˝˝voda") / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda"));


	let creditcdfAfricell = parseInt(secureLocalStorage.getItem("´ððprice˝˝africell") / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell"));
	let creditcdforange = parseInt(secureLocalStorage.getItem("´ððprice˝˝orange") / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange"));
	let creditcdfairtel = parseInt(secureLocalStorage.getItem("´ððprice˝˝airtel") / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel"));
	let creditcdfvoda = parseInt(secureLocalStorage.getItem("´ððprice˝˝voda") / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda"));



	let creditallusd = creditusdAfricell + creditusdorange + creditusdairtel + creditusdvoda;
	let creditallcdf = creditcdfAfricell + creditcdforange + creditcdfairtel + creditcdfvoda;


	let countAll = numAfricell + numVoda + numAirtel + numOrange;
	let sumpriceAll = priceAfricell + priceOrange + priceAirtel + priceVoda;


	// window.console.log(numVoda + ' ' + numAirtel + ' ' + numOrange + ' ' + numAfricell)


	const navigation = useNavigate();
	const { handleSubmit, reset, control } = useForm({});

	const [pin, setPin] = React.useState(null);
	const [fiatFirstName, setFiatFirstName] = React.useState(null);
	const [endday, setEndday] = React.useState(null);
	const [fiatLastName, setFiatLastName] = React.useState(null);

	const [pricecdf, setPricecdf] = React.useState(null);
	const [priceusd, setPriceusd] = React.useState(null);
	const [status, setStatus] = React.useState(null);

	const [stock, setStock] = React.useState(null);


	const [first, setFirst] = React.useState('');
	const [last, setLast] = React.useState('');
	const [profil, setProfil] = React.useState(null);
	const [thrift, setThrift] = React.useState(null);
	const [thriftcdf, setThriftcdf] = React.useState(null);

	const [commistockcdf, setCommistockcdf] = React.useState(null);
	const [commistockusd, setCommistockusd] = React.useState(null);

	const [commistockunite, setCommistockunite] = React.useState(null);
	const [commistockunitecdf, setCommistockunitecdf] = React.useState(null);


	const [pack, setPack] = React.useState(null);
	const [unit, setUnit] = React.useState(null);
	const [monthunitusd, setMonthunitusd] = React.useState(null);
	const [monthmoneyusd, setMonthmoneyusd] = React.useState(null);


	const [packcdf, setPackcdf] = React.useState(null);
	const [unitcdf, setUnitcdf] = React.useState(null);
	const [monthunitcdf, setMonthunitcdf] = React.useState(null);
	const [monthmoneycdf, setMonthmoneycdf] = React.useState(null);


	const [packvoda, setPackvoda] = React.useState(null);
	const [unitvoda, setUnitvoda] = React.useState(null);

	const [packairtel, setPackairtel] = React.useState(null);
	const [unitairtel, setUnitairtel] = React.useState(null);

	const [packorange, setPackorange] = React.useState(null);
	const [unitorange, setUnitorange] = React.useState(null);

	const [packafricell, setPackafricell] = React.useState(null);
	const [unitafricell, setUnitafricell] = React.useState(null);



	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');
	const [open, setOpen] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);



	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleproblemClose = () => {
		setProblem(false);
		navigation('/dash');
	};


	const handleClose = () => {
		setOpen(false);
	};
	const handleSolde = () => {
		setSolde(false);
	};
	const handlewifiClose = () => {
		setWifi(false);
		navigation('/dash');
	};


	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "client"));
		querySnapshot.forEach((doc) => {
			pushDocs.push(doc.id);
		});

		const verifierCollection = pushDocs.some((value) => value == secureLocalStorage.getItem("USER"));
		const unsub = onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {

			setPin(doc.data().code);
			setStatus(doc.data().state);
			setPriceusd(doc.data().usd);
			setPricecdf(doc.data().cdf);
			setProfil(doc.data().profile);
			setThrift(doc.data().thriftusd);
			setThriftcdf(doc.data().thriftcdf);

			setFirst(doc.data().firstname);
			setLast(doc.data().lastname);

			setSignature(doc.data().signaturecdf);
			setSignaturetype(doc.data().signaturecdftype);
			setSignaturemoney(doc.data().signaturemoneycdf);
			setSignaturekey(doc.data().signaturekeycdf);

			setSignaturedollard(doc.data().signatureusd);
			setSignaturetypedollard(doc.data().signatureusdtype);
			setSignaturemoneydollard(doc.data().signaturemoneyusd);
			setSignaturekeydollard(doc.data().signaturekeyusd);

		});

	}, []);
	React.useEffect(async () => {
		const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("USER"), secureLocalStorage.getItem("--vie&&id")), (doc) => {
			setFiatFirstName(doc.data().firstname);
			setFiatLastName(doc.data().lastname);
		});

	}, []);
	React.useEffect(async () => {

		const unsub = onSnapshot(doc(db, "muunganomoney", "stockunitaire"), (doc) => {

			setEndday(doc.data().enddayincrease);

			setPack(doc.data().pack);
			setUnit(doc.data().unit);
			setMonthunitusd(doc.data().monthunitusd);
			setMonthmoneyusd(doc.data().monthmoneyusd);


			setPackcdf(doc.data().pack);
			setUnitcdf(doc.data().unit);
			setMonthunitcdf(doc.data().monthunitcdf);
			setMonthmoneycdf(doc.data().monthmoneycdf);

			setPackvoda(doc.data().packvoda);
			setUnitvoda(doc.data().unitvoda);

			setPackairtel(doc.data().packairtel);
			setUnitairtel(doc.data().unitairtel);

			setPackorange(doc.data().packorange);
			setUnitorange(doc.data().unitorange);

			setPackafricell(doc.data().packafricell);
			setUnitafricell(doc.data().unitafricell);


		});

	}, []);
	React.useEffect(async () => {
		const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
			setCommistockcdf(doc.data().commistockcdf);
			setCommistockusd(doc.data().commistockusd);
			setCommistockunite(doc.data().commistockunite);
			setCommistockunitecdf(doc.data().commistockunitecdf);
		});

	}, []);
	React.useEffect(() => {

		const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
			setStock(doc.data().stock);
		});

	}, []);


	// Form send AGENT
	let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(pricecdf) + Number(signaturemoney))}${parseInt((Number(pricecdf) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
	let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(pricecdf) - Number(signaturemoney))}${parseInt((Number(pricecdf) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
	let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


	// Form send AGENT DOLLARD
	let sendagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(priceusd) + Number(signaturemoneydollard))}${parseInt((Number(priceusd) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekeydollard}`;
	let getagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(priceusd) - Number(signaturemoneydollard))}${parseInt((Number(priceusd) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekeydollard}`;
	let checkagentdollard = signaturetypedollard === 'envoyer' ? sendagentdollard === signaturedollard : getagentdollard === signaturedollard;


	const onSubmit = async (data) => {

		setLoad(true);

		if (moment().date() - 1 === endday) {

			const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
			// Set the "capital" field of the city 'DC'
			await updateDoc(washingtonRef, {

				enddayincrease: moment().date(),


				africellincreasemoneycdf: 0,
				africellincreasemoneyusd: 0,

				africellincreaseunitcdf: 0,
				africellincreaseunitusd: 0,

				airtelincreasemoneycdf: 0,
				airtelincreasemoneyusd: 0,

				airtelincreaseunitcdf: 0,
				airtelincreaseunitusd: 0,

				orangeincreasemoneycdf: 0,
				orangeincreasemoneyusd: 0,

				orangeincreaseunitcdf: 0,
				orangeincreaseunitusd: 0,

				vodaincreasemoneycdf: 0,
				vodaincreasemoneyusd: 0,

				vodaincreaseunitcdf: 0,
				vodaincreaseunitusd: 0,

			});

		} else {
			window.console.log(moment().format());
		}

		if (data.code === undefined || pin === '000000' || pin === null || pin != data.code) {

			setOpen(true);
			setLoad(false);
			reset();

		} else if (commistockunitecdf === null || commistockunitecdf === undefined || commistockunite === null || commistockunite === undefined || commistockcdf === null || commistockcdf === undefined || fiatFirstName === null || fiatFirstName === undefined || endday === null || endday === undefined || fiatLastName === null || fiatLastName === undefined || pricecdf === null || pricecdf === undefined || priceusd === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || signaturekey === null || signaturekey === undefined || signaturedollard === null || signaturedollard === undefined || signaturetypedollard === null || signaturetypedollard === undefined || signaturemoneydollard === null || signaturemoneydollard === undefined || signaturekeydollard === null || signaturekeydollard === undefined || first === null || first === undefined || last === null || last === undefined) {

			setWifi(true);
			setLoad(false);

		} else {

			if (pin != data.code) {
				setOpen(true);
				setLoad(false);
				reset();

			} else {

				if (pin != data.code) {
					setOpen(true);
					setLoad(false);
					reset();
				} else {

					if (secureLocalStorage.getItem("&&checked**stock##")) {

						if (checkagentdollard) {

							if (priceusd >= 1) {

								if (priceAfricell > 0.5) {

									let credit = Number(priceAfricell) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell");

									increaseMonthusd(parseInt(Number(priceAfricell)), parseInt(credit));
									increasePackAfricell(packafricell + 1, parseInt(credit + unitafricell));

									buyUnitaddDocument(secureLocalStorage.getItem("--vie&&id"), 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);
									buyUnitaddDocument('muunganounitafricell', 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)
									buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)

								};
								if (priceOrange > 0.5) {

									let credit = Number(priceOrange) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange");

									increaseMonthusd(parseInt(Number(priceOrange)), parseInt(credit));
									increasePackOrange(packorange + 1, parseInt(credit + unitorange));

									buyUnitaddDocument(secureLocalStorage.getItem("--vie&&id"), 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);
									buyUnitaddDocument('muunganounitorange', 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)
									buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)

								};
								if (priceAirtel > 0.5) {

									let credit = Number(priceAirtel) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel");

									increaseMonthusd(parseInt(Number(priceAirtel)), parseInt(credit));
									increasePackAirtel(packairtel + 1, parseInt(credit + unitairtel));

									buyUnitaddDocument(secureLocalStorage.getItem("--vie&&id"), 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);
									buyUnitaddDocument('muunganounitairtel', 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);
									buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);

								};
								if (priceVoda > 0.5) {

									let credit = Number(priceVoda) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda");

									increaseMonthusd(parseInt(Number(priceVoda)), parseInt(credit));
									increasePackVoda(packvoda + 1, parseInt(credit + unitvoda));


									buyUnitaddDocument(secureLocalStorage.getItem("--vie&&id"), 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key);
									buyUnitaddDocument('muunganounitvoda', 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)
									buyUnitaddDocument('unit' + secureLocalStorage.getItem("USER"), 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, priceusd, key)


								};

								swapSendUser(secureLocalStorage.getItem("USER"), `achat crédit`, sumpriceAll, priceusd, priceusd - sumpriceAll, 'envoyer', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thrift, thrift + (sumpriceAll * stock / 100), (sumpriceAll * stock / 100), 0, key + moment().format(), 'USD', priceusd, priceusd, creditallusd, sumpriceAll, Number(creditusdvoda), Number(creditusdairtel), Number(creditusdAfricell), Number(creditusdorange), Number(priceVoda), Number(priceAirtel), Number(priceAfricell), Number(priceOrange), secureLocalStorage.getItem("^&@##voda!!@@^^#"), secureLocalStorage.getItem("^&@##airtel!!@@^^#"), secureLocalStorage.getItem("^&@##orange!!@@^^#"), secureLocalStorage.getItem("^&@##africel!!@@^^#"), !!numVoda, !!numAirtel, !!numOrange, !!numAfricell);

								stockIncreasePackUsd(parseInt(unit + creditallusd), sumpriceAll, pack + countAll, monthunitusd, monthmoneyusd);
								updatesoldusd(secureLocalStorage.getItem("&&usdvalue**stock##"), commistockunite + creditallusd, commistockusd, thrift + (sumpriceAll * stock / 100), (sumpriceAll * stock / 100), priceusd, key);

							} else {


								setSolde(true);


							}

							updateTimeTransaction(moment().format());
							window.setTimeout(() => {

								window.localStorage.setItem('--vie&&id', JSON.stringify(null));
								window.localStorage.setItem('--vie&&first**', JSON.stringify(null));
								window.localStorage.setItem('--vie&&last**', JSON.stringify(null));
								window.localStorage.setItem('&&check**stock', JSON.stringify(false));
								navigation('/brokers/unite/thank');
							}, 3450);

						} else {

							setProblem(true);
							setLoad(false);

						}

					} else {

						if (checkagent) {

							if (pricecdf >= 2000) {

								if (priceAfricell > 10) {

									let credit = Number(priceAfricell) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell");

									increaseMonthcdf(parseInt(Number(priceAfricell)), parseInt(credit));
									increasePackAfricell(packafricell + 1, parseInt(credit + unitafricell));

									buyUnitaddDocumentCDF(secureLocalStorage.getItem("--vie&&id"), 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key);
									buyUnitaddDocumentCDF('muunganounitafricell', 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)
									buyUnitaddDocumentCDF('unit' + secureLocalStorage.getItem("USER"), 1, 'Africell', secureLocalStorage.getItem("^&@##africel!!@@^^#"), priceAfricell, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)

								};
								if (priceOrange > 10) {
									let credit = Number(priceOrange) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange");

									increaseMonthcdf(parseInt(Number(priceOrange)), parseInt(credit));
									increasePackOrange(packorange + 1, parseInt(credit + unitorange));

									buyUnitaddDocumentCDF(secureLocalStorage.getItem("--vie&&id"), 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key);
									buyUnitaddDocumentCDF('muunganounitorange', 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)
									buyUnitaddDocumentCDF('unit' + secureLocalStorage.getItem("USER"), 2, 'Orange', secureLocalStorage.getItem("^&@##orange!!@@^^#"), priceOrange, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)


								};
								if (priceAirtel > 10) {

									let credit = Number(priceAirtel) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel");

									increaseMonthcdf(parseInt(Number(priceAirtel)), parseInt(credit));
									increasePackAirtel(packairtel + 1, parseInt(credit + unitairtel));

									buyUnitaddDocumentCDF(secureLocalStorage.getItem("--vie&&id"), 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key);
									buyUnitaddDocumentCDF('muunganounitairtel', 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)
									buyUnitaddDocumentCDF('unit' + secureLocalStorage.getItem("USER"), 3, 'Airtel', secureLocalStorage.getItem("^&@##airtel!!@@^^#"), priceAirtel, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)


								};
								if (priceVoda > 10) {

									let credit = Number(priceVoda) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda");

									increaseMonthcdf(parseInt(Number(priceVoda)), parseInt(credit));
									increasePackVoda(packvoda + 1, parseInt(credit + unitvoda));

									buyUnitaddDocumentCDF(secureLocalStorage.getItem("--vie&&id"), 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key);
									buyUnitaddDocumentCDF('muunganounitvoda', 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)
									buyUnitaddDocumentCDF('unit' + secureLocalStorage.getItem("USER"), 4, 'Vodacom', secureLocalStorage.getItem("^&@##voda!!@@^^#"), priceVoda, parseInt(credit), fiatFirstName, fiatLastName, pricecdf, key)

								};

								swapSendUser(secureLocalStorage.getItem("USER"), `achat crédit`, sumpriceAll, pricecdf, pricecdf - sumpriceAll, 'envoyer', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thriftcdf, thriftcdf + (sumpriceAll * stock / 100), (sumpriceAll * stock / 100), 0, key + moment().format(), 'CDF', pricecdf, pricecdf, creditallcdf, sumpriceAll, Number(creditcdfvoda), Number(creditcdfairtel), Number(creditcdfAfricell), Number(creditcdforange), Number(priceVoda), Number(priceAirtel), Number(priceAfricell), Number(priceOrange), secureLocalStorage.getItem("^&@##voda!!@@^^#"), secureLocalStorage.getItem("^&@##airtel!!@@^^#"), secureLocalStorage.getItem("^&@##orange!!@@^^#"), secureLocalStorage.getItem("^&@##africel!!@@^^#"), !!numVoda, !!numAirtel, !!numOrange, !!numAfricell);

								stockIncreasePackCdf(parseInt(unit + creditallcdf), sumpriceAll, pack + countAll, monthunitcdf, monthmoneycdf);
								updatesoldcdf(secureLocalStorage.getItem("&&cdfvalue**stock##"), creditallcdf + commistockunitecdf, commistockcdf, thriftcdf + (sumpriceAll * stock / 100), (sumpriceAll * stock / 100), pricecdf, key);


							} else {

								setSolde(true);

							}

							updateTimeTransaction(moment().format());
							window.setTimeout(() => {

								window.localStorage.setItem('--vie&&id', JSON.stringify(null));
								window.localStorage.setItem('--vie&&first**', JSON.stringify(null));
								window.localStorage.setItem('--vie&&last**', JSON.stringify(null));

								window.localStorage.setItem('&&check**stock', JSON.stringify(false));
								navigation('/brokers/unite/thank');
							}, 3450);

						} else {

							setProblem(true);
							setLoad(false);

						}

					}

				}



			};

		}

	};

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>

				<FormControl
					sx={{ width: '100%' }}

					variant="standard">
					<InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

					<Controller
						name="code"
						control={control}
						render={({ field }) =>

							<Input
								id="standard-adornment-password"
								{...field}
								type={showPassword ? 'numeric' : 'password'}

								inputProps={{
									autoComplete: "off", inputMode: 'numeric'
								}}
								endAdornment={
									<InputAdornment position="end">

										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>

									</InputAdornment>
								}

							/>}
					/>

				</FormControl>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}>

					<DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								Code pin incorrect
     </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={solde}
					onClose={handleSolde}>

					<DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								Le solde de votre compte est insuffisant
       </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleSolde}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>


				<button className='Btn-Broker'>Envoi</button>
			</form>


			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={wifi}
				onClose={handlewifiClose}>

				<DialogContent>

					<DialogContentText>
						<div className='block-flex-center-wifi'>
							<CiWifiOff size={'2em'} color={'crimson'} />
							<p className='pop-up'>
								Connection internet faible

        </p>
						</div>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={problem}
				onClose={handleproblemClose}>
				<DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

							Cet transaction est temporairement suspendu,
							après vérification des informations sur
							cette transaction,  sur une situation
							incorrect des vos compte, soit de vous, soit de votre destinataire, veuillez contacter Muungano Money

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>


		</>
	);
};



// View component  CDF
export async function updatesoldcdf(money, unite, comm, commthrift, comFran, before, key) {

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let solde = secureLocalStorage.getItem("&@#^^#money6229@#&&##");
	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(solde).format()} ${'CDF'}`, phone: secureLocalStorage.getItem("USER"), user: 'achat unité', type: 'envoyer', price: euro(solde).format(), actual: euro(money).format() + ' ' + 'CDF', unite: 'CDF' }

	let commFran = comm + secureLocalStorage.getItem("&@#^^#money6229@#&&##");

	const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		cdf: money,
		commistockcdf: commFran,
		commistockunitecdf: unite,


		signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(solde))}${parseInt(Number(before))}${parseInt(Number(before) - Number(solde))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(solde)),
		signaturekeycdf: key,
		user: 'achat unité',
		money: parseInt(Number(solde)),
		swap: arrayUnion(send),
		commission: Number(comFran),
		sendtype: 'envoyer',
		unite: 'CDF',
		frais: 0,
		delay: moment().format(),

		thriftcdf: commthrift

	});


	delete window.updatesoldcdf;


};
// View component USD
export async function updatesoldusd(money, unite, comm, commthrift, commDollard, before, key) {

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

	let solde = secureLocalStorage.getItem("&@#^^#money6229@#&&##");
	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(solde).format()} ${'USD'}`, phone: secureLocalStorage.getItem("USER"), user: 'achat unité', type: 'envoyer', price: euro(solde).format(), actual: euro(money).format() + ' ' + 'USD', unite: 'USD' }

	let commFran = comm + secureLocalStorage.getItem("&@#^^#money6229@#&&##");

	const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		usd: money,
		commistockusd: commFran,
		commistockunite: unite,


		signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(solde))}${parseInt(Number(before))}${parseInt(Number(before) - Number(solde))}${key}`,
		signatureusdtype: 'envoyer',
		signaturemoneyusd: parseInt(Number(solde)),
		signaturekeyusd: key,
		sendtype: 'envoyer',

		user: 'achat unité',
		money: parseInt(Number(solde)),
		swap: arrayUnion(send),
		commission: Number(commDollard),
		unite: 'USD',
		frais: 0,
		delay: moment().format(),
		thriftusd: commthrift

	});

	delete window.updatesoldusd;

};

// View component Unit 
export async function updatestockunite(unite) {

	const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		commistockunite: unite
	});
};
export async function updatestockunitecdf(unite) {

	const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		commistockunitecdf: unite
	});
};


// Add all Command pack MuunganoMoney USD
export async function stockIncreasePackUsd(unit, money, pack, monthunitusd, monthmoneyusd) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		pack: pack,
		unit: unit,
		monthunitusd: unit + monthunitusd,
		monthmoneyusd: money + monthmoneyusd,
	});
};
// Add all Command pack MuunganoMoney CDF
export async function stockIncreasePackCdf(unit, money, pack, monthunitcdf, monthmoneycdf) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		pack: pack,
		unit: unit,
		monthunitcdf: unit + monthunitcdf,
		monthmoneycdf: money + monthmoneycdf,
	});
};


// Add all Command pack Vodacom
export async function increasePackVoda(packvoda, value) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		packvoda: packvoda,
		unitvoda: value
	});


};
// Add all Command pack Airtel
export async function increasePackAirtel(packairtel, value) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {

		packairtel: packairtel,
		unitairtel: value

	});
};
// Add all Command pack Orange
export async function increasePackOrange(packorange, value) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		packorange: packorange,
		unitorange: value

	});
};
// Add all Command pack Africell
export async function increasePackAfricell(packafricell, value) {

	const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {

		packafricell: packafricell,
		unitafricell: value

	});
};


// Month increase CDF
export async function increaseMonthcdf(money, unit) {

	const xmas = new Date();
	const time = format(xmas, "LLLL").toLowerCase();

	const washingtonRef = doc(db, "muunganounitdatamonth", time);
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		moneycdf: increment(money),
		unitcdf: increment(unit)
	});

};
// Mon  th increase USD
export async function increaseMonthusd(money, unit) {

	const xmas = new Date();
	const time = format(xmas, "LLLL").toLowerCase();

	const washingtonRef = doc(db, "muunganounitdatamonth", time);
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		moneyusd: increment(money),
		unitusd: increment(unit)
	});

};

// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter, unit, count, unitvoda, unitairtel, unitafricell, unitorange, countvoda, countairtel, countafricell, countorange, numvoda, numairtel, numorange, numafricell, fiatvoda, fiatairtel, fiatorange, fiatafricell) {

	const data = {
		action: action,
		money: money,
		before: before,
		after: after,
		type: type,
		date: date,
		delay: date,

		phone: phone,
		user: user,

		profile: profile,
		beforethrift: beforethrift,
		afterthrift: afterthrift,
		commission: commision,
		frais: frais,
		key: key,
		devise: devise,

		soldebefore: soldebefore,
		soldeafter: soldeafter,

		unit: unit,
		count: count,

		unitvoda: unitvoda,
		unitairtel: unitairtel,
		unitafricell: unitafricell,
		unitorange: unitorange,

		countvoda: countvoda,
		countairtel: countairtel,
		countafricell: countafricell,
		countorange: countorange,

		numvoda: numvoda,
		numairtel: numairtel,
		numafricell: numafricell,
		numorange: numorange,


		operavoda: fiatvoda,
		operaairtel: fiatairtel,
		operaorange: fiatorange,
		operaafricell: fiatafricell,


		fiatvoda: fiatvoda === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 4,
		fiatairtel: fiatairtel === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 3,
		fiatorange: fiatorange === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 2,
		fiatafricell: fiatafricell === false ? false : secureLocalStorage.getItem("&@#^^&&date#!!&&##") + 1,

		vodastatus: true,
		airtelstatus: true,
		orangestatus: true,
		africellstatus: true,


		fiat: 'unit' + secureLocalStorage.getItem("USER"),
		fiatmere: JSON.parse(window.localStorage.getItem('--vie&&id')),
		fiatid: JSON.parse(window.localStorage.getItem('--vie&&id')),

		typefiat: 'mere',
		cancel: true,

		color: '#F4F5F6',
		dateofcreate: serverTimestamp(),

		deletedoc: secureLocalStorage.getItem("&@#^^&&date#!!&&##"),
		team: 'next',

		dockey: secureLocalStorage.getItem("#@%##key!!##"),

		statetrans: false,
		statepret: false,
		stateunit: true,
		statetontine: false,
		statetv: false,
		statesolaire: false,
	};

	await setDoc(doc(db, "swap" + colSwap, secureLocalStorage.getItem("#@%##key!!##")), data);

};

