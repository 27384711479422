
import './Logo.css';

// logo Head
export default function RetuRnlogO() {
 return (
  <div className='logo-nav-top-hm'>
   <img src='/img/logo.png' />
  </div>
 );
};