
import './Nav.css';
import ReturnBacK from './Back';
import ReturnTitle from '../Title';

// view navbar component 
export default function ReturnNavBar() {
 return (
  <div className='wrp-tontine-navbar'>

   <ReturnBacK />
   <ReturnTitle />

  </div>
 );
};