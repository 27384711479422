
import RetuRnTAg from './Tag';
import RetuRnTitle from './Title';

// Head Credit
export default function RetuRnHeAd() {
 return (
  <div>
   <div className='flex-between'>
    <div></div>
    <RetuRnTAg />
   </div>

   <RetuRnTitle />
  </div>
 );
};
