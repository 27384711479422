import './Ticket.css';
import ReturnBox from './Box';

// Return Ticket
export default function ReturnTickeT() {
 return (
  <div className='wrp-ticket-print'>
   <ReturnBox />
  </div>
 );
};