import React from 'react';
import './Rate.css';
import { db } from '../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";


// Return view Rate
export default function ReturnRate() {

 const [item, setItem] = React.useState(null);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
   setItem(doc.data().rate);
  });

 }, [])

 return (
  <div className='wrp-rate-stock'>
   <span>Taux {item}</span>

  </div>
 );
};