import './Home.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';


// Home Creditaire
export default function ReturnHoME() {
 return (
  <div className='flex-hm-creditaire'>

   <HeAd />
   <BOdy />
   <div></div>

  </div>
 );
};