import './Nav.css';
import REturnArroW from './Arrow';
// import ReturnSubJect from './Subject';

// View Nav bar
export default function ReturnNavBar() {
 return (
  <div className='wrp-nav-profil'>

   <REturnArroW />
   {/* <ReturnSubJect /> */}

  </div>
 );
};