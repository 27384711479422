
import './Register.css';
import HomeRegisteR from './Home/Main';
import Region from './Region/Main';
import RegisterPhone from './Phone/Main';
import SignInRegister from './SignIn/Main';
import RegisterSuccess from './RegisterSuccess/Main';

// RegisteR Main Component
export default function REturnRegister() {
 return (
  <div className='wrp-regiter-home'>

   <HomeRegisteR />
   {/* <Region /> */}
   {/* <RegisterPhone /> */}
   {/* <SignInRegister /> */}
   {/* <RegisterSuccess /> */}

  </div>
 );
};