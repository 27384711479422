
import ReturnBacK from './Back';
import ReturnInvited from './Invited';


// view navbar component 
export default function ReturnNavBar() {
 return (
  <div className='wrp-tontine-navbar'>
   <ReturnBacK />
   <ReturnInvited />

  </div>
 );
};