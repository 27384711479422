import './Quote.css';
import ReturnIMA from './IMA';

// Quote view component 
export default function ReturnQuote() {
 return (
  <div className='qt-pret-method'>
   <ReturnIMA />
   <p>Remboursez comme vous le souhaitez</p>
  </div>
 )
}