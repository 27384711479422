// Sharing Back 
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { db } from '../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";



export default function RetuRnAvataR() {

 const [check, setCheck] = React.useState(null);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
   setCheck(doc.data().sharingverifieraccess);

  });

 }, []);

 return (
  <>
   {check &&
    <Tooltip title="MP">
     <IconButton>

      <Stack direction="row" spacing={2}>
       <Avatar
        src="/img/sharing-economy.png"
        sx={{ width: 45, height: 45, cursor: 'pointer' }}
       />
      </Stack>

     </IconButton>
    </Tooltip>

   }
  </>
 );
};
