import './IMA.css';

// Return IMA Component
export default function ReturnIMA(props) {
 return (
  <div className='wrp-ima-brokers-prix'>
   <img src={props.IMA} alt='images input' />

  </div>
 );
}