
import './Footer.css';

// Footer Component view
export default function ReturnFooteR() {
 return (
  <div className='tontine-footer-qt'>
   <span>Copyright ©  MuunganoMoney Groupe Tontine Corporation.</span>
  </div>
 );
};