
import './Body.css';
import BasicList from './List';


// GRoupBody function Component 
export default function ReturnGRoupBody() {
 return (
  <div className='wrp-bg-group-tontine'>
   <BasicList />

  </div>
 );
}