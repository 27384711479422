import './Withdraw.css';
import NavBar from './Nav/Main';
import ReturnPrix from './Prix';

import ReturnAskedWallet from './Btn';

// Withdraw Component !!
export default function ReturnWithdRAw() {
 return (
  <>
   <div className='wrp-withdraw-tontine'>
    <NavBar />
    <ReturnPrix />
    <ReturnAskedWallet />
   </div>
  </>
 );
};