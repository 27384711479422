import React from 'react';
import Media from 'react-media';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';

import './Btn.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Btn view component 
export default function ReturnBtn() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
};
export const ScreenLarge = () => (
	<div className='tontine-btn-next-budget'>
		<View />
	</div>
);
export const ScreenSmall = () => (
	<div className='tontine-btn-next-budget-sm'>
		<View />
	</div>
);

export function View() {

	const navigation = useNavigate();
	const [rising, setRising] = React.useState(0);
	const [asked, setAsked] = React.useState(0);

	const [open, setOpen] = React.useState(false);
	const [load, setLoad] = React.useState(false);

	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');

	const [active, setActive] = React.useState(false);

	const [position, setPosition] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [askedPosition, setAskedPosition] = React.useState(0);

	const [values, setValues] = React.useState({
		textmask: '(100) 000-0000',
		numberformat: '1320',
	});
	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleClose = () => {
		setOpen(false);
	};


	let colTon = secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil");
	let listNumber = secureLocalStorage.getItem("¥¥˙´¸list˘˘˚˚");


	React.useEffect(async () => {

		try {
			const unsub = onSnapshot(doc(db, colTon, secureLocalStorage.getItem("USER")), (doc) => {
				setActive(doc.data().soldeactive);
			});

		} catch (e) {
			window.console.log(e);
		}

	}, []);
	React.useEffect(async () => {

		try {

			const unsub = onSnapshot(doc(db, 'tontine', colTon), (doc) => {
				setPosition(doc.data().position);
				setCount(doc.data().count);
				setAskedPosition(doc.data().askedposition);
				setAsked(doc.data().asked);
				setRising(doc.data().rising);
			});

		} catch (e) {

			window.console.log(e);
		}

	}, []);

	const handlepath = (event) => {

		event.preventDefault();
		setLoad(true);

		if (rising >= 0 || asked >= 0) {

			secureLocalStorage.setItem("^^$%list++::act::", (count) === (Number(position) + 1) ? true : false);
			secureLocalStorage.setItem("***#$$view..<<valid++", true);
			secureLocalStorage.setItem("***#$$pso..<<askedpos**++", listNumber[askedPosition]);
			secureLocalStorage.setItem("***#$$pso..<<add++", position);

			secureLocalStorage.setItem("@%#^^#*@@%%#^rising", Number(rising));
			secureLocalStorage.setItem("@%#^^#*@@%%#^asked", Number(asked));

			window.setTimeout(() => {

				if (Number(count) === (Number(position) + 1)) {

					if ((Number(askedPosition) + 1) === Number(count)) {

						window.console.log(true + ' ' + (Number(asked) + Number(rising)));
						secureLocalStorage.setItem("&&**++<///last{}", true);
						secureLocalStorage.setItem("!@@++baskte&&++", Number(asked) + Number(rising));

					} else {

						window.console.log(false + ' ' + rising);
						secureLocalStorage.setItem("&&**++<///last{}", false);
						secureLocalStorage.setItem("!@@++baskte&&++", Number(rising));

					}

					window.setTimeout(() => {
						window.location.href = '/tontine/list/group/child/budget/pin/all'
						// navigation('/tontine/list/group/child/budget/pin/all');
					}, 500);

				} else {

					secureLocalStorage.setItem("&&**++<///last{}", false);
					window.setTimeout(() => {

						if (listNumber[askedPosition] === 0) {
							setOpen(true);
						} else {
							navigation('/tontine/list/group/child/budget/pin');
						}

					}, 500);


				}

			}, 2550);

		} else {

			setOpen(true);

		}


	};

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			{active || Number(askedPosition) === Number(count) ? <div></div> : <button onClick={handlepath}>Accumulation <img src={'/img/customer.png'} /></button>}

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				onClose={handleClose}
			>
				<DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
				<DialogContent>

					<DialogContentText>
						<p className='pop-up'>
							Désolé, vous n'appartenez à aucun groupe Tontine
       </p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

		</>
	)
};