import './Nav.css';
import ReturnIMA from './IMA';
import RetuRNAvatars from './Avatar';


// Return Navigation Bank
export default function ReturnNav() {
 return (
  <div className='nav-box-dashed'>
   <RetuRNAvatars />
   <ReturnIMA />
  </div>
 );
};