

import './Swipea.css';


// Msg send 
export default function RetuRnMsgsENd(props) {
 return (
  <div className='flex-swipeable-service'>
   <h4>{props.Title}</h4>
   <p>
    {props.Text}
   </p>
   <img src={props.IMA} />
  </div>
 );
}