import './Btn.css';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';


// Btn Model
export default function RetuRnBtn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='button-width-25'>
  <ViEw />
 </div>

);
export const ScreenSmall = () => (
 <div className='button-width-25-sm'>
  <ViEw />
 </div>

);

export const ViEw = () => {

 const navigation = useNavigate();
 const handleclick = (event) => {
  event.preventDefault();
  navigation('/tontine/form');
  // window.console.log(!!secureLocalStorage.getItem("#!!@%$$switch**^^%%"));
 };

 return (
  <button onClick={handleclick} className='Btn'>Suivant</button>
 );
};