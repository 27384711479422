import * as React from 'react';
import './Head.css';
// import ReturnProfil from './Profil';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import secureLocalStorage from "react-secure-storage";



let Acces = true;
let listRising = new Array();


function DrawerAppBar() {

 const navigation = useNavigate();

 const [load, setLoad] = React.useState(false);
 const [money, setMoney] = React.useState([' ']);
 const [devise, setDevise] = React.useState('');
 const [name, setName] = React.useState('');


 const pushDocs = secureLocalStorage.getItem("&&view$$list£¢ton…")
 const pushOther = secureLocalStorage.getItem("&&view$$list£¢toncol§§-…");


 React.useEffect(async () => {

  try {
   [...pushOther].map((item) => {

    const unsub = onSnapshot(doc(db, "tontine", item), (doc) => {
     window.setTimeout(() => {
      listRising.push(doc.data().rising === undefined ? 0 : doc.data().rising);
     }, 500);

    });

   })
  } catch (error) {
   window.console.log(error);
  }

 }, []);
 React.useEffect(async () => {

  try {

   const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {
    setMoney(doc.data().grouptontinemoney === undefined ? [] : doc.data().grouptontinemoney);
    setDevise(doc.data().grouptontinedevise === undefined ? [] : doc.data().grouptontinedevise);
    setName(doc.data().grouptontinename === undefined ? '' : doc.data().grouptontinename);

   });

  } catch (error) {
   window.console.log(error);

  }

 }, []);
 const handlepath = (event) => {
  event.preventDefault();
  navigation(-1);
 };


 if (Array.isArray(pushDocs) && pushDocs.length) {
  Acces = true;
 } else {
  Acces = false;
 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-head-list-tontine'>
    <header>
     <div className='container'>
      <nav className='navbar'>

       <div style={{ paddingTop: '1.2vh' }}>
        <HiArrowLeft onClick={handlepath} size={'1.6em'} color={'white'} className={'array-static-navbar'} />
       </div>


      </nav>
     </div>
    </header>

    <section>
     {
      Acces ?
       <ul>
        {
         [...Array(pushDocs.length).keys()].map(index => {

          let argent = '';
          let price = '';

          if ((devise[index]) === undefined || (money[index]) === undefined) {
           window.console.log('nothing !!!');
          } else {

           argent = (devise[index]);
           price = (money[index]);
           price = price.match(/(\d+)/)
           price = price[0];

          }

          return (
           <div
            onClick={async () => {
             setLoad(true);
             secureLocalStorage.setItem("¥¥˙´¸list˘˘22˚˚fil", pushOther[index]);
             const unsub = onSnapshot(doc(db, "tontine", pushOther[index]), (doc) => {
              secureLocalStorage.setItem("¥¥˙´¸list˘˘˚˚", doc.data().table);

             });

             window.setTimeout(() => {
              window.location.href = "/tontine/list/group/child";
             }, 5800);

            }}>

            <List>
             <ListItem disablePadding>
              <ListItemButton>

               <li key={index}>
                <div className='cmd-operator-title'>

                 <div className='cmd-operator-sub-title'>
                  <div className='flex-row-cmd-group'>

                   <p>
                    <span>{pushDocs[index].charAt(0).toUpperCase() + pushDocs[index].slice(1)}</span>

                   </p>


                  </div>
                 </div>
                </div>

                <p>{price.includes(price) ? price : 0} {argent.includes('USD') ? 'USD' : 'CDF'}</p>

               </li>

              </ListItemButton>
             </ListItem>
            </List>
            <Divider />
           </div>
          )
         })}
       </ul>
       :

       <div></div>
     }
    </section>
   </div>


  </>
 );
};



export default DrawerAppBar;