import React from 'react';
import './Title.css';
import Media from 'react-media';
import {
 gsap, Power2
} from 'gsap';




// Middle Head 
export default function RetuRnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='hm-title-footer-home'>
  <View />
 </div>
)
export const ScreenSmall = () => (
 <div className='hm-title-footer-home-sm'>
  <View />
 </div>
)
export const View = () => {

 const boxRef = React.useRef(null);
 React.useEffect(() => {
  gsap.from(boxRef.current, { delay: 1, duration: 1, y: 20, opacity: 0, ease: Power2.easeIn })


 })

 return (
  <p ref={boxRef}>
   Facilitez-vous la vie avec l'accès à distance et la gestion de vos investissements en temps réel Réduisez
   vos coûts liés aux opérations financières

  </p>
 );
}