import './Buy.css';
import ReturnBAck from './Back';
import ReturnQuoTe from './Quote';

// Broker Component
export default function ReturnBuyBroker() {
 return (
  <div className='wrp-buy-stock-thank'>
   <ReturnBAck />
   <ReturnQuoTe />
  </div>
 );
};