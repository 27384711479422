import './Dealer.css';
import React from 'react';
import { doc, onSnapshot, getDocs, collection } from "firebase/firestore";
import { db } from '../../firebase';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import Media from 'react-media';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";

import { v4 } from 'uuid';


let pushDoc = new Array();






export default function ReturnDeAler(props) {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall IMA={props.IMA} />}
     {matches.medium && <ScreenLarge IMA={props.IMA} />}
     {matches.large && <ScreenLarge IMA={props.IMA} />}
    </>
   )}
  </Media>
 );
}

export const ScreenLarge = (props) => (
 <div className='wrp-btn-dealer-unit'>
  <ViEw IMA={props.IMA} />
 </div>
);
export const ScreenSmall = (props) => (
 <div className='wrp-btn-dealer-unit-sm'>
  <ViEw IMA={props.IMA} />
 </div>
);

// Dealer IMA
export function ViEw() {

 const navigation = useNavigate();
 const [load, setLoad] = React.useState(false);
 const [loading, setLoading] = React.useState(false);

 const [vodausd, setVodausd] = React.useState(0);
 const [vodacdf, setVodacdf] = React.useState(0);

 const [orangeusd, setOrangeusd] = React.useState(0);
 const [orangecdf, setOrangecdf] = React.useState(0);

 const [airtelusd, setAirtelusd] = React.useState(0);
 const [airtelcdf, setAirtelcdf] = React.useState(0);

 const [africellusd, setAfricellusd] = React.useState(0);
 const [africellcdf, setAfricellcdf] = React.useState(0);

 const [state, setState] = React.useState(false);
 const [list, setList] = React.useState([]);
 const [stock, setStock] = React.useState(null);



 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);
  });

  setList([...new Set(pushDoc)]);

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganoratedealer", "vodacom"), (doc) => {

   setVodausd(doc.data().usd);
   setVodacdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganoratedealer", "orange"), (doc) => {

   setOrangeusd(doc.data().usd);
   setOrangecdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganoratedealer", "airtel"), (doc) => {

   setAirtelusd(doc.data().usd);
   setAirtelcdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganoratedealer", "africell"), (doc) => {
   setAfricellusd(doc.data().usd);
   setAfricellcdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
   setStock(doc.data().stock);
  });

 }, []);

 const handlepath = async (event) => {

  event.preventDefault();
  setLoading(true);
  setLoad(true);

  secureLocalStorage.setItem("@#access@@^^rate!@!!^^&", false);

  secureLocalStorage.setItem("@#5@@date!@!!^^&", moment().format());
  secureLocalStorage.setItem("@usd#5@@simple!@!!^^&voda", vodausd);
  secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&voda", vodacdf);

  secureLocalStorage.setItem("@usd#5@@simple!@!!^^&orange", orangeusd);
  secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&orange", orangecdf);


  secureLocalStorage.setItem("@usd#5@@simple!@!!^^&airtel", airtelusd);
  secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&airtel", airtelcdf);

  secureLocalStorage.setItem("@usd#5@@simple!@!!^^&africell", africellusd);
  secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&africell", africellcdf);

  secureLocalStorage.setItem("#@%^^^@@&&taux##stock", 0);
  secureLocalStorage.setItem("#@%$#%team", 'marchand');
  secureLocalStorage.setItem("#@%##key!!##", v4());


  if (stock === null || stock === undefined || vodausd === undefined || vodacdf === undefined || orangeusd === undefined || orangecdf === undefined || airtelusd === undefined || airtelcdf === undefined || africellusd === undefined || africellcdf === undefined) {
   window.location.href = '/dash';
  } else {
   window.setTimeout(() => {
    navigation('/brokers/unite/select');
   }, 1500)

  }


 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {list.includes(secureLocalStorage.getItem("USER")) ? null :
    <div onClick={handlepath} className='wrp-dealer-unit'>
     <img src={'/img/indentification.png'} />
     <p>Marchand</p>
    </div>
   }

  </>
 );
};