import './Box.css';
import REturnInputPhone from './Input';


// Box send 
export default function REturnBoxSend() {
 return (
  <div className='wrp-box-send'>
   <REturnInputPhone />
  </div>
 );
};