
import './IMA.css';

// view component pret 
export default function ReturnIMA() {
 return (
  <div className='wrp-pret-ima-method'>
   <img src={'/img/pret.png'} />
  </div>
 )
}