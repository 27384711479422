import React from 'react';
import { db } from '../../../../firebase';
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Name.css';



// Name User Account
export default function RetuRnNAme() {

 let pushDoc = new Array();

 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');


 React.useEffect(async () => {

  const querySnapshotClient = await getDocs(collection(db, "client"));
  querySnapshotClient.forEach((doc) => {
   pushDoc.push(doc.id);
  });

  var verifierCollection = pushDoc.some((value) => value == secureLocalStorage.getItem("USER"));

  try {

   const unsub = onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    // Document was found in the cache. If no cached document exists,
    setFirst(doc.data().firstname)
    setLast(doc.data().lastname)


   });

  } catch (error) {
   window.console.log(error);
  }

 }, []);


 return (
  <div className='box-green flex-credit-name'>
   <p>{first.toLowerCase()} {last.toLowerCase()}</p>
  </div>
 );
};
