
import './Title.css';


// Title Tontine component
export default function ReturnTitle() {
 return (
  <div className='title-bd-tontine'>
   <img src={'/img/cashback.png'} />
   <h1>Monnaie à utiliser pour la Tontine</h1>
  </div>
 );
}