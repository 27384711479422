import React from 'react';
import './Fran.css';
import { useNavigate } from 'react-router-dom';
import { TweenMax, Expo } from 'gsap';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';

import { db } from '../../firebase';
import { doc, onSnapshot } from "firebase/firestore";


// Return Fran 
export default function ReturnFrAn() {

 const navigation = useNavigate();
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);
 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("^^&&#$moment#@(@#date", moment().format());
  secureLocalStorage.setItem("^^&&register__pret", true);
  secureLocalStorage.setItem("##7@88#!!@@NAME#**##", `${first} ${last}`.toLowerCase());

  window.setTimeout(() => {
   navigation('/pret/fran/register');
  }, 1500)

 };
 React.useEffect(() => {
  TweenMax.from('.AnimFran', 1.2, { delay: 1, opacity: 0, x: 20, ease: Expo.easeInOut })

 }, []);

 window.console.log(first)

 return (
  <div onClick={handlepath} className='devise-pret-money AnimFran'>
   <img src={'/img/franc.png'} />
   <p>CDF</p>
  </div>
 );
};