
import './Cmd.css';
import NavStocK from './Nav/Main';


// View Cmd Command
export default function ReturnCommAnd() {
 return (
  <NavStocK />
 )
}