import './Nav.css';
import ReturnAvataR from './IMA';
import ReturnBacK from './Back';

// Return NavigaTion from
export default function ReturnNavigaTion() {
 return (
  <div className='wrp-nav-buy'>
   <ReturnBacK />
   <ReturnAvataR />

  </div>
 );
};