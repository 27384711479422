
import RetuRnTitle from './Title';
import './Footer.css';
// import ReturnBTn from './Btn';


// Footer Main
export default function ReturnFooTeR() {
 return (
  <div className='grid-center'>
   <RetuRnTitle />
   {/* <ReturnBTn /> */}
  </div>
 );
};