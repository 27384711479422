
import TitlE from './Title/Main';
import UsER from './User/Main';
import './Body.css';


// Body Sharing 
export default function RetuRnBodY() {
 return (
  <div className="wrp-body-sharing">
   <TitlE />
   <UsER />
  </div>
 );
};