import React from 'react';
import './Profil.css';
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';


export default function RetuRNAvatars() {

 let arrayClient = new Array();
 const [profil, setProfil] = React.useState(null);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   arrayClient.push(doc.id);
  });

  const collections = arrayClient.some(value => value == secureLocalStorage.getItem("USER"));

  try {
   const unsub = onSnapshot(doc(db, collections ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setProfil(doc.data().profile === undefined ? '' : doc.data().profile);
   });

  } catch (e) {
   window.console.log(e);
  }

 }, []);

 return (
  <Avatar
   src={profil}
   sx={{ width: 56, height: 56 }}
  />
 );
};