import * as React from 'react';
import './Head.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import secureLocalStorage from "react-secure-storage";
import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";
// import ReturnProfil from './Profil';

import CommI from './Commi/Main';
import CoMMICDF from './Commicdf/Main';
import currency from 'currency.js';



let pushDoc = new Array();
let pushDocId = new Array();
let listDocId = new Array();


let iconsize = '1.4em';
let iconcolor = '#00a5e0';


function DrawerAppBar() {

  const navigation = useNavigate();

  const [arr, setArr] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [table, setTable] = React.useState([]);


  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, 'unit' + secureLocalStorage.getItem("USER")));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushDoc.push(doc.data());
      pushDocId.push(doc.id);

    });

    setArr([...new Set(pushDoc)].reverse());
    setList([...new Set(pushDocId)]);

  }, []);
  React.useEffect(async () => {
    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listDocId.push(doc.id);
    });

    setTable(listDocId);

  }, [])

  const handlepath = (event) => {
    event.preventDefault();
    navigation(-1);
  };

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

  return (
    <div className='flex-head-list-cmd'>
      <header>
        <div className='container'>
          <nav className='navbar'>

            <HiArrowLeft style={{ paddingBottom: '1em' }} onClick={handlepath} size={'2.8em'} color={'white'} />
            {/* <ReturnProfil /> */}

          </nav>
        </div>
      </header>

      <section>
        {list.length === 0 || arr.length === 0 ?
          <div></div>
          :
          <>
            {table.includes(secureLocalStorage.getItem("USER")) ? null :

              <div className='wrp-flex-row-commi'>
                <CommI />
                <CoMMICDF />
              </div>}
          </>

        }

        {
          list.length === 0 || arr.length === 0 ?

            <div></div>
            :
            <ul>
              {
                [...Array(list.length).keys()].map(index => {

                  return (
                    <li key={index}>

                      <div className='box-data-cmd-list'>
                        <div></div>
                        <div>
                          <h2>{moment(arr[index].date).format('L')}</h2>
                          <h2>{moment(arr[index].date).format('LTS')}</h2>

                        </div>
                      </div>

                      <div className='cmd-operator-title'>

                        <div className='cmd-operator-sub-title'>
                          <h3>Opérateur</h3>
                          <div className='flex-row-other-cmd'>
                            <p key={index}>{arr[index].phone}</p>
                          </div>

                        </div>

                        <div className='cmd-operator-sub-title'>
                          <h3>Quantité</h3>

                          <div className='flex-row-other-cmd'>
                            <p key={index}>{arr[index].count}</p>
                          </div>
                        </div>

                        <div className='cmd-operator-sub-title'>
                          <h3 style={{ marginTop: '.1em' }}>Prix</h3>

                          <div className='flex-row-cmd'>
                            <p key={index}>{euro(arr[index].price).format()} {arr[index].devise === 'USD' ? '$' : 'F'}</p>
                          </div>

                        </div>

                        <div className='cmd-operator-sub-title'>
                          <h3 style={{ color: 'transparent' }}>Status</h3>

                          <div className='flex-row-cmd'>
                            <p key={index}>{arr[index].status === false ? <IoCheckmarkSharp size={iconsize} color={iconcolor} /> : <IoCheckmarkDoneSharp size={iconsize} color={iconcolor} />}</p>
                          </div>

                        </div>

                      </div>

                    </li>
                  )
                })}
            </ul>

        }

      </section>


    </div>
  );
};


export default DrawerAppBar;