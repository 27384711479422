// import OvERImageBG from './Over/Main';
import DepOsIT from './Deposit/Main';
import KEep from './Keep/Main';
import SeRvIce from './Service/Main';
import MoRe from './More/Main';
import AccepTed from './Accepted/Main';
import WAnt from './Want/Main';
import ChEcK from './Check/Main';
import TAp from './Tap/Main';
import EaRn from './Earn/Main';

import './Body.css';


// Body Main
export default function RetuRnBodY() {
 return (
  <div className='flex-block'>
   <DepOsIT />
   <KEep />
   <SeRvIce />
   {/* <MoRe /> */}
   <AccepTed />
   <WAnt />
   <ChEcK />
   <TAp />
   <EaRn />
  </div>
 );
};