import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";
import './Date.css';



// Date Component !!!
export default function ReturnDAte() {

 const [askdate, setAskdate] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "pret", secureLocalStorage.getItem("USER")), (doc) => {
   setAskdate(doc.data().id);
  });

 }, []);

 return (
  <div className='flex-date-prix-ask-dash-pret'>
   <div></div>
   <div>
    <h2>Date d'activation</h2>
    <p>{moment(askdate).format('LLLL')}</p>
   </div>
  </div>
 );
};
