
import RetuRnDepOsIT from './Want';
import RetuRnTitle from './Title';

// Deposit main
export default function EaRn() {
 return (
  <div className='flex-grid'>
   <RetuRnTitle />
   {/* <RetuRnDepOsIT /> */}
  </div>
 );
};