import React from 'react';
import './Link.css';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { ReturnFrAnc, ReturnDollArs } from './IMA';
import secureLocalStorage from "react-secure-storage";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';



//  REturn link withdraw
export default function REturnLinK() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-link-withdraw'>
  <Dollars Text={'USD'} />
  <Fran Text={'CDF'} />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-link-withdraw-sm'>
  <Dollars Text={'USD'} />
  <Fran Text={'CDF'} />
 </div>
);


secureLocalStorage.getItem("#$$!!$&%CDF@#")
secureLocalStorage.getItem("#$$!!$&%USD@#")


export const Fran = (props) => {

 const navigation = useNavigate();
 const [fran, setFran] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("%$%!!$&-ER@#"), secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {
   setFran(doc.data().cdf);
  });

 }, []);

 const handlepath = (event) => {

  event.preventDefault();
  if (fran === null || fran === undefined) {
   window.console.log('Hello');
  } else {
   secureLocalStorage.setItem("#$$!!$&%CDF@#", Number(fran));
   navigation('/fran');
  }

 };

 return (
  <buttton onClick={handlepath} className='Btn margin-link-button'>
   <span>{props.Text}</span><ReturnFrAnc />
  </buttton>
 );
};
export const Dollars = (props) => {

 const navigation = useNavigate();
 const [dollard, setDollard] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("%$%!!$&-ER@#"), secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {
   setDollard(doc.data().usd);
  });

 }, []);

 const handlepath = (event) => {

  event.preventDefault();

  if (dollard === null || dollard === undefined) {
   window.console.log('Hello');
  } else {

   secureLocalStorage.setItem("#$$!!$&%USD@#", Number(dollard));
   navigation('/usd');

  }

 };

 return (
  <buttton onClick={handlepath} className='Btn'>
   <span>{props.Text}</span><ReturnDollArs />
  </buttton>
 );
};