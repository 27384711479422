import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { doc, setDoc, updateDoc, increment, onSnapshot, arrayUnion , collection, serverTimestamp} from 'firebase/firestore';
import { db } from '../../firebase';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import "moment/locale/fr";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import { CiWifiOff } from "react-icons/ci";
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import currency from 'currency.js';
import axios from 'axios';





// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
 DatePicker,
 MuiPickersUtilsProvider,
} from '@material-ui/pickers';




export let nowField = moment().date();
export let pushDocs = new Array();
export let fromdate = moment().format();


let key = v4();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


// Input Field Component !!!
export default function ReturnInput() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};

export const SreenLarge = () => {
 return (
  <div className='wrp-input-signin-user'>
   <FormInput />
  </div>
 )
}
export const ScreenSmall = () => {
 return (
  <div className='wrp-input-signin-user-sm'>
   <FormInput />
  </div>
 )
};


export const FormInput = () => {



 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 let regular = /[a-z]+/;
 const navigation = useNavigate();

 const [locale, setLocale] = React.useState("fr");
 const [wifi, setWifi] = React.useState(false);
 const [selectedDate, handleDateChange] = React.useState(new Date());
 const [load, setLoad] = React.useState(false);
 const [problem, setProblem] = React.useState(false);


 const [open, setOpen] = React.useState(false);
 const [depot, setDepot] = React.useState(false);

 const [phone, setPhone] = React.useState(false);
 const [fran, setFran] = React.useState(null);
 const [franthrift, setFranthrift] = React.useState(null);
 const [profilagent, setProfilagent] = React.useState(null);

 const [amount, setAmount] = React.useState(null);
 const [amountup, setAmountup] = React.useState(null);

 const [firstname, setFirstname] = React.useState('');
 const [lastname, setLastname] = React.useState('');


 
  const [signature, setSignature] = React.useState(null);
  const [signaturetype, setSignaturetype] = React.useState(null);
  const [signaturemoney, setSignaturemoney] = React.useState(null);
  const [signaturekey, setSignaturekey] = React.useState(null);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const { register, formState, handleSubmit, control } = useForm({});
 const { isSubmitting } = formState;


 var eurocurrency = value => currency(value, { separator: ' ', decimal: '.', symbol: '' });

 let profildefault = 'https://firebasestorage.googleapis.com/v0/b/ungamamoney-wallet.appspot.com/o/image%2Flogo.png?alt=media&token=a1b84070-76b5-43c7-a3e1-199e353c0711'
 let phoneToclient = secureLocalStorage.getItem("--#%¢res¸˘˘");


 const existing = {
  active: true, state: "client", usd: 0, thriftcdf: 0, ip : '', thriftusd: 0, pretexten: '', sendtype: '', accountdate: moment().format(), pret: false, pretregister: false, pretactive: false, code: '000000', pin: 'ungano', solde: 0, frais: 0, extension: 'extension', profile: profildefault
 };


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };

 const handleClose = () => {
  setOpen(false);
 };
 const phoneClose = () => {
  setPhone(false);
 };
 const depotClose = () => {
  setDepot(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };



 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setFran(doc.data().cdf);
    setFranthrift(doc.data().thriftcdf);
    
    setProfilagent(doc.data().profile);
    setFirstname(doc.data().firstname);
    setLastname(doc.data().lastname);
   
     setSignature(doc.data().signaturecdf);
     setSignaturetype(doc.data().signaturecdftype);
     setSignaturemoney(doc.data().signaturemoneycdf);
     setSignaturekey(doc.data().signaturekeycdf);

   
   });
  } catch {
   window.console.log('erreur disponible');
  }


 }, []);
 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "muunganomoney", "commissionregister"), (doc) => {
    setAmount(doc.data().amount);
    setAmountup(doc.data().amountup);
   });
  } catch {
   window.console.log('erreur disponible');
  }


 }, []);


 let aftervalue = Number(fran) - Number(amount);

  // Form send AGENT
  let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(fran) + Number(signaturemoney))}${parseInt((Number(fran) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
  let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(fran) - Number(signaturemoney))}${parseInt((Number(fran) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
  let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;

 // Ref collection database!!
 const onSubmit = async (data) => {

  if (data.phone === undefined) {

   setPhone(true);
   setLoad(false);

  } else {

   setLoad(true);

   let num = (data.phone).match(/\d+/g);
   let numPhone = '';
   num.map(index => {
    numPhone += index;

   })

   if (numPhone.length != 10) {
    setPhone(true);
    setLoad(false);

   } else {

     if (fran === null || fran === undefined || franthrift === null || franthrift === undefined || amount === null || amount === undefined || amountup === null || amountup === undefined || firstname === '' || firstname === undefined || lastname === '' || lastname === undefined || profilagent === null || profilagent === undefined) {

     setWifi(true);
     setLoad(false);

    } else {
      
       if (checkagent) {

       if (fran >= 7000) {

        let dateBirth = data.birth;

        if (dateBirth) {

         Object.defineProperty(data, 'birth', {
          value: dateBirth.format('LL'),
          writable: true,
          configuration: true,
          enumerable: true

         });

         let mail = '';
         if (data.email === undefined || data.email === '') {
          mail = '';
         } else {
          mail = data.email;
         }

         var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
         let time = moment().format('LLL');
         let get = { date: time, solde: `${euro(amount).format()} CDF [INSCRIPTION]`, phone: secureLocalStorage.getItem("USER"), user: firstname + ' ' + lastname, type: 'Reçu', price: euro(amount).format(), actual: euro(amount).format()  + ' CDF', unite: 'CDF' }

         let objInput = {

          cdf: amount,
          // cdf: 0,
          addresse: data.addresse,
          birth: data.birth,
          email: mail,
          firstname: data.firstname,
          lastname: data.lastname,
          phone: numPhone,
          ville: data.ville,

          beforecdf : 0,

          iduser: (data.firstname + data.lastname + secureLocalStorage.getItem("--#%¢res¸˘˘")).toLowerCase(),
          idphone: secureLocalStorage.getItem("--#%¢res¸˘˘"),
          idcarte: data.id,

          signatureusd: `${secureLocalStorage.getItem("--#%¢res¸˘˘")}${0}${0}${0}${key}`,
          signatureusdtype: 'reussi',
          signaturemoneyusd: 0,
          signaturekeyusd: key,
          signaturefraisusd: 0,

          
          // signaturecdf: `${secureLocalStorage.getItem("--#%¢res¸˘˘")}${0}${0}${0}${key}`,
          // signaturecdftype: 'reussi',
          // signaturemoneycdf: Number(0),
          // signaturekeycdf: key,
          // signaturefraiscdf: 0,

          
          signaturecdf: `${secureLocalStorage.getItem("--#%¢res¸˘˘")}${Number(amount)}${0}${Number(amount)}${key}`,
          signaturecdftype: 'reussi',
          signaturemoneycdf: Number(amount),
          signaturekeycdf: key,
          signaturefraiscdf: 0,


          money: amount,
          swap: arrayUnion(get),
          user: firstname + ' ' + lastname,
          unite: 'CDF',
          phoneclient: secureLocalStorage.getItem("USER"),
          delay: moment().format(),
          sendtype: 'Reçu'

         };

         const clone = Object.assign(objInput, existing);
         udpateAgentRef(Number(aftervalue), Number(amount), (Number(franthrift) + Number(amountup)), amountup, secureLocalStorage.getItem("USER"), phoneToclient, data.firstname + ' ' + data.lastname,  fran, key);


        swapSendUser(secureLocalStorage.getItem("USER"), `inscription`, amount, fran, fran - amount, 'envoyer', moment().format(), secureLocalStorage.getItem("--#%¢res¸˘˘"), data.firstname + ' ' + data.lastname, profilagent, franthrift, Number(franthrift) + Number(amountup), Number(amountup), 0, key, 'CDF', 0, amount);
        swapGetUser(secureLocalStorage.getItem("--#%¢res¸˘˘"), `inscription`, amount, 0, amount, 'Reçu', moment().format(), secureLocalStorage.getItem("USER"), firstname + ' ' + lastname, profildefault, 0, 0, 0, 0, key, 'CDF', fran, fran - amount);
        
        saveInfoInWithDocs(secureLocalStorage.getItem("--#%¢res¸˘˘"), clone);
                 
         secureLocalStorage.setItem("--client#%¢res¸˘˘", false);

         let number = secureLocalStorage.getItem("--#%¢res¸˘˘");
         let phonesend = `243${number.slice(-9)}`;

         let apiRegister = `Bienvenue chez Cashbeck Epargne. Votre compte viens d'etre cree au nom de ${(data.firstname).toString().toUpperCase()} ${data.lastname.toString().toUpperCase()} ${secureLocalStorage.getItem("--#%¢res¸˘˘")}. Avec un depot de : ${(amount)} CDF`;

         let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${apiRegister}`,
          
          headers: {}
         };
          
          axios.request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });


         window.setTimeout(() => {
          window.location.href = "/register/success";
         }, 5000);



        } else {
         setLoad(false);
         setOpen(true);
        }
       } else {

        setLoad(false);
        setDepot(true);
       }

      } else {
       setProblem(true);
      }

    }
   }
  }

 };
  
 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nom</h1></InputLabel>

     <Controller
      name="firstname"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        autoFocus
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="firstname"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Prènom</h1></InputLabel>

     <Controller
      name="lastname"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="lastname"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Email</h1></InputLabel>

     <Controller
      name="email"

      control={control}
      render={({ field }) =>

       <Input
        inputProps={{ autoComplete: "off" }}
        name="email"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero de contact</h1></InputLabel>

     <Controller
      name="phone"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input

        required
        inputProps={{ autoComplete: "off" }}
        value={values.textmask}
        onChange={handleChange}
        InputLabelProps={{ required: true }}
        inputProps={{
         autoComplete: "off", inputMode: 'tel'
        }}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>ID Carte d'identité</h1></InputLabel>

     <Controller
      name="id"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="id"
        {...field}
       />

      }
     />
    </FormControl>


    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Ville</h1></InputLabel>

     <Controller
      name="ville"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="ville"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Addresse</h1></InputLabel>

     <Controller
      name="addresse"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="addresse"
        {...field}
       />

      }
     />
    </FormControl>



    <Controller
     name="birth"
     control={control}
     render={({ field }) =>

      <MuiPickersUtilsProvider
       libInstance={moment} utils={MomentUtils} locale={locale}>

       <DatePicker
        disableFuture
        openTo="year"
        format="dd/MM/yyyy"
        label="Date de naissance"
        views={["year", "month", "date"]}
        value={selectedDate}
        onChange={handleDateChange}
        {...field}
       />

      </MuiPickersUtilsProvider>}
    />

    <button disabled={isSubmitting} className='Btn'>Enregistre</button>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}
    >
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Veuillez confirmer la date de naissance
            </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={phone}
     onClose={phoneClose}
    >
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Ce numéro de téléphone est incorrect, veuillez vérifier ce numéro d'appel.
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={phoneClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={depot}
     onClose={depotClose}
    >
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Le solde actuel n'est pas suffisant pour finaliser cette inscription
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={depotClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

	 <Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={problem}
				onClose={handleproblemClose}>
				<DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

       Votre compte est temporairement suspendu,
							après vérification 
													
							sur une situation
							incorrect des vos comptes, de vous, soit de votre destinataire, veuillez contacter MuunganoMoney

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

  </>
 )
};


async function saveInfoInWithDocs(phoneInDocs, user) {
 await setDoc(doc(db, "client", phoneInDocs), user);

};
async function udpateAgentRef(value, amount, thrift, amountup, getPhone, sendPhone, sendUser, before, key) {


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 let time = moment().format('LLL');
 let send = { date: time, solde: `${euro(amount).format()} CDF [INSCRIPTION]`, phone: sendPhone, user: sendUser, type: 'envoyer', price: euro(amount).format(), actual: euro(value).format()  + ' CDF', unite: 'CDF' }
 

 const agentRef = doc(db, "agent", `${secureLocalStorage.getItem("USER")}`); 
 await updateDoc(agentRef, {

  cdf: value,
  money: amount,
  thriftcdf: thrift,
  commission: amountup,
  beforecdf: secureLocalStorage.getItem("&##$$!CDF%!!"),

   signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(amount))}${parseInt(Number(before))}${parseInt(Number(before) - Number(amount))}${key}`,
   signaturecdftype: 'envoyer',
   signaturemoneycdf: parseInt(Number(amount)),
   signaturekeycdf: key,

   swap: arrayUnion(send),
   sendtype: 'envoyer',
   delay: moment().format(),
   phoneclient: getPhone,
   unite: 'CDF',
   user: sendUser,

 });

};
// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

  // Add a new document with a generated id
  const newCityRef = doc(collection(db, "swap" + colSwap));
  // later...
  await setDoc(newCityRef, {

    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key,
    devise: devise,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    color: '#FFF5ED',
    dateofcreate: serverTimestamp(),

    statetrans: true,
    statepret: false,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,

  });

};
async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

  // Add a new document with a generated id
  const newCityRef = doc(collection(db, "swap" + colSwap));
  // later...
  await setDoc(newCityRef, {

    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key,
    devise: devise,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    color: '#FFF5ED',
    dateofcreate: serverTimestamp(),

    statepret: false,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,

  });

};


