
import './Title.css';
import Media from 'react-media';



// Middle Head 
export default function RetuRnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='accepted-title-quote-home'>
  <View />
 </div>
)
export const ScreenSmall = () => (
 <div className='accepted-title-quote-home-sm'>
  <View />
 </div>
)
export const View = () => (
 <>
  <h4>
   Accessibilité 24/7
   </h4>

  <p>
   Vous pouvez effectuer des transactions à tout moment cela vous donne une flexibilité maximale pour gérer vos investissements selon votre emploi du temps
   </p>
  <img src={'/img/techunsplash.jpg'} />
 </>
);