
import './IMA.css';

// Box IMA Franc view
export const ReturnFrAnc = () => (
 <div className='wrp-ima-franc'>
  <img src='/img/franc.png' alt='alt image view' />
 </div>
);
export const ReturnDollArs = () => (
 <div className='wrp-ima-franc'>
  <img src='/img/dollars.png' alt='alt image view' />
 </div>
);
