import React from 'react';
import { db } from '../../../../firebase';
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Balance.css';
import currency from 'currency.js';



// Name User Account
export default function RetuRnBAlAnce() {

 let pushDoc = new Array();
 const [list, setList] = React.useState([]);

 const [fran, setFran] = React.useState(null);
 const [dollard, setDollard] = React.useState(null);
 const [item, setItem] = React.useState('');


 React.useEffect(async () => {

  const querySnapshotClient = await getDocs(collection(db, "client"));
  querySnapshotClient.forEach((doc) => {
   pushDoc.push(doc.id);
  });

  var verifierCollection = pushDoc.some((value) => value == secureLocalStorage.getItem("USER"));

  try {

   const unsub = onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    // Document was found in the cache. If no cached document exists,
    setFran(doc.data().cdf === undefined ? 0 : doc.data().cdf);
    setDollard(doc.data().usd === undefined ? 0 : doc.data().usd);
   });

  } catch (error) {

   setFran(0);
   setDollard(0);
  }

 }, []);



 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);

  });

  setList([...new Set(pushDoc)]);

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##")), (doc) => {
   setItem(doc.data().devise);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-balance-user-creditaire'>
   {item === 'USD' && <p>{euro(dollard).format()} USD</p>}
   {item === 'CDF' && <p>{euro(fran).format()} CDF</p>}
  </div>
 );
};
