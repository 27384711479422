import React from 'react';
import secureLocalStorage from "react-secure-storage";


import './List.css';
import moment from 'moment';
import currency from 'currency.js';
import Media from 'react-media';


// List 
export default function RetuRnLisT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenSmall = () => {
 return (
  <ViEW />
 );
};
export const ScreenLarge = () => {
 return (
  <ViEW />
 );
};


export const ViEW = () => {

 const [list, setList] = React.useState(secureLocalStorage.getItem("#$$!@usd$%list!!"));
 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='list-exchange-buy-detail-view'>
   {[...Array(list.length).keys()].map((item) => {

    {/* let str = list[item].type === undefined ? '' : list[item].type; */ }

    return (
     <div style={{ backgroundColor: `${list[item].color}` }} className='list-top-exchange-buy-detail-view'>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Action  : </p>
       <p>{list[item].action}</p>
      </div>

      {list[item].canceloperator &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Operateur annuler  : </p>
        <p>{list[item].canceloperatorname}</p>
       </div>
      }

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant transféré  : </p>
       <p>{euro(list[item].money).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant avant  : </p>
       <p>{euro(list[item].before).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant après  : </p>
       <p>{euro(list[item].after).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Type de transfert  : </p>
       <p>{list[item].type}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Date du transfert  : </p>
       <p>{moment(list[item].date).format('LLL')}</p>
      </div>

      {/* <div className='list-sub-exchange-buy-detail-view'>
       <p>Délai consommer  : </p>
       <p>{moment().to(list[item].date)}</p>
      </div> */}



      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Numéro du bénéficiaire  : </p>
        <p>{list[item].phone}</p>
       </div>
      }

      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nom du bénéficiaire  : </p>
        <p>{(list[item].user + '').toLowerCase()}</p>
       </div>
      }


      {list[item].statetransed &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Numéro de l'envoyeur  : </p>
        <p>{list[item].phone}</p>
       </div>
      }

      {list[item].statetransed &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nom de l'envoyeur  : </p>
        <p>{(list[item].user + '').toLowerCase()}</p>
       </div>
      }


      {list[item].statecrediter &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Opérateur utilisé  : </p>
        <p>{list[item].operator}</p>
       </div>
      }


      <div className='list-sub-exchange-buy-detail-view'>
       <p>Frais  : </p>
       <p>{euro(list[item].frais).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Commission  : </p>
       <p>{euro(list[item].commission).format()} {list[item].devise}</p>
      </div>


      <div className='list-sub-exchange-buy-detail-view'>
       <p>Coffre actuel  : </p>
       <p>{euro(list[item].afterthrift).format()} {list[item].devise}</p>
      </div>


      {list[item].statecrediter
       &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Référence  : </p>
        <p>{list[item].ref}</p>
       </div>
      }

      {list[item].stateunit &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Montant d'achat  : </p>
        <p>{euro(list[item].count).format()} {list[item].devise}</p>
       </div>
      }

      {list[item].stateunit &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nombre d'unités acheté  : </p>
        <p>{list[item].unit} U</p>
       </div>
      }



      {list[item].stateshare &&

       <div className="grid">

        <>
         {[...list[item].clientshare].length === 0 && <div>dsk</div>}

         {[...list[item].clientshare].map((index) => {
          return (
           <div className='list-sub-exchange-buy-detail-view'>
            <p>{index.idphone} : </p>
            <p>{euro(index.sharingmoney).format()} {index.sharingdevise}</p>
           </div>
          )
         })}

        </>
       </div>
      }






      {list[item].operaorange && list[item].action !== 'achat annulée' &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Achat sur {list[item].numorange}  : </p>
        <p>{euro(list[item].countorange).format()} {list[item].devise}</p>
       </div>
      }


      {list[item].operavoda && list[item].action !== 'achat annulée' &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Achat sur {list[item].numvoda}  : </p>
        <p>{euro(list[item].countvoda).format()} {list[item].devise}</p>
       </div>
      }


      {list[item].operaairtel && list[item].action !== 'achat annulée' &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Achat sur {list[item].numairtel}  : </p>
        <p>{euro(list[item].countairtel).format()} {list[item].devise}</p>
       </div>
      }


      {list[item].operaafricell && list[item].action !== 'achat annulée' &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Achat sur {list[item].numafricell}  : </p>
        <p>{euro(list[item].countafricell).format()} {list[item].devise}</p>
       </div>
      }


     </div>
    );
   })}

  </div>
 );
};
