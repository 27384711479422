
import './Line.css';

export default function ReturnLine() {
 return (
  <>
   <div className='mr-top-15'></div>
   <div className='line'></div>
  </>
 );
};