

// Title Tontine component
export default function ReturnTitle() {
 return (
  <div className='title-bd-tontine'>
   <img src={'/img/goup-tontine.png'} />
   <h1>Vérifiez les informations de la Tontine</h1>
  </div>
 );
}