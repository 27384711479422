import React from 'react';
import './Input.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Media from 'react-media';

import { useNavigate } from 'react-router-dom';
import ReturnQuote from './Quote';

import { useForm, Controller } from 'react-hook-form';
import { db, auth } from '../firebase';
import { doc, onSnapshot, collection, getDocs, updateDoc } from "firebase/firestore";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import FadeLoader from 'react-spinners/FadeLoader';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import FormControl from '@mui/material/FormControl';
import REturnlogo from './Logo';
import secureLocalStorage from "react-secure-storage";
import ls from 'localstorage-slim';
import { CiWifiOff, CiLock } from "react-icons/ci";
import moment from 'moment';




ls.config.encrypt = true;
let expireNum = 2;
let bluecolor = '#0467a0';




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};




export let phoneX = '';
let pushAgent = new Array();
let pushClient = new Array();




let idemSix = ['111111', '222222', '333333', '444444', '555555', '666666', '777777', '888888', '999999']
let idemFive = ['11111', '22222', '33333', '44444', '55555', '66666', '77777', '88888', '99999']
let idemFour = ['1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999']


// Input Field Form
export default function REturnInPutConnexIon() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-form-input-sign'>
  <REturnlogo />
  <FormDataInput />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-sign'>
  <REturnlogo />
  <FormDataInput />
 </div>
);

export const FormDataInput = () => {


 const [checked, setChecked] = React.useState(true);
 const [open, setOpen] = React.useState(false);
 const [wifi, setWifi] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [loading, setLoading] = React.useState(false);
 const [codepin, setCodepin] = React.useState();

 const [listAgent, setListAgent] = React.useState([]);
 const [listClient, setListClient] = React.useState([]);

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');
 const { handleSubmit, reset, control } = useForm({});


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };



 React.useEffect(async () => {

  const agentSnapshot = await getDocs(collection(db, "agent"));
  agentSnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.id);
  });

  const clientSnapshot = await getDocs(collection(db, "client"));
  clientSnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.id);
  });


  setListAgent(pushAgent);
  setListClient(pushClient);

 }, []);

 let listClone = [...listClient, ...listAgent];

 const onSubmit = async (data) => {


  let api_id = 'API22388939215';
  let password = 'yT73OipkSK';

  secureLocalStorage.setItem("#$$@%%api$$key", api_id);
  secureLocalStorage.setItem("#$$@%%api$$pasword", password);
  secureLocalStorage.setItem("**@!##J@1777", true);

  secureLocalStorage.setItem("@#%%*@@#!!user++", []);  
  
  if ((!!(data.phone)) != true) {

   setOpen(true);
   setLoading(false)
   reset();


  } else {


   setLoading(true);

   let num = (data.phone).match(/\d+/g);
   let numPhone = '';
   num.map(index => {
    numPhone += index;
   });

   if (!listClone.length) {

    setWifi(true);
    setLoading(false)
    reset();

   } else {

    if (numPhone.length != 10 || numPhone.charAt(0) != 0) {

     setOpen(true);
     setLoading(false)
     reset();

    } else {

     if (listClone.includes(numPhone)) {

      const unsub = onSnapshot(doc(db, listClient.includes(numPhone) ? "client" : "agent", numPhone), (doc) => {
       setCodepin(doc.data().pin);
      });

      phoneX = numPhone;
      secureLocalStorage.setItem('USER', numPhone);

      setChecked(false);
      window.setTimeout(() => {
       setLoading(false)
      }, 1500);

     } else {

      window.setTimeout(() => {
       setLoading(false)
       setOpen(true);
      }, 5530);

      reset();
     }

    }

   }


  }


 };

 return (
  <>
   <div className='recaptcha-container'></div>

   {loading && <div className='Loading-Hm'>
    <FadeLoader
     size={15}
     color={bluecolor}
     loading={loading}
    />
   </div>}

   {checked
    ?
    <form onSubmit={handleSubmit(onSubmit)}>

     <ReturnQuote
      Text={`Envoyer, échanger ou accepter des fiat avec un numero personnel sur votre compte.`}
     />

     <FormControl sx={{ width: '100%' }} variant="standard">
      <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Téléphone</h1></InputLabel>

      <Controller
       name="phone"
       control={control}
       render={({ field }) =>

        <Input
         autoFocus
         value={values.textmask}
         onChange={handleChange}
         inputRef={inputRef}
         onChange={(e) => setSearchTerm(e.target.value)}

         inputProps={{
          autoComplete: "off", inputMode: 'tel'
         }}

         InputProps={{
          inputComponent: NumericFormatCustom,
         }}

         inputmode="tel"
         name="phone"
         id="formatted-text-mask-input"
         inputComponent={TextMaskCustom}
         {...field}
        />


       }
      />

     </FormControl>

     <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}>

      <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
      <DialogContent>

       <DialogContentText>
        <p className='pop-up'>
         Cashbeck ne reconnait pas ce numéro.
       </p>
       </DialogContentText>

      </DialogContent>
      <DialogActions>
       <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
      </DialogActions>
     </Dialog>

     <button className='Btn'>Suivant</button>
     <p className='color-red-msg'>
      Chère Client, pour la protection de votre compte, le code d’Accès et
      le code Pin  restent confidentiel. Merci
     </p>
    </form>

    :
    <InputCodeRecaptcha pin={codepin} collections={listClient.includes(phoneX)} phone={secureLocalStorage.getItem("USER")} />
   }

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

  </>
 );
};

// input Recaptcha  verifier!
export const InputCodeRecaptcha = (props) => {

 const navigation = useNavigate();
 const [ipUid, setIpUid] = React.useState('');
 const [otp, setOtp] = React.useState(false);
 const { handleSubmit, control } = useForm({});

 const [open, setOpen] = React.useState(false);
 const [cancel, setCancel] = React.useState(false);
 const [password, setPassword] = React.useState('');
 const [wifi, setWifi] = React.useState(false);
const [openx, setOpenx] = React.useState(false);

 const [firstname, setFirstname] = React.useState(null);
 const [lastname, setLastname] = React.useState(null);

 const [cdf, setCdf] = React.useState(null);
 const [usd, setUsd] = React.useState(null);

 const [thriftcdf, setThriftcdf] = React.useState(null);
 const [thriftusd, setThriftusd] = React.useState(null);

 const [money, setMoney] = React.useState(null);
 const [active, setActive] = React.useState(null);
 const [pass, setPass] = React.useState(false);

 const [mycode, setMycode] = React.useState(false);
 const [status, setStatus] = React.useState(null);

 const [typecdf, setTypecdf] = React.useState(null);
 const [typeusd, setTypeusd] = React.useState(null);

 const [moneyusd, setMoneyusd] = React.useState(null);
 const [moneycdf, setMoneycdf] = React.useState(null);
 
 
 const [fraiscdf, setFraiscdf] = React.useState(null);
 const [fraisusd, setFraisusd] = React.useState(null);



 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');
 const [loading, setLoading] = React.useState(false);
 const [showPassword, setShowPassword] = React.useState(false);

 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const textFirst = `Veuillez choisir un code d'accès à six chiffres à utiliser pour se connecter`;
 const textLast = `Veuillez définir votre code d'accès`;
 let isInDataPhone = pushClient.some(value => value == props.phone);



 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, isInDataPhone ? "client" : "agent", props.phone), (doc) => {
   
   setIpUid(doc.data().ip);
   setStatus(doc.data().state);

   setCdf(doc.data().cdf);
   setUsd(doc.data().usd);
   setMoney(doc.data().money);

   setThriftcdf(doc.data().thriftcdf)
   setThriftusd(doc.data().thriftusd)
   setActive(doc.data().active)
   setMycode(doc.data().pin)

   setFirstname(doc.data().firstname)
   setLastname(doc.data().lastname)

   setTypecdf(doc.data().signaturecdftype)
   setTypeusd(doc.data().signatureusdtype)

   setMoneyusd(doc.data().signaturemoneyusd)
   setMoneycdf(doc.data().signaturemoneycdf)
   
   setFraiscdf(doc.data().signaturefraiscdf)
   setFraisusd(doc.data().signaturefraisusd)


  });

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
   setPassword(doc.data().accessmain);
  });

 }, []);


 const [scroll, setScroll] = React.useState('paper');

 const handleClickOpenx = (scrollType) => () => {
  setOpen(true);
  setScroll(scrollType);
 };
 const handleClosex = () => {
  setOpenx(false);
 };

 const descriptionElementRef = React.useRef(null);
 
 React.useEffect(() => {
  if (open) {
   const { current: descriptionElement } = descriptionElementRef;
   if (descriptionElement !== null) {
    descriptionElement.focus();
   }
  }
 }, [open]);


 const handleClose = () => {
  setOpen(false);
 };
 const cancelClose = () => {
  setCancel(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleActiveClose = () => {
  setPass(false);
 };


window.console.log(
       cdf  + ' ' + usd  + ' '  +  money + ' ' +  thriftcdf + ' ' +  thriftusd + ' ' + active  +' ' + firstname + ' ' + lastname +  ' ' + typecdf + ' ' + typeusd  +  ' ' +  moneyusd  + ' ' +  moneycdf + ' ' + fraiscdf  + ' ' +  ' ' +  fraisusd
)


 const onSubmitOTP = async (data) => {

  setLoading(true);
  secureLocalStorage.setItem("#$^@!!!8img@((#00+=@@profil", 'https://firebasestorage.googleapis.com/v0/b/muungano-46098.appspot.com/o/image%2Flogo.png?alt=media&token=4af43c9a-4ab8-4f10-b0c5-5ec3ea7b0afe');

  var navigatorInfo = window.navigator;
  var navigatorScreen = window.screen;

  var uid = navigatorInfo.mimeTypes.length;
  uid += navigatorInfo.userAgent.replace(/\D+/g, '');
  uid += navigatorInfo.plugins.length;

  uid += navigatorScreen.height || '';
  uid += navigatorScreen.width || '';
  uid += navigatorScreen.pixelDepth || '';
  uid += props.phone;
  

  if (data.code === undefined || cdf === null || cdf === undefined || usd === null || usd === undefined || money === null || money === undefined || thriftcdf === null || thriftcdf === undefined || thriftusd === null || thriftusd === undefined || active === null || active === undefined || firstname === null || firstname === undefined || lastname === null || lastname === undefined || typecdf === null || typecdf === undefined || typeusd === null || typeusd === undefined || moneyusd === null || moneyusd === undefined || moneycdf === null || moneycdf === undefined || fraisusd === null || fraisusd === undefined) {


   window.setTimeout(() => {
    setWifi(true);
    setLoading(false);
   }, 300);


  } else {

   if (data.code === password) {       
    
    if (status === 'agent') {

     const fraisRef = doc(db, "agent", props.phone);
     await updateDoc(fraisRef, {
      signaturefraisusd : 0,
      signaturefraiscdf : 0,
     });

    }

    if (active === true) {

     secureLocalStorage.setItem("ip^^valid-&&access++dash", uid);
     secureLocalStorage.setItem("ACTIVE_M_USER", true);
     window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
     ls.set('last##73**++Phone &&*@&&@@Number', props.phone, { encrypt: true, secret: 500 });

     window.setTimeout(() => {
      navigation('/dash');
      setLoading(false)
     }, 450);

    } else {

     setLoading(false);
     setPass(true);

    }

   } else {
    
    // This code connexion !!!
    if (props.pin === 'ungano') {

     setOpenx(true);
     setScroll('body');
     setLoading(false);
     secureLocalStorage.setItem("#@#$$code!!##**", data.code);


    } else {

     if (props.pin === data.code) {

      if (active === true) {

       window.setTimeout(() => {

        if (uid === ipUid && props.phone === ls.get('last##73**++Phone &&*@&&@@Number', { decrypt: true, secret: 500 })) {

         secureLocalStorage.setItem("ip^^valid-&&access++dash", uid);
         secureLocalStorage.setItem("ACTIVE_M_USER", true);
         window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
         
         ls.set('last##73**++Phone &&*@&&@@Number', props.phone, { encrypt: true, secret: 500 });

         window.setTimeout(() => {
          navigation('/dash');
          setLoading(false)
         }, 450);

        } else {

         if (props.phone == '0899670921' || props.phone == '0976017724' || props.phone == '0995809841' || props.phone == '0892626145') {

          secureLocalStorage.setItem("ip^^valid-&&access++dash", uid);
          secureLocalStorage.setItem("ACTIVE_M_USER", true);
          window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
          navigation('/dash');

         }
         else {

          secureLocalStorage.setItem("ACTIVE_M_USER", false);
          window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
          secureLocalStorage.setItem("ip^^valid-&&access++dash", uid);
          window.location.href = "/auth/redirect/token";
         }

        }

       }, 750);

      } else {

       setLoading(false);
       setPass(true);

      }

     } else {

      setOtp(true);
      window.setTimeout(() => {
       setOpen(true);
       setLoading(false);
      }, 2100);

     }

    }

   }


  }

 };

 const handleOpenAccessx = async () => {

  setLoading(true);

  var navigatorInfo = window.navigator;
  var navigatorScreen = window.screen;

  var uid = navigatorInfo.mimeTypes.length;
  uid += navigatorInfo.userAgent.replace(/\D+/g, '');
  uid += navigatorInfo.plugins.length;

  uid += navigatorScreen.height || '';
  uid += navigatorScreen.width || '';
  uid += navigatorScreen.pixelDepth || '';
  uid += props.phone;


  const washingtonRef = doc(db, props.collections ? "client" : "agent", props.phone);
  await updateDoc(washingtonRef, {
   pin: secureLocalStorage.getItem("#@#$$code!!##**")
  });

  secureLocalStorage.setItem("ACTIVE_M_USER", true);
  window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
  ls.set('last##73**++Phone &&*@&&@@Number', props.phone, { encrypt: true, secret: 500 });

  secureLocalStorage.setItem("ip^^valid-&&access++dash", uid);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   ip: uid
  });

  window.setTimeout(() => {
   navigation('/dash');
   setLoading(false)
  }, 999);


 }

 return (
  <>
   {loading && <div className='Loading-Hm'>
    <FadeLoader
     size={15}
     color={bluecolor}
     loading={loading}
    />
   </div>}

   <form onSubmit={handleSubmit(onSubmitOTP)}>

    <input type="phone" className='recaptcha-container' />

    {otp ?
     <>
      <ReturnQuote Text={'valider le code envoyer'} />
      <FormControl
       sx={{ width: '100%' }}

       variant="standard">
       <InputLabel htmlFor="standard-adornment-password"><h1 className='pop-up'>Code actuel</h1></InputLabel>

       <Controller
        name="code"
        control={control}
        render={({ field }) =>

         <Input
          autoFocus
          id="standard-adornment-password"
          {...field}
          inputProps={{
           autoComplete: "off", inputMode: 'numeric'
          }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}
          type={showPassword ? 'text' : 'password'}

          endAdornment={
           <InputAdornment position="end">

            <IconButton
             aria-label="toggle password visibility"
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>

           </InputAdornment>
          }

         />}

       />

      </FormControl>
     
     </>
     :
     <>
      <ReturnQuote Text={props.pin == 'ungano' ? textFirst : textLast} />
      <FormControl
       sx={{ width: '100%' }}

       variant="standard">
       <InputLabel htmlFor="standard-adornment-password"><h1 className='pop-up'>Code actuel</h1></InputLabel>

       <Controller
        name="code"
        control={control}
        render={({ field }) =>

         <Input
          autoFocus
          id="standard-adornment-password"
          {...field}
          inputProps={{
           autoComplete: "off", inputMode: 'numeric'
          }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}
          type={showPassword ? 'text' : 'password'}

          endAdornment={
           <InputAdornment position="end">

            <IconButton
             aria-label="toggle password visibility"
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>

           </InputAdornment>
          }

         />
         
         
         }/>

      </FormControl>

     </>
    }

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={pass}
     onClose={handleActiveClose}>

     <DialogContent>

      <DialogContentText>
       <div className='block-flex-center-wifi'>
        <CiLock size={'2em'} color={'crimson'} />
        <p style={{ marginTop: '5vh' }} className='pop-up'>

         Votre compte a été verrouillé sur une situation
         administrative contacter MuunganoMoney pour plus d'informations

        </p>
       </div>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleActiveClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={wifi}
     onClose={handlewifiClose}>

     <DialogContent>

      <DialogContentText>
       <div className='block-flex-center-wifi'>
        <CiWifiOff size={'2em'} color={'crimson'} />
        <p className='pop-up'>
         Connection internet faible

        </p>
       </div>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Code d'accès incorrect!
       </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={cancel}
     onClose={cancelClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>

        Valider le code avec des valeurs numériques, Eviter
        les nombres identiques
        en caractères, Le code est valide de 4 à 6 caractères.

      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={cancelClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <button className='Btn'>Connexion</button>
   </form>


   <React.Fragment>
    <Dialog
     open={openx}
     onClose={handleClose}
     scroll={scroll}
     aria-labelledby="scroll-dialog-title"
     aria-describedby="scroll-dialog-description"
    >
     <DialogTitle id="scroll-dialog-title"><img src={'/img/logo.png'} style={{ width: '5em', height: '5em', objectFit: 'cover' }} /></DialogTitle>
     <DialogContent dividers={scroll === 'paper'}>
      <DialogContentText
       id="scroll-dialog-description"
       ref={descriptionElementRef}
       tabIndex={-1}
      >

       <h1 className='pop-title'>CONDITIONS GENERALES CASHBECK</h1>

       <p className='pop-up'>
        Les conditions d’utilisation suivantes régissent votre accès et votre utilisation du site.
        Veuillez les lire attentivement, car ce document constitue un accord juridique. En accédant
        au site et en l’utilisant, vous acceptez ces conditions. Si vous n’acceptez pas ces conditions, Cashbeck ne vous donnera plus l’accès.
       
       </p>

       <p className='pop-up'>
        Les présentes dispositions s’appliquent à toutes les offres de Service proposées par Cashbeck et s’exécuteront conformément à l’Instruction n° 008-05-2015 du 21 mai 2015 régissant les conditions et modalités d’exercice des émetteurs de monnaie électronique dans les Etats membres de l’Union Monétaire Ouest Africaine.
       </p>

       <p className='pop-up'>
        Les présentes conditions générales des Services restent applicables pendant toute l’utilisation du compte Cashbeck.

       </p>



       <h2 className='pop-title'>
        OBJET DU SERVICE CASHBECK
       </h2>


       <p className='pop-up'>
        Cashbeck est un service bancaire en ligne qui offre multiservices comme : un Compte Courant et un Compte Epargne; achat des produits; la Tontine et Microcrédit ; innovant permettant aux clients à l’échelle nationale, d’effectuer des transactions financières relativement simples à partir du téléphone mobile.
 </p>

 <p className='pop-up'>
        Les fonctionnalités potentielles du Service sont : recevoir les dépôts du public, collecter l'épargne, fournit et gérer les moyens de paiement et accorder des prêts. Le transfert d’argent l’alimentation du compte du Client; l’encaissement de monnaie espèces auprès des établissements distributeurs de monnaie et de toutes les agences de Cashbeck; le paiement de factures; l’achat de biens et services; les inscriptions et frais de concours de différents types et bien d’autres services qui feront leur apparition au fur et mesure de l’évolution du service Cashbeck.

 </p>


       <h3 className='pop-up'>

        CONDITIONS D’OUVERTURE DU COMPTE CLIENT AU PRES D’UN AGENT, L’UTILISATION DU SERVICE CASHBECK.

       </h3>
       <h1 className='pop-up'>L’OUVERTURE DU COMPTE</h1>


       <p className='pop-up'>
Être âgé de 18 ans révolus ; être détenteur d’une pièce d’identité valide.
Les mineurs non émancipés peuvent détenir un compte de monnaie électronique, sous réserve d’une autorisation dûment établie d’un tuteur détenteur d’un document officiel en cours de validité. Toutefois, Cashbeck peut refuser discrétionnairement toute demande d’ouverture de Compte,
notamment dans l’hypothèse où la Pièce d’Identité n’est pas considérée comme satisfaisante ou si les informations fournies ne sont pas complètes et précises à tous égards.

       </p>

       <p className='pop-up'>
        Les relations contractuelles entre Cashbeck et le Client (ouverture de compte, services, fermeture de compte) s’exécuteront, conformément et sous réserve de la législation monétaire, fiscale et tous autres textes, en vigueur en RD Congo.
       </p>

       <p className='pop-up'>
        Disposer d’un montant (Monnaie) en espèce comme premier dépôt et se présenter devant un Agent Cashbeck menue de vos pièces d’identités. L’ouverture de compte et gratuit.
       </p>



      <p className='pop-up'>
        Les Conditions Générales et Particulières acceptées par l’Utilisateur, lors de ses ouvertures de comptes, restent valides dans le cadre de l’utilisation de m-money, à l’exception de celles qui seraient amendées par les présentes Conditions Générales.
      </p>

       <h2 className='pop-up'>ACCES AU SERVICE PAR CASHBECK</h2>
       <h3 className='pop-up'>MATERIEL NECESSAIRE</h3>


       <p className='pop-up'>


        L’utilisation du service nécessite un micro-ordinateur ou un téléphone équipé d’un 
        système d’exploitation, une connexion à un réseau de communication électronique pour
         le transport des informations, ainsi que des logiciels de communication et de navigation que l’Utilisateur installe sur son micro- ordinateur ou un téléphone.
        L’Utilisateur fait son affaire personnelle de son accès à Internet (notamment le choix d’un fournisseur d’accès) et du bon fonctionnement de son équipement informatique. L’Utilisateur doit s’être assuré de la compatibilité du matériel et des logiciels destinés à l’usage du service proposé par la Banque. L’Utilisateur fait également son affaire personnelle du choix et de l’installation de son navigateur, étant entendu que la Banque ne pourra, en aucun cas, être tenue pour responsable des conséquences
         dommageables résultant de l’installation et de l’utilisation dudit navigateur.

         
       </p>

       <h4 className='pop-up'>ACCES AU SERVICE</h4>

       <span className='pop-up'>Pour accéder dans l’application Cashbeck, les informations suivantes sont requises :</span>

       <p className='pop-up'>– nom d’Utilisateur ;</p>
       <p className='pop-up'>– code d’Accès et code Pin en six chiffres</p>
       <p className='pop-up'>– Numéro d’identification RSA</p>
       <p className='pop-up'>(Code de sécurité transmis par e-Token)</p>


       <h5 className='pop-up'>CONFIDENTIALITE ET SECURITE</h5>

       <p className='pop-up'>

Les codes personnels : le code d’accès, le code Pin et l’identification sont strictement confidentiels. Il est donc de la responsabilité de l’Utilisateur de les tenir secrets et de ne les communiquer à personne. L’Utilisateur est entièrement responsable de la conservation et de l’utilisation de ses codes et, le cas échéant, des conséquences de leur divulgation ou de leur utilisation par des tiers. Il appartient notamment à l’Utilisateur de s’assurer que la conservation et la saisie de ses codes personnels sont effectuées dans des conditions parfaites de sécurité et de confidentialité.

       </p>

       <p className='pop-up'>
        L’utilisateur reconnaît la validité des transactions émises par le biais du service Cashbeck à l’aide de ses codes et numéro d’identification.
</p>

<h1 className='pop-up'>
ORDRES DE TRANSFERT
</h1>


<p className='pop-up'>

Le service Cashbeck est disponible 24h/24h. L’exécution de tous services dépend d’un accès internet que vous utilisez. Les instructions pour les transferts doivent être correctes et complètes. Un ordre de transfert transmis par Cashbeck peut être annulé si l’Utilisateur prend contact directement avec le service Cashbeck endéans 72 heures à heure d’une opération pour solliciter l’annulation d’un transfert après avoir pris en compte par la Banque de cet ordre. L’Utilisateur sera responsable d’une erreur de transfert due à l’inexactitude des informations transmises.

</p>


<p className='pop-up'>

L’Utilisateur doit changer le code d’Accès et le code Pin par défaut fourni par le service Cashbeck dès la première utilisation du site. Le code d’Accès et le code Pin ne doit pas être un code facilement identifiable (numéro de téléphone, date de naissance, numéro de plaque d’immatriculation, nom de proches...).

</p>


       <p className='pop-up'>
   En cas de soupçon d’abus d’utilisation d’un code secret sur un compte, ou si l’Utilisateur n’est plus en possession de son code Pin, l’Utilisateur doit en informer immédiatement le service Cashbeck et envoyer une confirmation écrite sous 48 heures. Le service Cashbeck bloquera alors le Compte de Client momentanément pour la sécurité. Les coûts relatifs à cette intervention seront supportés par l’Utilisateur.
</p>



       <h2 className='pop-up'>
        RESPONSABILITE
</h2>



<p className='pop-up'>
        L’Utilisateur est responsable des risques résultant de la divulgation de son code d’Accès et code pin à un tiers. Il est responsable des conséquences de l’usage de ces codes.
        Le service ne pourra pas être tenue pour responsable des pertes subies à la suite d’un mauvais usage du site, d’une mauvaise transmission de données, d’un défaut technique, d’un retard de transmission, d’une interruption des transmissions, d’un disfonctionnement ou d’une interférence de tiers, d’une déficience dans les services de télécommunications ou du fournisseur d’accès Internet, d’une surcharge du réseau informatique, d’un blocage pernicieux des accès par des tiers ou d’une défaillance du serveur.
</p>



<p className='pop-up'>
   Les informations concernant les comptes (les actifs, relevés des opérations...) sont données à titre informatif et n’ont pas de valeur légale. Lorsqu’un risque est détecté, le service Cashbeck se réserve le droit de désactiver le site, pour la sécurité de l’Utilisateur, jusqu’à la disparition du risque. Le service Cashbeck ne peut être tenue responsable des pertes engendrées par cette interruption du service.
</p>



       <h3 className='pop-up'>BLOCAGE DE L’ACCES AU SERVICE</h3>
       <p className='pop-up'>Par mesure de sécurité, l’ensemble des accès au service est bloqué temporairement après composition de trois codes secrets, ou numéro d’identification RSA erronés.</p>
       <p className='pop-up'>
        Le service Cashbeck se réserve le droit de modifier, à tout moment, les codes d’accès de l’Utilisateur au service et/ou de les résilier, sans notification préalable, ceci notamment en cas de risque concernant la sécurité du système informatique de Cashbeck, des services visés ou de non- respect des présentes stipulations.
       </p>

       <p className='pop-up'>
        L’inactivité du compte pendant 1080 jours sans un motif écrit valable entraine une suppression totale du compte sans tenir compte des actifs du compte.
</p>


<h1 className='pop-up'>
        SECURITE SUR INTERNET
</h1>


       <p className='pop-up'>Bien que toutes les mesures de sécurité soient prises, l’Utilisateur doit être conscient que son terminal informatique peut représenter une menace pour la sécurité des services bancaires en ligne. L’Utilisateur est notamment conscient qu’une mauvaise connaissance du système informatique et des procédures de sécurité insuffisantes (sauvegarde non protégée de données dans le disque dur, transfert de fichiers...) peut faciliter un accès non autorisé au service Cashbeck. Il appartient donc à l’Utilisateur de mettre en place les procédures de sécurité adéquate.</p>

       <p className='pop-up'>Par ailleurs, l’utilisation d’Internet engendre un risque élevé de contamination par virus informatique. L’Utilisateur doit donc impérativement équiper son terminal d’un anti- virus adéquat et n’utiliser des logiciels ne provenant que de sources sûres.</p>


       <h3 className='pop-up'>SECRET BANCAIRE</h3>

       <p className='pop-up'>

        Bien que toutes les dispositions soient prises par la Banque pour protéger la confidentialité des informations transmises, l’Utilisateur est conscient que l’utilisation d’Internet n’implique pas forcément le cryptage des informations (en raison, notamment de la législation de certains pays interdisant le cryptage). L’absence de ce cryptage est susceptible de porter atteinte au secret bancaire, ce dont l’Utilisateur est conscient.
         
       </p>


       <h2 className='pop-up'>FRAIS APPLICABLE</h2>
       <p className='pop-up'>
        Nous avons mis un carnet de frais applicable sur le site.</p>


       <h3 className='pop-up'>LA GARANTIE DU COMPTE</h3>

       <p className='pop-up'>

        Il n’y a pas au qu’un frais que la banque en linge prend en compte journalier, semestriel ni annuelle dans le compte de son client.
       </p>
     

       <p className='pop-up'>
        Pour la garantie de vos informations dans notre base de données, le service Cashbeck prend à compte d’un dollars américain dans le compte dollars et deux mille franc congolais dans le compte franc, donc toute transaction inférieur à un dollars américain ou deux mille franc de votre compte, Cashbeck ne vous donne plus l’accès.
     </p>
     
     
       <h5 className='pop-up'>FRAIS D’UNE TRANSACTION</h5>

       <p className='pop-up'>Tout dépôt de Cashbeck reste gratuit chez le client et Agent reçois un paiement d’une commission
au près du service Cashbeck.</p>

       <p className='pop-up'>Tout retrait ou l’envoi d’argent chez le client sont sous frais applicable chez le client.</p>
     
     
     <p className='pop-up'>
        Agent reçois un paiement d’une commission sur les entrés et sortis (retrait ou dépôt) des actifs qui sera valide les cinq premiers jours du mois.
     </p>
     
     

 <h3 className='pop-up'>ACHAT DE PRODUITS</h3>


       <p className='pop-up'>Achat de tous les produits restent gratuits et assuré.</p>
 
 
       <h5 className='pop-up'>MICROCREDIT</h5>


       <p className='pop-up'>

        Le frais sur le prêt se passe sur la convention du prêteur (service) et emprunteur (client) dès le jour d’activation du prêt dans le compte de l’emprunteur (client), ce frais conventionné sera journalier et visible dans le compte de l’emprunteur.

       </p>


     <p className='pop-up'>

Le prêt ne pas lier au compte courant ni épargne et le remboursement du prêt se fera dans le compte de l’emprunteur en se rassurant qu’il a les actifs dans son compte courant ou via un mandataire (dans notre bureau).

     </p>

     <p className='pop-up'>

        Tout l’argent donné chez un Agent ou une personne prétendant être au service de Cashbeck pour le remboursement d’un prêt, ne sera plus pris à compte chez le service Cashbeck.
     </p>


<p className='pop-up'>

        Il n’y a pas au qu’un frais connexe applicable sur le remboursement dans le compte courant de l’emprunteur.
</p>
       

       <h4 className='pop-up'>DUREE D’UN PRET</h4>

       <p className='pop-up'>La durée d’un prêt est de 180 jours renouvelable.</p>






       <h1 className='pop-up'>LA TONTINE</h1>
       <p className='pop-up'>La tontine est une pratique très économique africaine, une méthode d'épargne aussi fiable dans le monde.</p>
       <p className='pop-up'>
        La cotisation se fait à par égal par les membres et chaque mois un participant reçois la somme accumuler.
</p>
       <p className='pop-up'>C'est un système qui fonctionne par rotations jusqu'à ce que dernière personne reçoive sa part et une fois fait le cycle recommence. Chacun emprunte donc à son tour à condition de rembourser. On accumule au qu'un intérêt sur le prêt et il n'y a pas un contrat avec le service Cashbeck.</p>
       <p className='pop-up'>La tontine est entièrement basée sur la confiance mutuelle, l'entraide et la solidarité, et comme c'est un système entièrement basée sur la confiance il n'y a pas au qu'un recours l'égal si quelqu'un refuse à rembourser sa part.</p>



       <h1 className='pop-up'>RUPTURE OU FIN DE CONTRAT</h1>
       <p className='pop-up'>La Banque se réserve le droit de clôturer l’accès de l’Utilisateur, à tout moment, en cas d’utilisation non conforme du service. L’Utilisateur peut, à tout moment, et sans préavis, demander la clôture complète de son accès au service par simple lettre déposée à son agence. La cessation des relations bancaires entre l’Utilisateur et la Banque entraîne la résiliation immédiate de l’abonnement au service.</p>
       <p className='pop-up'>Toute modification de ces Conditions Générales sera communiquée par une notice affichée dans les bureaux des Agents (le point chaud). Ces modifications sont présumées acceptées par l’Utilisateur sauf désaccord exprimé par écrit par ce dernier, dans les 10 jours ouvrés après l’affichage de ces modifications.</p>




       <h1 className='pop-up'>LOI APPLICABLE</h1>
       <p className='pop-up'>Ces Conditions Générales sont régies par le droit de la République Démocratique du Congo.</p>


       <h3 className='pop-up'>MODIFICATIONS DU SERVICE</h3>
       <p className='pop-up'>Compte tenu notamment des améliorations à apporter au service, la Banque se réserve la possibilité d’adapter ou de modifier à tout moment la teneur des prestations offertes par le service. Les nouvelles caractéristiques seront portées à la connaissance de l’Utilisateur lors de la consultation du service.</p>


       <h4 className='pop-up'>RECLAMATIONS</h4>
       <p className='pop-up'>L’Utilisateur peut à tout moment contester la totalité ou une partie des transactions effectuées sur son compte.</p>
       <p className='pop-up'>Si l’Utilisateur ne conteste pas les transactions effectuées dans les 3 jours ouvrables, elles seront considérées comme valides.
La réclamation doit être faite sous forme écrite et être déposée par l’Utilisateur au service Cashbeck.</p>


       <p className='pop-up'>Service Client</p>
       <p className='pop-up'>Cashbeck</p>





      </DialogContentText>
     </DialogContent>
     <DialogActions>


      <div className='flex-between'>
       <div></div>
       <Button onClick={handleOpenAccessx}><p className='pop-up'>accepter</p></Button>
      </div>


     </DialogActions>
    </Dialog>

   </React.Fragment>
  
  </>
 );
};

