import React from 'react';
import './Loading.css';
// import Hm from './Hm/Main';
import Hm from './Home/Main';


function App() {
 return (
  <Hm />
 );
};


export default App;
// Export Component Main !!!