import './Fiat.css';
import ReturnNavBaR from './Nav';
import ReturnInput from './Input';


// Fiat Component 
export default function ReturnFiAt() {
 return (
  <div className='wrp-box-fiat'>
   <ReturnNavBaR />
   <ReturnInput />
  </div>
 )
}