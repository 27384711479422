import React from 'react';

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';


export default function RetuRNAvatars() {

 const [devise, setDevise] = React.useState('');
 React.useEffect(async () => {
  try {
   const unsub = onSnapshot(doc(db, 'tontine', secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")), (doc) => {
    setDevise(doc.data().currency);
   });
  } catch (e) {
   window.console.log(e);
  }

 }, []);


 return (
  <>
   {(devise).includes('USD') && <DollArdCuRRency />}
   {(devise).includes('CDF') && <FrAnCuRRency />}
  </>
 );
};

const DollArdCuRRency = () => {
 return (
  <Avatar
   src={'/img/dollars.png'}
   sx={{ width: 45, height: 45, marginBottom: '3vh' }}
  />
 );
};
const FrAnCuRRency = () => {
 return (
  <Avatar
   src={'/img/franc.png'}
   sx={{ width: 45, height: 45, marginBottom: '3vh' }}
  />
 );
};