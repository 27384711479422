import NavigaTion from './Nav/Main';
import ReturnStocKbody from './Body/Main';

// View Dash component 
export default function ReturnBUy() {
 return (
  <>
   <NavigaTion />
   <ReturnStocKbody />
  </>
 );
};