import React from 'react';
// import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';



import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


import './Input.css';




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


// Firebase auth 
export let phoneX = '';



// Input Field Component 
export default function ReturnInput() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};
export const SreenLarge = () => (
 <div className='wrp-form-input-sign'>
  <FormInput />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-sign'>
  <FormInput />
 </div>
);



export const FormInput = () => {

 const navigation = useNavigate();

 let pushDoc = new Array();
 const [count, setCount] = React.useState(null);
 const [devise, setDevise] = React.useState(null);
 const [first, setFirst] = React.useState(null);
 const [last, setLast] = React.useState(null);

 const [load, setLoad] = React.useState(false);
 const [list, setList] = React.useState([]);

 const { handleSubmit, reset, control } = useForm({});

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "muunganocreditaire"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);
  });

  setList([...new Set(pushDoc)]);

 }, []);



 const [open, setOpen] = React.useState(false);
 const [error, setError] = React.useState(false);
 const [full, setFull] = React.useState(false);


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });


 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleError = () => {
  setError(false);
 };
 const handleFull = () => {
  setFull(false);
 };


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 const onSubmit = async (data) => {

  setLoad(true);

  if ([...list].includes(data.reference)) {

   const unsub = onSnapshot(doc(db, "muunganocreditaire", data.reference), (doc) => {

    if (secureLocalStorage.getItem("#@8@@@**!!operator$$%%") === doc.data().operator) {

     if (doc.data().status === true) {
      setOpen(true);
      setLoad(false);

      setCount(doc.data().count);
      setDevise(doc.data().devise);
      setFirst(doc.data().first);
      setLast(doc.data().last);

     } else if (doc.data().status !== false) {
      setOpen(true);
      setLoad(false);
      setCount(doc.data().count);
      setDevise(doc.data().devise);
     } else {

      secureLocalStorage.setItem("#@!!try**55$$$check!!!", true);
      secureLocalStorage.setItem("#@!!try**55$$$reference##", data.reference);
      secureLocalStorage.setItem("#@!!$$phone##", doc.data().banknumber);
      navigation('/creditaire/view');
      setLoad(false);

     }

    } else {

     setFull(true);
     setLoad(false);

    }

    // window.console.log(doc.data().operator + ' ' + secureLocalStorage.getItem("#@8@@@**!!operator$$%%"));
    // window.console.log(secureLocalStorage.getItem("#@!!$$phone##"))

   });

  } else {
   setError(true);
   setLoad(false);
  }


 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl sx={{ width: '100%' }} variant="standard">

     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nº reference</h1></InputLabel>

     <Controller
      name="reference"
      control={control}
      render={({ field }) =>

       <Input
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        {...field}
       />

      }
     />
    </FormControl>

    <button className='Btn'>Obtenir</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={error}
    onClose={handleError}
   >
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>


       Veuillez vérifier votre numéro de
       référence ou attendre la dernière mise à jour


      </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleError}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}
   >
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <div className='red-creditaire'>
       <p className='pop-up center-creditaire'>
        Ce numéro de référence est déjà utilisé
       </p>
      </div>
      <div className='red-creditaire'>
       <p className='pop-up center-creditaire'>

        {(first + ' ' + last).toLowerCase()}

       </p>
      </div>

      <div className='center-creditaire'>
       <p className='pop-up'>
        {euro(count).format()} {devise}
       </p>
      </div>

     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={full}
    onClose={handleFull}
   >
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>
     <DialogContentText>

      <p className='pop-up'>
       La référence utilisée ne fait pas partie de cette opérateur
       veuillez consulter un MuunganoMoney pour plus d'informations
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleFull}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>


  </>
 );
};
