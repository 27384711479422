import './Quote.css';

// Quote COmpoent Component 
export default function ReturnQuOTe() {
 return (
  <div className='wrp-faq-quote'>
   <p>
    Chère Client, toutes réclamations ou suggestions d'une
    transaction sont permises endéans 30 jours, en date d'une operation.
    Merci de votre fidelité à MuunganoMoney
  </p>
  </div>
 );
}