import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';



export default function RetuRnTAg() {

 const [item, setItem] = React.useState(null);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##")), (doc) => {
   setItem(doc.data().image);
  });
 }, []);

 return (
  <Avatar
   src={item}
   sx={{ width: 80, height: 80 }}
   variant="rounded"
  />
 );
};
