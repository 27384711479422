import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, updateDoc, arrayUnion, collection, getDocs, deleteDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { v4 } from 'uuid';
import { CiWifiOff } from "react-icons/ci";
import currency from 'currency.js';
import axios from 'axios';



let key = v4();



// View Form Update view
export default function ReturnFormUpdate() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
};



let pushDocs = new Array();
let pushPret = new Array();




export const ScreenLarge = () => (
	<div className='wrp-form-input-nows'>
		<FormInputField />
	</div>
);
export const ScreenSmall = () => (
	<div className='wrp-form-input-nows'>
		<FormInputField />
	</div>
);


export const FormInputField = () => {


	const navigation = useNavigate();
	let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
	let password = secureLocalStorage.getItem("#$$@%%api$$pasword");
	let myphone = secureLocalStorage.getItem("USER");
	let soldepret = Number(secureLocalStorage.getItem("&&money::pret__"));


	const [wifi, setWifi] = React.useState(false);

	const { handleSubmit, reset, control } = useForm();
	const [pin, setPin] = React.useState(null);
	const [frais, setFrais] = React.useState(null);
	const [soldeMain, setSoldeMain] = React.useState(null);

	const [problem, setProblem] = React.useState(false);
	const [soldeBuget, setSoldeBuget] = React.useState(null);
	const [soldeBugetrefund, setSoldeBugetrefund] = React.useState(null);

	const [load, setLoad] = React.useState(false);
	const [arr, setArr] = React.useState([]);
	const [arrpret, setArrpret] = React.useState([]);
	const [thrift, setThrift] = React.useState(null);


	const [first, setFirst] = React.useState('');
	const [last, setLast] = React.useState('');


	const [signature, setSignature] = React.useState(null);
	const [signaturetype, setSignaturetype] = React.useState(null);
	const [signaturemoney, setSignaturemoney] = React.useState(null);
	const [signaturekey, setSignaturekey] = React.useState(null);

	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');
	const [open, setOpen] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);



	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleClose = () => {
		setOpen(false);
	};


	React.useEffect(async () => {

		try {
			await onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {
				setPin(doc.data().code);
				setSoldeMain(doc.data().cdf);
				setFrais(doc.data().frais);
				setThrift(doc.data().thriftcdf);

				setFirst(doc.data().firstname);
				setLast(doc.data().lastname);

				setSignature(doc.data().signaturecdf);
				setSignaturetype(doc.data().signaturecdftype);
				setSignaturemoney(doc.data().signaturemoneycdf);
				setSignaturekey(doc.data().signaturekeycdf);

			});

		} catch {
			window.console.log(`Erreur`);
		}

	}, []);
	React.useEffect(async () => {

		try {
			await onSnapshot(doc(db, "muunganomoney", "pretbudget"), (doc) => {
				setSoldeBuget(doc.data().cdf);
			});
		} catch {
			window.console.log(`Erreur`);
		}

	}, []);
	React.useEffect(async () => {

		try {
			await onSnapshot(doc(db, "muunganomoney", "pretbudgetrefund"), (doc) => {
				setSoldeBugetrefund(doc.data().cdf);
			});
		} catch {
			window.console.log(`Erreur`);
		}

	}, []);
	React.useEffect(async () => {

		try {

			const querySnapshot = await getDocs(collection(db, 'pretrefund' + secureLocalStorage.getItem("USER")));
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				pushPret.push(doc.id);
			});

			setArrpret([...new Set(pushPret)]);

		} catch (e) {
			window.console.log(e);
		}



	}, []);


	let colpret = 'pret' + secureLocalStorage.getItem("USER");


	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, colpret));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			pushDocs.push(doc.id);
		});

		setArr([...new Set(pushDocs)]);

	}, []);

	const handleproblemClose = () => {
		setProblem(false);
		navigation('/dash');
	};
	const handlewifiClose = () => {
		setWifi(false);
	};


	// From send CLIENT
	let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney) + Number(frais))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
	let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
	let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;



	// window.console.log(Number(secureLocalStorage.getItem("&&money::pret__")))
	// window.console.log(arrpret);


	const onSubmit = async (data) => {

		setLoad(true);
		if (data.code === undefined) {
			setOpen(true);
			setLoad(false);
			reset();

		} else {


			if (frais === null || frais === undefined || soldeMain === null || soldeMain === undefined || first === null || first === undefined || last === null || last === undefined || soldeBuget === null || soldeBuget === undefined || soldeBugetrefund === null || soldeBugetrefund === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === undefined || signaturemoney === null || signaturekey === null || signaturekey === undefined) {

				setWifi(true);
				setLoad(false);

			} else {

				if (pin != data.code) {
					setOpen(true);
					setLoad(false);
					reset();

				} else {

					if (checkclient) {

						asKedDecrimentpret((Number(soldeMain) - (Number(secureLocalStorage.getItem("&&money::pret__")))), first, last, Number(secureLocalStorage.getItem("&&money::pret__")), Number(soldeMain), key);
						incrementBudgetMain(Number(soldeBuget) + (Number(secureLocalStorage.getItem("&&money::pret__"))));
						incrementBudgetRefundMain(Number(soldeBugetrefund) + (Number(secureLocalStorage.getItem("&&money::pret__"))));
						swapSendUser(myphone, 'Clôture prèt', soldepret, soldeMain, Number(soldeMain) - Number(soldepret), 'envoyer', moment().format(), myphone, first + ' ' + last, '', thrift, thrift, 0, 0, key, 'CDF', 0, 0)
						historypretRemove('pret', secureLocalStorage.getItem("USER"));


						arrpret.map(index => {
							window.setTimeout(() => {
								historypretRemove('pretrefund' + secureLocalStorage.getItem("USER"), index);
							}, 100);

						});


						arr.map(index => {
							historypretRemove(colpret, index);
						});


						let number = secureLocalStorage.getItem("USER");
						let phonesend = `243${number.slice(-9)}`;
						let depotAPI = `Votre pret a ete cloture avec succes. Merci de contacter le service client au prochaine demande.`;


						let config = {
							method: 'get',
							maxBodyLength: Infinity,
							url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
							headers: {}
						};

						axios.request(config)
							.then((response) => {
								console.log(JSON.stringify(response.data));
							})
							.catch((error) => {
								console.log(error);
							});

						window.setTimeout(() => {

							secureLocalStorage.setItem("^^pret%%finish->ok", true);
							secureLocalStorage.setItem("^^pret->ok", false);
							secureLocalStorage.setItem("^^pret->", false);

							secureLocalStorage.setItem("^^pret->part", false);
							secureLocalStorage.setItem("^^snack->all", true);

							window.location.href = "/dash";

						}, 9650);

					} else {
						setProblem(true);
					}

				};

			}

		}

	};

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>

				<FormControl
					sx={{ width: '100%' }}

					variant="standard">
					<InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

					<Controller
						name="code"
						control={control}
						render={({ field }) =>

							<Input
								id="standard-adornment-password"
								{...field}
								type={showPassword ? 'numeric' : 'password'}
								inputProps={{
									autoComplete: "off", inputMode: 'numeric'
								}}

								endAdornment={
									<InputAdornment position="end">

										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>

									</InputAdornment>
								}

							/>}
					/>

				</FormControl>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}>

					<DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								Code pin Incorrect
     </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>

				<button className='Btn-Broker'>Envoi</button>
			</form>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={problem}
				onClose={handleproblemClose}>
				<DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

							Cette transaction ne peut pas être effectuée sur une situation
							de litige sur votre compte veuillez contacter
							MuunganoMoney pour résoudre ses litiges

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={wifi}
				onClose={handlewifiClose}>

				<DialogContent>

					<DialogContentText>
						<div className='block-flex-center-wifi'>
							<CiWifiOff size={'2em'} color={'crimson'} />
							<p className='pop-up'>
								Connection internet faible

        </p>
						</div>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

		</>
	);
};


export async function asKedDecrimentpret(prix, firstname, lastname, solde, before, key) {


	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
	let time = moment().format('LLL');
	let send = { date: time, solde: `${euro(solde).format()} ${'CDF'} [CLOTURE PRET]`, phone: secureLocalStorage.getItem("USER"), user: `${firstname} ${lastname}`, type: 'envoyer', price: euro(solde).format(), actual: euro(prix).format() + ' ' + 'CDF', unite: 'CDF' }


	const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		cdf: prix,
		pret: false,
		pretactive: false,
		pretregister: false,

		signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(solde))}${parseInt(Number(before))}${parseInt(Number(before) - Number(solde))}${key}`,
		signaturecdftype: 'envoyer',
		signaturemoneycdf: parseInt(Number(solde)),
		signaturekeycdf: key,
		signaturefraiscdf: 0,

		user: 'clôture prêt',

		money: parseInt(Number(solde)),
		swap: arrayUnion(send),
		commission: Number(0),
		sendtype: 'envoyer',
		unite: 'CDF',
		frais: 0,
		delay: moment().format(),

	});

};
async function historypretRemove(colpret, folder) {
	await deleteDoc(doc(db, colpret, folder));
};
export async function incrementBudgetMain(prix) {

	const washingtonRef = doc(db, "muunganomoney", "pretbudget");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		cdf: prix
	});

};
export async function incrementBudgetRefundMain(prix) {

	const washingtonRef = doc(db, "muunganomoney", "pretbudgetrefund");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		cdf: prix
	});

};

// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

	const docRef = await addDoc(collection(db, "swap" + colSwap), {

		action: action,
		money: money,
		before: before,
		after: after,
		type: type,
		date: date,
		delay: date,

		phone: phone,
		user: user,

		profile: profile,
		beforethrift: beforethrift,
		afterthrift: afterthrift,
		commission: commision,
		frais: frais,
		access: false,
		key: key + date,
		devise: devise,

		soldebefore: soldebefore,
		soldeafter: soldeafter,

		color: '#ffebc5',
		dateofcreate: serverTimestamp(),

		statetrans: false,
		statepret: true,
		stateunit: false,
		statetontine: false,
		statetv: false,
		statesolaire: false,


	});


};



