
import './Icon.css';

// Icon Return view
export default function ReturnIcon() {
 return (
  <div className='icon-budget-tontine-bd'>
   <img src={'/img/money-bag.png'} />
  </div>
 );
};