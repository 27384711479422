import React from 'react';
import REturnSignUp from './Sign';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Register Main Available
export default function SignUp() {

 const [open, setOpen] = React.useState(true);

 React.useEffect(() => {
  window.setTimeout(() => {
   setOpen(false);
  }, 6600);

 }, []);

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={open}>
     <CircularProgress color="inherit" />

    </Backdrop>
   </div>

   <REturnSignUp />
  </>
 );
};