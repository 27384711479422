import './Quote.css';

// Return Quote Component
export default function ReturnQuOte() {
 return (
  <div className='qte-broker-unite'>
   <p>
    Achetez sur MuunganoMoney. Obtenez les meilleurs prix, et tout cela grâce à votre cashback.
   </p>
  </div>
 );
};