import './Well.css';
import NavBar from './Nav/Main';
import WellBody from './Body/Main';


// well tontine
export default function ReturnWell() {
 return (
  <div className='wrp-well-tontine'>
   <NavBar />
   <WellBody />

  </div>
 );
};