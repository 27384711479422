import './Back.css';
import { useNavigate } from 'react-router';
import { HiArrowLeft } from 'react-icons/hi';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export function BacK() {
 const navigation = useNavigate();
 return (
  <div className='wrp-back-brokers'>
   <div onClick={() => navigation('/dash')} className='wrp-back-box-brokers'>

    <Tooltip title="Retour">
     <IconButton>
      <HiArrowLeft size={'1.3em'} color={'grey'} />
     </IconButton>
    </Tooltip>

   </div>
  </div>
 );
};

// Return Back
export default function ReturnBacK() {
 return (
  <BacK />
 );
};