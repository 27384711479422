import './Home.css';
import REturnLogo from './Logo';
import REturnBtn from './Btn';

// REturnBtn Home Component 
export default function RegisterHome() {
 return (
  <div className='wrp-box-register'>
   <REturnLogo />
   <REturnBtn />
  </div>
 );
};