import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import secureLocalStorage from "react-secure-storage";
import './Switch.css';


const PinkSwitch = styled(Switch)(({ theme }) => ({
 '& .MuiSwitch-switchBase.Mui-checked': {
  color: pink[600],
  '&:hover': {
   backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
  },
 },
 '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
  backgroundColor: pink[600],
 },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };


export default function RetuRnSwitches() {

 const [checked, setChecked] = React.useState(true);
 const handleChange = (event) => {
  secureLocalStorage.setItem("#!!@%$$switch**^^%%", checked);
  setChecked(event.target.checked);
 };

 return (
  <div className='flex-tontine-model'>

   {checked
    ?
    <h1>groupe ouvert</h1>
    :
    <h1>groupe fermé</h1>
   }


   <PinkSwitch

    checked={checked}
    onChange={handleChange}
    inputProps={{ 'aria-label': 'controlled' }}

    {...label} defaultChecked

   />
  </div>
 );
};
