import './Network.css';
import Media from 'react-media';
import RetuRnVodA from './Vodacom';
import RetuRnAiRtel from './Airtel';
import RetuRnORAnge from './Orange';
import RetuRnAfRicEll from './Africell';


// Network Operator
export default function ReturnNeTwoRK() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='flex-between flex-network-operator'>
  <RetuRnVodA />
  <RetuRnAiRtel />
  <RetuRnORAnge />
  <RetuRnAfRicEll />
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-between flex-network-operator-sm'>
  <RetuRnVodA />
  <RetuRnAiRtel />
  <RetuRnORAnge />
  <RetuRnAfRicEll />

 </div>
);

