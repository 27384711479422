
import './Rate.css';

// Return view Rate
export default function ReturnRate() {
 return (
  <div className='wrp-rate-stock'>
   <span>Taux 2400</span>
  </div>
 );

}