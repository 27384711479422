import './Title.css';
import Media from 'react-media';


// Middle Head 
export default function RetuRnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='service-quote-all'>
  <View Title={'Suivi en temps réel'} Text={'Nous vous fournissons des mises à jour en temps réel de vos investissements inclus de fixation de prix et performance et les dividendes'} />
  <div className='margin-top-5vh'></div>
  <View Title={'Diversification simplifiée'} Text={'vous pouvez diversifier vos investissements plus facilement en accès dans une large gamme de titres'} />
  <div className='margin-top-5vh'></div>
  <View Title={'Réduction des coûts'} Text={`Les frais associés aux transactions et à la gestion peuvent être rédigresse à l'automatisation et à l'efficacité des processus en ligne`} />
  <div className='margin-top-5vh'></div>
  <View Title={'Sécurité des données'} Text={`les portefeuilles en ligne utilisent des mesures de sécurité robustes pour protéger vos informations personnelles et financières offrant ainsi une tranquillité d'esprit`} />
 </div>
)
export const ScreenSmall = () => (
 <div></div>
)
export const View = (props) => (
 <div>
  <h3>
   {props.Title}
  </h3>

  <p>
   {props.Text}
  </p>
 </div>
);


