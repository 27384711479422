import wallet from '../../../images/wallet.gif';
import React from 'react';
import { TweenMax, Expo } from 'gsap';
import './IMA.css';
import RetuRNAvatars from './Avatar';

// View IMA 
export default function ReturnIMA() {

 React.useEffect(() => {
  TweenMax.from('.Anima-Send', 1, { delay: 5, opacity: 0, ease: Expo.easeInOut });

 }, [])

 return (
  <div className='wrp-tontine-ima-method'>

   <img src={wallet} alt='send wallet' className='package Anima-Send' />

   <p>
    Votre solde Tontine disponible
   </p>


   <RetuRNAvatars />
  </div>
 )
};