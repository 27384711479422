import './Operator.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';
import FooTER from './Footer/Main';


// Operator view Home 
export default function RetuRnOpeRAToR() {
 return (
  <div className='wrp-operator-creditor'>
   <HeAd />
   <BOdy />
   <FooTER />

  </div>
 );
};