import React from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp, addDoc, setDoc } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import './Button.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { v4 } from 'uuid';

import { CiWifiOff } from "react-icons/ci";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



let key = v4();


// Footer Main
export default function RetuRnButtoN() {

  const navigation = useNavigate();

  let pushDoc = new Array();
  const [load, setLoad] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [wifi, setWifi] = React.useState(false);


  const [fran, setFran] = React.useState(null);
  const [dollard, setDollard] = React.useState(null);
  const [devise, setDevise] = React.useState('');
  const [count, setCount] = React.useState(null);
  const [profil, setProfil] = React.useState(null);
  const [first, setFirst] = React.useState(null);
  const [last, setLast] = React.useState(null);
  const [thrift, setThrift] = React.useState(null);
  const [thriftdollard, setThriftdollard] = React.useState(null);


  const [riseDollard, setRiseDollard] = React.useState(null);
  const [riseFran, setRiseFran] = React.useState(null);


  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');


  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushDoc.push(doc.id);

    });

    setList([...new Set(pushDoc)]);

  }, []);
  React.useEffect(async () => {

    const querySnapshotClient = await getDocs(collection(db, "client"));
    querySnapshotClient.forEach((doc) => {
      pushDoc.push(doc.id);
    });

    var verifierCollection = pushDoc.some((value) => value == secureLocalStorage.getItem("USER"));

    try {

      const unsub = onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
        // Document was found in the cache. If no cached document exists,
        setFran(doc.data().cdf);
        setDollard(doc.data().usd);
        setProfil(doc.data().profile);
        setFirst(doc.data().firstname);
        setLast(doc.data().lastname);
        setThrift(doc.data().thriftcdf);
        setThriftdollard(doc.data().thriftusd);

      });

    } catch (error) {

      setFran(null);
      setDollard(null);
      setProfil(null);
      setFirst(null);
      setLast(null);


    }

  }, []);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##")), (doc) => {
      setDevise(doc.data().devise);
      setCount(doc.data().count);

    });


  }, []);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganomoney", "budgetcreditaire"), (doc) => {
      setRiseDollard(doc.data().dollard);
      setRiseFran(doc.data().fran);

    });


  }, []);


  const handlewifiClose = () => {
    setWifi(false);
  };

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

  const handleCreditaire = async () => {

    setLoad(true);

    if (thrift === null || thrift === undefined || thriftdollard === null || thriftdollard === null || riseDollard === null || riseDollard === undefined || riseFran === null || riseFran === undefined || fran === null || fran === undefined || dollard === null || dollard === undefined || count === null || count === undefined || profil === null || profil === undefined || first === null || first === undefined || last === null || last === undefined) {

      setWifi(true);

    } else {


      let moneyDollard = Number(riseDollard) - Number(count);
      let moneyFran = Number(riseFran) - Number(count);

      if (devise === 'USD') {

        let time = moment().format('LLL');
        let get = { date: time, solde: `${euro(count).format()} ${'USD'}`, phone: secureLocalStorage.getItem("#@!!$$phone##"), user: secureLocalStorage.getItem("#@8@@@**!!operator$$%%"), type: 'Reçu', pricenew: euro(Number(count) + Number(dollard)).format(), actual: euro(Number(count) + Number(dollard)).format() + ' ' + 'USD', unite: 'USD' }

        const creditairerizeRef = doc(db, "muunganomoney", "budgetcreditaire");
        // Set the "capital" field of the city 'DC'
        await updateDoc(creditairerizeRef, {
          dollard: moneyDollard,
        });

        const soldenewRef = doc(db, [...list].includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
        // Set the "capital" field of the city 'DC'
        await updateDoc(soldenewRef, {
          usd: Number(count) + Number(dollard),
          money: Number(count),
          user: 'Creditaire',
          unite: 'USD',

          phoneclient: '777',
          delay: moment().format(),
          commission: 0,

          signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt((count))}${parseInt((dollard))}${parseInt((dollard) + (count))}${key}`,
          signatureusdtype: 'reussi',
          signaturemoneyusd: parseInt(Number(count)),
          signaturekeyusd: key,
          signaturefraisusd: 0,

          swap: arrayUnion(get),
          frais: 0,
          sendtype: 'Reçu'

        });

        const creditaireRef = doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##"));
        // Set the "capital" field of the city 'DC'
        await updateDoc(creditaireRef, {
          status: true,
          dateactive: moment().format(),
          first: first,
          last: last,
        });

        // Add a new document with a generated id.
        await addDoc(collection(db, "muunganocreditaireclient"), {
          profil: profil,
          before: euro(Number(dollard)).format(),
          money: euro(Number(count)).format(),
          after: euro(Number(count) + Number(dollard)).format(),
          date: moment().format(),
          operator: secureLocalStorage.getItem("#@8@@@**!!operator$$%%"),
          ref: secureLocalStorage.getItem("#@!!try**55$$$reference##"),
          phone: secureLocalStorage.getItem("#@!!$$phone##"),
          first: first,
          last: last,
          devise: 'USD',
          dateofcreate: serverTimestamp()
        });

        swapGetUser(secureLocalStorage.getItem("USER"), `créditer balance`, count, dollard, dollard + count, 'Reçu', moment().format(), secureLocalStorage.getItem("#@!!$$phone##"), 'créditer balance', profil, thrift, thrift, 0, 0, key, 'USD', 0, 0, secureLocalStorage.getItem("#@8@@@**!!operator$$%%"), secureLocalStorage.getItem("#@!!try**55$$$reference##"));

        window.setTimeout(() => {
          window.location.href = '/dash';
        }, 3500);


      } else if (devise === 'CDF') {

        let time = moment().format('LLL');
        let get = { date: time, solde: `${euro(count).format()} ${'CDF'}`, phone: secureLocalStorage.getItem("#@!!$$phone##"), user: secureLocalStorage.getItem("#@8@@@**!!operator$$%%"), type: 'Reçu', pricenew: euro(Number(count) + Number(fran)).format(), actual: euro(Number(count) + Number(fran)).format() + ' ' + 'CDF', unite: 'CDF' }


        const creditairerizeRef = doc(db, "muunganomoney", "budgetcreditaire");
        // Set the "capital" field of the city 'DC'
        await updateDoc(creditairerizeRef, {
          fran: moneyFran,
        });

        const soldenewRef = doc(db, [...list].includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
        // Set the "capital" field of the city 'DC'
        await updateDoc(soldenewRef, {
          cdf: Number(count) + Number(fran),
          money: Number(count),
          user: 'Creditaire',

          unite: 'CDF',
          phoneclient: '777',
          delay: moment().format(),
          commission: 0,

          signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt((count))}${parseInt((fran))}${parseInt((fran) + (count))}${key}`,
          signaturecdftype: 'reussi',
          signaturemoneycdf: parseInt(Number(count)),
          signaturekeycdf: key,
          signaturefraiscdf: 0,

          swap: arrayUnion(get),
          frais: 0,
          sendtype: 'Reçu'

        });

        const creditaireRef = doc(db, "muunganocreditaire", secureLocalStorage.getItem("#@!!try**55$$$reference##"));
        // Set the "capital" field of the city 'DC'
        await updateDoc(creditaireRef, {
          status: true,
          dateactive: moment().format(),
          first: first,
          last: last,
        });

        // Add a new document with a generated id.
        await addDoc(collection(db, "muunganocreditaireclient"), {
          profil: profil,
          before: euro(Number(fran)).format(),
          money: euro(Number(count)).format(),
          after: euro(Number(count) + Number(fran)).format(),
          date: moment().format(),
          operator: secureLocalStorage.getItem("#@8@@@**!!operator$$%%"),
          ref: secureLocalStorage.getItem("#@!!try**55$$$reference##"),
          phone: secureLocalStorage.getItem("#@!!$$phone##"),
          first: first,
          last: last,
          devise: 'CDF',
          dateofcreate: serverTimestamp()
        });

        swapGetUser(secureLocalStorage.getItem("USER"), `créditer balance`, count, fran, fran + count, 'Reçu', moment().format(), secureLocalStorage.getItem("#@!!$$phone##"), 'créditer balance', profil, thrift, thrift, 0, 0, key, 'CDF', 0, 0, secureLocalStorage.getItem("#@8@@@**!!operator$$%%"), secureLocalStorage.getItem("#@!!try**55$$$reference##"));

        window.setTimeout(() => {
          window.location.href = '/dash';
        }, 5500);


      } else {
        navigation(0)
      }

    }

  };

  return (
    <div className='flex-footer-button-credit'>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <button onClick={handleCreditaire}>Valider</button>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={wifi}
        onClose={handlewifiClose}>

        <DialogContent>

          <DialogContentText>
            <div className='block-flex-center-wifi'>
              <CiWifiOff size={'2em'} color={'crimson'} />
              <p className='pop-up'>
                Connection internet faible

        </p>
            </div>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};


async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter, operator, ref) {

  // Add a new document with a generated id
  const newCityRef = doc(collection(db, "swap" + colSwap));
  // later...
  await setDoc(newCityRef, {

    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key,
    devise: devise,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    operator: operator,
    ref: ref,

    color: '#F1F2F0',
    dateofcreate: serverTimestamp(),

    statepret: false,
    statecrediter: true,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,

  });

};
