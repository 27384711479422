import React from 'react';
import './Icon.css';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import { db } from '../../../../firebase';
import { doc, collection, getDocs, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router-dom';


let pushDocs = new Array();


// view invite component
export default function ReturnIconPrinT() {

  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const navigation = useNavigate();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(async () => {

    const querySnapshotClient = await getDocs(collection(db, "client"));
    querySnapshotClient.forEach((doc) => {
      pushDocs.push(doc.id);
    });

    try {

      const unsub = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
        setList(doc.data().swap);
      });

    } catch (e) {
      window.console.log(e);
    }

  }, []);

  const handlepath = (event) => {

    event.preventDefault();
    if (JSON.parse(window.localStorage.getItem('##^^@@%^***^^++=$')) && secureLocalStorage.getItem("&&837$$prnt@*#())")) {
      navigation('/muungano/print/ticket');
    } else {
      setOpen(true);
    }

  };

  let col = pushDocs.includes(secureLocalStorage.getItem("USER"));

  return (
    <>
      <div className='tontine-nav-invited-icon-dash'>
        {!col &&
          <div onClick={handlepath}>
            <IconButton>
              <img src={'/img/printing.png'} />
            </IconButton>
          </div>
        }
      </div>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
        <DialogContent>

          <DialogContentText>
            <p className='pop-up'>
              Aucune transaction n'est disponible pour lancer Impression
       </p>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

    </>
  );
};