import * as React from 'react';
import './Head.css';
import { useForm, Controller } from 'react-hook-form';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

import { doc, setDoc, deleteDoc, collection, getDocs, arrayUnion, updateDoc, query } from "firebase/firestore";
import { db } from '../../../../firebase';

import { HiArrowLeft } from 'react-icons/hi';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';




let pushSnapshots = new Array();
let lastPush = new Array();


let orangePush = new Array();
let vodaPush = new Array();
let airtelPush = new Array();
let africellPush = new Array();





const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


let pathPhone = false;
let pathVoda = false;
let pathAirtel = false;
let pathAfricell = false;
let pathOrange = false;




function DrawerAppBar() {

 const navigation = useNavigate();
 const { handleSubmit, control } = useForm({});
 const timer = React.useRef();
 const [load, setLoad] = React.useState(false);

 React.useEffect(() => {
  return () => {
   clearTimeout(timer.current);
  };
 }, []);


 const [phone, setPhone] = React.useState(false);
 const [voda, setVoda] = React.useState(false);
 const [airtel, setAirtel] = React.useState(false);
 const [africell, setAfricell] = React.useState(false);
 const [orange, setOrange] = React.useState(false);

 const [list, setList] = React.useState([]);

 const [orangeArr, setOrangeArr] = React.useState([]);
 const [vodaArr, setVodaArr] = React.useState([]);
 const [airtelArr, setAirtelArr] = React.useState([]);
 const [africellArr, setAfricellArr] = React.useState([]);


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');


 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');

 const clearSearchString = (event) => {
  setSearchTerm('');
  inputRef.current.focus();
 }



 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'muunganounitorange'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   orangePush.push(doc.id);

  });

  setOrangeArr(orangePush)

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'muunganounitvoda'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   vodaPush.push(doc.id);

  });

  setVodaArr(vodaPush)

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'muunganounitairtel'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots

   airtelPush.push(doc.id);
  });

  setAirtelArr(airtelPush)

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'muunganounitafricell'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   africellPush.push(doc.id);

  });

  setAfricellArr(africellPush)

 }, []);




 // Obj
 // let getDocAlluserInfo = JSON.parse(window.localStorage.getItem('--fiat^^edit-doc'));
 let getDocAlluserInfo = secureLocalStorage.getItem("--fiat^^edit-doc")

 let fiatID = getDocAlluserInfo.id;
 let fiatFirst = getDocAlluserInfo.first;
 let fiatLast = getDocAlluserInfo.last;
 let fiatPhone = getDocAlluserInfo.contact;
 let fiatDwelling = getDocAlluserInfo.dwelling;
 let fiatResidence = getDocAlluserInfo.residence;
 let fiatStaf = getDocAlluserInfo.staf;

 let fiatVoda = getDocAlluserInfo.voda;
 let fiatAirtel = getDocAlluserInfo.airtel;
 let fiatOrange = getDocAlluserInfo.orange;
 let fiatAfricell = getDocAlluserInfo.africell;


 const str2 = fiatLast.charAt(0).toUpperCase() + fiatLast.slice(1);


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };


 const handlePhone = () => {
  setPhone(false);
 };
 const handleVoda = () => {
  setVoda(false);
 };

 const handleAirtel = () => {
  setAirtel(false);
 };
 const handleAfricell = () => {
  setAfricell(false);
 };
 const handleOrange = () => {
  setOrange(false);
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, fiatID));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   let obj = { id: doc.id, ...doc.data() }
   pushSnapshots.push(obj);

  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("--fiat^^edit-doc##id")));
  querySnapshot.forEach((doc) => {

   lastPush.push(doc.data() === undefined ? [] : doc.data());

  });


  setList([...new Set(lastPush)]);

 }, []);


 // Ref collection database!
 const onSubmit = async (data) => {

  setLoad(true);
  secureLocalStorage.setItem("@%^***>edit", false);

  let number = (data.phone).match(/\d+/g);
  let numPhone = '';
  number.map(index => {
   numPhone += index;
  });

  let voda = (data.voda).match(/\d+/g);
  let numPhoneVoda = '';
  voda.map(index => {
   numPhoneVoda += index;
  });


  let airtel = (data.airtel).match(/\d+/g);
  let numPhoneAirtel = '';
  airtel.map(index => {
   numPhoneAirtel += index;
  });

  let orange = (data.orange).match(/\d+/g);
  let numPhoneOrange = '';
  orange.map(index => {
   numPhoneOrange += index;
  });

  let africell = (data.africell).match(/\d+/g);
  let numPhoneAfricell = '';
  africell.map(index => {
   numPhoneAfricell += index;
  });


  if (/^\d+$/.test(`${numPhone}`) && numPhone.length == 10 && data.phone != undefined) {
   pathPhone = true;
  } else {
   setPhone(true);
   pathPhone = false;
   setLoad(false);
  };

  if (/^\d+$/.test(`${numPhoneVoda}`) && numPhoneVoda.length == 10 && (numPhoneVoda.includes('081') || numPhoneVoda.includes('082') || numPhoneVoda.includes('083'))) {
   pathVoda = true;
  } else {
   setVoda(true);
   pathVoda = false;
   setLoad(false);
  };

  if (/^\d+$/.test(`${numPhoneAirtel}`) && numPhoneAirtel.length == 10 && (numPhoneAirtel.includes('099') || numPhoneAirtel.includes('097') || numPhoneAirtel.includes('098'))) {
   pathAirtel = true;
  } else {
   setAirtel(true);
   pathAirtel = false;
   setLoad(false);
  };

  if (/^\d+$/.test(`${numPhoneOrange}`) && numPhoneOrange.length == 10 && (numPhoneOrange.includes('089') || numPhoneOrange.includes('084') || numPhoneOrange.includes('080') || numPhoneOrange.includes('085'))) {
   pathOrange = true;
  } else {
   setOrange(true);
   pathOrange = false;
   setLoad(false);
  };

  if (/^\d+$/.test(`${numPhoneAfricell}`) && numPhoneAfricell.length == 10 && (numPhoneAfricell.includes('090') || numPhoneAfricell.includes('091'))) {
   pathAfricell = true;
  } else {
   setAfricell(true);
   pathAfricell = false;
   setLoad(false);
  };


  let identity = {

   firstname: data.firstname.toLowerCase(),
   lastname: data.lastname.toLowerCase(),
   staf: data.staf.toLowerCase(),
   residence: data.residence.toLowerCase(),
   dwelling: data.dwelling.toLowerCase(),
   contact: numPhone,
   date: moment().format()
  }
  let allnum = {

   voda: numPhoneVoda,
   airtel: numPhoneAirtel,
   orange: numPhoneOrange,
   africell: numPhoneAfricell,

  }
  let newdata = {
   ...identity,
   ...allnum

  }

  let newIdFiat = data.firstname.toLowerCase() + data.lastname.toLowerCase() + data.staf.toLowerCase() + numPhoneVoda + numPhoneAirtel + numPhoneOrange + numPhoneAfricell

  if (pathVoda && pathAirtel && pathAfricell && pathOrange && pathPhone) {

   if (secureLocalStorage.getItem("--fiat^^edit-doc##id") === newIdFiat) {
    window.setTimeout(() => {
     navigation('/save/fiat/update/success');
    }, 2000);

   } else if (!(list?.length ? true : false)) {


    // update with new fiat
    createNewDocFiat(newIdFiat, newdata);
    // remove last  fiat
    removeDocFiat();
    window.setTimeout(() => {
     navigation('/brokers/sign/fiat/list');
    }, 2000);


   } else {

    // update with new fiat
    createNewDocFiat(newIdFiat, newdata);

    [...Array(list.length).keys()].map(async index => {

     await setDoc(doc(db, newIdFiat, list[index].fiatdocument), list[index]);
     updateDocFiatCmd(newIdFiat, list[index].fiatdocument, newIdFiat);

    });
    [...Array(list.length).keys()].map(async index => {

     if (orangeArr.includes(list[index].fiatdocument)) {
      setDocFiatChange('muunganounitorange', list[index].fiatdocument, newIdFiat, data.firstname.toLowerCase() + ' ' + data.lastname.toLowerCase())
     }

    });
    [...Array(list.length).keys()].map(async index => {

     if (vodaArr.includes(list[index].fiatdocument)) {
      setDocFiatChange('muunganounitvoda', list[index].fiatdocument, newIdFiat, data.firstname.toLowerCase() + ' ' + data.lastname.toLowerCase())

     }

    });
    [...Array(list.length).keys()].map(async index => {

     if (airtelArr.includes(list[index].fiatdocument)) {
      setDocFiatChange('muunganounitairtel', list[index].fiatdocument, newIdFiat, data.firstname.toLowerCase() + ' ' + data.lastname.toLowerCase())

     }

    });

    [...Array(list.length).keys()].map(index => {

     if (africellArr.includes(list[index].fiatdocument)) {
      setDocFiatChange('muunganounitafricell', list[index].fiatdocument, newIdFiat, data.firstname.toLowerCase() + ' ' + data.lastname.toLowerCase())
     }

    });
    [...Array(list.length).keys()].map(async index => {

     await deleteDoc(doc(db, secureLocalStorage.getItem("--fiat^^edit-doc##id"), list[index].fiatdocument));

    });
    [...Array(list.length).keys()].map(async index => {
     await deleteDoc(doc(db, secureLocalStorage.getItem("--fiat^^edit-doc##id"), list[index].fiatdocument));
    });

    // remove last  fiat !!!
    removeDocFiat();
    secureLocalStorage.removeItem('--fiat^^edit-doc##id');
    secureLocalStorage.removeItem('--fiat^^edit-doc');
    secureLocalStorage.setItem("@%^**fiatpath*>edit", false)
    secureLocalStorage.setItem("@%^***>edit", false);


    window.setTimeout(() => {
     // window.location.href = "/brokers/sign/fiat/list";
     window.location.href = "/dash";
    }, 13000);

   }

  } else {
   window.alert('not connexion');
  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-head-list-cmd flex-head-list-cmd-button'>

    <header>
     <div className='container'>
      <nav className='navbar'>

       <div onClick={() => navigation(-1)}>
        <HiArrowLeft size={'1.6em'} color={'white'} />
       </div>

       <span>{str2}</span>

      </nav>
     </div>
    </header>

    <section>
     <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nom</h1></InputLabel>

       <Controller
        name="firstname"
        defaultValue={fiatFirst}
        control={control}
        render={({ field }) =>

         <Input
          inputProps={{ autoComplete: "off" }}
          name="firstname"
          {...field}
         />}

       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Prènom</h1></InputLabel>

       <Controller
        name="lastname"
        defaultValue={fiatLast}
        control={control}
        render={({ field }) =>

         <Input
          inputProps={{ autoComplete: "off" }}
          name="lastname"
          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Staf</h1></InputLabel>

       <Controller
        name="staf"
        defaultValue={fiatStaf}
        control={control}
        render={({ field }) =>

         <Input
          inputProps={{ autoComplete: "off" }}
          name="staf"
          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Résidence actuelle</h1></InputLabel>

       <Controller
        name="residence"
        defaultValue={fiatResidence}
        control={control}
        render={({ field }) =>

         <Input
          inputProps={{ autoComplete: "off" }}
          name="residence"
          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Résidence du staf</h1></InputLabel>

       <Controller
        name="dwelling"
        defaultValue={fiatDwelling}
        control={control}
        render={({ field }) =>

         <Input
          inputProps={{ autoComplete: "off" }}
          name="dwelling"
          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero de contact</h1></InputLabel>

       <Controller
        name="phone"
        defaultValue={fiatPhone}
        control={control}
        render={({ field }) =>

         <Input
          value={values.textmask}
          onChange={handleChange}
          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}

          {...field}
         />

        }
       />
      </FormControl>


      <h1 className='mt-title-update-fiat'>Numéro d'utilisation</h1>


      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero Vodacom</h1></InputLabel>

       <Controller
        name="voda"
        defaultValue={fiatVoda}
        control={control}
        render={({ field }) =>

         <Input
          value={values.textmask}
          onChange={handleChange}
          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}

          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero Airtel</h1></InputLabel>

       <Controller
        name="airtel"
        defaultValue={fiatAirtel}
        control={control}
        render={({ field }) =>

         <Input
          value={values.textmask}
          onChange={handleChange}
          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}

          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero Orange</h1></InputLabel>

       <Controller
        name="orange"
        defaultValue={fiatOrange}
        control={control}
        render={({ field }) =>

         <Input
          value={values.textmask}
          onChange={handleChange}
          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}

          {...field}
         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero Africell</h1></InputLabel>

       <Controller
        name="africell"
        defaultValue={fiatAfricell}
        control={control}
        render={({ field }) =>

         <Input
          value={values.textmask}
          onChange={handleChange}
          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}

          {...field}
         />

        }
       />
      </FormControl>


      <button className='Btn'>Enregistre</button>
     </form>

    </section>


    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={phone}
     onClose={handlePhone}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Veuillez vérifier le numéro de Contact s'il vous plaît
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handlePhone}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={voda}
     onClose={handleVoda}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Veuillez vérifier le numéro de téléphone Vodacom s'il vous plaît
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleVoda}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={airtel}
     onClose={handleAirtel}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <p className='pop-up'>
        Veuillez vérifier le numéro de téléphone Airtel s'il vous plaît
      </p>

      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleAirtel}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={africell}
     onClose={handleAfricell}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Veuillez vérifier le numéro de téléphone Africell s'il vous plaît
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleAfricell}>Fermer</Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={orange}
     onClose={handleOrange}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <p className='pop-up'>
        Veuillez vérifier le numéro de téléphone Orange s'il vous plaît
      </p>

      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleOrange}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

   </div>

  </>
 );
};


export const createNewDocFiat = async (docFiat, data) => {
 await setDoc(doc(db, secureLocalStorage.getItem("USER"), docFiat), data);

};
// Remove last doc fiat
export const removeDocFiat = async () => {
 await deleteDoc(doc(db, secureLocalStorage.getItem("USER"), secureLocalStorage.getItem("--fiat^^edit-doc##id")));

};
// update fiat  to the two doc
export const updateDocFiatCmd = async (colfiat, docfiat, newIdFiat) => {

 const washingtonRef = doc(db, colfiat, docfiat);
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  fiatcollection: newIdFiat,
  fiat: newIdFiat,
 });

}
// update fiat to the two doc network
export const updateDocFiatCmdnetwork = async (colfiat, docfiat, newIdFiat, namefiat) => {

 const washingtonRef = doc(db, colfiat, docfiat);
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  fiat: newIdFiat,
  fiatname: namefiat
 });

}
// updadte doc with setDoc
export const setDocFiatChange = async (idCol, idDoc, newIdFiat, namefiat) => {

 const cityRef = doc(db, idCol, idDoc);
 setDoc(cityRef, {
  fiat: newIdFiat,
  fiatname: namefiat

 }, { merge: true });

}

export default DrawerAppBar;

