
import './Simple.css';
import REturnArroW from './Arrow';
import ReturnTitle from './Title';
import REturnlogo from './Logo';
import ReturnInput from './Input';

// Vlaid Simple
export default function ReturnValidSimple() {
 return (
  <div className='wrp-simple-valid'>
   <REturnArroW />
   <ReturnTitle />
   <REturnlogo />
   <ReturnInput />
  </div>
 );
};