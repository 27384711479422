

import './Title.css';
import ReturnLisT from './List';

// Return Title 
export default function ReturnTitle() {
 return (
  <div className='title-bd-overview-tontine'>
   <h1>Liste de tous les participants Tontine</h1>
   <ReturnLisT />
  </div>
 );
}