import React from 'react';
import './Title.css';
import Media from 'react-media';
import {
 gsap, Power2
} from 'gsap';



// Middle Head 
export default function RetuRnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='head-title-middle-home'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='head-title-middle-home-sm'>
  <View />
 </div>
);
export const View = () => {

 const boxRef = React.useRef(null);
 React.useEffect(() => {
  gsap.from(boxRef.current, { delay: 0.5, duration: 1, y: 10, opacity: 0, ease: Power2.easeIn });
 })

 return (
  <h3 ref={boxRef}>Cashbeck</h3>
 );
};