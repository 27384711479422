import React from 'react';
import { useNavigate } from 'react-router';
import { HiArrowLeft } from 'react-icons/hi';
import './Back.css';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CommI from './Commi/Main';

// Back 
export default function ReturnBacK() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/dash');
 };

 return (
  <div className='flex-between'>

   <div onClick={handlepath}>
    <Tooltip title="Retour">
     <IconButton>
      <HiArrowLeft size={'1.3em'} color={'grey'} />
     </IconButton>
    </Tooltip>

    <div style={{ paddingLeft: '1em', margin: '2vh 0' }}>
     <CommI />
    </div>


   </div>

   <div></div>
  </div>
 );
};