import './Nav.css';
import ReturnTitle from '../Title';

// View Nav Component
export default function ReturnNaV() {
 return (
  <div className='prix-nav-dashed'>
   <ReturnTitle Text={'Mon solde'} />

  </div>
 );
};