import './Btn.css';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';


// Btn
export default function ReturnBtn() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("??next^^**$$", true);
  secureLocalStorage.setItem("#@@!!%date%tontine$$#", moment().format());
  navigation('/tontine/form/pin');
 };

 return (
  <div className='wrp-btn-over-list'>
   <button onClick={handlepath} className='Btn'>Valider</button>
  </div>
 );
};