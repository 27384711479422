import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { FcKey } from 'react-icons/fc';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';





let pushDoc = new Array();





// This is Name view 
export default function ReturnPinSetting() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenLarge />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 const navigation = useNavigate();
 const [load, setLoad] = React.useState(false);

 const otp = `${Math.floor(1000 + Math.random() * 90000)}`;
 let pushDocs = new Array();


 const currentDate = dayjs();
 const currentMonth = currentDate.add(0, 'month');

 const [open, setOpen] = React.useState(false);

 const handleClose = () => {
  setOpen(false);
 };


 const [list, setList] = React.useState(null);

 const [updatepincheck, setUpdatepincheck] = React.useState(false);
 const [updatepinmonth, setUpdatepinmonth] = React.useState(null);
 const [state, setState] = React.useState(null);



 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   pushDocs.push(doc.id);
  });

  setList([... new Set(pushDocs)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);
  });

  try {

   const unsub = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setUpdatepincheck(doc.data().updatepincheck);
    setUpdatepinmonth(doc.data().updatepinmonth === undefined ? null : doc.data().updatepinmonth);
    setState(doc.data().state);
   });

  } catch (e) {
   window.console.log(e)
  }

 }, []);


 const handlepath = async (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("#@44!!@NB**$$", state);
  setLoad(true);


  if (!!updatepincheck === false) {

   const updatecodeInputRef = doc(db, [...list].includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
   // Set the "capital" field of the city 'DC'
   await updateDoc(updatecodeInputRef, {
    codehold: otp
   });

   const textmsgCode = `${otp} est votre code actuels de verification.`;
   const number = secureLocalStorage.getItem("USER");
   let phonesend = `243${number.slice(-9)}`;


   let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsgCode}`,

    headers: {}
   };

   axios.request(config)
    .then((response) => {
     console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
     console.log(error);
    });

   window.setTimeout(() => {
    navigation('/pin/now');
   }, 2000);


  } else {

   if (updatepincheck && currentMonth.format('MMMM') !== updatepinmonth) {

    setLoad(false);
    setOpen(true);

   } else {


    const updatecodeInputRef = doc(db, [...list].includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
    // Set the "capital" field of the city 'DC'
    await updateDoc(updatecodeInputRef, {
     codehold: otp
    });

    const textmsgCode = `${otp} est votre code actuels de verification.`;
    const number = secureLocalStorage.getItem("USER");
    let phonesend = `243${number.slice(-9)}`;


    let config = {
     method: 'get',
     maxBodyLength: Infinity,
     url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsgCode}`,

     headers: {}
    };

    axios.request(config)
     .then((response) => {
      console.log(JSON.stringify(response.data));
     })
     .catch((error) => {
      console.log(error);
     });

    window.setTimeout(() => {
     navigation('/pin/now');
    }, 2000);


   }

  }
 }


 return (
  <>
   <React.Fragment>

    <Dialog
     open={open}
     onClose={handleClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
    >
     <DialogTitle id="alert-dialog-title">
      <h1 className="pop-up">
       {"Cashbeck"}
      </h1>
     </DialogTitle>
     <DialogContent>
      <DialogContentText id="alert-dialog-description">

       <p className="pop-up">
        La modification du code se passe une fois le mois  veuillez patienter les prochains mois s'il vous plaît merci
       </p>

      </DialogContentText>
     </DialogContent>
     <DialogActions>

      <Button onClick={handleClose} autoFocus><p className="pop-up">Fermer</p></Button>
     </DialogActions>
    </Dialog>
   </React.Fragment>

   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div onClick={handlepath} className='wrp-boxname-profil'>
    <FcKey size={'2em'} color={'red'} />
    <ReturnPeople />
   </div>
  </>
 );
};
export const ReturnPeople = () => {
 return (
  <div className='profil-name-identity'>
   <h1>Modifier Pin</h1>
   <h2>**********</h2>
  </div>
 );
};