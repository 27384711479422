import './Withdraw.css';
import BoXwIthdraw from './Box/Main';
import REturnArroW from './Arrow';


// REturn WithdrAw
export default function REturnWithdrAw() {
 return (
  <div className='wrp-withdraw'>
   <BoXwIthdraw />
   <REturnArroW />
  </div>
 );
};