import './Nav.css';
import ReturnAvataR from './IMA';

// Return NavigaTion from
export default function ReturnNavigaTion() {
 return (
  <div className='wrp-nav-buy'>

   <div></div>
   <ReturnAvataR />

  </div>
 );
}