import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import secureLocalStorage from "react-secure-storage";
import './IMA.css';


export default function RetuRnAvatars() {
 return (
  <Stack direction="column" spacing={2}>
   <div className='flex-grid '>
    <Avatar sx={{ width: 85, height: 85 }} variant="rounded" src={secureLocalStorage.getItem("#@8###operator$$%%")} />

    <div className='wrp-operator-creditaire'>
     <p className='padding-2em'>
      Ajouter la référence de la commande faite sur ce réseau
     </p>
    </div>
   </div>

  </Stack>
 );
};
