
import './Title.css';


// Title component view
export default function ReturnTitle() {
 return (
  <div className='child-title-user'>
   <h1>Rechercher, est trouver vos ami Tontine</h1>

  </div>
 )
}