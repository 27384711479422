import './Quote.css';
import currency from 'currency.js';
import secureLocalStorage from "react-secure-storage";


// Quote Component Info
export default function ReturnQuoTe() {

 let name = secureLocalStorage.getItem("**tont>>name??");
 let devise = secureLocalStorage.getItem("**tont>>currency??");
 let count = secureLocalStorage.getItem("**tont>>count??");

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 euro(count).format();

 return (
  <div className='qt-info-tontine'>

   <div className='title-qt-info-tontine'>
    <p>Groupe Tontine : </p>
    <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
   </div>

   {/* <div className='title-qt-info-tontine'>
    <p>Devise Utiliser : </p>
    <p>{(devise.toUpperCase()).includes('USD') ? 'USD' : 'CDF'}</p>
   </div> */}

   <div className='title-qt-info-tontine'>
    <p>Montant de la tontine : </p>
    <p>{euro(count).format()} {(devise.toUpperCase()).includes('USD') ? 'USD' : 'CDF'}</p>
   </div>
  </div>
 );
};