
import './Quote.css';


// Quote compoent live
export default function ReturnQuOTe() {
 return (
  <div className='wrp-quote-budget-tontine'>
   <p>Cotisation complète de la Tontine</p>
  </div>
 );
}