import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, updateDoc, arrayUnion, setDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";

import { CiWifiOff } from "react-icons/ci";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { v4 } from 'uuid';
import currency from 'currency.js';
import axios from 'axios';




let pushDoc = new Array();
let key = v4();




// View Form Update view
export default function ReturnFormUpdate() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);

export const FormInputField = () => {


 const navigation = useNavigate();

 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 const [timeId, setTimeId] = React.useState(moment());
 const [fname, setFname] = React.useState(null);
 const [lname, setLname] = React.useState(null);
 const [profilclient, setProfilclient] = React.useState(null);
 const [moduloclient, setModuloclient] = React.useState(null);
 const [problem, setProblem] = React.useState(false);
 const [getFran, setGetFran] = React.useState(null);


 const [getFirst, setGetFirst] = React.useState(null);
 const [getLast, setGetLast] = React.useState(null);


 const [open, setOpen] = React.useState(false);
 const [high, setHigh] = React.useState(false);

 const [name, setName] = React.useState(null);
 const [usd, setUsd] = React.useState(null);
 const [modulo, setModulo] = React.useState(null);
 const [time, setTime] = React.useState(null);
 const [pin, setPin] = React.useState(null);

 const [budget, setBudget] = React.useState(null);
 const [current, setCurrent] = React.useState(null);
 const [pret, setPret] = React.useState(null);
 const [profil, setProfil] = React.useState(null);

 const [firstname, setFirstname] = React.useState('');
 const [lastname, setLastname] = React.useState('');

 const [thrift, setThrift] = React.useState(null);
 const [delay, setDelay] = React.useState(null);


 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);



 const [wifi, setWifi] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const { handleSubmit, reset, control } = useForm();
 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');
 const [showPassword, setShowPassword] = React.useState(false);

 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleHigh = () => {
  setHigh(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);
  });

  if (pushDoc.includes(secureLocalStorage.getItem("A@@ph$$&-@#"))) {

   try {
    const unsub = onSnapshot(doc(db, "pret", secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {

     setCurrent(doc.data().usd);
     setName(doc.data().name);
     setTime(doc.data().date);
     setTimeId(doc.data().id);

     setModulo(doc.data().pretmodulo);
     setModuloclient(doc.data().pretmodulo);

    });

   } catch (e) {

    window.console.log(e)
   }


  }

 }, []);
 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setPin(doc.data().code);
    setUsd(doc.data().usd)
    setFirstname(doc.data().firstname);
    setLastname(doc.data().lastname);
    setThrift(doc.data().thriftusd);

    setDelay(doc.data().delay);
    setProfil(doc.data().profile);

    setSignature(doc.data().signatureusd);
    setSignaturetype(doc.data().signatureusdtype);
    setSignaturemoney(doc.data().signaturemoneyusd);
    setSignaturekey(doc.data().signaturekeyusd);


   });
  } catch (e) {
   window.console.log(e);
  }

 }, []);
 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "client", secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {
    setFname(doc.data().firstname);
    setLname(doc.data().lastname);
    setProfilclient(doc.data().profile);
    setGetFran(doc.data().usd);

    // setGetBefore(doc.data().usd);
    // setGetProfile(doc.data().profile);
    // setGetThrift(doc.data().thriftusd);

    setGetFirst(doc.data().firstname);
    setGetLast(doc.data().lastname);

   });

  } catch (e) {
   window.console.log(e);
  }

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "pretbudget"), (doc) => {
   setBudget(doc.data().usd);

  });

 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganofrais", "commissions"), (doc) => {
   setPret(doc.data().pret);

  });

 }, []);



 let soldepret = Number(secureLocalStorage.getItem("@solde!#!"));
 const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");

 var now = moment(); //todays date
 let year = moment(time).get('year');
 let months = moment(time).get('month');
 let days = moment(time).get('date');
 var end = moment([year, months, days]); // another date

 var duration = moment.duration(now.diff(end));
 var day = duration.asDays();

 let pretUsd = current;
 let moduleusd = 0;

 for (let index = 0; index <= parseInt(day); index++) {

  if (index === 0) {
   continue;
  } else {
   moduleusd = (pretUsd * modulo / 100);
   pretUsd += moduleusd;
  }

 };


 let itemthrift = (Number(soldepret) * Number(pret) / 100) + thrift;

 let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) + Number(signaturemoney))}${parseInt((Number(usd) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) - Number(signaturemoney))}${parseInt((Number(usd) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;



 let date2 = moment(moment().format('YYYY-MM-DD'));
 let date1 = moment(moment(timeId).format('YYYY-MM-DD'));
 const numberDays = date2.diff(date1, 'd');

 const onSubmit = async (data) => {

  setLoad(true);

  if (data.code === undefined || pin == '000000' || Number(current) <= 0) {
   setLoad(false);
   setOpen(true);
   reset();

  } else {

   if (pin != data.code) {
    setLoad(false);
    setOpen(true);
    reset();

   } else {

    if (timeId === null || timeId === undefined || fname === null || fname === undefined || lname === null || lname === undefined || profilclient === null || profilclient === undefined || moduloclient === null || moduloclient === undefined || modulo === null || modulo === undefined || time === null || time === undefined || name === null || name === undefined || usd === null || usd === undefined || budget === null || budget === undefined || pin === null || pin === undefined || pret === null || pret === undefined || firstname === '' || lastname === '' || thrift === null || thrift === undefined || delay === null || delay === undefined || getFran === null || getFran === undefined) {

     setWifi(true);
     setLoad(false);
     reset();

    } else {

     if (checkagent) {

      if (Number(secureLocalStorage.getItem("@solde!#!")) >= (Number(pretUsd.toFixed(2))) + 1) {

       setHigh(true);
       setLoad(false);

      } else if (((Number(pretUsd).toFixed(2)) - Number(secureLocalStorage.getItem("@solde!#!"))) <= 1) {

       setHigh(true);
       setLoad(false);
      } else {

       secureLocalStorage.setItem("&&837$$commi@*#())", true);
       secureLocalStorage.setItem("&&837$$prnt@*#())", false);
       secureLocalStorage.setItem("@dateª©#&&++#", moment().format('LLLL'));

       asKedpret((Number(pretUsd.toFixed(2)) - Number(soldepret)));
       asKedDecrimentpret(soldepret, itemthrift, soldepret, getPhone, name.toLowerCase(), soldepret, usd, signature, (Number(soldepret) * Number(pret) / 100), key, firstname, lastname, usd, getFran);

       refundpretclient(fname + ' ' + lname, profilclient, moduloclient, Number(pretUsd), Number(soldepret), (Number(pretUsd.toFixed(2)) - Number(soldepret)), Number(budget) + Number(secureLocalStorage.getItem("@solde!#!")), Number(budget));
       muunganoallrefundpret(fname + ' ' + lname, profilclient, moduloclient, Number(pretUsd), Number(soldepret), (Number(pretUsd.toFixed(2)) - Number(soldepret)), Number(budget) + Number(secureLocalStorage.getItem("@solde!#!")));

       let pretInfo = 'pret' + secureLocalStorage.getItem("A@@ph$$&-@#");
       collectionPret(pretInfo, secureLocalStorage.getItem("$$%%@##moment#!++&&!"), (Number(pretUsd.toFixed(2)) - Number(soldepret)), Number(pretUsd), Number(soldepret));
       incrementBudgetMain(Number(budget) + Number(secureLocalStorage.getItem("@solde!#!")));
       swapSendUser(secureLocalStorage.getItem("USER"), 'remboursement prêt', soldepret, usd, usd - soldepret, 'envoyer', moment().format(), secureLocalStorage.getItem("A@@ph$$&-@#"), `${getFirst + ' ' + getLast}`, profil, thrift, (soldepret * pret / 100) + Number(thrift), soldepret * pret / 100, 0, key, 'USD', getFran, getFran, Number(pretUsd), Number(pretUsd) - Number(soldepret));

       secureLocalStorage.setItem("%%@#7**@@++view!&&!", false);


       let number = secureLocalStorage.getItem("A@@ph$$&-@#");
       let phonesend = `243${number.slice(-9)}`;
       let depotAPI = `Remboursement de ${(soldepret)} USD est effectue par le ${secureLocalStorage.getItem("USER")} ${firstname.toString().toUpperCase()} ${lastname.toString().toUpperCase()}. Pret en cours: ${parseInt(Number(pretUsd) - Number(soldepret))} USD`;


       let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
        headers: {}
       };

       axios.request(config)
        .then((response) => {
         console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
         console.log(error);
        });



       window.setTimeout(() => {
        // Remove
        secureLocalStorage.removeItem("%%@#7**@@++view!&&!");
        secureLocalStorage.removeItem("&&837$$commi@*#())");
        secureLocalStorage.removeItem("$$%%@##moment#!++&&!");

        navigation('/well/refunded/dollar');
       }, 7500);




      }

     } else {

      setProblem(true);
      setLoad(false);

     }


    }

   };

  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>

    <FormControl
     sx={{ width: '100%' }}

     variant="standard">
     <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

     <Controller
      name="code"
      control={control}
      render={({ field }) =>

       <Input
        id="standard-adornment-password"
        {...field}
        type={showPassword ? 'numeric' : 'password'}
        inputProps={{
         autoComplete: "off", inputMode: 'numeric'
        }}

        endAdornment={
         <InputAdornment position="end">

          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>

         </InputAdornment>
        }

       />}
     />

    </FormControl>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        PIN, Incorrect
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={high}
     onClose={handleHigh}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>

        La finalisation du prêt ne peut être effectuée par un mandataire.
        Contactez MuunganoMoney pour plus d'informations. Merci

     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleHigh}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <button className='Btn-Broker'>Envoi</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible
        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

       votre compte a été suspendu suite à une situation de litige contacter cashback pour plus d'informations

								</p>
     </DialogContentText>



    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>


  </>
 );
};


// Add pret for client
export async function asKedpret(prix) {

 const washingtonRef = doc(db, "pret", secureLocalStorage.getItem("A@@ph$$&-@#"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  usd: (prix),
  pretusd: (prix),
  askpret: (prix),
  date: moment().format()
 });

};
export async function asKedDecrimentpret(prix, fraisAgent, money, getPhone, getUser, main, usd, signature, commision, key, firstname, lastname, before, getFran) {


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 let send = { date: moment().format('LLL'), solde: `${euro(money).format()} USD [DÉPÔT PRÊT]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(usd - prix).format() + ' USD', unite: 'USD' }
 let get = { date: moment().format('LLL'), solde: `${euro(money).format()} USD [DÉPÔT PRÊT]`, phone: secureLocalStorage.getItem("USER"), user: ((firstname + ' ' + lastname).toString()).toLowerCase(), type: 'reçu', price: euro(main).format(), actual: euro(getFran).format() + ' USD', unite: 'USD' }


 const washingtonRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {

  usd: Number(usd) - Number(prix),
  thriftusd: (fraisAgent),
  money: money,
  signatureusd: signature,


  signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money))}${key}`,
  signatureusdtype: 'envoyer',
  signaturemoneyusd: Number(money),
  signaturekeyusd: key,
  signaturefraisusd: 0,

  sendtype: 'envoyer',
  frais: 0,
  user: getUser,
  delay: moment().format(),
  swap: arrayUnion(send),
  commission: commision,
  unite: 'USD'

 });

 const wasRef = doc(db, "client", getPhone);
 // Set the "capital" field of the city 'DC'
 await updateDoc(wasRef, {
  money: money,
  sendtype: 'reçu',
  frais: 0,
  user: ((firstname + ' ' + lastname).toString()).toLowerCase(),
  delay: moment().format(),
  swap: arrayUnion(get),
  commission: commision,
  unite: 'USD'

 });


 delete window.asKedDecrimentpret;

};
export async function collectionPret(userCollection, userDocs, current, pret, reimburse) {

 let obj = { pret: pret, reimburse: reimburse, current: current, devise: 'USD' };
 await setDoc(doc(db, userCollection, userDocs), { date: moment().format(), data: arrayUnion(obj), devise: true }, { merge: true });

};
export async function refundpretclient(name, profil, pretmodulo, pret, reimburse, current, newbudget, lastbudget) {

 // Add a new document in collection "cities"
 await setDoc(doc(db, "pretrefund" + secureLocalStorage.getItem("A@@ph$$&-@#"), secureLocalStorage.getItem("$$%%@##moment#!++&&!")), {
  name: name,
  phone: secureLocalStorage.getItem("A@@ph$$&-@#"),
  phoneagent: secureLocalStorage.getItem("USER"),
  profil: profil,
  date: moment().format(),
  pretmodulo: pretmodulo,
  pret: pret,
  reimburse: reimburse,
  current: current,
  devise: 'USD',
  newbudget: newbudget,
  lastbudget: lastbudget,

 });

};
export async function muunganoallrefundpret(name, profil, pretmodulo, pret, reimburse, current, newbudget) {
 // Add a new document in collection "cities"
 await setDoc(doc(db, "muunganopretrefundall", secureLocalStorage.getItem("$$%%@##moment#!++&&!")), {

  name: name,
  phone: secureLocalStorage.getItem("A@@ph$$&-@#"),
  phoneagent: secureLocalStorage.getItem("USER"),
  profil: profil,
  date: moment().format(),
  pretmodulo: pretmodulo,
  pret: pret,
  reimburse: reimburse,
  current: current,
  devise: 'USD',
  newbudget: newbudget

 });

};
export async function incrementBudgetMain(newSolde) {

 const washingtonRef = doc(db, "muunganomoney", "pretbudget");
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  // usd: increment(Number(secureLocalStorage.getItem("@solde!#!")))
  usd: newSolde
 });


};
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter, pretbefore, pretafter) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  key: key,
  devise: devise,

  soldebefore: soldebefore,
  soldeafter: soldeafter,

  color: '#ffebc5',
  dateofcreate: serverTimestamp(),

  pretbefore: pretbefore,
  pretafter: pretafter,


  statetrans: true,
  statepret: true,
  stateunit: false,
  statetontine: false,
  statetv: false,
  statesolaire: false,

 });

};






