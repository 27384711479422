
import './Body.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';
import MusT from './Must/Main';


// Body Credit
export default function ReturnCReDiTbOdy() {
 return (
  <div className='flex-body-credit-center'>

   <HeAd />
   <BOdy />
   <MusT />

  </div>
 );
};