
import FoRm from './Form/Main';
import OperatoR from './Operator/Main';
import './Body.css';


// Body Creditaire Home
export default function RetuRnBOdy() {
 return (
  <div className='flex-grid'>
   <OperatoR />
   <FoRm />
  </div>
 );
};