
import './Operator.css';
import RetuRnAvatars from './IMA';

// Operator View
export default function RetuRnOperatoR() {
 return (
  <div className='wrp-operator-credit'>
   <RetuRnAvatars />
  </div>
 );
};
