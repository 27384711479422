import * as React from 'react';
import './Head.css';
// import ReturnProfil from './Profil';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import { doc, onSnapshot, where, collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import secureLocalStorage from "react-secure-storage";



let Acces = true;
let listRising = new Array();
let listIDgroup = new Array();
let pushCount = new Array();



function DrawerAppBar() {


	const [load, setLoad] = React.useState(false);
	const [money, setMoney] = React.useState(['']);
	const [devise, setDevise] = React.useState(['']);

	const pushDocs = secureLocalStorage.getItem("&&view$$list£¢ton…")
	const pushOther = secureLocalStorage.getItem("&&view$$list£¢toncol§§-…");


	React.useEffect(async () => {

		[...pushOther].map((item) => {

			const unsub = onSnapshot(doc(db, "tontine", item), (doc) => {

				listIDgroup.push(doc.data().idgroup);

				window.setTimeout(() => {
					listRising.push(doc.data().rising === undefined ? 0 : doc.data().rising);
				}, 500);

			});

		})
	}, []);
	React.useEffect(async () => {
		const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {

			setMoney(doc.data().grouptontinemoney === undefined ? [''] : doc.data().grouptontinemoney);
			setDevise(doc.data().grouptontinedevise === undefined ? [''] : doc.data().grouptontinedevise);

		});

	}, []);



	const navigation = useNavigate();
	const handlepath = (event) => {
		event.preventDefault();
		navigation(-1);

	};



	if (Array.isArray(pushDocs) && pushDocs.length) {
		Acces = true;
	} else {
		Acces = false;
	};



	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<div className='flex-head-list-tontine'>
				<header>
					<div className='container'>
						<nav className='navbar'>

							<div style={{ paddingTop: '1vh' }}>
								<HiArrowLeft onClick={handlepath} size={'1.6em'} color={'white'} className={'array-static-navbar'} />
							</div>


							{/* <ReturnProfil /> */}

						</nav>
					</div>
				</header>

				<section>
					{
						Acces ?
							<ul>
								{
									[...Array(pushDocs.length).keys()].map(index => {

										let argent = '';
										let price = '';

										if ((devise[index]) === undefined || (money[index]) === undefined) {
											window.console.log('nothing !!!');
										} else {

											argent = (devise[index]);
											price = (money[index]);

											price = price.replace(/\D/g, '');

										}

										return (
											<div onClick={async () => {

												setLoad(true);
												secureLocalStorage.setItem("@!!#$$&&^==$$$", true)
												secureLocalStorage.setItem("¥¥˙´¸list˘˘22˚˚fil", pushOther[index]);


												const unsub = onSnapshot(doc(db, "tontine", pushOther[index]), (doc) => {

													secureLocalStorage.setItem("¥¥˙´¸list˘˘˚˚", doc.data().table);
													// secureLocalStorage.setItem('¥¥˙´¸##$@@##', doc.data().tontinetype);
													localStorage.setItem('¥¥˙´¸##$@@##', doc.data().tontinetype)

												});

												const collectionRef = collection(db, pushOther[index]);
												const querySnapshot = await getDocs(collectionRef);

												const count = querySnapshot.size;

												[...secureLocalStorage.getItem("¥¥˙´¸list˘˘˚˚")].map((item) => {

													const unsubidgroup = onSnapshot(doc(db, pushOther[index], item), (doc) => {
														pushCount.push(Number(doc.data().askedactive))

													});

												});


												window.console.log(localStorage.getItem('¥¥˙´¸##$@@##'))


												window.setTimeout(() => {

													if (localStorage.getItem('¥¥˙´¸##$@@##') == "true") {

														secureLocalStorage.setItem("@^#^@*name*#*##group", pushDocs[index])
														window.location.href = "/tontine/get/widthdraw";


													} else {

														let somme = [...pushCount].reduce((acc, valeur) => acc + valeur, 0)

														secureLocalStorage.setItem("@^#^@*name*#*##group", pushDocs[index])

														localStorage.setItem('@^#^$$*#*##!!', count === somme)
														window.location.href = "/tontine/get/widthdraw/open";

													}

												}, 4800);

											}}>

												<List>
													<ListItem disablePadding>
														<ListItemButton>

															<li key={index}>
																<div className='cmd-operator-title'>

																	<div className='cmd-operator-sub-title'>
																		<div className='flex-row-cmd-group'>

																			<p>
																				<span>{pushDocs[index].charAt(0).toUpperCase() + pushDocs[index].slice(1)}</span>

																			</p>
																		</div>
																	</div>

																</div>

																<p>{price.includes(price) ? price : 0} {argent.includes('USD') ? 'USD' : 'CDF'}</p>
															</li>

														</ListItemButton>
													</ListItem>
												</List>
												<Divider />
											</div>
										)
									})}
							</ul>
							: <div></div>
					}
				</section>
			</div>
		</>
	);
};



export default DrawerAppBar;