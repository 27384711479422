import ReturnQuOte from './Quote';
import './WellFiat.css';
import NavBar from './Nav/Main';

// Veiew
export default function ReturnWellFiAt() {
 return (
  <>
   <NavBar />

   <div className='wrp-well-fiat'>
    <ReturnQuOte />
   </div>
  </>
 );
};