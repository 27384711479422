import './Btn.css';
import { useNavigate } from 'react-router-dom';
import Media from 'react-media';



// Btn component 
export default function ReturnBTn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenSmall = () => {
 return (
  <div className='hm-btn-sm'>
   <ViEW />
  </div>
 )
};
export const ScreenLarge = () => {
 return (
  <div className='hm-btn-sm'>
   <ViEW />
  </div>
 )
};

export const ViEW = () => {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/sign');
 };

 return (
  <div onClick={handlepath}>
   <p>Connexion</p>
  </div>
 );
};