import './Footer.css';
import RetuRnQuOTE from './Quote';

// Footer Main
export default function RetuRnFooTER() {
 return (
  <div className='flex-footer-credit'>
   <RetuRnQuOTE />
  </div>
 );
};