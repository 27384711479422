import './Quote.css';

// Return Quote
export default function ReturnQuOTe() {
 return (
  <div className='quote-send-pret'>
   <p>
    Votre demande est en cours de traitement Veuillez patienter...
   </p>
  </div>
 );
};