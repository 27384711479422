
import './Body.css';
import RetuRnNAme from './Name';
import RetuRnBAlAnce from './Balance';

// Body Credit
export default function RetuRnBody() {
 return (
  <div className='flex-body-center-credit'>
   <div className='flex-bord-crediter'>
    <RetuRnNAme />
    <RetuRnBAlAnce />

   </div>
  </div>
 );
};