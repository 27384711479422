import React from 'react';
import './Avatar.css';
import Media from 'react-media';
import { collection, getDocs, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, storage } from '../firebase';
import { ref, uploadBytes, deleteObject, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { VscCheck } from 'react-icons/vsc';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";


let pushDocs = new Array();


function CircularProgressWithLabel(props) {
 return (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
   <CircularProgress variant="determinate" {...props} />
   <Box
    sx={{
     top: 0,
     left: 0,
     bottom: 0,
     right: 0,
     position: 'absolute',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
    }}
   >
    <Typography variant="caption" component="div" color="text.secondary">
     {`${Math.round(props.value)}%`}
    </Typography>
   </Box>
  </Box>
 );
}

CircularProgressWithLabel.propTypes = {
 /**
  * The value of the progress indicator for the determinate variant.
  * Value between 0 and 100.
  * @default 0
  */
 value: PropTypes.number.isRequired,
};


// Avatar IMAGE VIew
export default function ReturnAvataR() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-avatar-profil'>
  <div className='avatar-circle-profil'>
   <LetteRName />
  </div>
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-avatar-profil-sm'>
  <div className='avatar-circle-profil-sm'>
   <LetteRName />
  </div>
 </div>
);


export const LetteRName = () => {

 const [imageUpload, setImageUpload] = React.useState(null);
 const [url, setUrl] = React.useState(null);
 const [profil, setProfil] = React.useState('/img/logo.png');
 const [progress, setProgress] = React.useState(0);
 const [viewBtn, setViewBtn] = React.useState(false);
 const [exten, setExten] = React.useState(null);

 const [load, setLoad] = React.useState(false);

 const navigation = useNavigate();

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDocs.push(doc.id);
  });

  try {

   const unsub = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setProfil(doc.data().profile);
    setExten(doc.data().extension);
   });

  } catch (e) {
   window.console.log(e)
  }

 }, []);




 const uploadImage = async () => {

  setLoad(true);

  if (imageUpload == null) {
   return;
  }

  const imgRef = imageUpload.name + v4();
  const imageRef = ref(storage, `image/${imgRef}`);

  uploadBytes(imageRef, imageUpload).then(() => {

   getDownloadURL(imageRef, imageUpload).then((url) => {

    const uploadTask = uploadBytesResumable(imageRef, imageUpload)
    uploadTask.on('state_changed', (snapshot) => {
     const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
     setProgress(prog);
    },
     (err) => window.console.log(err), () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => console.log(url))
     })

    setUrl(url);
    // const collect = pushDocs.includes(secureLocalStorage.getItem("USER"))
    const washingtonRef = doc(db, pushDocs.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
    // Set the "capital" field of the city 'DC'
    updateDoc(washingtonRef, {
     profile: url,
     extension: imgRef
    });

   }).catch(error => { window.console.log(error.message) })
   setImageUpload(null);

  }).catch(error => { window.console.log(error.message) });


  setViewBtn(false);

  if (exten !== 'extension') {

   window.setTimeout(() => {

    // Create a reference to the file to delete
    const desertRef = ref(storage, `image/${exten}`);
    // Delete the file
    deleteObject(desertRef).then(() => {
     // File deleted successfully
     window.console.log('Success remove image');

    }).catch((error) => {
     window.console.log(error);
     // Uh-oh, an error occurred!
    });

   }, 999)


  };

 };

 if (progress > 97) {
  navigation(0);
 } else {
  window.console.log('not cool');
 }

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='wrp-profil-avatar-user'>

    <IconButton color="primary" aria-label="upload picture" component="label">
     <div className='profile-user'>

      <img src={profil} />

      <input
       hidden
       accept="image/*"
       type="file"
       onChange={(event) => {
        setImageUpload(event.target.files[0]);
        setViewBtn(true);
       }}

      />
      <div className='icon-camera-profil'>
       <PhotoCamera />
      </div>
     </div>
    </IconButton>

    <CircularProgressWithLabel value={progress} />
    {viewBtn &&
     <button onClick={uploadImage}>
      <span>Cliqué pour changer image </span><VscCheck size={'1.2em'} />
     </button>
    }


   </div>

  </>
 );
};

