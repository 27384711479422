
// Return Title  
import './Title.css';

// Return Title view 
export default function ReturnTitle() {
 return (
  <div className='wrp-title-form-success'>
   <h1>Enregistrer l'utilisateur</h1>
  </div>
 );
};