import RetuRnlogO from './Logo';
import ReturnBTn from '../../Footer/Btn';
import './Nav.css';
import Media from 'react-media';



// Nav Main
export default function RetuRnNAv() {
 return (
  <div className='flex-between flx-pad-2'>
   <RetuRnlogO />
   <ReturnBTn />
  </div>
 );
};


