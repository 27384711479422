import React from 'react';
import './Commi.css';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../../firebase';
import secureLocalStorage from "react-secure-storage";

import Avatar from '@mui/material/Avatar';
import currency from 'currency.js';


// ReturnCommi Return 
export default function ReturnCommi() {

 const [commiCdf, setCommiCdf] = React.useState(0);
 const [commiUsd, setCommiUsd] = React.useState(0);

 const [uniteStock, setUniteStock] = React.useState(0);
 const [uniteStockcdf, setUniteStockcdf] = React.useState(0);


 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setCommiCdf(docSnap.data().commistockcdf)
  } else {
   // docSnap.data() will be undefined in this case
   setCommiCdf(0)
  }

 }, [])
 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setCommiUsd(docSnap.data().commistockusd)
  } else {
   // docSnap.data() will be undefined in this case
   setCommiUsd(0)
  }

 }, [])
 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setUniteStock(docSnap.data().commistockunite)
   setUniteStockcdf(docSnap.data().commistockunitecdf)
  } else {
   // docSnap.data() will be undefined in this case
   setUniteStock(0)
  }

 }, [])

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });


 return (
  <div className='wrp-commi-box-unite-stock'>
   <div className='flex-commi-stock'>
    <Avatar src="/img/dollars.png" sx={{ width: 35, height: 35 }} />
    <p style={{ padding: '0 1em' }}>{euro(commiUsd).format()} / {parseInt(uniteStock)}U</p>
   </div>

   <div className='flex-commi-stock'>
    <Avatar src="/img/franc.png" sx={{ width: 35, height: 35 }} />
    <p style={{ padding: '0 1em' }}>{euro(uniteStockcdf).format()} / {parseInt(commiCdf)}U</p>
   </div>

  </div>
 );
};