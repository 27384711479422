
import './Nav.css';
import ReturnBacK from './Back';
import DrAweR from './Drawer/Main';


// view navbar component 
export default function ReturnNavBar() {
 return (
  <div className='wrp-sharing-navbar'>
   <ReturnBacK />
   <DrAweR />
  </div>
 );
};