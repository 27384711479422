import ReturnLine from './Line';

// Title Return Compoennt
export default function ReturnQuote() {
 return (
  <>
   <ReturnLine />
   <div className='wrp-quote-tiket-msg'>
    <p>
     Chère Client, veuillez vérifier votre compte Sur
     notre site MuunganoMoney.com Car la
     réclamation d’une transaction est permise
     Endéans 30 jours. En date d’une opération.
        </p>
   </div>
  </>
 );
};