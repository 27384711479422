import REturnQuOte from './Quote';
import ReturnValues from './Values';
import './Box.css';

// Transaction view available
export default function ReturnBoxExchAnge() {
 return (
  <div className='wrp-exchange-box'>
   <REturnQuOte />
   {/* <ReturnValues /> */}
  </div>
 );
};