
import ReturnIcOn from './Icon';

// Return Component ReturnSuccessPin
export default function ReturnSuccessPin() {
 return (
  <div className='wrp-success-pin'>
   <ReturnIcOn />
  </div>
 );
}