import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { doc, getDocs, collection, updateDoc, increment, onSnapshot, arrayUnion, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';
import REturnQuOte from './Quote';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { CiWifiOff } from "react-icons/ci";


import moment from 'moment';


import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { reactLocalStorage } from 'reactjs-localstorage';
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import currency from 'currency.js';

import axios from 'axios';




export let nowField = moment().date();
export let now = moment().date();

export let prixHash = 0;
export let hashTrue = true;


let key = v4();
let timespinner = 6570;




// Input Field Component 
export default function ReturnInput() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};


export const SreenLarge = () => (
 <div className='wrp-form-input-pin'>
  <REturnQuOte />
  <FormInput />

 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-pin'>
  <REturnQuOte />
  <FormInput />
 </div>
);


export const FormInput = () => {


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");



 let pushDocs = new Array();
 let verifierSend;
 let verifierGet;


 const [wifi, setWifi] = React.useState(false);
 const [problem, setProblem] = React.useState(false);
 const [blem, setBlem] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const navigation = useNavigate();
 const { handleSubmit, reset, control } = useForm({});

 // send React useState  
 const [pin, setPin] = React.useState(null);
 const [state, setState] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');
 const [soldeMain, setSoldeMain] = React.useState(null);
 const [profil, setProfil] = React.useState(null);
 const [thrift, setThrift] = React.useState(null);
 const [frais, setFrais] = React.useState(null);

 // getting React useState
 const [getstate, setGetstate] = React.useState(null);
 const [getfirst, setGetFirst] = React.useState('');
 const [getlast, setGetLast] = React.useState('');
 const [price, setPrice] = React.useState(null);
 const [getProfil, setGetProfil] = React.useState(null);
 const [getState, setGetState] = React.useState(null);
 const [getthrift, setGetThrift] = React.useState(null);
 const [getfrais, setGetfrais] = React.useState(null);
 const [getprice, setGetprice] = React.useState(null);


 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);


 const [getsignature, setGetsignature] = React.useState(null);
 const [getsignaturetype, setGetsignaturetype] = React.useState(null);
 const [getsignaturemoney, setGetsignaturemoney] = React.useState(null);
 const [getsignaturekey, setGetsignaturekey] = React.useState(null);



 const [open, setOpen] = React.useState(false);
 const [cancel, setCancel] = React.useState(false);
 const [click, setClick] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [showPassword, setShowPassword] = React.useState(false);
 const [getTeam, setGetTeam] = React.useState('');
 const [sendTeam, setSendTeam] = React.useState('');


 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const cancelClose = () => {
  setCancel(false);
  setClick(true);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };



 const handleblemClose = () => {
  setBlem(false);
  navigation('/dash');
 };


 let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


 // From get AGENT
 let othersendagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
 let othergetagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
 let checkotheragent = getsignaturetype === 'envoyer' ? othersendagent === getsignature : othergetagent === getsignature;


 // ===========================================

 // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney) + Number(frais))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;

 // From get CLIENT
 let sendotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney) + Number(getfrais))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
 let getotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
 let checkotherclient = getsignaturetype === 'envoyer' ? sendotherclient === getsignature : getotherclient === getsignature;



 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   pushDocs.push(doc.id);
  });

  verifierSend = pushDocs.some(value => value == secureLocalStorage.getItem("USER"));
  verifierGet = pushDocs.some(value => value == secureLocalStorage.getItem("A@@ph$$&-@#"));

  const unsub = onSnapshot(doc(db, verifierSend ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {

   setPin(doc.data().code);
   setState(doc.data().state);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);
   setSoldeMain(doc.data().usd);
   setSendTeam(doc.data().team);
   setProfil(doc.data().profile);
   setThrift(doc.data().thriftusd);
   setFrais(doc.data().frais);

   setSignature(doc.data().signatureusd);
   setSignaturetype(doc.data().signatureusdtype);
   setSignaturemoney(doc.data().signaturemoneyusd);
   setSignaturekey(doc.data().signaturekeyusd);

  });
  const unsubget = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("A@@ph$$&-@#")) ? "client" : "agent", secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {

   setGetstate(doc.data().state);
   setGetFirst(doc.data().firstname);
   setGetLast(doc.data().lastname);
   setPrice(doc.data().thriftusd);
   setGetTeam(doc.data().team);
   setGetProfil(doc.data().profile);
   setGetState(doc.data().state);
   setGetThrift(doc.data().thriftusd);
   setGetfrais(doc.data().frais);
   setGetprice(doc.data().usd);

   setGetsignature(doc.data().signatureusd);
   setGetsignaturetype(doc.data().signatureusdtype);
   setGetsignaturemoney(doc.data().signaturemoneyusd);
   setGetsignaturekey(doc.data().signaturekeyusd);

  });

 }, []);




 const onSubmit = async (data) => {


  setLoad(true);
  secureLocalStorage.setItem("@dateª©#&&++#", moment().format('LLLL'));
  reactLocalStorage.set('##^^@@%^***^^++=$', true);
  secureLocalStorage.setItem("@!vew*%%%!!!@@@", parseInt(Number(price)))


  if (getprice === null || state === null || state === undefined || first === '' || first === undefined || last === '' || last === undefined || soldeMain === null || soldeMain === undefined || getstate === null || getstate === undefined || getfirst === '' || getfirst === undefined || getlast === '' || getlast === undefined || price === null || price === undefined || profil === null || profil === undefined || getProfil === null || getProfil === undefined || getState === null || getState === undefined || thrift === null || thrift === undefined || getthrift === null || getthrift === undefined || frais === null || frais === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || getsignature === null || getsignature == undefined || getsignaturetype == null || getsignaturetype == undefined || getsignaturemoney == null || getsignaturemoney == undefined) {

   setWifi(true);
   setLoad(false);

  } else {

   // Checked if value code is length valid
   if (pin != data.code || pin === '000000' || soldeMain <= 1) {

    setOpen(true);
    setLoad(false);
    reset();


   } else {

    secureLocalStorage.setItem("&&837$$prnt@*#())", true);
    const sendUser = first.toLowerCase() + ' ' + last.toLowerCase();
    const getUser = getfirst.toLowerCase() + ' ' + getlast.toLowerCase();

    if (false) {
     window.console.log('well');

    } else if (false) {

     window.console.log('well')

    } else {

     if (state == "agent") {

      if (checkagent) {

       if (true) {

        window.localStorage.setItem('@ª©##', JSON.stringify(false));

        hashTrue = false;
        prixHash = secureLocalStorage.getItem("@solde!#!");


        const sendPhone = secureLocalStorage.getItem("USER");
        const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
        const money = secureLocalStorage.getItem("@solde!#!");
        const unite = secureLocalStorage.getItem("@unite!#!");
        const main = secureLocalStorage.getItem("@main!#!");

        let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$versclients%%") / 100;

        let soldMainMoney = Number(soldeMain) - Number(money);
        let priceMoney = Number(price) + Number(money);


        swapSendUser(sendPhone, `Transfért d'argent épargner`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, Number(comm), 0, key, 'USD', price, priceMoney);
        swapGetUser(getPhone, `Transfért d'argent épargner`, money, getprice, getprice, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift + money, 0, 0, key, 'USD', soldeMain, soldMainMoney);
        swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key);


        let number = secureLocalStorage.getItem("A@@ph$$&-@#");
        let phonesend = `243${number.slice(-9)}`;
        let depotAPI = `Depot epargne de ${(money)} ${unite} est effectue par le ${sendPhone} ${first.toString().toUpperCase()} ${last.toString().toUpperCase()}. Nouveau solde: ${parseInt(priceMoney)} ${unite}.`;


        let config = {
         method: 'get',
         maxBodyLength: Infinity,
         url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
         headers: {}
        };


        axios.request(config)
         .then((response) => {
          console.log(JSON.stringify(response.data));
         })
         .catch((error) => {
          console.log(error);
         });


        window.setTimeout(() => {
         navigation('/send-success-usd');
        }, timespinner)


       }


      } else {

       if (checkagent == false) {

        setProblem(true);
        setLoad(false);

       } else {

        setBlem(true);
        setLoad(false);

       }

      }


     } else {
      // checkotheragent && checkclient
      if (false) {

       window.console.log('well');

      } else {

       setProblem(true);
       setLoad(false);

      }

     }

    }

   }

  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>

    <FormControl
     sx={{ width: '100%' }}

     variant="standard">
     <InputLabel htmlFor="standard-adornment-password"><div className='pop-up'>Pin actuel</div></InputLabel>

     <Controller
      name="code"
      control={control}
      render={({ field }) =>

       <Input
        id="standard-adornment-password"
        inputProps={{
         autoComplete: "off", inputMode: 'numeric'
        }}

        {...field}
        type={showPassword ? 'numeric' : 'password'}


        endAdornment={
         <InputAdornment position="end">

          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>

         </InputAdornment>
        }

       />}
     />

    </FormControl>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        PIN, Incorrect
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <button className='Btn'>Envoi</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

       Votre Compte USD a été suspendu suite à une situation de litige
       contacter cashbeck pour plus d'informations

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={blem}
    onClose={handleblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

       le compte USD de votre bénéficiaire a été suspendu

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>



   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

  </>
 )
};


async function swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, main, price, prix, unite, solde, comm, before, key) {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 let time = moment().format('LLL');
 let send = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
 let get = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + prix).format(), actual: euro(solde + prix).format() + ' ' + unite, unite: unite }





 const sendRef = doc(db, "agent", sendPhone);
 await updateDoc(sendRef, {
  usd: Number(main),

  money: prix,
  user: getUser,
  unite: unite,
  phoneclient: getPhone,
  delay: moment().format(),

  signatureusd: `${sendPhone}${parseInt(Number(prix))}${parseInt(Number(before))}${parseInt(Number(before) - Number(prix))}${key}`,
  signatureusdtype: 'envoyer',
  signaturemoneyusd: Number(prix),
  signaturekeyusd: key,
  signaturefraisusd: 0,

  thriftusd: increment(Number(comm)),
  commission: Number(comm),

  swap: arrayUnion(send),
  frais: 0,
  sendtype: 'envoyer'

 });

 const getRef = doc(db, "client", getPhone);
 await updateDoc(getRef, {

  thriftusd: Number(price) + Number(prix),
  swap: arrayUnion(get),
  phoneclient: sendPhone,
  user: sendUser,
  unite: unite,
  delay: moment().format(),
  money: prix,
  sendtype: 'Reçu'

 });


 delete window.swapInWithDocsAgentForSuperToClient;


};

// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

 await setDoc(doc(db, "swap" + colSwap, key + date), {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  key: key + date,
  devise: devise,
  access: false,

  soldebefore: soldebefore,
  soldeafter: soldeafter,

  color: '#EFF7FE',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: false,
  statetv: false,
  statesolaire: false,


 });

};
async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

 await setDoc(doc(db, "swap" + colSwap, key + date), {


  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  key: key + date,
  devise: devise,

  soldebefore: soldebefore,
  soldeafter: soldeafter,

  color: '#EFF7FE',
  dateofcreate: serverTimestamp(),

  statetransed: true,
  statepret: false,
  stateunit: false,
  statetontine: false,
  statetv: false,
  statesolaire: false,

 });

};


