import React from 'react';
import Avatar from '@mui/material/Avatar';
import { TweenMax, Expo } from 'gsap';

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import './Vodacom.css';



const Transition = React.forwardRef(function Transition(props, ref) {
 return <Slide direction="up" ref={ref} {...props} />;
});


// Network Operator Vodacom
export default function RetuRnORAnge() {

 const navigation = useNavigate();
 const [item, setItem] = React.useState(null);
 const [load, setLoad] = React.useState(false);
 const [open, setOpen] = React.useState(false);


 React.useEffect(() => {
  TweenMax.from('.Anima-orange', 1, { delay: 0.9, opacity: 0, x: 20, ease: Expo.easeIn });
 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "creditairephone"), (doc) => {
   setItem(doc.data().orange);
  });

 }, []);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleCreditaire = async () => {

  secureLocalStorage.setItem("#@8###operator$$%%", '/img/orange-money.png');
  secureLocalStorage.setItem("#@8@@@**!!operator$$%%", 'orange');
  setLoad(true);
  window.setTimeout(() => {
   navigation('/dash/creditaire');
  }, 500);

 };


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <React.Fragment>
    <div onClick={handleClickOpen} style={{ cursor: 'pointer' }} className='Anima-orange'>
     <Avatar
      src="/img/orange-money.png"
      sx={{ width: 56, height: 56 }}
      variant="rounded"
     />
    </div>

    <Dialog
     open={open}
     TransitionComponent={Transition}
     keepMounted
     onClose={handleClose}
     aria-describedby="alert-dialog-slide-description"
    >
     <DialogTitle>

      <div>
       <Avatar
        src="/img/orange-money.png"
        sx={{ width: 50, height: 50 }}
        variant="rounded"
       />

       <div className='flex-center flex-title-network flex-martop-3vh'>
        <h3 className='pop-up'>Effectuez vos retraits sur ce numéro</h3>
       </div>

      </div>


     </DialogTitle>
     <DialogContent>

      <div>
       <div className='flex-center flex-box-network'>
        <p>Agent</p>
        <p>
         {item}
        </p>
       </div>

       <p className='pop-up-last martp-1ch'>
        Votre retrait sera disponible après la dernière mise à jour
        qui est effectué les 10 minutes du lundi au samedi de 8h à 19h et le dimanche de 9h à 11h
       </p>

      </div>

     </DialogContent>
     <DialogActions>

      <div className='flex-between'>
       <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
       <Button onClick={handleCreditaire}><p className='pop-up'>Suivant</p></Button>
      </div>


     </DialogActions>
    </Dialog>
   </React.Fragment>

  </>
 );
};