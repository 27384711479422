import * as React from 'react';
import './Head.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import secureLocalStorage from "react-secure-storage";
import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";



let Acces = true;
let pushDoc = new Array();
let pushDocId = new Array();


let iconsize = '1.4em';
let iconcolor = '#00a5e0';


function DrawerAppBar() {

 const navigation = useNavigate();
 let first = JSON.parse(window.localStorage.getItem('--vie&&first**'))
 let last = JSON.parse(window.localStorage.getItem('--vie&&last**'))
 const str1 = first.charAt(0).toUpperCase() + first.slice(1);
 let pushDocs = JSON.parse(window.localStorage.getItem('%%docs&&col**'));

 const [arr, setArr] = React.useState([]);
 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("--vie&&id")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
   pushDocId.push(doc.id);

  });

  setArr([...new Set(pushDoc)].reverse());
  setList([...new Set(pushDocId)]);

 }, []);


 if (Array.isArray(pushDocs) && pushDocs.length) {
  Acces = true;
 } else {
  Acces = false;
 }

 const handlepath = (event) => {
  event.preventDefault();
  navigation(-1);
 };


 return (
  <div className='flex-head-list-cmd'>
   <header>
    <div className='container'>
     <nav className='navbar'>

      <HiArrowLeft onClick={handlepath} size={'1.6em'} color={'white'} />
      <div>
       <span style={{ marginRight: '.5em' }}>{str1}</span>
      </div>

     </nav>
    </div>
   </header>

   <section>
    {
     Acces ?
      <ul>
       {
        [...Array(list.length).keys()].map(index => {

         return (
          <li key={index}>

           <div className='box-data-cmd-list'>
            <div></div>
            <div>
             <h2>{moment(arr[index].date).format('L')}</h2>
             <h2>{moment(arr[index].date).format('LTS')}</h2>

            </div>
           </div>


           <div className='cmd-operator-title'>

            <div className='cmd-operator-sub-title'>
             <h3>Opérateur</h3>
             <div className='flex-row-other-cmd'>
              <p key={index}>{arr[index].phone}</p>
             </div>

            </div>

            <div className='cmd-operator-sub-title'>
             <h3>Quantité</h3>

             <div className='flex-row-other-cmd'>
              <p key={index}>{arr[index].count}</p>
             </div>
            </div>

            <div className='cmd-operator-sub-title'>
             <h3 style={{ marginTop: '.1em' }}>Prix</h3>

             <div className='flex-row-cmd'>
              <p key={index}>{arr[index].price}{arr[index].devise === 'USD' ? '$' : 'F'}</p>
             </div>

            </div>

            <div className='cmd-operator-sub-title'>
             <h3 style={{ color: 'transparent' }}>Status</h3>

             <div className='flex-row-cmd'>
              <p key={index}>{arr[index].status === false ? <IoCheckmarkSharp size={iconsize} color={iconcolor} /> : <IoCheckmarkDoneSharp size={iconsize} color={iconcolor} />}</p>
             </div>

            </div>



           </div>

          </li>
         )
        })}
      </ul>

      : <div></div>
    }

   </section>

  </div>
 );
}

export default DrawerAppBar;