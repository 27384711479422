import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import * as React from 'react';
import { db } from '../../../firebase';
import { doc, onSnapshot, getDocs, collection } from "firebase/firestore";
import currency from 'currency.js';
import { useNavigate } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export default function RetuRnDevIse(props) {

 const navigation = useNavigate();
 let pushClient = new Array();


 const [item, setItem] = React.useState(!!secureLocalStorage.getItem("**@!##J@1777"));
 const [dollard, setDollard] = React.useState(0);
 const [fran, setFran] = React.useState(0);

 const [load, setLoad] = React.useState(false);
 const [listclient, setListclient] = React.useState([]);



 const [open, setOpen] = React.useState(false);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());

  });

  setListclient([... new Set(pushClient)])

 }, []);

 const handleDevise = async (event) => {

  event.preventDefault();
  setLoad(true);
  secureLocalStorage.setItem("**@!##J@1777", !secureLocalStorage.getItem("**@!##J@1777"));
  setItem(!!secureLocalStorage.getItem("**@!##J@1777"));

  window.setTimeout(() => {
   navigation(0);
  }, 2000)

 };

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("#@88#&DD!!#"), secureLocalStorage.getItem("USER")), (doc) => {

   setDollard(doc.data().thriftusd);
   setFran(doc.data().thriftcdf);


  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div style={{ cursor: 'pointer' }}
    className="flex-usd-devise-sharing"
    onClick={handleDevise}>
    {item ?

     <div className="flex-center " style={{ gap: '0.2em', marginRight: '1.5em' }}>
      <p style={{ color: dollard - props.value < 0 && "#fff" }} className="pad-top-2 ">{euro(Number(dollard) - Number(props.value)).format()}</p>
      <Avatar
       src="/img/dollars.png"
       sx={{ width: 30, height: 30 }}
      />
     </div>

     :

     <div className="flex-center " style={{ gap: '1em', marginRight: '1.5em' }}>

      <p style={{ color: fran - props.value < 0 && "#fff" }} className="pad-top-2 ">{euro(Number(fran) - Number(props.value)).format()}</p>
      <Avatar
       src="/img/franc.png"
       sx={{ width: 30, height: 30 }}
      />
     </div>

    }
   </div>

  </>
 );
};
