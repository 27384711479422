import './SignIn.css';
import REturnQuOte from './Quote';
import ReturnInput from './Input';

// View Sign In 
export default function ReturnSignInRegister() {
 return (
  <>
   <div className='wrp-signin-register'>
    <REturnQuOte />
    <ReturnInput />
   </div>
  </>
 );
};