import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, updateDoc, increment, arrayUnion, setDoc, serverTimestamp, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";
import { CiWifiOff } from "react-icons/ci";
import { v4 } from 'uuid';
import axios from 'axios';

import currency from 'currency.js';



let key = v4();




function exclureElement (list, itemexclut) {
 return list.filter(index => index !== itemexclut )
}


let pushDoc = new Array();





// View Form Update view
export default function ReturnFormUpdate() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

let userDevise = secureLocalStorage.getItem("##!!devi&&*>>");

export const ScreenLarge = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);

export const FormInputField = () => {


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 let regular = /[a-z]+/;
 const navigation = useNavigate();
 const { handleSubmit, reset, control } = useForm({});
 const [load, setLoad] = React.useState(false);
 const [wifi, setWifi] = React.useState(false);

 const [pin, setPin] = React.useState(null);
 const [cdf, setCdf] = React.useState(null);
 const [usd, setUsd] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 const [rising, setRising] = React.useState(null);
 const [namegroup, setNamegroup] = React.useState(null);
 const [problem, setProblem] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [nothing, setNothing] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const [thrift, setThrift] = React.useState(null);
 const [thriftcdf, setThriftcdf] = React.useState(null);

 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);
 const [signaturefrais, setSignaturefrais] = React.useState(null);


 const [signaturecdf, setSignaturecdf] = React.useState(null);
 const [signaturetypecdf, setSignaturetypecdf] = React.useState(null);
 const [signaturemoneycdf, setSignaturemoneycdf] = React.useState(null);
 const [signaturekeycdf, setSignaturekeycdf] = React.useState(null);
 const [signaturefraiscdf, setSignaturefraiscdf] = React.useState(null);

 const [showPassword, setShowPassword] = React.useState(false);
 
 const [listArray, setListArray] = React.useState([]);


 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };

 
 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleNothing = () => {
  setNothing(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };

 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {

    setPin(doc.data().code);
    setCdf(doc.data().cdf);
    setUsd(doc.data().usd);
    setFirst(doc.data().firstname);
    setLast(doc.data().lastname);

    setThrift(doc.data().thriftusd);
    setThriftcdf(doc.data().thriftcdf);

    setSignature(doc.data().signatureusd);
    setSignaturetype(doc.data().signatureusdtype);
    setSignaturemoney(doc.data().signaturemoneyusd);
    setSignaturekey(doc.data().signaturekeyusd);
    setSignaturefrais(doc.data().signaturefraisusd);

    setSignaturecdf(doc.data().signaturecdf);
    setSignaturetypecdf(doc.data().signaturecdftype);
    setSignaturemoneycdf(doc.data().signaturemoneycdf);
    setSignaturekeycdf(doc.data().signaturekeycdf);
    setSignaturefraiscdf(doc.data().signaturefraiscdf);

   });

  } catch {
   window.console.log(`Erreur`);
  }

 }, []);
 React.useEffect(async () => {

  try {

   await onSnapshot(doc(db, "tontine", secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")), (doc) => {
    setRising(doc.data().rising);
    setNamegroup(doc.data().namegroup);
   });

  } catch {
   window.console.log(`Erreur`);
  }

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id)

  });

  setListArray([...new Set(pushDoc)]);

 }, []);


  // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) + Number(signaturemoney) + Number(signaturefrais))}${parseInt((Number(usd) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) - Number(signaturemoney))}${parseInt((Number(usd) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;

 let sendclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(cdf) + Number(signaturemoneycdf) + Number(signaturefraiscdf))}${parseInt((Number(cdf) + Number(signaturemoneycdf)) - Number(signaturemoneycdf))}${signaturekeycdf}`;
 let getclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(cdf) - Number(signaturemoneycdf))}${parseInt((Number(cdf) - Number(signaturemoneycdf)) + Number(signaturemoneycdf))}${signaturekeycdf}`;
 let checkclientfran = signaturetypecdf === 'envoyer' ? sendclientfran === signaturecdf : getclientfran === signaturecdf;


   
   // let newArray = listgroup.slice(0, listgroup.length)
   
  let newArrayExclure = exclureElement(listArray, secureLocalStorage.getItem("USER"));
   let noFirstchartArray = newArrayExclure.map(item => item.slice(1))

   let prefixe = '243';
   let newArrayprefixe = noFirstchartArray.map(item => prefixe + item);
   const arrayJion = newArrayprefixe.join(',')

  // window.console.log(arrayJion)

 const onSubmit = async (data) => {

  if (cdf === null || cdf === undefined || usd === null || usd === undefined || rising === null || rising === undefined || namegroup === null || namegroup === undefined || first === '' || first === undefined || last === '' || last === undefined) {

   setWifi(false);

  } else {

   setLoad(true);
   
   if (data.code === undefined || pin != data.code) {

    setOpen(true);
    setLoad(false);
    reset();

   } else {

     
    if (checkclient && checkclientfran) {

    secureLocalStorage.setItem("##previous##$$none", false);

    if (userDevise == 'USD') {

     if (Number(parseInt(usd)) < Number(rising)) {

      setNothing(true);
      setLoad(false);

     } else {

      let textApi = `Le panier du groupe ${namegroup}. de ${rising} USD vient d etre ajouter par ${first} ${last} ${secureLocalStorage.getItem("USER")}`;
      
      decrementMoneyClientDollar(Number(usd) - Number(rising), Number(rising), Number(usd), 0, key);

      addBasket(Number(rising));
      accretionChildUpdate(Number(rising), moment().format());
      secureLocalStorage.setItem("^^add&&@!!**", true);
      swapSendUser(secureLocalStorage.getItem("USER"), 'augmentation du panier', Number(rising), Number(usd), Number(usd) - Number(rising), 'envoyer', moment().format(), '', first + ' ' + last, '', thrift, thrift, 0, 0, key, 'USD');


      let config = {
       method: 'get',
       maxBodyLength: Infinity,
       url: `https://api2.dream-digital.info/api/SendSMSMulti?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${arrayJion}&textmessage=${textApi}`,
       headers: {}
      };

      axios.request(config)
       .then((response) => {
        console.log(JSON.stringify(response.data));
       })
       .catch((error) => {
        console.log(error);
       });



      window.setTimeout(() => {
       secureLocalStorage.setItem("***#$$view..<<valid++", false);
       navigation('/tontine');
      }, 4394);

     }

    }
    if (userDevise === 'CDF') {

     if (Number(parseInt(cdf)) < Number(rising)) {
      setNothing(true);
      setLoad(false);

     } else {
    
    
     let textApi = `Le panier du groupe ${namegroup}. de ${rising} CDF vient d etre ajouter par ${first} ${last} ${secureLocalStorage.getItem("USER")}`;

      decrementMoneyClientFran(Number(cdf) - Number(rising), Number(rising), Number(cdf), 0, key);
      addBasket(Number(rising));
      accretionChildUpdate(Number(rising), moment().format());
      secureLocalStorage.setItem("^^add&&@!!**", true);

      swapSendUser(secureLocalStorage.getItem("USER"), 'augmentation du panier', Number(rising), Number(cdf), Number(cdf) - Number(rising), 'envoyer', moment().format(), '', first + ' ' + last, '', thriftcdf, thriftcdf, 0, 0, key, 'CDF');


      let config = {
       method: 'get',
       maxBodyLength: Infinity,
       url: `https://api2.dream-digital.info/api/SendSMSMulti?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${arrayJion}&textmessage=${textApi}`,
       headers: {}
      };

      axios.request(config)
       .then((response) => {
        console.log(JSON.stringify(response.data));
       })
       .catch((error) => {
        console.log(error);
       });


      window.setTimeout(() => {
       secureLocalStorage.setItem("***#$$view..<<valid++", false);
       navigation('/tontine');
      }, 4394);


     }


    }

    }else {

     setProblem(true);
    }


   }
  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl
     sx={{ width: '100%' }}

     variant="standard">
     <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

     <Controller
      name="code"
      control={control}
      render={({ field }) =>

       <Input
        id="standard-adornment-password"
        {...field}
        type={showPassword ? 'numeric' : 'password'}
        inputProps={{
         autoComplete: "off", inputMode: 'numeric'
        }}

        endAdornment={
         <InputAdornment position="end">

          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>

         </InputAdornment>
        }

       />}
     />

    </FormControl>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        PIN, Incorrect
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={nothing}
     onClose={handleNothing}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        Votre portefeuille n'a pas le montant minimum pour cette transaction
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleNothing}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={wifi}
     onClose={handlewifiClose}>

     <DialogContent>

      <DialogContentText>
       <div className='block-flex-center-wifi'>
        <CiWifiOff size={'2em'} color={'crimson'} />
        <p className='pop-up'>
         Connection internet faible

        </p>
       </div>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>


    <button className='Btn-Broker'>Ajouter au panier</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

							Cette transaction est temporairement suspendu,
							après vérification 
													
							sur une situation
							incorrect des vos comptes, de vous, soit de votre destinataire, veuillez contacter Muungano Money

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>



  </>
 );
};


export async function decrementMoneyClientDollar(solde, money, before, frais, key) {

 let time = moment().format('LLL');
 let send = { date: time, solde: `${money} USD [DÉPÔT TONTINE]`, phone: secureLocalStorage.getItem("USER"), user: 'tontine', type: 'envoyer', price: parseInt(Number(money)), actual: parseInt(Number(solde)) + ' ' + 'USD', unite: 'USD' }

 const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  usd: solde,
   

  signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
  signatureusdtype: 'envoyer',
  signaturemoneyusd: parseInt(Number(money)),
  signaturekeyusd: key,
  signaturefraisusd: frais,

  money: money,
  sendtype: 'envoyer',
  frais: 0,
  user: 'tontine',
  delay: moment().format(),
  swap: arrayUnion(send),
  unite: 'USD'



 });


  delete window.decrementMoneyClientDollar;

};
export async function decrementMoneyClientFran(solde, money, before, frais, key) {

 let time = moment().format('LLL');
 let send = { date: time, solde: `${money} CDF [DÉPÔT TONTINE]`, phone: secureLocalStorage.getItem("USER"), user: 'tontine', type: 'envoyer', price: parseInt(Number(money)), actual: parseInt(Number(solde)) + ' ' + 'CDF', unite: 'CDF' }

 const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {

  cdf: solde,
   

  signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
  signaturecdftype: 'envoyer',
  signaturemoneycdf: parseInt(Number(money)),
  signaturekeycdf: key,
  signaturefraiscdf: frais,

  money: money,
  sendtype: 'envoyer',
  frais: 0,
  user: 'tontine',
  delay: moment().format(),
  swap: arrayUnion(send),
  unite: 'CDF'

 });


  delete window.decrementMoneyClientFran;

};


export async function addBasket(money) {

 const washingtonRef = doc(db, "tontine", secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  asked: increment(money),
  position: increment(1)
 });

};
export async function updateBasket(money) {

 const washingtonRef = doc(db, "tontine", secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"));

 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  asked: money,
  askedposition: increment(1),
  position: 1
 });

};
export async function accretionChildTon(numDocs) {


 const washingtonRef = doc(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"), numDocs);
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  solde: Number(0),
  soldeactive: false,
 });


 const clientRef = doc(db, "client", numDocs);
 // Set the "capital" field of the city 'DC'
 await updateDoc(clientRef, {
  solde: Number(0),
  soldeactive: false,
 });


};
export async function accretionChildUpdate(rising, date) {

 const washingtonRef = doc(db, secureLocalStorage.getItem("¥¥˙´¸list˘˘22˚˚fil"), secureLocalStorage.getItem("USER"));
 let obj = { asked: 0, date: date, solde: rising }
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  date: moment().format(),
  solde: Number(rising),
  soldeactive: true,
  activity: arrayUnion(obj)
 });

};

// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  access: false,
  key: key,
  devise: devise,

  id: newCityRef.id,

  color: '#eff6e0',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: true,
  statetv: false,
  statesolaire: false,

 });

};

