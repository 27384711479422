import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, updateDoc, arrayUnion, setDoc, serverTimestamp, collection  } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CiWifiOff } from "react-icons/ci";
import { v4 } from 'uuid';
import axios from 'axios';

import currency from 'currency.js';




let thisTime = moment().format();
let key = v4();



let colors = [
 "3c096c", "5a189a", "012a4a", "014f86", "013a63", "05668d", "780116", "252422", "00509d", "00296b", "284b63",
 "772e25", "197278", "5e503f", "003d5b", "0d1321", "0081a7", "d81159", "01161e", "003459", "2f6690", "583101",
 "6a00f4", "db00b6", "f20089", "2f4858", "703d57", "402a2c", "241715", "76520e", "262626", "274c77", "343330",
 "7e5920", "45462a", "7e5920", "07498f", "036666", "7e1f86", "283d3b", "772e25", "735751", "212529", "03045e"
];


// View Form Update view
export default function ReturnFormUpdate() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-nows'>
  <FormInputField />
 </div>
);



export const FormInputField = () => {


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 let regular = /[a-z]+/;

 const [wifi, setWifi] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const navigation = useNavigate();
 const { handleSubmit, reset, control } = useForm();
 const [pin, setPin] = React.useState(null);
 const [cdf, setCdf] = React.useState(null);
 const [usd, setUsd] = React.useState(null);

 const [thrift, setThrift] = React.useState(null);
 const [thriftcdf, setThriftcdf] = React.useState(null);

 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');
 const [problem, setProblem] = React.useState(false);


 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);
 const [signaturefrais, setSignaturefrais] = React.useState(null);


 const [signaturecdf, setSignaturecdf] = React.useState(null);
 const [signaturetypecdf, setSignaturetypecdf] = React.useState(null);
 const [signaturemoneycdf, setSignaturemoneycdf] = React.useState(null);
 const [signaturekeycdf, setSignaturekeycdf] = React.useState(null);
 const [signaturefraiscdf, setSignaturefraiscdf] = React.useState(null);




 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');
 const [open, setOpen] = React.useState(false);
 const [showPassword, setShowPassword] = React.useState(false);


 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };


 let rising = secureLocalStorage.getItem("**tont>>count??");
 let namegroup = secureLocalStorage.getItem("**tont>>name??")
 let currencygroup = secureLocalStorage.getItem("**tont>>currency??").toUpperCase();
 let listgroup = secureLocalStorage.getItem("@@xi^^,view**++");
 let docTon = namegroup + secureLocalStorage.getItem("USER");


 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };

 
 const handleClose = () => {
  setOpen(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 React.useEffect(async () => {

  try {

   await onSnapshot(doc(db, "client", secureLocalStorage.getItem("USER")), (doc) => {

    setPin(doc.data().code);
    setCdf(doc.data().cdf);
    setUsd(doc.data().usd);
    setThrift(doc.data().thriftusd);
    setThriftcdf(doc.data().thriftcdf);

    setFirst(doc.data().firstname);
    setLast(doc.data().lastname);

    setSignature(doc.data().signatureusd);
    setSignaturetype(doc.data().signatureusdtype);
    setSignaturemoney(doc.data().signaturemoneyusd);
    setSignaturekey(doc.data().signaturekeyusd);
    setSignaturefrais(doc.data().signaturefraisusd);

    setSignaturecdf(doc.data().signaturecdf);
    setSignaturetypecdf(doc.data().signaturecdftype);
    setSignaturemoneycdf(doc.data().signaturemoneycdf);
    setSignaturekeycdf(doc.data().signaturekeycdf);
    setSignaturefraiscdf(doc.data().signaturefraiscdf);


   });
  } catch {
   window.console.log(`Erreur`);
  }

 }, []);



 // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) + Number(signaturemoney) + Number(signaturefrais))}${parseInt((Number(usd) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(usd) - Number(signaturemoney))}${parseInt((Number(usd) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;



 let sendclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(cdf) + Number(signaturemoneycdf) + Number(signaturefraiscdf))}${parseInt((Number(cdf) + Number(signaturemoneycdf)) - Number(signaturemoneycdf))}${signaturekeycdf}`;
 let getclientfran = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneycdf)}${parseInt(Number(cdf) - Number(signaturemoneycdf))}${parseInt((Number(cdf) - Number(signaturemoneycdf)) + Number(signaturemoneycdf))}${signaturekeycdf}`;
 let checkclientfran = signaturetypecdf === 'envoyer' ? sendclientfran === signaturecdf : getclientfran === signaturecdf;




 var euro = value => currency(value, { separator: ' ', decimal: '.', symbol: '' })
  

 let typeTontine = !!secureLocalStorage.getItem("#!!@%$$switch**^^%%") ? "fermer" : "ouverte";
 let nameofgroup = (secureLocalStorage.getItem("**tont>>name??") + '').toUpperCase()

  let textApi = `Bienvenu dans l'espace Tontine ${typeTontine}. Vous etes inscrit au groupe ${nameofgroup} de ${(rising)} ${secureLocalStorage.getItem("**tont>>currency??").includes('usd') ? 'USD' : 'CDF'}`;

 let newArray = listgroup.slice(1, listgroup.length)
 let noFirstchartArray = newArray.map(item => item.slice(1))

 let prefixe = '243';
 let newArrayprefixe = noFirstchartArray.map(item => prefixe + item);


 const arrayJion = newArrayprefixe.join(',');


 const onSubmit = async (data) => {

  if (cdf === null || cdf === undefined || usd === null || usd === undefined || first === '' || first === undefined || last === '' || last === undefined) {
   setWifi(true);
  } else {
   setLoad(true);

   if (data.code === undefined || pin === '000000') {
    
				setOpen(true);
    setLoad(false);
    reset();

   } else {

    if (pin != data.code) {
     
					setOpen(true);
     setLoad(false);
     reset();

    } else {

     if (pin != data.code) {

      setOpen(true);
      setLoad(false);
      reset();

     } else {

      if (checkclient && checkclientfran) {

      let group = namegroup + secureLocalStorage.getItem("USER");

      if (secureLocalStorage.getItem("**tont>>currency??").includes('usd')) {

       decrementMoneyClientDollar(Number(usd) - Number(rising), Number(rising), Number(usd), 0, key);
       swapSendUser(secureLocalStorage.getItem("USER"), 'création du groupe tontine', Number(rising), Number(usd), Number(usd) - Number(rising), 'envoyer', moment().format(), '', first + ' ' + last, '', thrift, thrift, 0, 0, key, 'USD');

      } else {
       
       
       decrementMoneyClientFran(Number(cdf) - Number(rising), Number(rising), Number(cdf), 0, key);
       swapSendUser(secureLocalStorage.getItem("USER"), 'création du groupe tontine', Number(rising), Number(cdf), Number(cdf) - Number(rising), 'envoyer', moment().format(), '', first + ' ' + last, '', thriftcdf, thriftcdf, 0, 0, key, 'CDF');
            
      }

      let list = secureLocalStorage.getItem("@@xi^^,view**++");

      [...list].map((item) => {

       if (item === secureLocalStorage.getItem("USER")) {

        window.setTimeout(async () => {
         allUserTontine(docTon, item, rising, 0, true, thisTime, list.indexOf(item));
         const washingtonRef = doc(db, "client", item);
         await updateDoc(washingtonRef, {

          grouptontine: arrayUnion(group),
          grouptontinename: arrayUnion(namegroup),
          grouptontinemoney: arrayUnion(namegroup + rising),
          grouptontinedevise: arrayUnion(currencygroup),


         });

        }, 500);
       } else {

        window.setTimeout(async () => {

         allUserTontine(docTon, item, 0, 0, false, thisTime, list.indexOf(item));
         // allUserTontineTOClient(item, 0, 0, false, list.indexOf(item))

         const washingtonRef = doc(db, "client", item);
         await updateDoc(washingtonRef, {
          grouptontine: arrayUnion(group),
          grouptontinename: arrayUnion(namegroup),
          grouptontinemoney: arrayUnion(namegroup + rising),
          grouptontinedevise: arrayUnion(currencygroup),
         });

        }, 500);
       };

      });


       let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api2.dream-digital.info/api/SendSMSMulti?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${arrayJion}&textmessage=${textApi}`,
        headers: {}
       };

       axios.request(config)
        .then((response) => {
         console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
         console.log(error);
        });


      createTontine(docTon, rising, rising, listgroup.length, currencygroup, listgroup, thisTime, namegroup, colors[Math.floor(Math.random() * (colors.length - 0 + 1)) + 0], namegroup + listgroup[0]);
      secureLocalStorage.setItem("??next^^**$$", false);
      secureLocalStorage.setItem("??next^^**$$group", true);
      secureLocalStorage.setItem("##previous##$$none#create", false);

      window.setTimeout(() => {
       navigation('/tontine');
      }, 3450);


      }else {
       setProblem(true);
      }

     }

    };

   }

  }
 

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl
     sx={{ width: '100%' }}

     variant="standard">
     <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin actuel</span></InputLabel>

     <Controller
      name="code"
      control={control}
      render={({ field }) =>

       <Input
        id="standard-adornment-password"
        {...field}
        type={showPassword ? 'numeric' : 'password'}
        inputProps={{
         autoComplete: "off", inputMode: 'numeric',
        }}

        endAdornment={
         <InputAdornment position="end">

          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>

         </InputAdornment>
        }

       />}
     />

    </FormControl>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>MuunganoMoney</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        PIN, Incorrect
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={wifi}
     onClose={handlewifiClose}>

     <DialogContent>

      <DialogContentText>
       <div className='block-flex-center-wifi'>
        <CiWifiOff size={'2em'} color={'crimson'} />
        <p className='pop-up'>
         Connection internet faible

        </p>
       </div>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>


    <button className='Btn-Broker'>Créer</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

							Cette transaction est temporairement suspendu,
							après vérification 
													
							sur une situation
							incorrect des vos comptes, veuillez contacter Cashbeck

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

  </>
 );
};


export async function decrementMoneyClientDollar(solde, money, before, frais, key) {

 let time = moment().format('LLL');
 let send = { date: time, solde: `${money} USD [DÉPÔT TONTINE]`, phone: secureLocalStorage.getItem("USER"), user: 'tontine', type: 'envoyer', price: parseInt(Number(money)), actual: parseInt(Number(solde)) + ' ' + 'USD', unite: 'USD' }

 const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  usd: solde,
   


  signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
  signatureusdtype: 'envoyer',
  signaturemoneyusd: parseInt(Number(money)),
  signaturekeyusd: key,
  signaturefraisusd: frais,

  money: money,
  sendtype: 'envoyer',
  frais: 0,
  user: 'tontine',
  delay: moment().format(),
  swap: arrayUnion(send),
  unite: 'USD'

 });


  delete window.decrementMoneyClientDollar;

};
export async function decrementMoneyClientFran(solde, money, before, frais, key) {

 let time = moment().format('LLL');
 let send = { date: time, solde: `${money} CDF [DÉPÔT TONTINE]`, phone: secureLocalStorage.getItem("USER"), user: 'tontine', type: 'envoyer', price: parseInt(Number(money)), actual: parseInt(Number(solde)) + ' ' + 'CDF', unite: 'CDF' }

 const washingtonRef = doc(db, "client", secureLocalStorage.getItem("USER"));
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {
  cdf: solde,
   

  signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
  signaturecdftype: 'envoyer',
  signaturemoneycdf: parseInt(Number(money)),
  signaturekeycdf: key,
  signaturefraiscdf: frais,

  money: money,
  sendtype: 'envoyer',
  frais: 0,
  user: 'tontine',
  delay: moment().format(),
  swap: arrayUnion(send),
  unite: 'CDF'

 });


  delete window.decrementMoneyClientFran;


};
export async function createTontine(docUser, rising, asked, count, currency, table, date, namegroup, color, idgroup) {

 // Add a new document in collection "client"
 await setDoc(doc(db, "tontine", docUser), {
  rising: Number(rising),
  date: date,
  asked: Number(asked),
  count: Number(count),
  currency: currency,
  countasked : 0,

  position: 1,
  askedposition: 0,
  table: table,
  namegroup: namegroup,
  idgroup: idgroup,
  color: color,
   tontinetype: !!secureLocalStorage.getItem("#!!@%$$switch**^^%%"),
 });



};
export async function allUserTontine(colUser, docUser, solde, asked, soldeactive, date, position) {

 let obj = { solde: solde, date: date, asked: asked }
 // Add a new document in collection "tontine"
 await setDoc(doc(db, colUser, docUser), {
  solde: Number(solde),
  soldeactive: soldeactive,
  date: moment().format(),
  asked: Number(asked),
  position: Number(position),
  activity: arrayUnion(obj)
 });


};
export async function allUserTontineTOClient(docUser, solde, asked, soldeactive, position) {

 // Add a new document in collection "tontine"
 await setDoc(doc(db, "client", docUser), {
  tontinesolde: Number(solde),
  tontinesoldeactive: soldeactive,
  tontinedate: moment().format(),
  tontineasked: Number(asked),
  tontineposition: Number(position),
 }, { merge: true });


};

async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  access: false,
  key: key,
  devise: devise,

  id :newCityRef.id,

  color: '#eff6e0',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: true,
  statetv: false,
  statesolaire: false,

 });

};

