
// Title Return Compoennt
export default function ReturnType() {

 let type = JSON.parse(window.localStorage.getItem('@ª©##courant**^^'));

 return (
  <div className='wrp-title-print-tickets-client'>
   <h2>COMPTE : </h2>
   <h2>{type}</h2>
  </div>
 );
};