import React from 'react';
import './Btn.css';
import Media from 'react-media';
import { doc, onSnapshot, getDocs, collection, updateDoc, arrayUnion, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';


import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { v4 } from 'uuid';

import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';


import secureLocalStorage from "react-secure-storage";
import { CiUnlock } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { CiWifiOff } from "react-icons/ci";
import currency from 'currency.js';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import RetuRnDevIse from './Devise';



export let exchAnge = 0; // Export view 
let pushDocs = new Array();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="00000000000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};



let key = v4();



// Return Phone input component
export default function REturnCurrenT() {
 return (
  <>
   <Media
    queries={{
     small: '(max-width: 599px)',
     medium: '(min-width: 600px) and (max-width:1199px)',
     large: '(min-width: 1200px)',
    }}>
    {matches => (
     <>
      {matches.small && <ScreenSmall />}
      {matches.medium && <ScreenLarge />}
      {matches.large && <ScreenLarge />}
     </>
    )}
   </Media>
  </>
 );
};

export const ScreenLarge = () => {
 return (
  <div className='wrp-frais-current'>
   <FormInputValue />
  </div>
 );
};
export const ScreenSmall = () => {
 return (
  <div className='wrp-frais-current-sm'>
   <FormInputValue />
  </div>
 )
};


export const FormInputValue = () => {


 const navigation = useNavigate();

 const {
  register,
  watch,
  control,
  reset,
  handleSubmit,
 } = useForm();




 const [problem, setProblem] = React.useState(false);
 const [profil, setProfil] = React.useState(null);
 const [wifi, setWifi] = React.useState(false);

 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);

 const [signaturedollard, setSignaturedollard] = React.useState(null);
 const [signaturetypedollard, setSignaturetypedollard] = React.useState(null);
 const [signaturemoneydollard, setSignaturemoneydollard] = React.useState(null);
 const [signaturekeydollard, setSignaturekeydollard] = React.useState(null);





 const [opn, setOpn] = React.useState(false);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 const [cdf, setCdf] = React.useState(null);
 const [usd, setUsd] = React.useState(null);

 const [frais, setFrais] = React.useState(null);
 const [status, setStatus] = React.useState(null);

 const [thriftcdf, setThriftcdf] = React.useState(null);
 const [thriftusd, setThriftusd] = React.useState(null);


 const [open, setOpen] = React.useState(false);
 const [add, setAdd] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const handleClose = () => {
  setOpen(false);
  navigation('/dash');
 };
 const handleAdd = () => {
  navigation('/dash');
  setAdd(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handlClose = () => {
  setOpn(false);
  reset();
 };



 let sourceIncome = watch("count");
 let Income = sourceIncome == undefined ? 0 : sourceIncome;



 const [loading, setLoading] = React.useState(false);
 const [success, setSuccess] = React.useState(false);
 const timer = React.useRef();

 const buttonSx = {
  ...(success && {
   bgcolor: green[500],
   '&:hover': {
    bgcolor: green[700],
   },
  }),
 };

 const [list, setList] = React.useState([])


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');



 React.useEffect(() => {
  return () => {
   clearTimeout(timer.current);
  };
 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   pushDocs.push(doc.id);
  });
  setList([...new Set(pushDocs)]);

 }, []);
 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("#@88#&DD!!#"), secureLocalStorage.getItem("USER")), (doc) => {


    setCdf(doc.data().cdf);
    setUsd(doc.data().usd);


    setThriftusd(doc.data().thriftusd);
    setThriftcdf(doc.data().thriftcdf);


    setFirst(doc.data().firstname);
    setLast(doc.data().lastname);
    setProfil(doc.data().profile);
    setStatus(doc.data().state);
    setFrais(doc.data().frais);

    setSignature(doc.data().signaturecdf);
    setSignaturetype(doc.data().signaturecdftype);
    setSignaturemoney(doc.data().signaturemoneycdf);
    setSignaturekey(doc.data().signaturekeycdf);

    setSignaturedollard(doc.data().signatureusd);
    setSignaturetypedollard(doc.data().signatureusdtype);
    setSignaturemoneydollard(doc.data().signaturemoneyusd);
    setSignaturekeydollard(doc.data().signaturekeyusd);


   });
  } catch {
   window.console.log('error');
  }


 }, []);



 // Form send AGENT
 let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(cdf) + Number(signaturemoney))}${parseInt((Number(cdf) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(cdf) - Number(signaturemoney))}${parseInt((Number(cdf) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


 // Form send AGENT DOLLARD
 let sendagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(usd) + Number(signaturemoneydollard))}${parseInt((Number(usd) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekeydollard}`;
 let getagentdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(usd) - Number(signaturemoneydollard))}${parseInt((Number(usd) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekeydollard}`;
 let checkagentdollard = signaturetypedollard === 'envoyer' ? sendagentdollard === signaturedollard : getagentdollard === signaturedollard;


 // // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(cdf) + Number(signaturemoney) + Number(frais))}${parseInt((Number(cdf) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(cdf) - Number(signaturemoney))}${parseInt((Number(cdf) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;


 let sendclientdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(usd) + Number(signaturemoneydollard) + Number(frais))}${parseInt((Number(usd) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekey}`;
 let getclientdollard = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoneydollard)}${parseInt(Number(usd) - Number(signaturemoneydollard))}${parseInt((Number(usd) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekey}`;
 let checkclientdollard = signaturetypedollard === 'envoyer' ? sendclientdollard === signaturedollard : getclientdollard === signaturedollard;



 let dollard = Number(usd) + Number(Income);
 let thrifT = Number(thriftusd) - Number(Income);

 let fran = Number(cdf) + Number(Income);
 let thrifTcdf = Number(thriftcdf) - Number(Income);



 const handleIncrementMoney = async () => {

  if (Number(Income) === 0) {

   window.console.log("nothing");

  } else {

   setAdd(true);
   setOpn(false);
   setOpen(false);

   if (!!secureLocalStorage.getItem("**@!##J@1777")) {

    if (cdf === null || cdf === undefined || usd === null || usd === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || signaturekey === null || signaturekey === undefined || signaturedollard === null || signaturedollard === undefined || signaturetypedollard === null || signaturetypedollard === undefined || signaturemoneydollard === null || signaturemoneydollard === undefined || signaturekeydollard === null || signaturekeydollard === undefined) {

     setWifi(true);

    } else {

     var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
     let time = moment().format('LLL');
     let send = { date: time, solde: `${euro(parseInt(Number(Income))).format()} USD`, phone: secureLocalStorage.getItem("USER"), user: 'commission&épargne', type: 'Reçu', price: euro(parseInt(Number(Income))).format(), actual: euro(Income).format() + ' ' + 'USD', unite: 'USD' }

     const updateRediutRef = doc(db, list.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
     // Set the "capital" field of the city 'DC'
     await updateDoc(updateRediutRef, {
      usd: dollard,

      signatureusd: `${secureLocalStorage.getItem("USER")}${parseInt(Number(Number(Income)))}${parseInt(Number(usd))}${parseInt((usd)) + (parseInt(Number(Income)))}${key}`,
      signatureusdtype: 'reussi',
      signaturemoneyusd: parseInt(Number(Income)),
      signaturekeyusd: key,
      signaturefraisusd: 0,

      user: 'commission&épargne',
      money: parseInt(Number(Income)),
      swap: arrayUnion(send),
      commission: Number(0),
      sendtype: 'reçu',
      unite: 'USD',
      frais: 0,
      delay: moment().format(),

     });

     const reduceRef = doc(db, list.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
     // Set the "capital" field of the city 'DC'
     await updateDoc(reduceRef, {
      thriftusd: Number(thrifT),
      zoneclick: arrayUnion({

       date: moment().format(),
       devise: 'USD',
       sendmoney: Number(Income)

      })
     });

     swapSendUser(secureLocalStorage.getItem("USER"), `commission&épargne`, parseInt(Number(Income)), usd, usd + parseInt(Income), 'Reçu', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thriftusd, thrifT, 0, 0, key, 'USD', usd, dollard);

    }


   } else {

    if (signature === null || cdf === null || cdf === undefined || usd === null || usd === undefined || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || signaturekey === null || signaturekey === undefined || signaturedollard === null || signaturedollard === undefined || signaturetypedollard === null || signaturetypedollard === undefined || signaturemoneydollard === null || signaturemoneydollard === undefined || signaturekeydollard === null || signaturekeydollard === undefined) {

     setWifi(true);

    } else {


     var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
     let time = moment().format('LLL');
     let geted = { date: time, solde: `${euro(parseInt(Income)).format()} CDF`, phone: secureLocalStorage.getItem("USER"), user: 'commission&épargne', type: 'Reçu', price: euro(parseInt(Income)).format(), actual: euro(fran).format() + ' ' + 'CDF', unite: 'CDF' }


     const updatereduceRef = doc(db, list.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
     // Set the "capital" field of the city 'DC'
     await updateDoc(updatereduceRef, {

      cdf: fran,
      signaturecdf: `${secureLocalStorage.getItem("USER")}${parseInt(Number(Income))}${parseInt(Number(cdf))}${parseInt((cdf)) + (parseInt(Income))}${key}`,
      signaturecdftype: 'reussi',
      signaturemoneycdf: parseInt(Number(Income)),
      signaturekeycdf: key,
      signaturefraiscdf: 0,
      user: 'commission&épargne',

      money: parseInt(Number(Income)),
      swap: arrayUnion(geted),
      commission: Number(0),
      sendtype: 'reçu',
      unite: 'CDF',
      frais: 0,
      delay: moment().format(),

     });

     const reduceRef = doc(db, list.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
     // Set the "capital" field of the city 'DC'
     await updateDoc(reduceRef, {
      thriftcdf: Number(thrifTcdf),
      zoneclick: arrayUnion({
       date: moment().format(),
       devise: 'USD',
       sendmoney: Number(Income)
      })
     });

     swapSendUserFran(secureLocalStorage.getItem("USER"), `commission&épargne`, parseInt(Income), cdf, fran, 'Reçu', moment().format(), secureLocalStorage.getItem("USER"), first + ' ' + last, profil, thriftcdf, thrifTcdf, 0, 0, key, 'CDF', cdf, fran);



    }

   }

  }


  window.setTimeout(() => {
   navigation(0);
  }, 3500)

  delete window.handleIncrementMoney;

 };


 const handleDialog = () => {
  setOpn(true);
 }

 return (
  <>
   <React.Fragment>
    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={opn}
     onClose={handlClose}
     PaperProps={{
      component: 'form',
      onSubmit: (event) => {
       event.preventDefault();
       const formData = new FormData(event.currentTarget);
       const formJson = Object.fromEntries(formData.entries());
       const email = formJson.email;
       console.log(email);
       handlClose();
      },
     }}>

     <div>
      <DialogTitle><h1 className="pop-up">Cashbeck</h1></DialogTitle>
      <div className="flex-between">
       <div></div>
       <RetuRnDevIse value={Income} />
      </div>
     </div>

     <DialogContent>
      <DialogContentText>
      </DialogContentText>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Montant</h1></InputLabel>

       <Controller
        name="count"
        control={control}
        render={({ field }) =>

         <Input
          // autoFocus
          value={values.textmask}
          onChange={handleChange}
          inputRef={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}

          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}


          sx={{ fontSize: '1.3em', fontFamily: "Alata" }}
          InputProps={{
           inputComponent: NumericFormatCustom,
          }}

          inputmode="tel"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          {...field}

         />

        }
       />
      </FormControl>


     </DialogContent>

     <DialogActions>


      <div className="flex-between" style={{ padding: "0 15px" }}>

       <Button onClick={handlClose}><span className="pop-up" style={{ color: "#006494" }}>Annuler</span></Button>


       {!!secureLocalStorage.getItem("**@!##J@1777") === true &&
        Number(thriftusd) - Number(Income) >= 0 && Number(thriftusd) > 0 &&
        <Button onClick={handleIncrementMoney} ><span className="pop-up" style={{ color: "#006494" }}>Confirmer</span></Button>

       }

       {!!secureLocalStorage.getItem("**@!##J@1777") === false &&
        Number(thriftcdf) - Number(Income) >= 0 && Number(thriftcdf) > 0 &&
        <Button onClick={handleIncrementMoney}><span className="pop-up" style={{ color: "#006494" }}>Confirmer</span></Button>
       }
      </div>


     </DialogActions>

    </Dialog>
   </React.Fragment>

   {moment().date() <= 5 ?
    <div>
     <Box sx={{ m: 1, position: 'relative' }} onClick={handleDialog}>
      <Fab
       aria-label="save"
       color="primary"
       sx={buttonSx}
       onClick={handleDialog}
      >
       {success ? <CheckIcon /> : <CiUnlock size={'3em'} />}
      </Fab>


      {loading && (
       <CircularProgress
        size={68}
        sx={{
         color: green[500],
         position: 'absolute',
         top: -6,
         left: -6,
         zIndex: 1,
        }}
       />)}


     </Box>

    </div>
    :
    <div></div>
   }

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}>
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Votre solde est actuellement révisé annulé
     </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={add}
    onClose={handleAdd}>
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Le solde principal vient d'être augmenté
     </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleAdd}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={problem}
    onClose={handleproblemClose}>
    <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
    <DialogContent>

     <DialogContentText>

      <p className='info-detect'>

       Cette transaction ne peut pas être effectuée sur une situation
       de litige sur votre compte veuillez contacter
       MuunganoMoney pour résoudre ses litiges

								</p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

  </>
 );
};


async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  key: key,
  devise: devise,

  soldebefore: soldebefore,
  soldeafter: soldeafter,

  color: '#FEF4F3',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: false,
  statetv: false,
  statesolaire: false,

 });

};
async function swapSendUserFran(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

 // Add a new document with a generated id
 const newCityRef = doc(collection(db, "swap" + colSwap));
 // later...
 await setDoc(newCityRef, {

  action: action,
  money: money,
  before: before,
  after: after,
  type: type,
  date: date,
  delay: date,

  phone: phone,
  user: user,

  profile: profile,
  beforethrift: beforethrift,
  afterthrift: afterthrift,
  commission: commision,
  frais: frais,
  key: key,
  devise: devise,

  soldebefore: soldebefore,
  soldeafter: soldeafter,

  color: '#FEF4F3',
  dateofcreate: serverTimestamp(),

  statetrans: true,
  statepret: false,
  stateunit: false,
  statetontine: false,
  statetv: false,
  statesolaire: false,

 });

};
