import React, { useState, useEffect } from 'react';
import './Network.css';
import './Input.css';
import './Prix.css';
import ReturnTitle from './Title';
import { useForm } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import ReturnDevise from './Devise';

import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';
import ReturnRate from './Rate';
import { useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import './Progres.css';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';

import { v4 } from 'uuid';




// Money Africell
let africount = 1;
let afriprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell");

// Money Orange
let orangecount = 1;
let orangeprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange");

// Money Airtel
let airtelcount = 1;
let airtelprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel");

// Money Vodacom
let vodacount = 1;
let vodaprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda");


let pushDocs = new Array();

let firstname = '';
let lastname = '';

let dialogVoda = false;
let dialogAirtel = false;

let dialogOrange = false;
let dialogAfricell = false;



let money = 0;



// Form Input Stock 
export default function ReturnStocKInput() {

 const { register, handleSubmit, watch } = useForm({});
 const navigation = useNavigate();

 const [cdf, setCdf] = React.useState(0);
 const [usd, setUsd] = React.useState(0);
 const [checked, setChecked] = React.useState(true);

 const [open, setOpen] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [dialog, setDialog] = React.useState(false);
 const [scroll, setScroll] = React.useState('paper');


 const [voda, setVoda] = React.useState(0);
 const [africell, setAfricell] = React.useState(0);
 const [orange, setOrange] = React.useState(0);
 const [airtel, setAirtel] = React.useState(0);


 const handleClickDialog = (scrollType) => () => {
  setDialog(true);
  setScroll(scrollType);
 };

 const dialogClose = () => {
  setDialog(false);
 };

 const dialogConfirm = () => {

  setDialog(false);
  setIsRunning(true);

  window.localStorage.setItem('%%docs**stock', JSON.stringify(moment().format()));
  window.setTimeout(() => {

   window.localStorage.setItem('&&check**stock', JSON.stringify(true));
   window.localStorage.setItem('&&obj**stock', JSON.stringify({}));

   firstname = JSON.parse(window.localStorage.getItem('--vie&&first**'));
   lastname = JSON.parse(window.localStorage.getItem('--vie&&last**'));

   const collectionIdname = firstname.toLowerCase() + lastname.toLowerCase();
   window.localStorage.setItem('$id&&fiat**col', JSON.stringify(collectionIdname));

   navigation('/stock/pin');
  }, 6930);

 };

 const descriptionElementRef = React.useRef(null);
 React.useEffect(() => {
  if (dialog) {
   const { current: descriptionElement } = descriptionElementRef;
   if (descriptionElement !== null) {
    descriptionElement.focus();
   }
  }
 }, [dialog]);
 React.useEffect(async () => {

  firstname = JSON.parse(window.localStorage.getItem('--vie&&first**'));
  lastname = JSON.parse(window.localStorage.getItem('--vie&&last**'));


  const querySnapshotClient = await getDocs(collection(db, "client"));
  querySnapshotClient.forEach((doc) => {
   pushDocs.push(doc.id);
  });

  var verifierCollection = pushDocs.some((value) => value == secureLocalStorage.getItem("USER"));

  try {
   await onSnapshot(doc(db, verifierCollection ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {
    setUsd(doc.data().usd);
    setCdf(doc.data().cdf);
   });
  } catch (e) {
   window.console.log(`Erreur console usd ${e}`);
  }

 }, []);


 const handleChangeSwitch = (event) => {
  event.preventDefault();
  setChecked(!checked); // on change currency money
 };

 const handleClose = () => {
  setOpen(false);
 };

 let cdfnumber = cdf;
 let usdnumber = usd;

 let usdvalue = usd;
 let cdfvalue = cdf;

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("USER"), secureLocalStorage.getItem("--vie&&id")), (doc) => {

   setVoda(doc.data().voda);
   setAfricell(doc.data().africell);
   setOrange(doc.data().orange);
   setAirtel(doc.data().airtel);

  });

 }, []);



 const str = lastname.toLowerCase() + ' ' + firstname.toLowerCase();
 const str2 = str.charAt(0).toUpperCase() + str.slice(1);


 // View Africell
 if (checked) {
  usdnumber -= watch('afriprice');
  usdvalue -= watch('afriprice');
  afriprice = watch('afriprice');

  dialogAfricell = true;

  if (Number(afriprice) > secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell")) {

   africount = watch('afriprice', true) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell");
   africount = parseInt(africount, 10);
   afriprice = watch('africount', true) * secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell");

  } else {
   afriprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell");
   africount = 1;

  }
 } else {

  cdfnumber -= watch('afriprice');
  cdfvalue -= watch('afriprice');
  afriprice = watch('afriprice');

  dialogAfricell = false;

  if (Number(afriprice) > secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell")) {

   africount = watch('afriprice', true) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell");
   africount = parseInt(africount, 10);
   afriprice = watch('africount', true) * secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell");

  } else {
   afriprice = secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell");
   africount = 1;

  }

 }

 // View Orange
 if (checked) {
  usdnumber -= watch('orangeprice');
  usdvalue -= watch('orangeprice');
  orangeprice = watch('orangeprice');

  dialogOrange = true;


  if (Number(orangeprice) > secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange")) {

   orangecount = watch('orangeprice', true) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange");
   orangecount = parseInt(orangecount, 10);
   orangeprice = watch('orangecount', true) * secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange");

  } else {
   orangeprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange");
   orangecount = 1;

  }
 } else {

  cdfnumber -= watch('orangeprice');
  cdfvalue -= watch('orangeprice');
  orangeprice = watch('orangeprice');

  dialogOrange = false;

  if (Number(orangeprice) > 21) {

   orangecount = watch('orangeprice', true) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange");
   orangecount = parseInt(orangecount, 10);
   orangeprice = watch('orangecount', true) * secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange");

  } else {
   orangeprice = secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange");
   orangecount = 1;

  }

 }

 // View Airtel
 if (checked) {
  usdnumber -= watch('airtelprice');
  usdvalue -= watch('airtelprice');
  airtelprice = watch('airtelprice');

  dialogAirtel = true;

  if (Number(airtelprice) > secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel")) {

   airtelcount = watch('airtelprice', true) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel");
   airtelcount = parseInt(airtelcount, 10);
   airtelprice = watch('airtelcount', true) * secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel");

  } else {
   airtelprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel");
   airtelcount = 1;

  }
 } else {

  cdfnumber -= watch('airtelprice');
  cdfvalue -= watch('airtelprice');
  airtelprice = watch('airtelprice');

  dialogAirtel = false;

  if (Number(airtelprice) > secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel")) {

   airtelcount = watch('airtelprice', true) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel");
   airtelcount = parseInt(airtelcount, 10);
   airtelprice = watch('airtelcount', true) * secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel");

  } else {
   airtelprice = secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel");
   airtelcount = 1;

  }

 }

 // View Vodacom
 if (checked) {
  usdnumber -= watch('vodaprice');
  usdvalue -= watch('vodaprice');
  vodaprice = watch('vodaprice');

  dialogVoda = true;

  if (Number(vodaprice) > secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda")) {

   vodacount = watch('vodaprice', true) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda");
   vodacount = parseInt(vodacount, 10);
   vodaprice = watch('vodacount', true) * secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda");

  } else {
   vodaprice = secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda");
   vodacount = 1;

  }
 } else {

  cdfnumber -= watch('vodaprice');
  cdfvalue -= watch('vodaprice');
  vodaprice = watch('vodaprice');

  dialogVoda = false;

  if (Number(vodaprice) > 21) {

   vodacount = watch('vodaprice', true) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda");
   vodacount = parseInt(vodacount, 10);
   vodaprice = watch('vodacount', true) * secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda");

  } else {
   vodaprice = secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda");
   vodacount = 1;

  }

 }



 const [filled, setFilled] = useState(0);
 const [isRunning, setIsRunning] = useState(false);


 useEffect(() => {
  if (filled < 100 && isRunning) {
   setTimeout(() => setFilled(prev => prev += 5), 50)
  }
 }, [filled, isRunning]);

 let colorUsd = usdnumber <= 0 ? '#e6e8e6' : '#1a659e';
 let colorCdf = cdfnumber <= 0 ? '#e6e8e6' : '#1a659e';

 usdnumber = (usdnumber).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&, ');
 cdfnumber = (cdfnumber).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&, ');


 const onSubmit = async (data) => {

  secureLocalStorage.setItem("&@#^^&&date#!!&&##", moment().format());
  secureLocalStorage.setItem("#@%##key!!##", v4());

  if (checked) {

   secureLocalStorage.setItem("&&usdvalue**stock##", usdvalue)
   secureLocalStorage.setItem("&&checked**stock##", true)


  } else {

   secureLocalStorage.setItem("&&cdfvalue**stock##", Number(cdfvalue));
   secureLocalStorage.setItem("&&checked**stock##", false);

  }


  money = Number(data.afriprice) + Number(data.orangeprice) + Number(data.airtelprice) + Number(data.vodaprice);
  secureLocalStorage.setItem("&@#^^#money6229@#&&##", money);


  if (checked === true && money < 0) {
   setOpen(true);
  } else if (checked === false && money < 0) {
   setOpen(true);
  } else {

   if (checked === true && parseInt(usdvalue) < 0) {
    setOpen(true);
   } else if (checked === false && parseInt(cdfvalue) < 0) {
    setOpen(true);
   } else {

    if (Number(data.afriprice) > 0) {

     secureLocalStorage.setItem("´ððchecked˝˝africell", true);
     secureLocalStorage.setItem("´ððprice˝˝africell", Number(data.afriprice));

     // window.localStorage.setItem('´ððchecked˝˝africell', JSON.stringify(true));
     // window.localStorage.setItem('´ððprice˝˝africell', JSON.stringify(Number(data.afriprice)));

     if (africell == 0) {
      setOpen(true);
     } else {

      secureLocalStorage.setItem("^&@##africel!!@@^^#", africell);
     }

    } else {

     if (africell == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##africel!!@@^^#", africell);
     }

     secureLocalStorage.setItem("´ððchecked˝˝africell", false);
     secureLocalStorage.setItem("´ððprice˝˝africell", Number(data.afriprice));

     // window.localStorage.setItem('´ððchecked˝˝africell', JSON.stringify(false));
     // window.localStorage.setItem('´ððprice˝˝africell', JSON.stringify(Number(false)));
    }

    if (Number(data.orangeprice) > 0) {

     // window.localStorage.setItem('´ððchecked˝˝orange', JSON.stringify(true));
     // window.localStorage.setItem('´ððprice˝˝orange', JSON.stringify(Number(data.orangeprice)));

     secureLocalStorage.setItem("´ððchecked˝˝orange", true);
     secureLocalStorage.setItem("´ððprice˝˝orange", Number(data.orangeprice));

     if (orange == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##orange!!@@^^#", orange);
     }

    } else {

     if (orange == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##orange!!@@^^#", orange);
     }
     // window.localStorage.setItem('´ððchecked˝˝orange', JSON.stringify(false));
     // window.localStorage.setItem('´ððprice˝˝orange', JSON.stringify(Number(false)));

     secureLocalStorage.setItem("´ððchecked˝˝orange", false);
     secureLocalStorage.setItem("´ððprice˝˝orange", Number(false));

    }

    if (Number(data.airtelprice) > 0) {

     if (airtel == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##airtel!!@@^^#", airtel);
     }

     // window.localStorage.setItem('´ððchecked˝˝airtel', JSON.stringify(true));
     // window.localStorage.setItem('´ððprice˝˝airtel', JSON.stringify(Number(data.airtelprice)));


     secureLocalStorage.setItem("´ððchecked˝˝airtel", true);
     secureLocalStorage.setItem("´ððprice˝˝airtel", Number(data.airtelprice));


    } else {

     if (airtel == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##airtel!!@@^^#", airtel);
     }

     // window.localStorage.setItem('´ððchecked˝˝airtel', JSON.stringify(false));
     // window.localStorage.setItem('´ððprice˝˝airtel', JSON.stringify(Number(false)));

     secureLocalStorage.setItem("´ððchecked˝˝airtel", false);
     secureLocalStorage.setItem("´ððprice˝˝airtel", Number(false));


    }

    if (Number(data.vodaprice) > 0) {

     if (voda == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##voda!!@@^^#", voda);
     }

     // window.localStorage.setItem('´ððchecked˝˝voda', JSON.stringify(true));
     // window.localStorage.setItem('´ððprice˝˝voda', JSON.stringify(Number(data.vodaprice)));


     secureLocalStorage.setItem("´ððchecked˝˝voda", true);
     secureLocalStorage.setItem("´ððprice˝˝voda", Number(data.vodaprice));


    } else {

     if (voda == 0) {
      setOpen(true);
     } else {
      secureLocalStorage.setItem("^&@##voda!!@@^^#", voda);
     }

     // window.localStorage.setItem('´ððchecked˝˝voda', JSON.stringify(false));
     // window.localStorage.setItem('´ððprice˝˝voda', JSON.stringify(Number(false)));

     secureLocalStorage.setItem("´ððchecked˝˝voda", false);
     secureLocalStorage.setItem("´ððprice˝˝voda", Number(false));


    }


    secureLocalStorage.setItem("´ððprice˝˝devise", checked);
    // window.localStorage.setItem('´ððprice˝˝devise', JSON.stringify(checked));

    window.setTimeout(() => {
     setDialog(true);
    }, 300);

   }


  }


 };


 let OpVoda = secureLocalStorage.getItem("´ððchecked˝˝voda")
 let OpAirtel = secureLocalStorage.getItem("´ððchecked˝˝airtel")
 let OpOrange = secureLocalStorage.getItem("´ððchecked˝˝orange")
 let OpAfricell = secureLocalStorage.getItem("´ððchecked˝˝africell")


 return (
  <div className='wrp-input-stock'>
   <div className='wrp-input-stock-box-prix'>

    <div className='wrp-input-stock-subbox-prix'>
     <div></div>

     <div className='wrp-devise-switch'>
      {checked ? <ReturnDevise IMA={'/img/dollars.png'} /> : <ReturnDevise IMA={'/img/franc.png'} />}

      <Switch
       checked={checked}
       onChange={handleChangeSwitch}
       inputProps={{ 'aria-label': 'controlled' }}
      />

     </div>

    </div>

    <div className='wrp-input-stock-subbox-prix'>
     {checked ?
      <div style={{ color: colorUsd }} className='stock-wallet-prix'>
       <span>{usdnumber}</span>
       <span className='margin-input-stock'>USD</span>
      </div>

      :
      <div style={{ color: colorCdf }} className='stock-wallet-prix'>
       <span>{cdfnumber}</span>
       <span className='margin-input-stock'>CDF</span>
      </div>
     }

     <div></div>
    </div>

    <div className='wrp-input-stock-subbox-prix'>
     <div></div>
     <h3>{str2}</h3>
    </div>

   </div>

   <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
    <ReturnTitle />

    <div className='wrp-network-stock'>
     <div className='afri-change'>
      <h2>Africell</h2>
      <input className='afri-change' autocomplete="off" type='number' placeholder={africount} {...register('africount')} step="0.01" pattern="[0-9]*" />
      <input className='afri-change' autocomplete="off" type='number' placeholder={afriprice} {...register('afriprice')} step="0.01" pattern="[0-9]*" />
     </div>
    </div>

    <div className='wrp-network-stock'>
     <div className='orange-change'>
      <h2>Orange</h2>
      <input className='orange-change' autocomplete="off" type='number' placeholder={orangecount} {...register('orangecount')} step="0.01" pattern="[0-9]*" />
      <input className='orange-change' autocomplete="off" type='number' placeholder={orangeprice} {...register('orangeprice')} step="0.01" pattern="[0-9]*" />
     </div>
    </div>

    <div className='wrp-network-stock'>
     <div className='airtel-change'>
      <h2>Airtel</h2>
      <input className='airtel-change' autocomplete="off" type='number' placeholder={airtelcount} {...register('airtelcount')} step="0.01" pattern="[0-9]*" />
      <input className='airtel-change' autocomplete="off" type='number' placeholder={airtelprice} {...register('airtelprice')} step="0.01" pattern="[0-9]*" />
     </div>

    </div>

    <div className='wrp-network-stock'>
     <div className='voda-change'>
      <h2>Vodacom</h2>
      <input className='voda-change' autocomplete="off" type='number' placeholder={vodacount} {...register('vodacount')} step="0.01" pattern="[0-9]*" />
      <input className='voda-change' autocomplete="off" type='number' placeholder={vodaprice} {...register('vodaprice')} step="0.01" pattern="[0-9]*" />
     </div>
    </div>

    <ReturnRate />

    <div className="progressbar-stock">
     <div className="progressbar">

      <div style={{
       height: "100%",
       width: `${filled}%`,
       backgroundColor: "#2ecc2e",
       transition: "width 1s"
      }}></div>


      <span className="progressPercent">{filled}%</span>
     </div>
    </div>

    <button className='Btn'>Suivant</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}
   >
    <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Désolé la quantité non prise en compte
       </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={dialog}
    onClose={dialogClose}
    scroll={scroll}
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
   >
    <DialogTitle id="scroll-dialog-title"><h4 className='pop-up'>Facturation</h4></DialogTitle>
    <DialogContent dividers={scroll === 'body'}>

     <DialogContentText
      id="scroll-dialog-description"
      ref={descriptionElementRef}
      tabIndex={-1}
     >

      {OpAfricell && <Billing devise={checked ? 'USD' : 'CDF'} devise={checked ? 'USD' : 'CDF'} Operator='Africell' prix={watch('afriprice')} count={checked ? Number(watch('afriprice')) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!africell") : Number(watch('afriprice')) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!africell")} />}
      {OpOrange && <Billing devise={checked ? 'USD' : 'CDF'} Operator='Orange' prix={watch('orangeprice')} count={checked ? Number(watch('orangeprice')) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!orange") : Number(watch('orangeprice')) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!orange")} />}
      {OpAirtel && <Billing devise={checked ? 'USD' : 'CDF'} Operator='Airtel' prix={watch('airtelprice')} count={checked ? Number(watch('airtelprice')) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!aitel") : Number(watch('airtelprice')) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!aitel")} />}
      {OpVoda && <Billing devise={checked ? 'USD' : 'CDF'} Operator='Vodacom' prix={watch('vodaprice')} count={checked ? Number(watch('vodaprice')) / secureLocalStorage.getItem("^^dollar->@%%#^@@@#!!voda") : Number(watch('vodaprice')) / secureLocalStorage.getItem("^^fran->@%%#^@@@#!!voda")} />}


     </DialogContentText>

    </DialogContent>

    <DialogActions>



     <div className='flex-between'>

      <Button onClick={dialogClose}><span className='pop-up'>Annuler</span></Button>
      <Button onClick={dialogConfirm}><span className='pop-up'>Valider</span></Button>
     </div>



    </DialogActions>
   </Dialog>

  </div>
 );
};

export const Billing = (props) => {
 return (
  <div className='view-dialog-billing'>

   <h2>{props.Operator}</h2>
   <div className='view-dialog-billing-quote'>

    <div className='dialog-count-left margin-dialog-count'>
     <h3>Montant</h3>
     <p>{props.prix} {props.devise}</p>
    </div>

    <div className='dialog-count-right'>
     <h3>Unite</h3>
     <p>{parseInt(Number(props.count))}</p>
    </div>

   </div>

   <div className='divider-dialog'></div>
  </div>

 );
};
