
import ReturnBoxWell from './Box';

// View with draw
export default function ReturnWithdrAw() {
 return (
  <div className='wrp-withdraw-succes'>
   <ReturnBoxWell />

  </div>
 );
};