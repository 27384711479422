
import './Logo.css';
// import ReturnTitle from './Title';

//  Logo Print Available
export default function ReturnLogo() {
 return (
  <div className='wrp-logo-print-tickets'>
   <img src={'/img/MUUNGANO4.png'} />
   {/* <ReturnTitle /> */}
  </div>
 );
};