import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TweenMax, Expo } from 'gsap';
import secureLocalStorage from "react-secure-storage";


// Return Fran 
export default function ReturnFrAn() {

 const navigation = useNavigate();
 let namegroup = secureLocalStorage.getItem("**tont>>name??")

 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**tont>>currency??", namegroup + 'cdf');
  navigation('/tontine/form/currency/cdf');

 }

 React.useEffect(() => {
  TweenMax.from('.AnimFran', 1.2, { delay: 1, opacity: 0, x: 20, ease: Expo.easeInOut })
 }, []);

 return (
  <div onClick={handlepath} className='devise-pret-money AnimFran'>
   <img src={'/img/franc.png'} />
   <p>CDF</p>
  </div>
 );
};