
import RetuRnTitle from './Title';
import './Middle.css';


// Middle Head 
export default function ReturnMiddlE() {
 return (
  <div className='grid-center'>
   <RetuRnTitle />
  </div>
 );
};