import './Send.css';
import ReturnQuOTe from './Quote';
import ReturnIMA from './IMA';
import REturnArroW from './Arrow';

// View Send Component 
export default function ReturnSend() {
 return (
  <div className='wrp-send-pret'>
   <REturnArroW />
   <ReturnQuOTe />
   <ReturnIMA />

  </div>
 );
}