import './Nav.css';
import ReturnBacK from './Back';
import ReturnViEw from './View';

// Return Nav Component Money
export default function ReturnNavigaTion() {
 return (
  <div className='wrp-nav-pret-slide'>
   <ReturnBacK />
   <ReturnViEw />
  </div>
 );
};