import './Credit.css';
import HeAd from './Head/Main';
import CReDiTbOdy from './Body/Main';
import FooTER from './Footer/Main';


// Credit Main Brokers
export default function RetuRnCReDit() {
 return (
  <div className='wrp-home-credit'>
   <HeAd />
   <CReDiTbOdy />
   <FooTER />
  </div>
 );
};