import React from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';




// ReturnCommi Return 
export default function ReturnCommi() {

 const [commiUsd, setCommiUsd] = React.useState(0);
 const [uniteStock, setUniteStock] = React.useState(0);

 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setCommiUsd(docSnap.data().commistockusd)
  } else {
   // docSnap.data() will be undefined in this case
   setCommiUsd(0)
  }

 }, [])
 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setUniteStock(docSnap.data().commistockunite)
  } else {
   // docSnap.data() will be undefined in this case
   setUniteStock(0)
  }

 }, [])

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })


 return (
  <div className='wrp-commi-box-unite-stock blue-bgcolor'>

   <p className='blue-bgcolor'>
    <span>
     {parseInt(uniteStock)} U /
    </span>
    <span>
     {euro(parseInt(commiUsd)).format()} USD
    </span>
   </p>

  </div>
 );
};