
import './Quote.css';

// Return Quote Component
export default function ReturnQuOte() {
 return (
  <div className='wrp-qt-broker-unite'>
   <p>
    Sélectionnez les opérateurs que vous souhaitez créditer
   </p>
  </div>
 );
};