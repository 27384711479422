import './Box.css';
import ReturnProfil from './Profil';

// Return Box Component
export default function ReturnBoX() {
 return (
  <div className='box-dashed'>
   <ReturnProfil />
  </div>
 );
};