import React from 'react';
import secureLocalStorage from "react-secure-storage";

// Title Return Compoennt
export default function ReturnDateClienT() {
 return (
  <div className='wrp-title-print-tickets-client'>
   <h2>DATE : </h2>
   <h2>{secureLocalStorage.getItem("@dateª©#&&++#")}</h2>
  </div>
 );
};