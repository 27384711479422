import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { doc, getDocs, collection, updateDoc, increment, onSnapshot, arrayUnion, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../firebase';
import REturnQuOte from './Quote';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { reactLocalStorage } from 'reactjs-localstorage';
import secureLocalStorage from "react-secure-storage";

import { CiWifiOff } from "react-icons/ci";
import { v4 } from 'uuid';
import currency from 'currency.js';
import axios from 'axios';




export let nowField = moment().date();
export let now = moment().date();

export let prixHash = 0;
export let hashTrue = true;

let timespinner = 6570;
let key = v4();



// Input Field Component 
export default function ReturnInput() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <SreenLarge />}
          {matches.large && <SreenLarge />}
        </>
      )}
    </Media>
  );
};

export const SreenLarge = () => (
  <div className='wrp-form-input-pin'>
    <REturnQuOte />
    <FormInput />

  </div>
);
export const ScreenSmall = () => (
  <div className='wrp-form-input-pin'>
    <REturnQuOte />
    <FormInput />
  </div>
);


export const FormInput = () => {


  let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
  let password = secureLocalStorage.getItem("#$$@%%api$$pasword");



  let pushDocs = new Array();
  let verifierSend;
  let verifierGet;


  const [view, setView] = React.useState(true);
  const [wifi, setWifi] = React.useState(false);

  const [problem, setProblem] = React.useState(false);
  const [blem, setBlem] = React.useState(false);


  const [rate, setRate] = React.useState(null);
  const [load, setLoad] = React.useState(false);
  const navigation = useNavigate();
  const { handleSubmit, reset, control } = useForm({});


  // send React useState  
  const [pin, setPin] = React.useState(null);
  const [state, setState] = React.useState(null);


  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState('');

  const [soldeMain, setSoldeMain] = React.useState(null);
  const [profil, setProfil] = React.useState(null);
  const [thrift, setThrift] = React.useState(null);

  const [frais, setFrais] = React.useState(null);

  // getting React useState
  const [getstate, setGetstate] = React.useState(null);
  const [getfirst, setGetFirst] = React.useState('');
  const [getlast, setGetLast] = React.useState('');
  const [price, setPrice] = React.useState(null);
  const [getProfil, setGetProfil] = React.useState(null);
  const [getState, setGetState] = React.useState(null);
  const [getthrift, setGetThrift] = React.useState(null);
  const [getfrais, setGetfrais] = React.useState(null);


  const [signature, setSignature] = React.useState(null);
  const [signaturetype, setSignaturetype] = React.useState(null);
  const [signaturemoney, setSignaturemoney] = React.useState(null);
  const [signaturekey, setSignaturekey] = React.useState(null);
  const [signaturefrais, setSignaturefrais] = React.useState(null);


  const [getsignature, setGetsignature] = React.useState(null);
  const [getsignaturetype, setGetsignaturetype] = React.useState(null);
  const [getsignaturemoney, setGetsignaturemoney] = React.useState(null);
  const [getsignaturekey, setGetsignaturekey] = React.useState(null);
  const [getsignaturefrais, setGetsignaturefrais] = React.useState(null);


  const [open, setOpen] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [click, setClick] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [showPassword, setShowPassword] = React.useState(false);
  const [getTeam, setGetTeam] = React.useState('');
  const [sendTeam, setSendTeam] = React.useState('');


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  const handleClose = () => {
    setOpen(false);
  };
  const cancelClose = () => {
    setCancel(false);
    setClick(true);
  };
  const handlewifiClose = () => {
    setWifi(false);
  };
  const handleproblemClose = () => {
    setProblem(false);
    navigation('/dash');
  };
  const handleblemClose = () => {
    setBlem(false);
    navigation('/dash');
  };


  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
      setRate(doc.data().rate)
    });

  }, []);
  // Form send AGENT
  React.useEffect(async () => {


    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      pushDocs.push(doc.id);
    });

    verifierSend = pushDocs.some(value => value == secureLocalStorage.getItem("USER"));
    verifierGet = pushDocs.some(value => value == secureLocalStorage.getItem("A@@ph$$&-@#"));

    const unsub = onSnapshot(doc(db, verifierSend ? "client" : "agent", secureLocalStorage.getItem("USER")), (doc) => {

      setPin(doc.data().code);
      setState(doc.data().state);
      setFirst(doc.data().firstname);
      setLast(doc.data().lastname);
      setSoldeMain(doc.data().usd);
      setSendTeam(doc.data().team);
      setProfil(doc.data().profile);
      setThrift(doc.data().thriftusd);
      setFrais(doc.data().frais);

      setSignature(doc.data().signatureusd);
      setSignaturetype(doc.data().signatureusdtype);
      setSignaturemoney(doc.data().signaturemoneyusd);
      setSignaturekey(doc.data().signaturekeyusd);
      setSignaturefrais(doc.data().signaturefraisusd);

    });
    const unsubget = onSnapshot(doc(db, pushDocs.includes(secureLocalStorage.getItem("A@@ph$$&-@#")) ? "client" : "agent", secureLocalStorage.getItem("A@@ph$$&-@#")), (doc) => {

      setGetstate(doc.data().state);
      setGetFirst(doc.data().firstname);
      setGetLast(doc.data().lastname);
      setPrice(doc.data().usd);
      setGetTeam(doc.data().team);
      setGetProfil(doc.data().profile);
      setGetState(doc.data().state);
      setGetThrift(doc.data().thriftusd);
      setGetfrais(doc.data().frais);

      setGetsignature(doc.data().signatureusd);
      setGetsignaturetype(doc.data().signatureusdtype);
      setGetsignaturemoney(doc.data().signaturemoneyusd);
      setGetsignaturekey(doc.data().signaturekeyusd);
      setGetsignaturefrais(doc.data().signaturefraisusd);

    });

  }, []);



  let sendagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
  let getagent = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
  let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


  // From get AGENT
  let othersendagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
  let othergetagent = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
  let checkotheragent = getsignaturetype === 'envoyer' ? othersendagent === getsignature : othergetagent === getsignature;


  // ===========================================

  // From send CLIENT
  let sendclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney) + Number(signaturefrais))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
  let getclient = `${secureLocalStorage.getItem("USER")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
  let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;


  // From get CLIENT
  let sendotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) + Number(getsignaturemoney) + Number(getsignaturefrais))}${parseInt((Number(price) + Number(getsignaturemoney)) - Number(getsignaturemoney))}${getsignaturekey}`;
  let getotherclient = `${secureLocalStorage.getItem("A@@ph$$&-@#")}${parseInt(getsignaturemoney)}${parseInt(Number(price) - Number(getsignaturemoney))}${parseInt((Number(price) - Number(getsignaturemoney)) + Number(getsignaturemoney))}${getsignaturekey}`;
  let checkotherclient = getsignaturetype === 'envoyer' ? sendotherclient === getsignature : getotherclient === getsignature;



  // window.console.log(checkagent + ' send agent ' + checkotherclient);
  // window.console.log(checkagent + ' send agent ' + checkotheragent);
  // window.console.log(checkclient + ' send client ' + checkotheragent);
  // window.console.log(checkclient + ' send client to client ' + checkotherclient);


  // window.console.log(sendagent);
  // window.console.log(signature);
  window.console.log(checkagent && checkotheragent);


  // window.console.log(getagent);
  // window.console.log(signature);

  // window.console.log(signaturetype);



  // window.console.log(signaturemoney + ' ' + soldeMain);
  // window.console.log(signaturemoney - soldeMain);

  //  window.console.log(soldeMain + ' ' + signaturemoney + ' ' + signaturefrais);
  //  window.console.log(soldeMain + signaturemoney + signaturefrais)


  // window.console.log(
  //   state + ' ' + first + ' ' + last + ' ' + soldeMain + ' ' + getstate + ' ' + getfirst + ' ' + getlast === ' ' + price + ' ' + profil + ' ' + getProfil + ' ' + getState + ' ' + thrift + ' ' + getthrift + ' ' + frais + ' ' + signature + ' ' + signaturetype + ' ' + signaturemoney + ' ' + getsignature + ' ' + getsignaturetype + ' ' + getsignaturetype + ' ' + getsignaturemoney + ' '
  // )


  const onSubmit = async (data) => {

    var eurocurrency = value => currency(value, { separator: ' ', decimal: '.', symbol: '' });

    setLoad(true);
    secureLocalStorage.setItem("@dateª©#&&++#", moment().format('LLLL'));
    reactLocalStorage.set('##^^@@%^***^^++=$', true);
    secureLocalStorage.setItem("@!vew*%%%!!!@@@", parseInt(Number(price)))

    // Checked if value code is length valid
    if (data.code === undefined || pin != data.code || pin == '000000' || soldeMain <= 1) {

      setOpen(true);
      setLoad(false);
      reset();

    } else {

      secureLocalStorage.setItem("&&837$$prnt@*#())", true);
      const sendUser = first.toLowerCase() + ' ' + last.toLowerCase();
      const getUser = getfirst.toLowerCase() + ' ' + getlast.toLowerCase();

      if (state === null || state === undefined || first === '' || first === undefined || last === '' || last === undefined || soldeMain === null || soldeMain === undefined || getstate === null || getstate === undefined || getfirst === '' || getfirst === undefined || getlast === '' || getlast === undefined || price === null || price === undefined || profil === null || profil === undefined || getProfil === null || getProfil === undefined || getState === null || getState === undefined || thrift === null || thrift === undefined || getthrift === null || getthrift === undefined || frais === null || frais === undefined || signature === null || signature === undefined || signaturetype === null || signaturetype === undefined || signaturemoney === null || signaturemoney === undefined || getsignature === null || getsignature == undefined || getsignaturetype == null || getsignaturetype == undefined || getsignaturemoney == null || getsignaturemoney == undefined) {

        setWifi(true);
        setLoad(false);

      } else {

        if (state == "agent" && getstate == "agent") {

          if (checkagent && checkotheragent) {

            setView(false);

            if (sendTeam === 'mere') {

              if (sendTeam === 'mere' && getTeam === 'mere') {

                window.localStorage.setItem('@ª©##', JSON.stringify(false));
                window.localStorage.setItem('@cost##', JSON.stringify(true));

                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");

                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const unite = secureLocalStorage.getItem("@unite!#!");
                const money = secureLocalStorage.getItem("@solde!#!");
                let comm = 0;


                let soldMainMoney = Number(soldeMain) - Number(money);
                let priceMoney = Number(price) + Number(money);


                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, 0, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, soldMainMoney);

                swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);



                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner);

              } else {

                window.localStorage.setItem('@ª©##', JSON.stringify(false));
                window.localStorage.setItem('@cost##', JSON.stringify(true));

                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");

                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const unite = secureLocalStorage.getItem("@unite!#!");
                const money = secureLocalStorage.getItem("@solde!#!");


                let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$spagtversagt%%") / 100;


                let retraitAPI = `Retrait de ${money} ${unite} est effectue par le ${getPhone} ${getlast}, nouveau solde: ${soldeMain - money} ${unite}.`;
                let depotAPI = `Depot de ${money} ${unite} est effectue par le ${sendPhone} ${last}, nouveau solde: ${price + money} ${unite}.`;


                window.console.log(depotAPI)
                window.console.log(retraitAPI)

                let soldMainMoney = Number(soldeMain) - Number(money);
                let priceMoney = Number(price) + Number(money);


                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, comm, 0, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, soldMainMoney);
                swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);



                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner);

              }

            } else {


              window.localStorage.setItem('@ª©##', JSON.stringify(false));
              window.localStorage.setItem('@cost##', JSON.stringify(true));

              hashTrue = false;
              prixHash = secureLocalStorage.getItem("@solde!#!");

              const sendPhone = secureLocalStorage.getItem("USER");
              const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
              const unite = secureLocalStorage.getItem("@unite!#!");
              const money = secureLocalStorage.getItem("@solde!#!");
              const main = secureLocalStorage.getItem("@main!#!");

              let comm = money * 0 / 100;


              let soldMainMoney = Number(soldeMain) - Number(money);
              let priceMoney = Number(price) + Number(money);


              swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, 0, key, 'USD', price, priceMoney);
              swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, soldMainMoney);
              swapInWithDocsAgent(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);

              window.setTimeout(() => {
                navigation('/send-success-usd');
              }, timespinner);

            }


          } else {

            if (checkagent == false) {

              setProblem(true);
              setLoad(false);

            } else {

              setBlem(true);
              setLoad(false);

            }

          }

        } else if (state == "client" && getstate == "client") {

          if (checkclient && checkotherclient) {

            setView(false);

            window.localStorage.setItem('@ª©##', JSON.stringify(false));

            hashTrue = false;
            prixHash = secureLocalStorage.getItem("@solde!#!");


            const sendPhone = secureLocalStorage.getItem("USER");
            const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
            const money = secureLocalStorage.getItem("@solde!#!");
            const unite = secureLocalStorage.getItem("@unite!#!");
            const frais = secureLocalStorage.getItem("@frais!#!");
            const main = secureLocalStorage.getItem("@main!#!");


            let priceMoney = Number(price) + Number(money);


            swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'USD', price, price + money);
            swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, main);
            isSwapInWithClientToClient(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, key, soldeMain, price);



            let number = secureLocalStorage.getItem("USER");
            let phonesend = `243${number.slice(-9)}`;

            let numbergetter = secureLocalStorage.getItem("A@@ph$$&-@#");
            let phonegetter = `243${numbergetter.slice(-9)}`;

            let depotAPI = `Vous avez recu ${(money)} ${unite} de ${first.toString().toUpperCase()} ${last.toString().toUpperCase()} ${sendPhone}. Nouveau solde: ${parseInt(priceMoney)} ${unite}.`;
            let retraitAPI = `Votre transfert de ${(money)} ${unite} est effectue par ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()} ${getPhone}. Frais: ${(frais)} ${unite}. Nouveau solde: ${parseInt(main)} ${unite}.`;



            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonegetter}&textmessage=${depotAPI}`,
              headers: {}
            };

            let transfertconfig = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
              headers: {}
            };


            axios.request(config)
              .then((response) => {
                console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });

            axios.request(transfertconfig)
              .then((response) => {
                console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });



            window.setTimeout(() => {
              navigation('/send-success-usd');
            }, timespinner)



          } else {

            if (checkclient == false) {

              setProblem(true);
              setLoad(false);

            } else {

              setBlem(true);
              setLoad(false);

            }

          }

        } else {

          if (state == "agent") {

            if (checkagent && checkotherclient) {

              setView(false);

              if (sendTeam === 'mere') {

                window.localStorage.setItem('@ª©##', JSON.stringify(false));

                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");


                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const money = secureLocalStorage.getItem("@solde!#!");
                const unite = secureLocalStorage.getItem("@unite!#!");


                let soldMainMoney = Number(soldeMain) - Number(money)
                let priceMoney = Number(price) + Number(money)


                let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$versclients%%") / 100;

                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, Number(comm), 0, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, soldMainMoney);
                swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);



                let number = secureLocalStorage.getItem("A@@ph$$&-@#");
                let phonesend = `243${number.slice(-9)}`;
                let depotAPI = `Depot de ${(money)} ${unite} est effectue par le ${sendPhone} ${first.toString().toUpperCase()} ${last.toString().toUpperCase()}. Nouveau solde: ${parseInt(priceMoney)} ${unite}.`;

                let config = {
                  method: 'get',
                  maxBodyLength: Infinity,
                  url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
                  headers: {}
                };


                axios.request(config)
                  .then((response) => {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });




                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner)


              } else {



                window.localStorage.setItem('@ª©##', JSON.stringify(false));
                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");


                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const money = secureLocalStorage.getItem("@solde!#!");
                const unite = secureLocalStorage.getItem("@unite!#!");


                let soldMainMoney = Number(soldeMain) - Number(money)
                let priceMoney = Number(price) + Number(money)


                let comm = money * secureLocalStorage.getItem("#@@!!#*##frais@@**$versclients%%") / 100;

                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, soldMainMoney, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift + comm, comm, 0, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift, 0, 0, key, 'USD', soldeMain, soldMainMoney);
                swapInWithDocsAgentToClient(sendPhone, getPhone, sendUser, getUser, soldMainMoney, price, money, unite, price, Number(comm), soldeMain, key, price);


                let number = secureLocalStorage.getItem("A@@ph$$&-@#");
                let phonesend = `243${number.slice(-9)}`;
                let depotAPI = `Depot de ${(money)} ${unite} est effectue par le ${sendPhone} ${first.toString().toUpperCase()} ${last.toString().toUpperCase()}. Nouveau solde: ${parseInt(priceMoney)} ${unite}.`;


                let config = {
                  method: 'get',
                  maxBodyLength: Infinity,
                  url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${depotAPI}`,
                  headers: {}
                };

                axios.request(config)
                  .then((response) => {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });


                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner)

              }

            } else {

              if (checkagent == false) {

                setProblem(true);
                setLoad(false);

              } else {

                setBlem(true);
                setLoad(false);

              }

            }

          } else {

            if (checkclient && checkotheragent) {

              if (getTeam === 'mere') {

                window.localStorage.setItem('@ª©##', JSON.stringify(false));

                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");


                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const money = secureLocalStorage.getItem("@solde!#!");
                const unite = secureLocalStorage.getItem("@unite!#!");
                const frais = secureLocalStorage.getItem("@frais!#!");
                const main = secureLocalStorage.getItem("@main!#!");


                let priceMoney = Number(price) + Number(money)


                let comm = Number(money) * secureLocalStorage.getItem("#@@!!#*##frais@@**$client%%") / 100;


                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift + comm, comm, 0, key, 'USD', soldeMain, main);
                isSwapInWithClientToAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, Number(comm), soldeMain, signaturekey, price);


                let number = secureLocalStorage.getItem("USER");
                let phonesend = `243${number.slice(-9)}`;
                let retraitAPI = `Retrait de ${(money)} ${unite} est effectue par le ${getPhone} ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()}. Frais: ${(frais)} ${unite}. Nouveau solde: ${parseInt(main)} ${unite}.`;

                let config = {
                  method: 'get',
                  maxBodyLength: Infinity,
                  url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
                  headers: {}
                };


                axios.request(config)
                  .then((response) => {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner)


              } else {

                window.localStorage.setItem('@ª©##', JSON.stringify(false));

                hashTrue = false;
                prixHash = secureLocalStorage.getItem("@solde!#!");

                const sendPhone = secureLocalStorage.getItem("USER");
                const getPhone = secureLocalStorage.getItem("A@@ph$$&-@#");
                const money = secureLocalStorage.getItem("@solde!#!");
                const unite = secureLocalStorage.getItem("@unite!#!");
                const frais = secureLocalStorage.getItem("@frais!#!");
                const main = secureLocalStorage.getItem("@main!#!");


                let priceMoney = Number(price) + Number(money)


                let comm = Number(money) * secureLocalStorage.getItem("#@@!!#*##frais@@**$client%%") / 100;

                swapSendUser(sendPhone, `Transfért d'argent`, money, soldeMain, main, 'envoyer', moment().format(), getPhone, getfirst + ' ' + getlast, profil, thrift, thrift, 0, frais, key, 'USD', price, priceMoney);
                swapGetUser(getPhone, `Transfért d'argent`, money, price, priceMoney, 'Reçu', moment().format(), sendPhone, first + ' ' + last, getProfil, getthrift, getthrift + comm, comm, 0, key, 'USD', soldeMain, main);
                isSwapInWithClientToAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, price, Number(comm), soldeMain, signaturekey, price);



                let number = secureLocalStorage.getItem("USER");
                let phonesend = `243${number.slice(-9)}`;
                let retraitAPI = `Retrait de ${(money)} ${unite} est effectue par le ${getPhone} ${getfirst.toString().toUpperCase()} ${getlast.toString().toUpperCase()}. Frais: ${(frais)} ${unite}. Nouveau solde: ${parseInt(main)} ${unite}.`;

                let config = {
                  method: 'get',
                  maxBodyLength: Infinity,
                  url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${retraitAPI}`,
                  headers: {}
                };


                axios.request(config)
                  .then((response) => {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                window.setTimeout(() => {
                  navigation('/send-success-usd');
                }, timespinner);

              }

            } else {

              if (checkclient == false) {

                setProblem(true);
                setLoad(false);

              } else {

                setBlem(true);
                setLoad(false);

              }

            }

          }
        }

      }

    }



  };

  return (
    <>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <FormControl
          sx={{ width: '100%' }}

          variant="standard">
          <InputLabel htmlFor="standard-adornment-password"><div className='pop-up'>Pin actuel</div></InputLabel>

          <Controller
            name="code"
            control={control}
            render={({ field }) =>

              <Input
                id="standard-adornment-password"
                inputProps={{
                  autoComplete: "off", inputMode: 'numeric'
                }}

                {...field}
                type={showPassword ? 'numeric' : 'password'}


                endAdornment={
                  <InputAdornment position="end">

                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>

                  </InputAdornment>
                }

              />}
          />

        </FormControl>


        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}>

          <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                PIN, Incorrect
        </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        {view && <button className='Btn'>Envoi</button>}

      </form>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={wifi}
        onClose={handlewifiClose}>

        <DialogContent>

          <DialogContentText>
            <div className='block-flex-center-wifi'>
              <CiWifiOff size={'2em'} color={'crimson'} />
              <p className='pop-up'>
                Connection internet faible

        </p>
            </div>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={problem}
        onClose={handleproblemClose}>
        <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
        <DialogContent>

          <DialogContentText>

            <p className='info-detect'>

              Votre compte USD a été suspendu suite à une situation de litige
              contacter cashbeck pour plus d'informations

								</p>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={blem}
        onClose={handleblemClose}>
        <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
        <DialogContent>

          <DialogContentText>

            <p className='info-detect'>

              le compte USD de votre bénéficiaire a été suspendu

								</p>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleblemClose}><span className='pop-up'>Fermer</span></Button>
        </DialogActions>
      </Dialog>



    </>
  );
};


async function swapInWithDocsAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, unite, solde, comm, before, key, getbefore) {


  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

  let time = moment().format('LLL');
  let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }



  const sendRef = doc(db, "agent", sendPhone);
  await updateDoc(sendRef, {
    usd: Number(main),
    money: Number(money),
    user: getUser,
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),
    swap: arrayUnion(send),
    commission: Number(comm),


    signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: 0,
    thriftusd: increment(Number(comm)),

    frais: 0,
    sendtype: 'envoyer',

  });


  const getRef = doc(db, "agent", getPhone);
  await updateDoc(getRef, {
    usd: Number(money) + Number(price),
    money: Number(money),
    user: sendUser,
    unite: unite,
    phoneclient: sendPhone,
    delay: moment().format(),


    signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: 0,
    thriftcdf: increment(Number(comm)),
    swap: arrayUnion(get),
    frais: 0,
    commission: Number(comm),
    sendtype: 'Reçu'
  });


  delete window.swapInWithDocsAgentForSuper;


};
async function swapInWithDocsAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, unite, solde, comm, before, key, getbefore) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');
  let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }



  const sendRef = doc(db, "agent", sendPhone);
  await updateDoc(sendRef, {
    usd: (main),
    money: money,
    user: getUser,
    unite: unite,
    phoneclient: getPhone,


    signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    delay: moment().format(),
    swap: arrayUnion(send),
    commission: Number(comm),
    frais: 0,
    sendtype: 'envoyer',
    thriftusd: increment(Number(comm))
  });

  const getRef = doc(db, "agent", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(money),
    money: money,
    user: sendUser,
    unite: unite,
    phoneclient: sendPhone,
    delay: moment().format(),


    signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    swap: arrayUnion(get),
    frais: 0,
    commission: 0,
    sendtype: 'Reçu'
  });



  delete window.swapInWithDocsAgent;

};
async function swapInWithDocsAgentForSuperToClient(sendPhone, getPhone, sendUser, getUser, main, price, prix, unite, solde, comm, before, key, getbefore) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');
  let send = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + prix).format(), actual: euro(solde + prix).format() + ' ' + unite, unite: unite }







  const sendRef = doc(db, "agent", sendPhone);
  await updateDoc(sendRef, {
    usd: Number(main),
    money: prix,
    user: getUser,
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),


    signatureusd: `${sendPhone}${parseInt(Number(prix))}${parseInt(Number(before))}${parseInt(Number(before) - Number(prix))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: parseInt(Number(prix)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    swap: arrayUnion(send),
    thriftusd: increment(Number(comm)),
    commission: Number(comm),
    frais: 0,
    sendtype: 'envoyer'
  });

  const getRef = doc(db, "client", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(prix),
    money: prix,
    user: sendUser,
    unite: unite,
    phoneclient: sendPhone,


    signatureusd: `${getPhone}${Number(prix)}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(prix))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: parseInt(Number(prix)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    delay: moment().format(),
    swap: arrayUnion(get),
    frais: 0,
    sendtype: 'Reçu'
  });


  delete window.swapInWithDocsAgentForSuperToClient;

};
async function swapInWithDocsAgentToClient(sendPhone, getPhone, sendUser, getUser, main, price, prix, unite, solde, comm, before, key, getbefore) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');

  let send = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(prix).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + prix).format(), actual: euro(solde + prix).format() + ' ' + unite, unite: unite }



  const sendRef = doc(db, "agent", sendPhone);
  await updateDoc(sendRef, {
    usd: Number(main),
    money: prix,
    user: getUser,
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),


    signatureusd: `${sendPhone}${parseInt(Number(prix))}${parseInt(Number(before))}${parseInt(Number(before) - Number(prix))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: parseInt(Number(prix)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    swap: arrayUnion(send),
    thriftusd: increment(Number(comm)),
    commission: Number(comm),
    frais: 0,
    sendtype: 'envoyer'
  });

  const getRef = doc(db, "client", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(prix),
    money: prix,
    user: sendUser,
    unite: unite,
    phoneclient: sendPhone,
    delay: moment().format(),
    swap: arrayUnion(get),


    signatureusd: `${getPhone}${parseInt(Number(prix))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(prix))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: parseInt(Number(prix)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    frais: 0,
    sendtype: 'Reçu'
  });

  delete window.swapInWithDocsAgentToClient;

};
async function isSwapInWithClientToAgentForSuper(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, fraisAgent, before, key, getbefore) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');

  let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }

  // let fraisAdmin = frais;





  const sendRef = doc(db, "client", sendPhone);
  await updateDoc(sendRef, {
    usd: Number(main),
    money: Number(money),
    user: getUser,
    unite: unite,
    phoneclient: getPhone,


    signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: parseInt(Number(money)),
    signaturekeyusd: key,
    signaturefraisusd: frais,

    delay: moment().format(),
    swap: arrayUnion(send),
    frais: Number(frais),
    sendtype: 'envoyer'
  });

  const getRef = doc(db, "agent", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(money),
    money: Number(money),
    user: sendUser,

    unite: unite,
    thriftusd: increment(Number(fraisAgent)),
    signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: parseInt(Number(money)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    phoneclient: sendPhone,
    delay: moment().format(),
    swap: arrayUnion(get),
    frais: 0,
    sendtype: 'Reçu',
    commission: Number(fraisAgent)
  });



  delete window.isSwapInWithClientToAgentForSuper;

};
async function isSwapInWithClientToAgent(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, fraisAgent, before, key, getbefore) {

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');

  let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }






  const sendRef = doc(db, "client", sendPhone);
  await updateDoc(sendRef, {
    usd: Number(main),
    money: Number(money),
    user: getUser,
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),


    signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,

    signatureusdtype: 'envoyer',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: frais,

    swap: arrayUnion(send),
    frais: Number(frais),
    sendtype: 'envoyer'


  });

  const getRef = doc(db, "agent", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(money),
    money: Number(money),

    user: sendUser,
    unite: unite,
    thriftusd: increment(Number(fraisAgent)),

    signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getbefore))}${parseInt(Number(getbefore) + Number(money))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: Number(money),
    signaturekeyusd: key,
    signaturefraisusd: 0,


    phoneclient: sendPhone,
    delay: moment().format(),
    swap: arrayUnion(get),
    frais: 0,
    sendtype: 'Reçu',
    commission: Number(fraisAgent)
  });


  delete window.isSwapInWithClientToAgent;

};
async function isSwapInWithClientToClient(sendPhone, getPhone, sendUser, getUser, main, price, money, frais, unite, solde, key, before, getbefore) {


  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  let time = moment().format('LLL');

  let send = { date: time, solde: `${euro(money).format()} ${unite}`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(main).format(), actual: euro(main).format() + ' ' + unite, unite: unite }
  let get = { date: time, solde: `${euro(money).format()} ${unite}`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(solde + money).format(), actual: euro(solde + money).format() + ' ' + unite, unite: unite }






  const sendRef = doc(db, "client", sendPhone);
  await updateDoc(sendRef, {
    usd: (main),
    money: money,
    user: getUser,
    unite: unite,
    phoneclient: getPhone,


    signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(before))}${parseInt(Number(before) - Number(money) - Number(frais))}${key}`,
    signatureusdtype: 'envoyer',
    signaturemoneyusd: parseInt(Number(money)),
    signaturekeyusd: key,
    signaturefraisusd: frais,

    delay: moment().format(),
    swap: arrayUnion(send),
    frais: frais,
    sendtype: 'envoyer'

  });

  const getRef = doc(db, "client", getPhone);
  await updateDoc(getRef, {
    usd: Number(price) + Number(money),
    money: money,
    user: sendUser,
    unite: unite,
    phoneclient: sendPhone,
    delay: moment().format(),


    signatureusd: `${getPhone}${parseInt((money))}${parseInt((getbefore))}${parseInt((getbefore) + (money))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: parseInt(Number(money)),
    signaturekeyusd: key,
    signaturefraisusd: 0,

    swap: arrayUnion(get),
    frais: 0,
    sendtype: 'Reçu'
  });


  delete window.isSwapInWithClientToClient;


};


// swapGetUser
async function swapSendUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

  await setDoc(doc(db, "swap" + colSwap, key + date), {

    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    access: false,
    key: key + date,
    devise: devise,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    color: '#EFF7FE',
    dateofcreate: serverTimestamp(),

    statetrans: true,
    statepret: false,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,


  });

};
async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

  await setDoc(doc(db, "swap" + colSwap, key + date), {


    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key + date,
    devise: devise,
    access: false,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    color: '#EFF7FE',
    dateofcreate: serverTimestamp(),

    statetransed: true,
    statepret: false,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,

  });

};

