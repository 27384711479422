
// import REturnLogo from './Logo';
// import REturnQuOte from './Quote';
// import ReturnBtn from './Btn';
import ReturnIconSuccess from './IconSuccess';
import './RegisterSuccess.css';

import NavBar from './Nav/Main';

// Register Main Success
export default function ReturnRegisterSuccess() {
 return (
  <div className='wrp-succes-register'>


   <NavBar />
   <ReturnIconSuccess />
  </div>
 );
};