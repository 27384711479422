import React from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';




// ReturnCommi Return 
export default function ReturnCommi() {

 const [commiCdf, setCommiCdf] = React.useState(0);
 const [uniteStock, setUniteStock] = React.useState(0);


 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setCommiCdf(docSnap.data().commistockcdf)
  } else {
   // docSnap.data() will be undefined in this case
   setCommiCdf(0)
  }

 }, [])
 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setCommiCdf(docSnap.data().commistockcdf)
  } else {
   // docSnap.data() will be undefined in this case
   setCommiCdf(0)
  }

 }, [])
 React.useEffect(async () => {

  const docRef = doc(db, "agent", secureLocalStorage.getItem("USER"));
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
   setUniteStock(docSnap.data().commistockunitecdf)
  } else {
   // docSnap.data() will be undefined in this case
   setUniteStock(0)
  }

 }, [])


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

 return (
  <div className='wrp-commi-box-unite-stock'>
   <p>
    <span>
     {parseInt(uniteStock)} U /
    </span>
    <span>
     {euro(parseInt(commiCdf)).format()} CDF
    </span>
   </p>

  </div>
 );
};