
import './IMA.css';
import Media from 'react-media';


// IMA Background 
export default function ReturnDeposiTIMA() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='flex-deposite-image'>
  <ViEW />
 </div>
);
export const ScreenSmall = () => (
 <div
 >
  <img style={{ height: '55vh', width: '100vw', objectFit: 'cover' }} src='/img/rawImage.png' />
 </div>
);
export const ViEW = () => (
 <img src='/img/rawImage.png' />
);