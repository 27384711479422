import './Body.css';
import RetuRnIMA from './IMA';
import RetuRnSwitches from './Switch';
import RetuRnBtn from './Btn';


// Body  display grid
export default function RetuRnBody() {
 return (
  <div className='display-grid'>

   <RetuRnIMA />
   <RetuRnSwitches />
   <RetuRnBtn />

  </div>
 );
};