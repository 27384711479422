import './Model.css';
import HeAd from './Head/Main';
import ModelBody from './Body/Main';


// Model List groupe
export default function ReturnModEl() {
 return (
  <div className='grid-template-row'>
   <HeAd />
   <ModelBody />
   <div></div>
  </div>
 );
};