import React from 'react';
import { TweenMax, Power3 } from 'gsap';
import { useNavigate } from 'react-router-dom';

import { collection, getDocs, doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 } from 'uuid';



let pushClient = new Array();




// Btn Component Hm
export default function RetuRnBtn() {

  const navigation = useNavigate();
  const [arr, setArr] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [datecom, setDatecom] = React.useState(null);
  const [load, setLoad] = React.useState(false);

  const [vodausd, setVodausd] = React.useState(null);
  const [vodacdf, setVodacdf] = React.useState(null);

  const [orangeusd, setOrangeusd] = React.useState(null);
  const [orangecdf, setOrangecdf] = React.useState(null);

  const [airtelusd, setAirtelusd] = React.useState(null);
  const [airtelcdf, setAirtelcdf] = React.useState(null);

  const [africellusd, setAfricellusd] = React.useState(null);
  const [africellcdf, setAfricellcdf] = React.useState(null);
  const [stock, setStock] = React.useState(null);


  let color = JSON.parse(window.localStorage.getItem('^^blue--color≥'));



  React.useEffect(() => {
    TweenMax.from('.wrp-btn-brokers button', 1, { delay: 1.4, opacity: 0, x: 0, ease: Power3.easeIn })
  }, []);
  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushClient.push(doc.id);

    });

    setArr(pushClient);

  }, []);
  React.useEffect(async () => {

    const docRef = doc(db, arr.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setDatecom(docSnap.data().commistockedate)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

  }, []);

  React.useEffect(async () => {
    const unsub = onSnapshot(doc(db, "muunganoratesimple", "vodacom"), (doc) => {

      setVodausd(doc.data().usd);
      setVodacdf(doc.data().cdf);

    });

  }, []);
  React.useEffect(async () => {
    const unsub = onSnapshot(doc(db, "muunganoratesimple", "orange"), (doc) => {

      setOrangeusd(doc.data().usd);
      setOrangecdf(doc.data().cdf);

    });

  }, []);
  React.useEffect(async () => {
    const unsub = onSnapshot(doc(db, "muunganoratesimple", "airtel"), (doc) => {

      setAirtelusd(doc.data().usd);
      setAirtelcdf(doc.data().cdf);

    });

  }, []);
  React.useEffect(async () => {
    const unsub = onSnapshot(doc(db, "muunganoratesimple", "africell"), (doc) => {
      setAfricellusd(doc.data().usd);
      setAfricellcdf(doc.data().cdf);

    });

  }, []);
  React.useEffect(async () => {
    const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
      setStock(doc.data().stock);
    });

  }, []);




  //  window.console.log(
  //   datecom + ' ' + vodausd + ' ' + orangeusd + ' ' + airtelusd + ' ' + stock + ' '
  //  );


  const handlepath = async (event) => {

    event.preventDefault();

    if (vodausd === null || vodausd === undefined || orangeusd === null || orangeusd === undefined || airtelusd === null || airtelusd === undefined || stock === null || stock === undefined) {
      window.location.href = '/dash';

    } else {


      setLoading(true);
      setLoad(true);

      secureLocalStorage.setItem("@#access@@^^rate!@!!^^&", true);
      secureLocalStorage.setItem("@#5@@date!@!!^^&", moment().format());

      secureLocalStorage.setItem("@usd#5@@simple!@!!^^&voda", vodausd);
      secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&voda", vodacdf);

      secureLocalStorage.setItem("@usd#5@@simple!@!!^^&orange", orangeusd);
      secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&orange", orangecdf);

      secureLocalStorage.setItem("@usd#5@@simple!@!!^^&airtel", airtelusd);
      secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&airtel", airtelcdf);

      secureLocalStorage.setItem("@usd#5@@simple!@!!^^&africell", africellusd);
      secureLocalStorage.setItem("@cdf#5@@simple!@!!^^&africell", africellcdf);

      secureLocalStorage.setItem("#@%^^^@@&&taux##stock", stock);
      secureLocalStorage.setItem("#@%##key!!##", v4());


      secureLocalStorage.setItem("#@%$#%team", 'next');


      if (arr.includes(secureLocalStorage.getItem("USER"))) {

        if (datecom === undefined) {

          const washingtonRef = doc(db, arr.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
          // Set the "stock " field of the city 'DC' date
          await updateDoc(washingtonRef, {
            commistockedate: moment().month()
          });

        } else {

          if (datecom != moment().month()) {

            const washingtonRef = doc(db, arr.includes(secureLocalStorage.getItem("USER")) ? "client" : "agent", secureLocalStorage.getItem("USER"));
            // Set the "stock " field of the city 'DC' date
            await updateDoc(washingtonRef, {
              commistockedate: moment().month(),
              commistockcdf: 0,
              commistockusd: 0,
              commistockunite: 0,
              commistockunitecdf: 0

            });

          }
          else {
            window.console.log('continue');

          }

        }

      };

      window.setTimeout(() => {
        navigation('/brokers/unite/select');
      }, 2300);


    }

  };


  return (
    <>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className='wrp-btn-brokers'>
        <button onClick={handlepath} className='Btn'>
          Continue
   </button>
      </div>
    </>
  );
};