import './Devise.css';
import ReturnSelect from './Select';

// View
export default function ReturnStocKDevise() {
 return (
  <div className='wrp-devise-stock'>
   <ReturnSelect />
  </div>
 )
}